import {
    Circle,
    Database,
    Home,
    Mail,
    User,
    File,
    FileText,
    Archive
} from 'react-feather';

export const homeMenu = [
    {
        name: 'Home',
        icon: <Home strokeWidth={2} size={16} style={{ color: '#2c3e50' }} />,
        path: '/',
        role: ['USER', 'SUPERADMIN'],
        key: 'menu-home'
    },
]

export const incomingDocumentMenu = [
    {
        name: 'Incoming Document',
        icon: <Mail strokeWidth={2} size={16} style={{ color: '#2c3e50' }} />,
        // path: '/surat-masuk',
        path: '/incoming-document',
        role: ['USER', 'SUPERADMIN'],
        key: 'menu-incoming-document'
    },
]

export const outgoingDocumentMenu = [
    {
        name: 'Outgoing Document',
        icon: <Mail strokeWidth={2} size={16} style={{ color: '#2c3e50' }} />,
        // path: '/surat-keluar',
        path: '/outgoing-document',
        role: ['USER', 'SUPERADMIN'],
        key: 'menu-outgoing-document'
    },
]

export const documentManagementMenu = [
    {
        name: 'Document Management',
        icon: <Database strokeWidth={2} size={16} style={{ color: '#2c3e50' }} />,
        path: '/file-manager',
        role: ['USER', 'SUPERADMIN'],
        key: 'menu-document-management'
    },
]

export const manageDocumentSharedMenu = [
    {
        name: 'Manage Document Shared',
        icon: <Database strokeWidth={2} size={16} style={{ color: '#2c3e50' }} />,
        path: '/shared-document',
        role: ['USER', 'SUPERADMIN'],
        key: 'menu-mangae-document-shared'
    },
]

export const organizationMenu = [
    {
        name: 'Organization',
        icon: <User strokeWidth={2} size={16} style={{ color: '#2c3e50' }} />,
        children: [
            {
                icon: <Circle strokeWidth={2} size={10} style={{ color: '#2c3e50' }} />,
                path: '/organization/department',
                name: 'Department',
                role: ['SUPERADMIN'],
                key: 'menu-organization-department'
            },
            {
                icon: <Circle strokeWidth={2} size={10} style={{ color: '#2c3e50' }} />,
                path: '/organization/position',
                name: 'Position',
                role: ['SUPERADMIN'],
                key: 'menu-organization-position'
            },
            {
                icon: <Circle strokeWidth={2} size={10} style={{ color: '#2c3e50' }} />,
                path: '/organization/employee',
                name: 'Employee',
                role: ['SUPERADMIN'],
                key: 'menu-organization-employee'
            },
        ],
        key: 'menu-organization'
    },
]

export const masterMenu = [
    {
        name: 'Master',
        icon: <Database strokeWidth={2} size={16} style={{ color: '#2c3e50' }} />,
        children: [
            // {
            //     icon: <Circle strokeWidth={2} size={10} />,
            //     path: '/master/document-type',
            //     name: 'Document Type',
            //     role: ['SUPERADMIN']
            // },
            // {
            //     icon: <Circle strokeWidth={2} size={10} />,
            //     path: '/master/document-number',
            //     name: 'Document Number',
            //     role: ['SUPERADMIN']
            // },
            // {
            //     icon: <Circle strokeWidth={2} size={10} />,
            //     path: '/master/document-option',
            //     name: 'Document Option',
            //     role: ['SUPERADMIN']
            // },
            // {
            //     icon: <Circle strokeWidth={2} size={10} />,
            //     path: '/master/document-priority',
            //     name: 'Document Priority',
            //     role: ['SUPERADMIN']
            // },
            // {
            //     icon: <Circle strokeWidth={2} size={10} />,
            //     path: '/master/courier',
            //     name: 'Courier',
            //     role: ['SUPERADMIN']
            // },
            // {
            //     icon: <Circle strokeWidth={2} size={10} />,
            //     path: '/master/expedition',
            //     name: 'Status Expedition',
            //     role: ['SUPERADMIN']
            // },
            {
                icon: <Circle strokeWidth={2} size={10} style={{ color: '#2c3e50' }} />,
                path: '/master/archive-location',
                name: 'Archive Location',
                role: ['SUPERADMIN'],
                key: 'menu-master-archive-location'
            },
            // {
            //     icon: <Circle strokeWidth={2} size={10} />,
            //     path: '/master/action-disposition',
            //     name: 'Action Disposition',
            //     role: ['SUPERADMIN']
            // },
            // {
            //     icon: <Circle strokeWidth={2} size={10} />,
            //     path: '/master/response-disposition',
            //     name: 'Respon Disposition',
            //     role: ['SUPERADMIN']
            // },
            // {
            //     icon: <Circle strokeWidth={2} size={10} />,
            //     path: '/master/menu',
            //     name: 'Menu',
            //     role: ['SUPERADMIN']
            // },
            {
                icon: <Circle strokeWidth={2} size={10} style={{ color: '#2c3e50' }} />,
                path: '/master/folder',
                name: 'OCR Folder',
                role: ['SUPERADMIN'],
                key: 'menu-master-ocr-folder',
            },
            // {
            //     icon: <Circle strokeWidth={2} size={10} />,
            //     path: '/master/file-manager',
            //     name: 'File Manager',
            //     role: ['SUPERADMIN']
            // },
        ],
        key: 'menu-master'
    },
]

export const documentLog = [
    {
        name: 'Document Log',
        icon: <File strokeWidth={2} size={16} style={{ color: '#2c3e50' }} />,
        path: '/document-log',
        role: ['SUPERADMIN'],
        key: 'menu-document-log'
    },
]

export const documentProcessLog = [
    {
        name: 'Document Process Log',
        icon: <File strokeWidth={2} size={16} style={{ color: '#2c3e50' }} />,
        path: '/document-process-log',
        role: ['SUPERADMIN', 'ADMIN', 'USER'],
        key: 'menu-document-process-log'
    },
]

export const rolePermissions = [
    {
        name: 'Role Permission',
        icon: <User strokeWidth={2} size={16} style={{ color: '#2c3e50' }} />,
        path: '/roles-permissions/roles',
        role: ['SUPERADMIN'],
        key: 'menu-role-permission'
    },
]

export const documentLoaning = [
    {
        name: 'Document Loaning',
        icon: <FileText strokeWidth={2} size={16} style={{ color: '#2c3e50' }} />,
        path: '/document-loan',
        role: ['SUPERADMIN', 'ADMIN', 'USER'],
        key: 'menu-document-loaning'
    }
]

export const manageArchive = [
    {
        name: 'Manage Archive',
        icon: <Archive strokeWidth={2} size={16} style={{ color: '#2c3e50' }} />,
        path: '/manage-archive',
        role: ['SUPERADMIN', 'ADMIN'],
        key: 'menu-manage-archive'
    }
]


export const documentReturn = [
    {
        name: 'Document Return',
        icon: <FileText strokeWidth={2} size={16} style={{ color: '#2c3e50' }} />,
        path: '/document-return',
        role: ['SUPERADMIN', 'ADMIN'],
        key: 'menu-document-return'
    }
]

export const loanLog = [
    {
        name: 'Loan Log',
        icon: <File strokeWidth={2} size={16} style={{ color: '#2c3e50' }} />,
        path: '/loan-log',
        role: ['SUPERADMIN', 'ADMIN', 'USER'],
        key: 'menu-loan-log'
    }
]