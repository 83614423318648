import React, { useState, useEffect } from 'react';
import { Card, Typography, Col, Row, message, Space, Tag, Button, Tabs, Badge } from 'antd';
import { Mail, Send } from 'react-feather';
import { DataTable, PageHeader, Spinner, TextButton, DetailModal } from '../../components';
import StatCard from '../../components/shared/StatCard';
import { ApiService } from '../../services/ApiService';
import Avatar from 'antd/lib/avatar/avatar';
import { currentUser } from '../../utils/authData';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/id';

const { TabPane } = Tabs;
const { Text } = Typography;
const defaultTab = (sessionStorage.getItem('selectedDashboardTab')) ? sessionStorage.getItem('selectedDashboardTab') : 'receive'

const DokumenMasuk = () => {
    const [loaded, setLoaded] = useState(true)
    const [incomingMailCreate, setIncomingMailCreate] = useState([])
    const [incomingMailReceive, setIncomingMailReceive] = useState([])
    const [dispositionMail, setDispositionMail] = useState([])
    const [incomingMailById, setIncomingMailById] = useState(null)
    const [modalTitleCreate, setModalTitleCreate] = useState(null)
    const [modalTitleReceive, setModalTitleReceive] = useState(null)
    const [isModalCreateVisible, setIsModalCreateVisible] = useState(false)
    const [isModalReceiveVisible, setIsModalReceiveVisible] = useState(false)
    const [isRead, setIsRead] = useState([])
    const history = useHistory()
    const letterTypeCreate = []
    const letterTypeReceive = []
    const user = currentUser

    const fetchIncomingMailCreate = () => {
        setLoaded(false)
        const data = {
            user_id: user.id
        }
        ApiService.getList('incoming-mail-create', data).then((res) => {
            setIncomingMailCreate(res.data)
            for (let i = 0; i < res.data.length; i++) {
                letterTypeCreate.push(res.data[i].letter_type)
            }
            setLoaded(true)
        }).catch((err) => {
        })
    }

    const fetchIncomingMailReceive = () => {
        setLoaded(false)
        const data = {
            user_id: user.id
        }

        ApiService.getList('incoming-mail-receive', data).then((res) => {
            setIncomingMailReceive(res.data)
            for (let j = 0; j < res.data.length; j++) {
                letterTypeReceive.push(res.data[j].letter_type)
            }
            setIsRead(res.data[0].is_read)
            setLoaded(true)
        }).catch((err) => {
        })
    }

    const fetchDispositionMail = () => {
        setLoaded(false)
        const data = {
            user_id: user.id
        }

        ApiService.getList('disposition-mail-receive', data).then((res) => {
            setDispositionMail(res.data)
        }).catch((err) => {
        })

    }

    const edit = (v) => {
        setModalTitleCreate('Detail Pembuatan Dokumen Masuk')
        setIsModalCreateVisible(true)
        setIncomingMailById(v)
    }

    const updateRead = (v) => {
        setModalTitleReceive('Detail Dokumen Masuk')
        setIsModalReceiveVisible(true)
        const data = {

            user_id: user.id
        }

        ApiService.update('update-read', v.id, 0, data).then((res) => {
            setIsRead(1)
        }).catch((err) => {
        })
        setIncomingMailById(v)
    }


    const destroyAsReceiver = (id) => {
        setLoaded(false)
        ApiService.destroy('incoming-mail-receive', id).then((res) => {
            fetchIncomingMailReceive()
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const destroyAsCreate = (id) => {
        ApiService.destroy('incoming-mail-create', id).then((res) => {
            fetchIncomingMailCreate()
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    useEffect(() => {
        fetchIncomingMailCreate()
        fetchIncomingMailReceive()
        fetchDispositionMail()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '3%'
        },
        {
            title: 'Tipe Dokumen',
            render: (row) => (
                isRead === 0 ?
                    <Badge dot>
                        {row?.letter_type}
                    </Badge> :
                    row?.letter_type
            ),
            key: 'letter_type',
            align: 'center',
        },
        {
            title: 'Tanggal Dokumen',
            render: (row) => (
                moment(row?.date).locale('id').format('dddd, D MMMM YYYY')
            ),
            key: 'date',
            align: 'center',
            defaultSortOrder: 'descend',
        },
        {
            title: 'Nomor Dokumen',
            render: (row) => (
                row?.letter_number
            ),
            key: 'letter_number',
            align: 'center'
        },
        {
            title: 'Perihal',
            render: (row) => (
                row?.subject ? row?.subject : row?.letter_subject
            ),
            key: 'subject',
            align: 'center'
        },
        {
            title: 'Pengirim',
            render: (row) => (
                row?.from_user
            ),
            key: 'sender',
            align: 'center'
        },
        {
            title: 'Status Pengiriman',
            render: (row) => (
                <Tag color={row?.status === 0 ? "blue" : "green"}>{(row?.status === 0) ? 'Draft' : 'Terkirim'}</Tag>
            ),
            key: 'sending_status',
            align: 'center'
        },
        {
            title: 'Status Dokumen',
            render: (row) => (
                row?.type === 'DISP' || row?.type === 3 ?
                    row?.response_id === 0 || row?.response_id === null ?
                        <Tag color={"blue"}>Menunggu</Tag>
                        :
                        <Tag color={"green"}>Sudah Direspon</Tag>
                    :
                    <Tag color={"blue"}>Sudah Diterima</Tag>
            ),
            key: 'letter_status',
            align: 'center'
        },
        {
            title: 'Tipe Dokumen',
            render: (row) => (
                row?.type === 'DISP' ? <Tag color="green">Disposisi</Tag> : <Tag color="blue">Bukan Disposisi</Tag>
            ),
            key: 'letter_type',
            align: 'center'
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <TextButton type={"primary"} shape={"round"} title={"Info"} onClick={() => updateRead(row)} />
                        <TextButton type={"danger"} shape={"round"} title={"Hapus"} deleted onConfirm={() => destroyAsReceiver(row.id)} />
                    </Space>
                </div>
            )
        },
    ];

    const columns2 = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '3%'
        },
        {
            title: 'Tipe Dokumen',
            render: (row) => (
                row?.letter_type

            ),
            key: 'letter_type',
            align: 'center'
        },
        {
            title: 'Tanggal Dokumen',
            render: (row) => (
                moment(row?.date).locale('id').format('dddd, D MMMM YYYY')
            ),
            key: 'date',
            align: 'center',
        },
        {
            title: 'Nomor Dokumen',
            render: (row) => (
                row?.letter_number
            ),
            key: 'letter_number',
            align: 'center'
        },
        {
            title: 'Perihal',
            render: (row) => (
                row?.subject ? row?.subject : row?.letter_subject
            ),
            key: 'subject',
            align: 'center'
        },
        {
            title: 'Penerima',
            render: (row) => (
                row?.receiver_user ? row?.receiver_user : row?.receiver_eksternal
            ),
            key: 'sender',
            align: 'center'
        },
        {
            title: 'Status Pengiriman',
            render: (row) => (
                <Tag color={row?.status === 0 ? "blue" : "green"}>{(row?.status === 0) ? 'Draft' : 'Terkirim'}</Tag>
            ),
            key: 'sending_status',
            align: 'center'
        },
        {
            title: 'Status Dokumen',
            render: (row) => (
                row?.type === 'DISP' || row?.type === 3 ?
                    row?.response_id === 0 || row?.response_id === null ?
                        <Tag color={"blue"}>Menunggu</Tag>
                        :
                        <Tag color={"green"}>Sudah Direspon</Tag>
                    :
                    <Tag color={"blue"}>Sudah Diterima</Tag>
            ),
            key: 'sending_status',
            align: 'center'
        },
        {
            title: 'Tipe Dokumen',
            render: (row) => (
                row?.type === "DISP" ? <Tag color="green">Disposisi</Tag> : <Tag color="blue">Bukan Disposisi</Tag>
            ),
            key: 'letter_type',
            align: 'center'
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Row justify='space-between'>
                        <Col>
                            <TextButton type={"primary"} shape={"round"} title={"Info"} onClick={() => edit(row)} />
                        </Col>
                        <Col>
                            <TextButton type={"primary"} style={{ background: "#8bc34a", border: 'none', hover: "#add580" }} onClick={() => {
                                history.push(`/surat-masuk/update/${row.id}`)
                            }} shape={"round"} title={"Edit"} />
                        </Col>
                    </Row>
                    <Row className='mt-2' justify='center'>
                        <TextButton type={"danger"} shape={"round"} title={"Hapus"} deleted onConfirm={() => destroyAsCreate(row.id)} />
                    </Row>
                </div>
            )
        },
    ];

    const changeTab = (selected) => {
        sessionStorage.setItem('selectedDashboardTab', selected)
    }

    return (
        <div>
            {
                user.role_id === 1 || user.role_id === 2 ?
                    <PageHeader title="Dokumen Masuk" breadcrumbs={[['Dokumen Masuk']]} createLink={'/surat-masuk/create'} />
                    :
                    <PageHeader title="Dokumen Masuk" breadcrumbs={[['Dokumen Masuk']]} />
            }

            <Row justify='space-between'>
                {
                    user.role_id === 1 || user.role_id === 2 ?
                        <>
                            <Col span={7}>
                                <StatCard
                                    type="fill"
                                    title="Total Pembuatan Dokumen Masuk"
                                    value={incomingMailCreate.length}
                                    icon={<Mail size={27} strokeWidth={1} />}
                                    color={"#07c3db"}
                                />
                            </Col>
                            <Col span={7}>
                                <StatCard
                                    type="fill"
                                    title="Total Dokumen Masuk"
                                    value={incomingMailReceive.length}
                                    icon={<Mail size={27} strokeWidth={1} />}
                                    color={"#a957e0"}
                                />
                            </Col>
                            <Col span={7}>
                                <StatCard
                                    type="fill"
                                    title="Total Riwayat Disposisi"
                                    value={dispositionMail.length}
                                    icon={<Send size={27} strokeWidth={1} />}
                                    color={"#f6ac0d"}
                                />
                            </Col>
                        </> :
                        <>
                            <Col span={9}>
                                <StatCard
                                    type="fill"
                                    title="Total Dokumen Masuk"
                                    value={incomingMailReceive.length}
                                    icon={<Mail size={30} strokeWidth={1} />}
                                    color={"#07c3db"}
                                />
                            </Col>
                            <Col span={9}>
                                <StatCard
                                    type="fill"
                                    title="Total Riwayat Disposisi"
                                    value={incomingMailCreate.length}
                                    icon={<Mail size={30} strokeWidth={1} />}
                                    color={"#f6ac0d"}
                                />
                            </Col>
                            <Col span={3}>
                            </Col>
                        </>
                }
            </Row>

            <Row>
                {
                    loaded &&
                    <Col span={24}>
                        <Card style={{ borderRadius: 10 }}>
                            {
                                user.role_id === 1 || user.role_id === 2 ?
                                    <Tabs defaultActiveKey={defaultTab} onChange={changeTab}>
                                        <TabPane tab="Pembuatan Dokumen Masuk" key="create">
                                            {
                                                (loaded) ?
                                                    <DataTable size="small" columns={columns2} sources={incomingMailCreate} bordered />
                                                    : <Spinner />
                                            }
                                        </TabPane>
                                        <TabPane tab="Dokumen Masuk" key="receive">
                                            {
                                                (loaded) ?
                                                    <DataTable size="small" columns={columns} sources={incomingMailReceive} bordered />
                                                    : <Spinner />
                                            }
                                        </TabPane>
                                    </Tabs> :
                                    <>
                                        {
                                            (loaded) ?
                                                <DataTable size="small" columns={columns} sources={incomingMailReceive} bordered />
                                                : <Spinner />
                                        }
                                    </>
                            }

                            {
                                isModalCreateVisible && incomingMailById ?
                                    <DetailModal title={modalTitleCreate} showModal={isModalCreateVisible} onCloseModal={() => setIsModalCreateVisible(false)}>
                                        <Row style={{ marginBottom: 10 }}>
                                            <Col span={24} align="end">
                                                <Text>
                                                    {moment(incomingMailById.date).format('dddd, D MMMM YYYY')}
                                                </Text>
                                            </Col>
                                        </Row>
                                        <Row className='mb-3'>
                                            <Col style={{ alignItems: 'center', justifyContent: 'center', marginTop: 8 }} span={4}>
                                                <Avatar shape='square' size={50}><Mail style={{ marginTop: '-5px' }} /></Avatar>
                                            </Col>
                                            <Col span={14}>
                                                <Text style={{ fontSize: 18, fontWeight: 'bold' }}><b>Perihal:</b> {incomingMailById.subject}</Text><br />
                                                <Text style={{ fontSize: 12 }}><b>Pengirim:</b> {incomingMailById.from_user}</Text><br />
                                                <Text style={{ fontSize: 12 }}><b>No Dokumen:</b> {incomingMailById.letter_number}</Text>
                                            </Col>
                                            <Col>
                                                <Button type="primary" className="button-primary" style={{ marginTop: 20, marginLeft: 12, borderRadius: 10 }} onClick={() => history.push(`/surat-masuk-detail-create/${incomingMailById.id}`)}>Lihat Dokumen</Button>
                                            </Col>
                                        </Row>
                                        {
                                            incomingMailById.type !== 'DISP' ?
                                                <Tag color="blue">Bukan Disposisi</Tag>
                                                :
                                                <>
                                                    <Row>
                                                        <Text style={{ fontSize: 14 }}><b>Arahan:</b> {incomingMailById.action_disposition ?? '-'}</Text>
                                                    </Row>
                                                    <Row>
                                                        <Text style={{ fontSize: 14 }}><b>Intruksi:</b> {incomingMailById.action_description ?? '-'}</Text>
                                                    </Row>
                                                </>
                                        }
                                    </DetailModal>
                                    :
                                    null
                            }

                            {
                                isModalReceiveVisible && incomingMailById ?
                                    <DetailModal title={modalTitleReceive} showModal={isModalReceiveVisible} onCloseModal={() => setIsModalReceiveVisible(false)}>
                                        <Row style={{ marginBottom: 10 }}>
                                            <Col span={24} align="end">
                                                <Text>
                                                    {moment(incomingMailById.date).format('dddd, D MMMM YYYY')}
                                                </Text>
                                            </Col>
                                        </Row>
                                        <Row className='mb-3'>
                                            <Col style={{ alignItems: 'center', justifyContent: 'center', marginTop: 8 }} span={4}>
                                                <Avatar shape='square' size={50}><Mail style={{ marginTop: '-5px' }} /></Avatar>
                                            </Col>
                                            <Col span={14}>
                                                <Text style={{ fontSize: 18, fontWeight: 'bold' }}><b>Perihal:</b> {incomingMailById.subject}</Text><br />
                                                <Text style={{ fontSize: 12 }}><b>Pengirim:</b> {incomingMailById.from_user}</Text><br />
                                                <Text style={{ fontSize: 12 }}><b>No Dokumen:</b> {incomingMailById.letter_number}</Text>
                                            </Col>
                                            <Col>
                                                <Button type="primary" className="button-primary" style={{ marginTop: 20, marginLeft: 12, borderRadius: 10 }} onClick={() => history.push(`/surat-masuk-detail-receive/${incomingMailById.id}`)}>Lihat Dokumen</Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Text style={{ fontSize: 14 }}><b>Penerima:</b> {incomingMailById.receiver_user ?? incomingMailById.receiver_eksternal}</Text>
                                        </Row>
                                        {
                                            incomingMailById.type !== 'DISP' ?
                                                <Tag color="blue">Bukan Disposisi</Tag>
                                                :
                                                <>
                                                    <Row>
                                                        <Text style={{ fontSize: 14 }}><b>Arahan:</b> {incomingMailById.action_disposition ?? '-'}</Text>
                                                    </Row>
                                                    <Row>
                                                        <Text style={{ fontSize: 14 }}><b>Intruksi:</b> {incomingMailById.action_description ?? '-'}</Text>
                                                    </Row>
                                                </>
                                        }
                                    </DetailModal>
                                    :
                                    null
                            }
                        </Card>
                    </Col>
                }
            </Row>
        </div >
    )
}

export default DokumenMasuk
