import React, { useState, useEffect } from 'react'
import { Typography, Card, Form, Input, Switch, Space, message } from 'antd'
import { EditButton, DeleteButton, DataTable, PageHeader, Spinner, FormModal } from './../../../components'
import { ApiService } from '../../../services/ApiService'
import { currentUser } from '../../../utils/authData'

const { Text } = Typography

const SuratRahasia = () => {
    const [loaded, setLoaded] = useState(true)
    const [letterConfidentiality, setLetterConfidentiality] = useState([])
    const [modalTitle, setModalTitle] = useState(null)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})
    const [isSaving, setIsSaving] = useState(false)
    const [status, setStatus] = useState(false)
    const user = currentUser

    const fetchLetterConfidentiality = () => {
        setLoaded(false);
        ApiService.getList('letter-confidentiality').then((res) => {
            setLetterConfidentiality(res.data)
            setLoaded(true)
        }).catch((err) => {
        })
    }

    const add = () => {
        setModalTitle('Tambah Lokasi Arsip')
        setDefaultValues({
            id: 0,
            name: '',
            code: '',
            status: true
        })
        setIsModalVisible(true)
        setStatus(true)
    }

    const edit = (v) => {
        setModalTitle('Edit Lokasi Arsip')
        setDefaultValues({
            id: v.id,
            name: v.name,
            code: v.code,
            status: parseInt(v.status)
        })
        setIsModalVisible(true)
        setStatus(v.status)
    }

    const destroy = (id) => {
        ApiService.destroy('letter-confidentiality', id).then((res) => {
            message.success(res.message, 2)
            fetchLetterConfidentiality()
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const onFinish = (el) => {
        setIsSaving(true)
        let payload = {
            ...el,
            status: status ? 1 : 0
        }

        if (el.id === 0) {
            Object.assign(payload, { created_by: user.id, updated_by: user.id })
            setIsSaving(true)
            ApiService.store(`letter-confidentiality`, payload)
                .then((res) => {
                    message.success(res.message, 2)
                    setIsModalVisible(false)
                    setIsSaving(false)
                    fetchLetterConfidentiality()
                }).catch((err) => {
                    message.warning(err.message, 2)
                })
        } else {
            Object.assign(payload, { updated_by: user.id })
            setIsSaving(true)
            ApiService.update(`letter-confidentiality`, el.id, payload)
                .then((res) => {
                    message.success(res.message, 2)
                    setIsModalVisible(false)
                    setIsSaving(false)
                    fetchLetterConfidentiality()
                }).catch((err) => {
                    message.warning(err.message, 2)
                })
        }
    }

    useEffect(() => {
        fetchLetterConfidentiality()
    }, []);

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Nama',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Kode',
            dataIndex: 'code',
            key: 'code'
        },
        // {
        //     title: 'Status',
        //     dataIndex: 'status',
        //     align: 'center',
        //     key: 'status',
        //     render: (text) => (
        //         <Tag color={text === 1 ? "green" : "red"}>{(text === 1) ? 'Active' : 'InActive'}</Tag>
        //     )
        // },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => edit(row)} />
                        <DeleteButton onConfirm={() => destroy(row.id)} />
                    </Space>
                </div>
            )
        },
    ];

    return (
        <div>
            <PageHeader title="Surat Rahasia" breadcrumbs={[['Master'], ['Surat Rahasia']]} onAdd={add} />
            <Card style={{ borderRadius: 10 }}>

                <>
                    {
                        (loaded) ?
                            <DataTable size="small" columns={columns} sources={letterConfidentiality} bordered />
                            : <Spinner />
                    }
                </>

                {
                    (isModalVisible) ?
                        <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => onFinish(v)} width={600} showModal={isModalVisible} onCloseModal={() => setIsModalVisible(false)} saving={isSaving}>
                            <Form.Item name="id" hidden>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Nama" name="name" required rules={[{ required: true }]}>
                                <Input placeholder="Nama" />
                            </Form.Item>
                            <Form.Item label="Kode" name="code">
                                <Input placeholder="Kode" />
                            </Form.Item>
                            <Form.Item label="Status" name="status" rules={[{ required: true }]}>
                                <Switch checkedChildren="Ya" unCheckedChildren="Tidak" onChange={(v) => setStatus(v)} defaultChecked={defaultValues.status} />
                            </Form.Item>
                            <Text>Inputan dengan tanda (<span style={{ color: 'red' }}>*</span>) wajib diisi</Text>
                        </FormModal> :
                        null
                }
            </Card>
        </div>
    )
}

export default SuratRahasia
