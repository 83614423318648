import React, { useState, useEffect } from 'react'
import { Card, Form, Input, Select, Row, Col, message } from 'antd'
import { DataTable, FileUpload, FormModal, PageHeader, ShowButton, Spinner } from '../../components'
import { ApiService } from '../../services/ApiService'
import { currentUser } from '../../utils/authData'
import moment from 'moment'

const { Option } = Select

const DocumentManagementSystem = () => {
    const [loaded, setLoaded] = useState(false)
    const [documents, setDocuments] = useState([])
    const [folder, setFolder] = useState([])
    const [uploading, setUploading] = useState(false)
    const [saving, setSaving] = useState(false)
    const [showModalUploadDocument, setShowModalUploadDocument] = useState(false)
    const [modalUplodaTitle, setModalUploadTitle] = useState('')
    const [attachmentFile, setAttachmentFile] = useState([])
    const user = currentUser

    const fetchFolder = () => {
        ApiService.getList('folder').then((res) => {
            setFolder(res.data)
        })
    }

    const fetchDocument = () => {
        ApiService.getList('document-dms').then((res) => {
            setDocuments(res.data)
            setLoaded(true)
        })
    }

    const showModal = () => {
        setShowModalUploadDocument(true)
        setModalUploadTitle('Upload Document')
    }

    const fileUploaded = (v) => {
        let atcs = attachmentFile
        if (v.message === 'Success') {
            atcs.push({ filename: v.response.data.filename })
        }
        setAttachmentFile(atcs)
    }

    const uploadDms = (v) => {
        setSaving(true)
        let payload = {
            title: v.title,
            folder_id: v.folder_id,
            attachment_file: attachmentFile,
            created_by: user.id,
            document_origin: 'DMS',
        }

        ApiService.store('document', payload).then((res) => {
            message.success(res.message)
            setSaving(false)
            window.location.reload();
        }).catch(() => {
            message.warning('Failed save data')
        })
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            align: 'center'
        },
        {
            title: 'File Name',
            key: 'original_filename',
            render: (text) => (
                text.original_filename ?? text.filename
            ),
            align: 'center'
        },
        {
            title: 'Date',
            key: 'created_at',
            render: (text) => (
                moment(text).format('dddd, DD MMMM YYYY - mm:hh:ss')
            ),
            align: 'center'
        },
        {
            title: 'Action',
            key: 'action',
            render: (row) => (
                <ShowButton onShow={() => window.open(`${process.env.REACT_APP_FILE_URL}/${row.filename}`)} />
            ),
            align: 'center'
        }
    ];

    useEffect(() => {
        fetchFolder()
        fetchDocument()
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            <PageHeader title="Upload Document" breadcrumbs={[['Document Management System'], ['Upload Document']]} onUpload={showModal} />

            <Card style={{ borderRadius: 10 }}>
                {
                    (loaded) ?
                        documents.length !== 0 ?
                            <DataTable size="small" columns={columns} sources={documents} bordered />
                            : null
                        : <Spinner />
                }
            </Card>

            {
                showModalUploadDocument ?
                    <FormModal title={modalUplodaTitle} submitForm={(v) => uploadDms(v)} width={800} showModal={showModalUploadDocument} onCloseModal={() => setShowModalUploadDocument(false)} saving={saving || uploading}>
                        <Row justify="space-between">
                            <Col span={11}>
                                <Form.Item label="Title" name="title">
                                    <Input placeholder="Title" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item label="Select Folder" name="folder_id">
                                    <Select placeholder="Select Folder">
                                        {
                                            folder.map((el, key) => (
                                                <Option value={el.id} key={key}>{el.name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item>
                            <FileUpload path='file-attachment' multiple={true} dragger onUploaded={(v, key) => fileUploaded(v)} isUploading={(v) => setUploading(v)} />
                        </Form.Item>
                    </FormModal>
                    : null
            }

        </div>
    )
}

export default DocumentManagementSystem