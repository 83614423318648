import React, { useState, useEffect } from 'react';
import { Mail, Send } from 'react-feather';
import { Row, Col, message } from 'antd';
import DashboardItem from './DashboardItem';
import { Spinner } from '../../components';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import StatCard from '../../components/shared/StatCard';
import { ApiService } from '../../services/ApiService';
import { currentUser } from '../../utils/authData';
import moment from 'moment';

Chart.register(...registerables);

const DataSurat = () => {
    const [loaded, setLoaded] = useState(false)
    // eslint-disable-next-line
    const [loadedBarData, setLoadedBarData] = useState(false)
    const [barData, setBarData] = useState(null)
    const [incomingMail, setIncomingMail] = useState([])
    const [outgoingMail, setOutgoingMail] = useState([])
    const [dispositionMail, setDispositionMail] = useState([])
    // eslint-disable-next-line
    const [selectedDate, setSelectedDate] = useState([null, null])
    const user = currentUser

    const fetchIncomingMail = () => {
        const data = {
            user_id: user.id
        }

        ApiService.getList('incoming-mail-receive', data).then((res) => {
            setIncomingMail(res.data)
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const fetchOutgoingMail = () => {
        const data = {
            user_id: user.id
        }

        ApiService.getList('outgoing-mail', data).then((res) => {
            setOutgoingMail(res.data)
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const fetchDispositionMail = () => {
        const data = {
            user_id: user.id
        }

        ApiService.getList('disposition-mail-receive', data).then((res) => {
            setDispositionMail(res.data)
        }).catch((err) => {
            message.warning(err.message)
        })
    }

    const fetchMailData = (v) => {
        setLoaded(false)
        setLoadedBarData(false)
        if (v) {
            ApiService.getList('dashboard', { user_id: user.id, start_date: v.start_date, end_date: v.end_date }).then((res) => {
                setBarData(res.data)
                setLoaded(true)
                setLoadedBarData(true)
            }).catch((err) => {
                message.warning(err)
            })
        } else {
            ApiService.getList('dashboard', { user_id: user.id, start_date: '2022-01-01', end_date: '2022-01-10' }).then((res) => {
                setBarData(res.data)
                setLoaded(true)
                setLoadedBarData(true)
            }).catch((err) => {
                message.warning(err)
            })
        }
    }

    useEffect(() => {
        fetchIncomingMail()
        fetchOutgoingMail()
        fetchDispositionMail()
        fetchMailData({ start_date: moment().startOf('weeks').format('YYYY-MM-DD'), end_date: moment().endOf('weeks').format('YYYY-MM-DD') })

        setSelectedDate([moment().startOf('weeks'), moment().endOf('weeks')])
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const data = {
        labels: (barData) ? barData.listDate : [],
        datasets: [
            {
                label: 'Dokumen Masuk',
                data: (barData) ? barData.dataIncomingMail : [],
                backgroundColor: 'rgba(0, 255, 0, 0.3)',
                borderColor: '#2ecc71',
                borderWidth: 1
            },
            {
                label: 'Dokumen Keluar',
                data: (barData) ? barData.dataOutgoingMail : [],
                backgroundColor: 'rgba(255, 99, 132, 0.3)',
                borderColor: '#ff7675',
                borderWidth: 1
            },
            {
                label: 'Surat Disposisi',
                data: (barData) ? barData.dataDispositionMail : [],
                backgroundColor: 'rgba(57,192,198,0.3)',
                borderColor: '#39c0c6',
                borderWidth: 1
            }
        ]
    }

    // const filterMail = () => {
    //     fetchMailData({ start_date: moment(selectedDate[0]).format('YYYY-MM-DD'), end_date: moment(selectedDate[1]).format('YYYY-MM-DD') })
    // }

    return (
        <div>
            {
                loaded &&
                <>
                    <Row justify='space-between' span={24}>
                        <Col xs={24} sm={12} md={7}>
                            <StatCard
                                type="fill"
                                title="Total Dokumen Masuk"
                                value={incomingMail.length ? incomingMail.length : 0}
                                icon={<Mail size={20} strokeWidth={1} />}
                                color={"#07c3db"}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={7}>
                            <StatCard
                                type="fill"
                                title="Total Dokumen Keluar"
                                value={outgoingMail.length ? outgoingMail.length : 0}
                                icon={<Send size={20} strokeWidth={1} />}
                                color={"#a957e0"}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={7}>
                            <StatCard
                                type="fill"
                                title="Total Surat Disposisi"
                                value={dispositionMail.length ? dispositionMail.length : 0}
                                icon={<Send size={20} strokeWidth={1} />}
                                color={"#f6ac0d"}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <DashboardItem title={
                                <div>
                                    Data Incoming, Outgoing, and Disposition Document
                                    {/* <Space style={{ float: 'right' }}>
                                        <RangePicker onChange={(v) => setSelectedDate(v)} style={{ width: '300px', float: 'right' }} defaultValue={selectedDate} />
                                        <Button icon={<ZoomInOutlined />} loading={!loadedBarData} onClick={() => filterMail()} type="primary">
                                            Show
                                        </Button>
                                    </Space> */}
                                </div>
                            } height={400}>
                                {
                                    (loaded) ?
                                        <Bar
                                            data={data}
                                            width={100}
                                            height={320}
                                            options={{ maintainAspectRatio: false }}
                                        />
                                        : <Spinner />
                                }
                            </DashboardItem>
                        </Col>
                    </Row>
                </>
            }
        </div>
    )
}

export default DataSurat
