import { decrypt } from "./helpers"

export const docPermission = [
    {
        key: 'OPEN_NEW_TAB_DOC',
        title: 'Open New Tab Document'
    },
    {
        key: 'UPLOAD_DOC',
        title: 'Upload Document'
    },
    {
        key: 'SEARCH_DOC',
        title: 'Search Document'
    },
    {
        key: 'DELETE_DOC',
        title: 'Delete Document'
    },
    {
        key: 'DOWNLOAD_DOC',
        title: 'Download Document'
    },
    {
        key: 'RENAME_DOC',
        title: 'Rename Document'
    },
    {
        key: 'MOVE_DOC',
        title: 'Move Document'
    },
    {
        key: 'ADD_FOLDER',
        title: 'Add Folder'
    },
    {
        key: 'RENAME_FOLDER',
        title: 'Rename Folder'
    },
    {
        key: 'DELETE_FOLDER',
        title: 'Delete Folder'
    },
]

export function docAccess(code) {
    let doc_access = localStorage.getItem('docPermission') ? JSON.parse(decrypt(localStorage.getItem('docPermission'))) : []
    return doc_access.filter(el => el.code === code)?.length ? true : false
}