import { useState, useEffect } from 'react';
import { Avatar, Card, Col, Row, Space, Tabs, Tag, Typography, Button, message } from 'antd';
import { Mail, Send } from 'react-feather';
import { DataTable, DetailModal, PageHeader, Spinner, TextButton, TextButtonDanger } from '../../components';
import StatCard from '../../components/shared/StatCard';
import { ApiService } from '../../services/ApiService';
import { role } from '../../utils/authData';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import 'moment/locale/id';

const { Text } = Typography
const { TabPane } = Tabs;
const defaultTab = (sessionStorage.getItem('selectedDashboardTab')) ? sessionStorage.getItem('selectedDashboardTab') : 'receive'

const OutgoingDocument = () => {
    const history = useHistory()
    const [loaded, setLoaded] = useState(false)
    const [outgoingModalVisible, setOutgoingModalVisible] = useState(false)
    const [outgoingModalInfoTitle, setOutgoingModalInfoTitle] = useState('')
    const [outgoingDocumentInfo, setOutgoingDocumentInfo] = useState(0)
    const [outgoingDocument, setOutgoingDocument] = useState([])
    const [draftData, setDraftData] = useState([])
    const [tabStatus, setTabStatus] = useState('sent')

    const fetchOutgoingDocument = () => {
        ApiService.getList('outgoing-document').then((res) => {
            setOutgoingDocument(res.data)
            setLoaded(true)
        })
    }

    const fetchDraft = () => {
        ApiService.getList('draft-document').then((res) => {
            setDraftData(res.data)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchOutgoingDocument()
        fetchDraft()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const changeTab = (selected) => {
        setTabStatus(selected)
        sessionStorage.setItem('selectedDashboardTab', selected)
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '3%'
        },
        {
            title: 'Document Type',
            render: (row) => (
                row?.letter_type?.letter_type_name
            ),
            key: 'letter_type',
            align: 'center',
        },
        {
            title: 'Document Date',
            render: (row) => (
                moment(row?.date).locale('id').format('dddd, D MMMM YYYY')
            ),
            key: 'date',
            align: 'center',
            defaultSortOrder: 'descend',
        },
        {
            title: 'Document Number',
            render: (row) => (
                row?.letter_number
            ),
            key: 'letter_number',
            align: 'center'
        },
        {
            title: 'Subject',
            render: (row) => (
                row?.subject ? row?.subject : row?.letter_subject
            ),
            key: 'subject',
            align: 'center'
        },
        {
            title: 'Sender',
            render: (row) => (
                row?.letter_movement?.map((el, key) => (
                    <Tag>{el?.receiver_user?.name}</Tag>
                )) ?? '-'
            ),
            key: 'receive',
            align: 'center'
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <TextButton type={"primary"} shape={"round"} title={"Info"} onClick={() => show(row)} />
                        {
                            tabStatus === 'draft' ?
                                <TextButton type={"primary"} style={{ background: "#8bc34a", border: 'none', hover: "#add580" }} shape={"round"} title={"Edit"} onClick={() => {
                                    history.push(`/surat-keluar/update/${row.id}`)
                                }} />
                                : null
                        }
                        <TextButtonDanger type={"primary"} shape={"round"} title={"Delete"} deleted onConfirm={() => destroy(row.id)} />
                    </Space>
                </div>
            ),
            align: 'center'
        },
    ];

    const show = (v) => {
        setOutgoingModalInfoTitle('Detail Outgoing Document')
        setOutgoingModalVisible(true)
        setOutgoingDocumentInfo(v)
    }

    const destroy = (id) => {
        ApiService.destroy('outgoing-mail', id).then((res) => {
            fetchOutgoingDocument()
            fetchDraft()
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    return (
        <div>
            <PageHeader title="Outgoing Document" breadcrumbs={[['Outgoing Document']]} createLink={'/surat-keluar/create'} />

            <Row justify='space-between'>
                <Col span={8}>
                    <StatCard
                        type="fill"
                        title="Total Outgoing Document"
                        value={outgoingDocument.length}
                        icon={<Send size={30} strokeWidth={1} />}
                        color={"#07c3db"}
                    />
                </Col>
                <Col span={8}>
                    <StatCard
                        type="fill"
                        title="Total Draft"
                        value={draftData.length}
                        icon={<Send size={30} strokeWidth={1} />}
                        color={"#a957e0"}
                    />
                </Col>
                <Col span={4}>
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <Card style={{ borderRadius: 10 }}>
                        {
                            loaded ?
                                <Tabs defaultActiveKey={role.includes("SUPERADMIN", "ADMIN") ? defaultTab : 'receive'} onChange={changeTab}>
                                    <TabPane tab="Terkirim" key="sent">
                                        <DataTable size="small" columns={columns} sources={outgoingDocument} bordered />
                                    </TabPane>
                                    <TabPane tab="Draft" key="draft">
                                        <DataTable size="small" columns={columns} sources={draftData} bordered />
                                    </TabPane>
                                </Tabs>
                                : <Spinner />
                        }
                    </Card>
                </Col>
            </Row>

            {
                outgoingModalVisible && outgoingDocumentInfo ?
                    <DetailModal title={outgoingModalInfoTitle} showModal={outgoingModalVisible} onCloseModal={() => setOutgoingModalVisible(false)}>
                        <Row style={{ marginBottom: 10 }}>
                            <Col span={24} align="end">
                                <Text>
                                    {moment(outgoingDocumentInfo.date).format('dddd, D MMMM YYYY')}
                                </Text>
                            </Col>
                        </Row>
                        <Row className='mb-3'>
                            <Col style={{ alignItems: 'center', justifyContent: 'center', marginTop: 8 }} span={4}>
                                <Avatar shape='square' size={50}><Mail style={{ marginTop: '-5px' }} /></Avatar>
                            </Col>
                            <Col span={14}>
                                <Text style={{ fontSize: 18, fontWeight: 'bold' }}><b>Subject:</b> {outgoingDocumentInfo.subject}</Text><br />
                                <Text style={{ fontSize: 12 }}><b>Sender:</b> {outgoingDocumentInfo.from_user}</Text><br />
                                <Text style={{ fontSize: 12 }}><b>Document Number:</b> {outgoingDocumentInfo.letter_number}</Text>
                            </Col>
                            <Col>
                                <Button type="primary" className="button-primary" style={{ marginTop: 20, marginLeft: 12, borderRadius: 10 }} onClick={() => history.push(`/outgoing-document/${outgoingDocumentInfo.id}`)}>Show Dokumen</Button>
                            </Col>
                        </Row>
                    </DetailModal>
                    :
                    null
            }
        </div >
    )
}

export default OutgoingDocument
