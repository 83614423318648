import { decrypt } from "./helpers"

export const rolePermission = [
    {
        key: 'ADD_DEPT',
        title: 'Add Department'
    },
    {
        key: 'EDIT_DEPT',
        title: 'Edit Department'
    },
    {
        key: 'DELETE_DEPT',
        title: 'Delete Department'
    },
    {
        key: 'ADD_POST',
        title: 'Add Position'
    },
    {
        key: 'EDIT_POST',
        title: 'Edit Position'
    },
    {
        key: 'DELETE_POST',
        title: 'Delete Position'
    },
    {
        key: 'ADD_USER',
        title: 'Add User'
    },
    {
        key: 'EDIT_USER',
        title: 'Edit User'
    },
    {
        key: 'DELETE_USER',
        title: 'Delete User'
    },
    {
        key: 'ADD_DOC_LOAN',
        title: 'Add Document Loaning'
    },
    {
        key: 'DOC_PROCESS_LOG',
        title: 'Document Process Log'
    },
    {
        key: 'DOC_LOG',
        title: 'Document Log'
    },
    {
        key: 'MASTER_MENU',
        title: 'Master Menu'
    },
    {
        key: 'ORGANIZATION_MENU',
        title: 'Organization Menu'
    }
]

export function roleAccess(code) {
    let role_access = localStorage.getItem('rolePermission') ? JSON.parse(decrypt(localStorage.getItem('rolePermission'))) : []
    return role_access.filter(el => el.code === code)?.length ? true : false
}