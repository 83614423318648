import { useState, useEffect } from 'react'
import { Button, Card, Col, Form, Image, Input, Row, Space, Tree, Typography, message, Dropdown, Select, InputNumber, Modal, Checkbox } from 'antd'
import { CloseOutlined, ExclamationCircleFilled, SearchOutlined } from '@ant-design/icons';
import moment from 'moment'
import { BasicModal, DmsUpload, FormModal } from '../../../../components'
import { Docs, Excel, Folder, Images, Pdf, Ppt } from '../../../../components/Folder';
import { ApiService } from '../../../../services/ApiService'
import IcFolder from '../../../../assets/images/ic_folder.png'
import EmptyFolderImage from '../../../../assets/images/empty-folder.png'
import EmptyDocumentImage from '../../../../assets/images/empty-document.png'
import { decrypt } from '../../../../utils/helpers'
import { GenerateFolder } from '../../../../utils/tree';
import { docAccess } from '../../../../utils/docPermission';
// import { role } from '../../../../utils/authData'

const { DirectoryTree } = Tree;
const { Text } = Typography;
const { Option } = Select;
const { confirm } = Modal;
const fileUrl = process.env.REACT_APP_FILE_URL;

const MyFolder = () => {
    const [form] = Form.useForm()
    const [loaded, setLoaded] = useState(false)
    // eslint-disable-next-line
    const [folder, setFolder] = useState([])
    const [listFolder, setListFolder] = useState([])
    const [uploading, setUploading] = useState(false)
    const [loadedSearch, setLoadedSearch] = useState(false)
    const [nestedTree, setNestedTree] = useState([])
    const [inTree, setInTree] = useState([])
    // eslint-disable-next-line
    const [onChangeFile, setOnChangeFile] = useState(0)
    const [resultSearch, setResultSearch] = useState(false)
    const [resultFile, setResultFile] = useState([])
    const [showModalUploadDocumentSmart, setShowModalUploadDocumentSmart] = useState(false)
    const [modalUplodaTitleSmart, setModalUploadTitleSmart] = useState('')
    const [saving, setSaving] = useState(false)
    const [listAttachment, setListAttachment] = useState([])
    const [isFile, setIsFile] = useState(false)
    const [fileNameUrl, setFileNameUrl] = useState('')
    const [fileName, setFileName] = useState('')
    // eslint-disable-next-line
    const [fileId, setFileId] = useState(0)
    // eslint-disable-next-line
    const [parentId, setParentId] = useState(0)
    const [stringValue, setStringValue] = useState('')
    const [isSaving, setIsSaving] = useState(false)
    const [modalTitleFormFolder, setModalTitleFormFolder] = useState(null)
    const [isModalVisibleFormFolder, setIsModalVisibleFormFolder] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})
    const [showModalRename, setShowModalRename] = useState(false)
    const [showModalMove, setShowModalMove] = useState(false)
    const [modalRenameTitle, setModalRenameTitle] = useState('')
    const [modalMoveTitle, setModalMoveTitle] = useState('')
    const [isUpdateFolder, setIsUpdateFolder] = useState(false)
    const [fileEkstension, setFileEkstension] = useState('')
    const [alertModal, setAlertModal] = useState(false)
    const [checkboxValue, setCheckboxValue] = useState(false)
    const [archiveLocation, setArchiveLocation] = useState([])
    const user = localStorage.getItem('currentUser') ? JSON.parse(decrypt(localStorage.getItem('currentUser'))) : []
    const role = localStorage.getItem('role') ? JSON.parse(decrypt(localStorage.getItem('role'))) : []

    function getNestedChildren(folder, file) {
        let folderChild = folder
        for (let i = 0; i < folderChild.length; i++) {
            let children = []
            for (let j = 0; j < file.length; j++) {
                if (folderChild[i].root_id === file[j].parent) {
                    children.push(file[j])
                }
            }

            Object.assign(folderChild[i], { children: children })
        }

        const data = [Object.assign({ title: "My Folder", icon: <Image preview={false} src={IcFolder} width={20} />, }, { children: folderChild })]

        return data;
    }

    const fetchNestedTree = (v) => {
        if (v) {
            setLoaded(false)
            ApiService.getList('my-folder', { role: role }).then((res) => {
                const tree = res.data
                const data = GenerateFolder(tree)

                let folder = []
                let file = []
                data.forEach((el, key) => {
                    if (el.type === "FOLDER") {
                        folder.push(el)
                    } else {
                        file.push(el)
                    }
                })
                const generateTree = getNestedChildren(folder, tree)
                setNestedTree(generateTree)
                setLoaded(true)
            })
        } else {
            ApiService.getList('my-folder', { role: role }).then((res) => {
                const tree = res.data
                const data = GenerateFolder(tree)

                let folder = []
                let file = []
                data.forEach((el, key) => {
                    if (el.type === "FOLDER") {
                        folder.push(el)
                    } else {
                        file.push(el)
                    }
                })
                const generateTree = getNestedChildren(folder, tree)
                setNestedTree(generateTree)
            })
        }
    }

    const fetchFolder = () => {
        ApiService.getObject('folder').then((res) => {
            const v = res.data
            let arr = []
            v.forEach((el) => {
                arr.push({ value: el.id, label: el.name })
            })
            setListFolder(arr)
            setFolder(res.data)
            fetchNestedTree(false)
        })
    }

    const fetchArchiveLocation = () => {
        ApiService.getList('archive-location').then((res) => {
            const v = res.data
            let arr = []
            v.forEach((el) => {
                arr.push({ label: el.archive_location_name, value: el.archive_location_id })
            })

            setArchiveLocation(arr)
        })
    }

    const fetchInFolder = (v) => {
        let params = {
            role: role,
            user_id: user.id
        }
        ApiService.getObject('infolder', v, params).then((res) => {
            setInTree(res.data)
        })
    }

    useEffect(() => {
        fetchNestedTree(true)
        fetchFolder()
        fetchArchiveLocation()

        // eslint-disable-next-line
    }, []);

    const onSelect = (selectedKeys, info) => {
        const v = info.node
        setResultSearch(false)
        form.resetFields(['search_file']);
        form.setFieldsValue({ name: v.name })
        setOnChangeFile(1)
        setFileId(v.file_origins_id)
        if (v.type === 'FOLDER') {
            fetchInFolder(v.root_id)
            setIsFile(false)
        }
        if (v.type === 'FILE') {
            setIsFile(true)
            setFileName(v.original_filename)
            setFileNameUrl(v.name)
            setParentId(v.parent)
        }
    }

    const onSelectFile = (url, name, id) => {
        setIsFile(true)
        setFileNameUrl(url)
        setFileName(name)
        setFileId(id)
    }

    const downloadFile = (v) => {
        let payload = {
            document_id: v.id,
            description: 'Download File from Web',
            original_filename: v.original_filename,
            filename: v.name,
        }

        ApiService.store('file-log', payload).then((res) => {
            window.open(`${v.name}`)
        }).catch((err) => {
            message.warning('Log Not Saved')
        })
    }

    const searchFileManager = (v) => {
        form.resetFields(['name']);
        setLoadedSearch(false)
        let payload = {
            search: v.search_file === '' ? stringValue : v.search_file
        }
        let params = {
            user_id: user.id,
            role: role
        }

        ApiService.store('search-file', payload, params)
            .then((res) => {
                setIsFile(false)
                setResultSearch(true)
                setResultFile(res.data)
                setLoadedSearch(true)
            })
    }

    const clearSearch = () => {
        setIsFile(false)
        setResultSearch(false)
        form.resetFields(['search_file']);
    }

    const showModalSmartUpload = () => {
        setShowModalUploadDocumentSmart(true)
        setModalUploadTitleSmart('Upload Document')
    }

    const fileUploaded = (v) => {
        if (v.message === 'Success') {
            setListAttachment(v.response)
        } else {
            setListAttachment([])
        }
    }

    const uploadSmartDms = (v) => {
        setSaving(true)
        let payload = {
            title: v.title,
            attachment_file: listAttachment,
            document_origin: 'DMS',
            user_id: user.user_id,
            description: 'Upload File',
            document_from: 'WEB',
            pdf_split: v.pdf_split,
            archive_location_id: v.archive_location_id,
            folder_id: v.folder_id,
            is_auto: checkboxValue,
        }

        console.log(payload)

        ApiService.store('document-smart', payload).then((res) => {
            message.success(res.message)
            setSaving(false)
            setTimeout(() => {
                window.location.reload();
            }, 2000)
        }).catch(() => {
            message.warning('Failed save data')
            setSaving(false)
        })
    }

    // const deleteFile = () => {
    //     let params = {
    //         user: user.id,
    //         file_id: fileId,
    //         filename: fileNameUrl,
    //         document_from: 'WEB'
    //     }

    //     ApiService.destroy('document', fileId, params).then(() => {
    //         message.success('Success Delete File')
    //         fetchNestedTree(true)
    //         fetchFolder()
    //         fetchInFolder(parentId)
    //         setIsFile(false)
    //     })
    // }

    const changeInputValue = (v) => {
        setStringValue(v)
    }

    const addFolder = () => {
        setModalTitleFormFolder('Add Folder')
        setDefaultValues({
            id: 0,
            name: '',
        })
        setIsModalVisibleFormFolder(true)
    }

    const onFinishFolder = (el) => {
        let payload = {
            name: el.name,
            root_folder: el.root_folder,
            status: 1
        }

        setIsSaving(true)
        ApiService.store(`folder`, payload)
            .then((res) => {
                message.success(res.message, 2)
                setIsModalVisibleFormFolder(false)
                setIsSaving(false)
                fetchFolder()
            }).catch((err) => {
                message.warning(err.response.data.message)
                setIsSaving(false)
            })
    }

    const showDelete = (v) => {
        let params = {
            filename: v.name,
            original_filename: v.original_filename,
            document_from: 'WEB'
        }
        confirm({
            title: 'Are you sure delete this document?',
            icon: <ExclamationCircleFilled />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                ApiService.destroy('document', v.file_origins_id, params).then(() => {
                    message.success('Success Delete File')
                    fetchNestedTree(false)
                    fetchFolder()
                    fetchInFolder(v.parent)
                    setIsFile(false)
                }).catch((err) => {
                    message.warning(err.response.data.message)
                })
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const showDeleteFolder = (v) => {
        confirm({
            title: 'Are you sure delete this folder?',
            icon: <ExclamationCircleFilled />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                ApiService.destroy('folder-parent', v.file_origins_id).then((res) => {
                    message.success(res.message)
                    fetchNestedTree(false)
                    fetchFolder()
                }).catch((err) => {
                    message.warning(err.response.data.message)
                })
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const onShowRenameFolder = (v) => {
        setShowModalRename(true)
        setModalRenameTitle('Rename Folder')
        setIsUpdateFolder(true)
        form.setFieldsValue({
            id: v.file_origins_id,
            original_filename: v.original_filename
        })
    }

    const onShowRenameFile = (v) => {
        setShowModalRename(true)
        setModalRenameTitle('Rename File')
        setIsUpdateFolder(false)
        setFileEkstension(v.original_filename.split('.')[1])
        form.setFieldsValue({
            id: v.file_origins_id,
            original_filename: v.original_filename.split('.')[0]
        })
    }

    const onShowMove = (v) => {
        setShowModalMove(true)
        setModalMoveTitle('Move File')
        form.setFieldsValue({
            id: v.file_origins_id,
        })
    }

    const items = (v) => {
        return (
            <div style={{ backgroundColor: 'white', padding: 5, borderRadius: 5, border: '1px solid #e8e8e8', width: 150 }}>
                {
                    docAccess('OPEN_NEW_TAB_DOC') ?
                        <Row className="mb-1">
                            <a href="!#" className="a-link" onClick={() => window.open(`${fileUrl}/v1/show-file/${v?.original_filename}`)} >
                                Open New Tab
                            </a>
                        </Row> : null
                }
                {
                    docAccess('DOWNLOAD_DOC') ?
                        <Row className="mb-1">
                            <a href="!#" className="a-link" onClick={() => downloadFile({ name: v.name, original_filename: v.original_filename, id: v.file_origins_id })} >
                                Download
                            </a>
                        </Row> : null
                }
                {
                    docAccess('DELETE_DOC') ?
                        <>
                            <Row className="mb-1">
                                <a href="!#" className="a-link" onClick={() => showDelete(v)} >
                                    Delete
                                </a>
                            </Row>
                        </> : null
                }
                {
                    docAccess('MOVE_DOC') ?
                        <Row className="mb-1">
                            <a href="!#" className="a-link" onClick={() => onShowMove(v)} >
                                Move
                            </a>
                        </Row> : null
                }
                {
                    docAccess('RENAME_DOC') ?
                        <Row className="mb-1">
                            <a href="!#" className="a-link" onClick={() => onShowRenameFile(v)} >
                                Rename
                            </a>
                        </Row> : null
                }
            </div>
        )
    }

    const itemsFolder = (v) => {
        return (
            <div style={{ backgroundColor: 'white', padding: 5, borderRadius: 5, border: '1px solid #e8e8e8', width: 150 }}>
                <Row className="mb-1">
                    <a href="!#" className="a-link" onClick={() => showModalSmartUpload(true, v)} >
                        Upload
                    </a>
                </Row>
                {
                    docAccess('RENAME_FOLDER') ?
                        <Row className="mb-1">
                            <a href="!#" className="a-link" onClick={() => onShowRenameFolder(v)} >
                                Rename
                            </a>
                        </Row> : null
                }
                {
                    docAccess('DELETE_FOLDER') ?
                        <Row className="mb-1">
                            <a href="!#" className="a-link" onClick={() => showDeleteFolder(v)} >
                                Delete
                            </a>
                        </Row> : null
                }
            </div>
        )
    }

    const onFinishRename = (v) => {
        if (isUpdateFolder) {
            ApiService.update('rename-folder', v.id, { name: v.original_filename })
                .then((res) => {
                    message.success(res.message)
                    setShowModalRename(false)
                    fetchNestedTree(false)
                }).catch((err) => {
                    message.warning(err.response.data.message)
                })
        } else {
            ApiService.update('rename-document', v.id, { name: `${v.original_filename}.${fileEkstension}` })
                .then((res) => {
                    message.success(res.message)
                    setShowModalRename(false)
                    fetchNestedTree(false)
                }).catch((err) => {
                    message.warning(err.response.data.message)
                })
        }
    }

    const onFinishMove = (v) => {
        ApiService.update('move-document', v.id, { folder_id: v.folder_id })
            .then((res) => {
                message.success(res.message)
                setShowModalMove(false)
                fetchNestedTree(false)
            }).catch((err) => {
                message.warning(err.response.data.message)
            })
    }

    const changeCheckbox = () => {
        setAlertModal(true)
    }

    const buttonYes = () => {
        setAlertModal(false)
        setCheckboxValue(true)
    }

    const buttonNo = () => {
        setAlertModal(false)
        setCheckboxValue(false)
    }

    return (
        <div>
            <Card style={{ borderRadius: 10 }}
                title={
                    <Row justify='space-between'>
                        <Col>
                            <Text>My Folder</Text>
                        </Col>
                        <Col>
                            {
                                docAccess('UPLOAD_DOC') ?
                                    <Space>
                                        <Button type='primary' onClick={showModalSmartUpload}>+ Upload Document</Button>
                                    </Space>
                                    : null
                            }
                        </Col>
                    </Row>
                }>
                <Row justify='space-between'>
                    <Col span={8}>
                        <Card title={
                            docAccess('ADD_FOLDER') ?
                                <div style={{ float: 'left' }}>
                                    <Button onClick={addFolder} type='primary'>Add Folder</Button>
                                </div>
                                : null
                        }>
                            {
                                loaded ?
                                    <DirectoryTree
                                        onSelect={onSelect}
                                        treeData={nestedTree}
                                        titleRender={(node) => {
                                            const { title, type } = node;
                                            return (
                                                <>
                                                    {
                                                        type === 'FILE' ?
                                                            <Dropdown overlay={items(node)} trigger={["contextMenu"]}>
                                                                <span>{title}</span>
                                                            </Dropdown>
                                                            :
                                                            type === 'FOLDER' && title !== 'My Folder' ?
                                                                <Dropdown overlay={itemsFolder(node)} trigger={["contextMenu"]}>
                                                                    <span>{title}</span>
                                                                </Dropdown>
                                                                : <span>{title}</span>
                                                    }
                                                </>
                                            )
                                        }}
                                    />
                                    : null
                            }
                        </Card>
                    </Col>
                    <Col span={15}>
                        <Card>
                            <div style={{ marginBottom: '15px' }}>
                                {
                                    docAccess('SEARCH_DOC') ?
                                        <Form form={form} onFinish={searchFileManager}>
                                            <Row justify='start'>
                                                <Col span={24}>
                                                    <Form.Item name="search_file">
                                                        <Input placeholder='Search My Folder...' onChange={(v) => changeInputValue(v.target.value)} style={{ width: '100%', borderRadius: '10px' }} suffix=
                                                            {
                                                                <Space>
                                                                    <Button type='text' icon={<CloseOutlined />} size="small" onClick={clearSearch} />
                                                                    <Button onClick={searchFileManager} type="primary" shape="circle" icon={<SearchOutlined />} />
                                                                </Space>
                                                            }
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Form>
                                        : null
                                }
                            </div>

                            {
                                !isFile ?
                                    !resultSearch ?
                                        inTree.length !== 0 ?
                                            <Row>
                                                {
                                                    inTree.map((el) => (
                                                        <Col span={6}>
                                                            {
                                                                el?.type === 'FOLDER' ?
                                                                    <Folder width={50} buttonHeight={70} onClick={() => fetchInFolder(el?.root_id)} title={el?.name} />
                                                                    :
                                                                    el?.original_filename.split('.')[1].toLowerCase() === 'pdf' ?
                                                                        <Pdf width={50} buttonHeight={70} onClick={() => onSelectFile(el?.name, el?.original_filename, el?.file_origins_id)} title={el.original_filename ?? `${el.name.split('/')[1].substring(0, 10)}...`} />
                                                                        :
                                                                        el.original_filename.split('.')[1].toLowerCase() === 'pptx' || el.original_filename.split('.')[1].toLowerCase() === 'ppt' ?
                                                                            <Ppt width={50} buttonHeight={70} onClick={() => onSelectFile(el?.name, el?.original_filename, el?.file_origins_id)} title={el?.original_filename ?? `${el?.name.split('/')[1].substring(0, 10)}...`} />
                                                                            :
                                                                            el?.original_filename.split('.')[1].toLowerCase() === 'docx' || el?.original_filename.split('.')[1].toLowerCase() === 'doc' ?
                                                                                <Docs width={50} buttonHeight={70} onClick={() => onSelectFile(el?.name, el?.original_filename, el?.file_origins_id)} title={el.original_filename ?? `${el?.name.split('/')[1].substring(0, 10)}...`} />
                                                                                :
                                                                                el?.original_filename.split('.')[1].toLowerCase() === 'xlsx' || el?.original_filename.split('.')[1].toLowerCase() === 'xls' ?
                                                                                    <Excel width={50} buttonHeight={70} onClick={() => onSelectFile(el.name, el?.original_filename, el?.file_origins_id)} title={el?.original_filename ?? `${el?.name.split('/')[1].substring(0, 10)}...`} />
                                                                                    :
                                                                                    <Images width={50} buttonHeight={70} onClick={() => onSelectFile(el?.name, el?.original_filename, el?.file_origins_id)} title={el?.original_filename ?? `${el?.name.split('/')[1].substring(0, 10)}...`} />
                                                            }
                                                        </Col>
                                                    ))
                                                }
                                            </Row>
                                            :
                                            <div className='text-center'>
                                                <Image src={EmptyFolderImage} preview={false} width={150} />
                                                <p style={{ fontSize: 18, fontWeight: 'bold', color: 'black' }}>File Not Found</p>
                                            </div>
                                        :
                                        loadedSearch ?
                                            resultFile.length !== 0 ?
                                                <table className='table-custom'>
                                                    <thead>
                                                        <tr>
                                                            <th>Kind</th>
                                                            <th>Filename</th>
                                                            <th>Total Page</th>
                                                            <th>Created At</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            resultFile.map((el, key) => (
                                                                <tr key={key} onClick={() => window.open(`${el?.filename}`)}>
                                                                    <td>
                                                                        {
                                                                            el?.filename_document?.toLowerCase().split('.')[1] === 'pdf' ?
                                                                                <Pdf width={30} buttonHeight={50} onClick={() => window.open(`${el?.filename}`)} />
                                                                                :
                                                                                el?.filename_document?.toLowerCase().split('.')[1] === 'doc' || el?.filename_document?.toLowerCase().split('.')[1] === 'docx' ?
                                                                                    <Docs width={30} buttonHeight={50} onClick={() => window.open(`${el?.filename}`)} /> :
                                                                                    el?.filename_document?.toLowerCase().split('.')[1] === 'xls' || el?.filename_document?.toLowerCase().split('.')[1] === 'xlsx' ?
                                                                                        <Excel width={30} buttonHeight={50} onClick={() => window.open(`${el?.filename}`)} /> :
                                                                                        el?.filename_document?.toLowerCase().split('.')[1] === 'ppt' || el?.filename_document?.toLowerCase().split('.')[1] === 'pptx' ?
                                                                                            <Ppt width={30} buttonHeight={50} onClick={() => window.open(`${el?.filename}`)} />
                                                                                            :
                                                                                            <Images width={30} buttonHeight={50}
                                                                                                onClick={() => window.open(`${el?.filename}`)} />
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {el?.filename_document}
                                                                    </td>
                                                                    <td>
                                                                        {el?.page}
                                                                    </td>
                                                                    <td>
                                                                        {moment(el?.created_at).format('dddd, DD MMMM YYYY')}
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                                :
                                                <div className='text-center'>
                                                    <Image src={EmptyDocumentImage} preview={false} width={150} />
                                                    <p style={{ fontSize: 18, fontWeight: 'bold', color: 'black' }}>Document not found</p>
                                                </div>
                                            :
                                            null
                                    :
                                    <div>
                                        {/* <Row justify='end' style={{ marginBottom: 20 }}>
                                            <Space>

                                                {
                                                    fileName.split('.')[1].toLowerCase() === 'docx' || fileName.split('.')[1].toLowerCase() === 'doc' || fileName.split('.')[1].toLowerCase() === 'xlsx' || fileName.split('.')[1].toLowerCase() === 'xls' || fileName.split('.')[1].toLowerCase() === 'pptx' || fileName.split('.')[1].toLowerCase() === 'ppt' ?
                                                        null :
                                                        <Button type="primary" className="button-primary" onClick={downloadFile}>Download</Button>
                                                }

                                                <Button type="primary" className="button-primary" danger onClick={deleteFile}>Delete</Button>
                                            </Space>
                                        </Row> */}

                                        {
                                            fileName.split('.')[1].toLowerCase() === 'pdf' || fileName.split('.')[1].toLowerCase() === 'jpg' || fileName.split('.')[1].toLowerCase() === 'jpeg' || fileName.split('.')[1].toLowerCase() === 'png' ?
                                                <Row>
                                                    <iframe src={`${fileNameUrl}#toolbar=0`} width="730" height="950" title="Document Review" />
                                                </Row>
                                                :
                                                <Card style={{ borderRadius: 10 }}>
                                                    <Row justify='center' className='mb-3'>
                                                        <div className='text-center'>
                                                            <h1 style={{ fontSize: 22, fontWeight: 600 }}>Document Can't be Show</h1>
                                                            <p>Download file for show Document</p>
                                                        </div>
                                                    </Row>
                                                    <Row justify='center'>
                                                        <div>
                                                            <Button type='primary' onClick={downloadFile}>Download</Button>
                                                        </div>
                                                    </Row>
                                                </Card>
                                        }
                                    </div>
                            }
                        </Card>
                    </Col>
                </Row>
            </Card>

            {
                showModalUploadDocumentSmart ?
                    <FormModal title={modalUplodaTitleSmart} submitForm={(v) => uploadSmartDms(v)} width={800} showModal={showModalUploadDocumentSmart} onCloseModal={() => setShowModalUploadDocumentSmart(false)} saving={saving || uploading}>
                        <Row justify="space-between">
                            <Col span={11}>
                                <Form.Item label="Title" name="title">
                                    <Input placeholder="Title" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item label="PDF Split" name="pdf_split">
                                    <InputNumber placeholder="PDF Split" style={{ width: '100%' }} min={0} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item>
                            <Checkbox checked={checkboxValue} onChange={changeCheckbox}>Auto Distribution</Checkbox>
                        </Form.Item>

                        {
                            !checkboxValue ?
                                <Form.Item label="Destination Folder" name="folder_id">
                                    <Select
                                        placeholder="Select Destinaton Folder"
                                        showSearch
                                        options={listFolder}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                    />
                                </Form.Item>
                                : null
                        }

                        <Form.Item label="Archive Location" name="archive_location_id">
                            <Select
                                placeholder="Select Archive Location"
                                showSearch
                                options={archiveLocation}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                            />
                        </Form.Item>

                        <Form.Item>
                            <DmsUpload path='file-attachment' multiple={false} dragger onUploaded={(v, key) => fileUploaded(v)} isUploading={(v) => setUploading(v)} />
                        </Form.Item>
                    </FormModal>
                    : null
            }

            {
                (isModalVisibleFormFolder) ?
                    <FormModal defaultValues={defaultValues} title={modalTitleFormFolder} submitForm={(v) => onFinishFolder(v)} width={600} showModal={isModalVisibleFormFolder} onCloseModal={() => setIsModalVisibleFormFolder(false)} saving={isSaving}>
                        <Form.Item name="id" hidden>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Name" name="name" required rules={[{ required: true }]}>
                            <Input placeholder="Name" />
                        </Form.Item>
                    </FormModal> :
                    null
            }

            {
                showModalRename ?
                    <FormModal form={form} title={modalRenameTitle} submitForm={(v) => onFinishRename(v)} width={400} showModal={showModalRename} onCloseModal={() => setShowModalRename(false)} saving={saving}>
                        <Form.Item name="id" hidden>
                            <Input />
                        </Form.Item>
                        <Form.Item name="original_filename" label="Name">
                            <Input placeholder='Name' />
                        </Form.Item>
                    </FormModal>
                    : null
            }

            {
                showModalMove ?
                    <FormModal form={form} title={modalMoveTitle} submitForm={(v) => onFinishMove(v)} width={400} showModal={showModalMove} onCloseModal={() => setShowModalMove(false)} saving={saving}>
                        <Form.Item name="id" hidden>
                            <Input />
                        </Form.Item>
                        <Form.Item name="folder_id" label="Destination">
                            <Select>
                                {
                                    folder.map((el, key) => (
                                        <Option key={key} value={el.id}>{el.name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </FormModal>
                    : null
            }

            {
                alertModal ?
                    <BasicModal title="Alert" width={500} showModal={alertModal} onCloseModal={() => setAlertModal(false)}>
                        <div className='mb-3'>
                            <Text>
                                This will take some time, do you want to continue?
                            </Text>
                        </div>
                        <Space>
                            <Button type='primary' onClick={buttonYes}>Yes</Button>
                            <Button type='primary' danger onClick={buttonNo}>No</Button>
                        </Space>
                    </BasicModal>
                    : null
            }

        </div>
    )
}

export default MyFolder