import React from 'react'
import moment from 'moment'
import { Image } from 'antd'
import IcFolder from '../assets/images/ic_folder.png'
import IcPdf from '../assets/images/ic_pdf.png'
import IcWord from '../assets/images/ic_word.png'
import IcExcel from '../assets/images/ic_xls.png'
import IcPpt from '../assets/images/ic_ppt.png'
import IcImage from '../assets/images/ic_images.png'

export const GenerateFolder = (data) => {
    const tree = data

    for (let i = 0; i < tree.length; i++) {
        Object.assign(tree[i], {
            key: `${moment(tree[i].created_at).format('YYYYMMDD-hhmmss')}${tree[i].id}-${tree[i].name}`
        })

        if (tree[i].type === 'FOLDER') {
            Object.assign(tree[i], {
                icon: <Image preview={false} src={IcFolder} width={20} />,
                title: tree[i].name
            })
        }

        if (tree[i].type === 'FILE') {
            if (tree[i].original_filename.split('.')[1].toLowerCase() === 'pdf') {
                Object.assign(tree[i], {
                    icon: <Image preview={false} src={IcPdf} width={20} />,
                    title: tree[i].original_filename ?? `${tree[i].name.split('/')[1].substring(0, 10)}...`
                })
            }
            if (tree[i].original_filename.split('.')[1].toLowerCase() === 'pptx' || tree[i].original_filename.split('.')[1].toLowerCase() === 'ppt') {
                Object.assign(tree[i], {
                    icon: <Image preview={false} src={IcPpt} width={20} />,
                    title: tree[i].original_filename ?? `${tree[i].name.split('/')[1].substring(0, 10)}...`
                })
            }
            if (tree[i].original_filename.split('.')[1].toLowerCase() === 'docx' || tree[i].original_filename.split('.')[1].toLowerCase() === 'doc') {
                Object.assign(tree[i], {
                    icon: <Image preview={false} src={IcWord} width={20} />,
                    title: tree[i].original_filename ?? `${tree[i].name.split('/')[1].substring(0, 10)}...`
                })
            }
            if (tree[i].original_filename.split('.')[1].toLowerCase() === 'xlsx' || tree[i].original_filename.split('.')[1].toLowerCase() === 'xls') {
                Object.assign(tree[i], {
                    icon: <Image preview={false} src={IcExcel} width={20} />,
                    title: tree[i].original_filename ?? `${tree[i].name.split('/')[1].substring(0, 10)}...`
                })
            }
            if (tree[i].original_filename.split('.')[1].toLowerCase() === 'jpg' || tree[i].original_filename.split('.')[1].toLowerCase() === 'jpeg' || tree[i].original_filename.split('.')[1].toLowerCase() === 'png') {
                Object.assign(tree[i], {
                    icon: <Image preview={false} src={IcImage} width={20} />,
                    title: tree[i].original_filename ?? `${tree[i].name.split('/')[1].substring(0, 10)}...`
                })
            }
        }
    }

    return tree;
}