import React, { useState } from 'react';
import { Row, Form, Input, Button, message, Card, Typography } from 'antd'
import { Eye, User } from 'react-feather';
import { Link } from 'react-router-dom';
import bgImg from './../../assets/images/bg-login.jpg';
import smail from './../../assets/icon/logo-smail.svg'
import { ApiService } from '../../services/ApiService';
import { useHistory } from 'react-router-dom';

const { Text } = Typography

const Forgot = () => {
    const history = useHistory()
    const [correct, setCorrect] = useState(false)
    const [data, setData] = useState([])

    const onFinish = (v) => {
        let payload = {
            ...v
        }

        ApiService.store('check-email', payload).then((res) => {
            if (res.message === 'Success') {
                setCorrect(true)
                message.success('Username dan Email cocok')
                setData(v)
            }
        }).catch(() => {
            message.warning('Username dan Email tidak cocok')
        })
    }

    const onFinishPassword = (v) => {
        let payload = {
            ...v
        }

        ApiService.store('forgot-password', payload).then((res) => {
            if (res.message === 'Success') {
                setCorrect(true)
                message.success('Sukses Reset Password')
                setTimeout(() => {
                    history.push('/login')
                }, 3000);
            }
        }).catch((err) => {
            message.warning('Password tidak cocok')
        })
    }

    return (
        <Row type="flex" align="middle" justify="center" className="px-3 bg-white mh-page" style={{ minHeight: '100vh', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundImage: `url(${bgImg})` }}>
            <Card style={{ width: '500px', borderRadius: 15, boxShadow: '1px 2px 18px 4px rgba(0,0,0,0.33)' }}>
                <div style={{ maxWidth: '400px', zIndex: 2, minWidth: '300px', margin: '0 auto' }}>
                    <div className="text-center mb-3">
                        <Link to="/login">
                            <img style={{ width: 200 }} src={smail} alt="smail"></img>
                        </Link>
                    </div>
                    <div className='text-center mb-3'>
                        <h5 className="mb-0">Reset your account SMAIL</h5>
                        <p className="text-muted">First please check your username and email</p>
                    </div>

                    {
                        correct ?
                            <Form initialValues={data} layout="vertical" onFinish={onFinishPassword}>
                                <Form.Item label="Username" name="username" rules={[{ required: true, message: 'Please input your username' }]}>
                                    <Input prefix={
                                        <User
                                            size={16}
                                            strokeWidth={1}
                                            style={{ color: 'rgba(0,0,0,.25)' }}
                                        />
                                    } placeholder="Username" />
                                </Form.Item>

                                <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Please input your email' }]}>
                                    <Input prefix={
                                        <User
                                            size={16}
                                            strokeWidth={1}
                                            style={{ color: 'rgba(0,0,0,.25)' }}
                                        />
                                    } placeholder="Username" />
                                </Form.Item>
                                <Form.Item label="New Password" name="new_password" rules={[{ required: true, message: 'Please input your new password' }]} >
                                    <Input.Password prefix={
                                        <Eye
                                            size={16}
                                            strokeWidth={1}
                                            style={{ color: 'rgba(0,0,0,.25)' }}
                                        />
                                    } type="password" placeholder="Password" />
                                </Form.Item>

                                <Form.Item label="Confirm New Password" name="confirm_password" rules={[{ required: true, message: 'Please confirm your password' }]} >
                                    <Input.Password prefix={
                                        <Eye
                                            size={16}
                                            strokeWidth={1}
                                            style={{ color: 'rgba(0,0,0,.25)' }}
                                        />
                                    } type="password" placeholder="Password" />
                                </Form.Item>

                                <Form.Item>
                                    <Text>
                                        Back to
                                        <Link to="/login">
                                            <span> </span>
                                            <span className="text-xs-right">
                                                Login
                                            </span>
                                        </Link>
                                    </Text>

                                    <Button type="primary" htmlType="submit" block className="button-primary mt-5">
                                        Reset Password
                                    </Button>
                                </Form.Item>
                            </Form>
                            :
                            <Form layout="vertical" onFinish={onFinish}>
                                <Form.Item label="Username" name="username" rules={[{ required: true, message: 'Please input your username' }]}>
                                    <Input prefix={
                                        <User
                                            size={16}
                                            strokeWidth={1}
                                            style={{ color: 'rgba(0,0,0,.25)' }}
                                        />
                                    } placeholder="Username" />
                                </Form.Item>

                                <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Please input your email' }]}>
                                    <Input prefix={
                                        <User
                                            size={16}
                                            strokeWidth={1}
                                            style={{ color: 'rgba(0,0,0,.25)' }}
                                        />
                                    } placeholder="Username" />
                                </Form.Item>
                                <Form.Item>
                                    <span>
                                        <Text>
                                            {`Back to `}
                                        </Text>
                                        <Link to="/login">
                                            Login
                                        </Link>
                                    </span>
                                    <Button type="primary" htmlType="submit" block className="mt-3">
                                        Check Username and Email
                                    </Button>
                                </Form.Item>
                            </Form>
                    }

                </div>
            </Card>
        </Row>
    );
}

export default Forgot;
