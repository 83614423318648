import React, { useState } from 'react';
import { Modal, Space, Image } from 'antd'
import { ZoomInOutlined, EditOutlined } from '@ant-design/icons'
import '../assets/image-hover.css';

export const ImageViewer = (props) => {
    const { previewable = false, onEdit = false, src = process.env.PUBLIC_URL + '/images/noimage.png' } = props
    const [showModal, setShowModal] = useState(false)
    return (
        <div>
            {
                (previewable || onEdit) ?
                    <div className="image-hover-container">
                        <Image src={src} alt="" className="image-hover" style={{ width: '100%' }}
                            fallback={process.env.PUBLIC_URL + '/images/noimage.png'} />
                        <div className="image-hover-middle">
                            <div className="image-hover-text">
                                <Space>
                                    {
                                        (previewable) ?
                                            <ZoomInOutlined onClick={() => setShowModal(true)} style={{ fontSize: 25, color: '#000', cursor: 'pointer' }} /> : null
                                    }
                                    {
                                        (onEdit) ?
                                            <EditOutlined onClick={props.onEdit} style={{ fontSize: 25, color: '#000', cursor: 'pointer' }} /> : null
                                    }
                                </Space>
                            </div>
                        </div>
                    </div> :
                    <img src={src} alt="Avatar" style={{ width: '100%' }} />
            }

            {
                showModal ?
                    <Modal
                        visible={showModal}
                        title={null}
                        width={700}
                        footer={null}
                        onCancel={() => setShowModal(false)}
                    >
                        <img alt="example" style={{ width: '100%' }} src={src} />
                    </Modal> : null
            }
        </div>
    );
}

