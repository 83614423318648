import React, { useState, useEffect } from 'react'
import { Typography, Card, Form, Input, Switch, message, Space } from 'antd'
import { EditButton, DeleteButton, DataTable, PageHeader, Spinner, FormModal, ShowButton } from '../../../components'
import { ApiService } from '../../../services/ApiService'
import { currentUser } from '../../../utils/authData'
import { useHistory } from 'react-router-dom'

const { Text } = Typography

const Roles = () => {
    const history = useHistory()
    const [loaded, setLoaded] = useState(true)
    const [role, setRole] = useState([])
    const [modalTitle, setModalTitle] = useState(null)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})
    const [isSaving, setIsSaving] = useState(false)
    const [status, setStatus] = useState(false)
    const user = currentUser

    const fetchRole = () => {
        setLoaded(false);
        ApiService.getList('role').then((res) => {
            setRole(res.data)
            setLoaded(true)
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const add = () => {
        setModalTitle('Add Roles')
        setDefaultValues({
            id: 0,
            name: '',
            status: true
        })
        setIsModalVisible(true)
        setStatus(true)
    }

    const edit = (v) => {
        setModalTitle('Edit Roles')
        setDefaultValues({
            id: v.id,
            name: v.name,
            status: parseInt(v.status)
        })
        setIsModalVisible(true)
        setStatus(v.status)
    }

    const destroy = (id) => {
        ApiService.destroy('role', id, { user: user.id }).then((res) => {
            message.success(res.message, 2)
            fetchRole()
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const onFinish = (el) => {
        let payload = {
            ...el,
            status: status ? 1 : 0
        }

        if (el.id === 0) {
            Object.assign(payload, { created_by: user.id, updated_by: user.id })
            setIsSaving(true)
            ApiService.store(`role`, payload)
                .then((res) => {
                    message.success(res.message, 2)
                    setIsModalVisible(false)
                    setIsSaving(false)
                    fetchRole()
                }).catch((err) => {
                    message.warning(err.message, 2)
                })
        } else {
            Object.assign(payload, { updated_by: user.id })
            setIsSaving(true)
            ApiService.update(`role`, el.id, payload)
                .then((res) => {
                    message.success(res.message, 2)
                    setIsModalVisible(false)
                    setIsSaving(false)
                    fetchRole()
                }).catch((err) => {
                    message.warning(err.message, 2)
                })
        }
    }

    useEffect(() => {
        fetchRole()
    }, []);

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Nama',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Total Permission',
            key: 'total_permission',
            render: (row) => (
                row?.permission?.length
            ),
            align: 'center',
            width: '15%'
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <ShowButton onShow={() => history.push(`/roles-permissions/roles/detail/${row.id}`)} />
                        <EditButton onEdit={() => edit(row)} />
                        <DeleteButton onConfirm={() => destroy(row.id)} />
                    </Space>
                </div>
            )
        },
    ];

    return (
        <div>
            <PageHeader title="Roles" breadcrumbs={[['Roles & Permission'], ['Roles']]} onAdd={add} />

            <Card style={{ borderRadius: 10 }}>

                {
                    (loaded) ?
                        <DataTable size="small" columns={columns} sources={role} bordered />
                        : <Spinner />
                }

                {
                    (isModalVisible) ?
                        <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => onFinish(v)} width={600} showModal={isModalVisible} onCloseModal={() => setIsModalVisible(false)} saving={isSaving}>
                            <Form.Item name="id" hidden>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Name" name="name" required rules={[{ required: true }]}>
                                <Input placeholder="Name" />
                            </Form.Item>
                            <Form.Item label="Status" name="status" rules={[{ required: true }]}>
                                <Switch checkedChildren="Yes" unCheckedChildren="No" onChange={(v) => setStatus(v)} defaultChecked={defaultValues.status} />
                            </Form.Item>
                            <Text>Inputs marked with (<span style={{ color: 'red' }}>*</span>) are required</Text>
                        </FormModal> :
                        null
                }
            </Card>
        </div>
    )
}

export default Roles;
