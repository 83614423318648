import React, { useState, useEffect } from 'react'
import { Typography, Card, Form, Input, Switch, Select, message, Space, Tag, DatePicker } from 'antd'
import { EditButton, DeleteButton, DataTable, PageHeader, Spinner, FormModal } from '../../../components'
import { ApiService } from '../../../services/ApiService'
import moment from 'moment'

const { Option } = Select
const { RangePicker } = DatePicker
const { Text } = Typography

const ApiKey = () => {
    const [loaded, setLoaded] = useState(true)
    const [customerLicense, setCustomerLicenses] = useState([])
    const [customer, setCustomer] = useState([])
    const [modalTitle, setModalTitle] = useState(null)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})
    const [isSaving, setIsSaving] = useState(false)
    const [status, setStatus] = useState(false)

    const fetchCustomerLicenses = () => {
        setLoaded(false);
        ApiService.getList('customer-license').then((res) => {
            setCustomerLicenses(res.data)
            setLoaded(true)
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const fetchCustomer = () => {
        ApiService.getList('customer').then((res) => {
            setCustomer(res.data)
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const add = () => {
        setModalTitle('Tambah Customer Licenses')
        setDefaultValues({
            id: 0,
            customer_id: '',
            serial_number: '',
            star_date: '',
            end_date: '',
            status: true
        })
        setIsModalVisible(true)
        setStatus(true)
    }

    const edit = (v) => {
        setModalTitle('Edit Customer Licenses')
        setDefaultValues({
            id: v.id,
            customer_id: v.customer_id,
            serial_number: v.serial_number,
            expired: [moment(v.start_date), moment(v.end_date)],
            status: parseInt(v.status)
        })
        setIsModalVisible(true)
        setStatus(v.status)
    }

    const destroy = (id) => {
        ApiService.destroy('customer', id).then((res) => {
            message.success(res.message, 2)
            fetchCustomerLicenses()
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const onFinish = (el) => {
        let payload = {
            ...el,
            start_date: moment(el.expired[0]).format('YYYY-MM-DD'),
            end_date: moment(el.expired[1]).format('YYYY-MM-DD'),
            status: status ? 1 : 0
        }

        if (el.id === 0) {
            setIsSaving(true)
            ApiService.store(`customer-license`, payload)
                .then((res) => {
                    message.success(res.message, 2)
                    setIsModalVisible(false)
                    setIsSaving(false)
                    fetchCustomerLicenses()
                }).catch((err) => {
                    message.warning(err.message, 2)
                })
        } else {
            setIsSaving(true)
            ApiService.update(`customer-license`, el.id, payload)
                .then((res) => {
                    message.success(res.message, 2)
                    setIsModalVisible(false)
                    setIsSaving(false)
                    fetchCustomerLicenses()
                }).catch((err) => {
                    message.warning(err.message, 2)
                })
        }
    }

    useEffect(() => {
        fetchCustomerLicenses()
        fetchCustomer()
    }, []);

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Customer',
            dataIndex: 'customer',
            key: 'customer_id',
            align: 'center'
        },
        {
            title: 'Serial Number',
            dataIndex: 'serial_number',
            key: 'serial_number',
            align: 'center'
        },
        {
            title: 'Start Date',
            render: (row) => (
                moment(row?.start_date).format('DD-MM-YYYY')
            ),
            key: 'start_date',
            align: 'center'
        },
        {
            title: 'End Date',
            render: (row) => (
                moment(row?.end_date).format('DD-MM-YYYY')
            ),
            key: 'end_date',
            align: 'center'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            align: 'center',
            key: 'status',
            render: (text) => (
                <Tag color={text === 1 ? "green" : "red"}>{(text === 1) ? 'Active' : 'In Active'}</Tag>
            ),
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => edit(row)} />
                        <DeleteButton onConfirm={() => destroy(row.id)} />
                    </Space>
                </div>
            ),
            align: 'center'
        },
    ];

    return (
        <div>
            <PageHeader title="Api Key" breadcrumbs={[['Access Control'], ['Api Key']]} onAdd={add} />

            <Card style={{ borderRadius: 10 }}>

                {
                    (loaded) ?
                        <DataTable size="small" columns={columns} sources={customerLicense} bordered />
                        : <Spinner />
                }

                {
                    (isModalVisible) ?
                        <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => onFinish(v)} width={600} showModal={isModalVisible} onCloseModal={() => setIsModalVisible(false)} saving={isSaving}>
                            <Form.Item name="id" hidden>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Customer" name="customer_id" required rules={[{ required: true }]}>
                                <Select>
                                    {
                                        customer.map((el, key) => (
                                            <Option key={key} value={el.id}>{el.name}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item label="Serial Number" name="serial_number">
                                <Input placeholder="Serial Number" />
                            </Form.Item>
                            <Form.Item label="Expired" name="expired">
                                <RangePicker />
                            </Form.Item>
                            <Form.Item label="Status" name="status" rules={[{ required: true }]}>
                                <Switch checkedChildren="Ya" unCheckedChildren="Tidak" onChange={(v) => setStatus(v)} defaultChecked={defaultValues.status} />
                            </Form.Item>
                            <Text>Inputan dengan tanda (<span style={{ color: 'red' }}>*</span>) wajib diisi</Text>
                        </FormModal> :
                        null
                }
            </Card>
        </div>
    )
}

export default ApiKey;
