import { useState, useEffect } from 'react'
import { Row, Col, Card, message, Button, Form, Input, Typography, Tabs, Select, Descriptions } from 'antd'
import { DataTable, FormModal, PageHeader } from '../../components'
import { useParams } from 'react-router-dom'
import { ApiService } from '../../services/ApiService'
import moment from 'moment'
import LetterDetail from './detail-tabs/tabs-detail'

const { TextArea } = Input;
const { Title } = Typography;
const { TabPane } = Tabs;
const { Option } = Select;
const defaultTab = (sessionStorage.getItem('selectedDashboardTab')) ? sessionStorage.getItem('selectedDashboardTab') : 'incoming'

const DocumentIncomingDetail = () => {
    const { id } = useParams()
    const [documentDetail, setDocumentDetail] = useState([])
    const [logDisposition, setLogDisposition] = useState([])
    const [department, setDepartment] = useState([])
    const [employee, setEmployee] = useState([])
    const [archiveLocation, setArchiveLocation] = useState([])
    const [actionDisposition, setActionDisposition] = useState([])
    const [responseDisposition, setResponseDisposition] = useState([])
    const [defaultValues, setDefaultValues] = useState({})
    const [loaded, setLoaded] = useState(false)
    const [showModalDisposition, setShowModalDisposition] = useState(false)
    const [showModalEndDisp, setShowModalEndDisp] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [dispositionModalTitle, setDispositionModalTitle] = useState('')
    const [endDispModalTitle, setEndDispModalTitle] = useState('')
    const [level, setLevel] = useState(0)

    const fetchIncomingDocumentDetail = () => {
        ApiService.getObject('incoming-document', id).then((res) => {
            setDocumentDetail(res.data)
            console.log(res.data)
            setLevel(res.data.letter_movement[res.data.letter_movement.length - 1].level)
            setLogDisposition(res.data.letter_movement)
            setLoaded(true)
        })
    }

    const fetchDepartment = () => {
        ApiService.getList('department').then((res) => {
            setDepartment(res.data)
        }).catch((err) => {
        })
    }

    const fetchEmployee = (el) => {
        ApiService.getObject('employee-by-department', el).then((res) => {
            setEmployee(res.data)
        }).catch((err) => {
        })
    }

    const fetchArchiveLocation = () => {
        ApiService.getList('archive-location').then((res) => {
            setArchiveLocation(res.data)
        }).catch((err) => {
        })
    }

    const fetchResponseDisposition = () => {
        ApiService.getList('response-disposition').then((res) => {
            setResponseDisposition(res.data)
        }).catch((err) => {
        })
    }

    const fetchActionDisposition = () => {
        ApiService.getList('action-disposition').then((res) => {
            setActionDisposition(res.data)
        }).catch((err) => {
        })
    }

    useEffect(() => {
        fetchIncomingDocumentDetail()
        fetchDepartment()
        fetchArchiveLocation()
        fetchResponseDisposition()
        fetchActionDisposition()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columnsLog = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '3%'
        },
        {
            title: 'Document Date',
            render: (row) => (
                moment(row?.date).locale('id').format('dddd, D MMMM YYYY')
            ),
            key: 'date',
            align: 'center'
        },
        {
            title: 'Instruction',
            render: (row) => (
                row?.action_disposition?.action_disposition_name ?? '-'
            ),
            key: 'subject',
            align: 'center'
        },
        {
            title: 'Action Description',
            render: (row) => (
                row?.action_description ?? '-'
            ),
            key: 'subject',
            align: 'center'
        },
        {
            title: 'Disposition',
            render: (row) => (
                row?.receiver_user?.name
            ),
            key: 'to',
            align: 'center'
        },
        {
            title: 'Response Disposition Name',
            render: (row) => (
                row?.response_disposition?.response_disposition_name ?? '-'
            ),
            key: 'subject',
            align: 'center'
        },
        {
            title: 'Descrtipion',
            render: (row) => (
                row?.response_description ?? '-'
            ),
            key: 'subject',
            align: 'center'
        },
    ];

    const changeEmployee = (el) => {
        fetchEmployee(el)
    }

    const changeTab = (selected) => {
        sessionStorage.setItem('selectedDashboardTab', selected)
    }

    const setDisposition = () => {
        setDispositionModalTitle("Disposition Document")
        setDefaultValues({
            action_disposition_id: '',
            action_description: '',
        })
        setShowModalDisposition(true)
    }

    const setEndDisposition = () => {
        setEndDispModalTitle("End Disposition Document")
        setDefaultValues({
            archive_location_id: '',
        })
        setShowModalEndDisp(true)
    }

    const onFinishDisposition = (el) => {
        let payload = {
            ...el,
            movement_id: documentDetail.letter_movement[documentDetail.letter_movement.length - 1].id,
            letter_id: id,
            type: 'DISP',
            status: 1,
            level: level,
            physical_file: 1,
            status_disposition: 1,
        }

        setIsSaving(false)
        setLoaded(false)
        ApiService.store('add-disposition', payload).then((res) => {
            message.success(res.message)
            setIsSaving(true)
            setShowModalDisposition(false)
            setTimeout(() => {
                fetchIncomingDocumentDetail()
            }, 500);
        }).catch((err) => {
            message.warning(err.response.data.message)
        })
    }

    const onFinishEndDisposition = (el) => {
        let payload = {
            ...el,
            movement_id: documentDetail.letter_movement[documentDetail.letter_movement.length - 1].id
        }

        setIsSaving(false)
        ApiService.update('end-disposition', id, payload).then((res) => {
            message.success(res.message)
            setIsSaving(true)
            setShowModalEndDisp(false)
            setTimeout(() => {
                fetchIncomingDocumentDetail()
            }, 500);
        }).catch((err) => {
            message.warning(err.response.data.message)
        })
    }

    return (
        <div>
            <PageHeader title="Incoming Document" breadcrumbs={[['Incoming Document'], ['Detail']]} createLink={'/surat-masuk/create'} />
            <Card style={{ borderRadius: 10 }}>
                <Tabs defaultActiveKey={defaultTab} onChange={changeTab}>
                    {
                        loaded ?
                            <>
                                <TabPane tab="Incoming Document" key="incoming">
                                    <LetterDetail data={documentDetail} />
                                </TabPane>
                                {
                                    documentDetail.type === 'DISP' ?
                                        <TabPane tab="Disposition" key="dispotision">
                                            <Row className='mb-4' justify='space-between'>
                                                <Col>
                                                    <Title style={{ fontSize: 22, fontWeight: 400 }}>Document Number: {documentDetail?.letter_number}</Title>
                                                </Col>
                                                <Col>
                                                    <>
                                                        <Button size='middle' type='danger' style={{ borderRadius: 10, marginRight: 20 }} onClick={setEndDisposition}>End Disposition</Button>
                                                        <Button size='middle' type='primary' style={{ borderRadius: 10 }} onClick={setDisposition}>Disposisikan</Button>
                                                    </>
                                                </Col>
                                            </Row>
                                            <Row className='mb-5'>
                                                <Card bodyStyle={{ border: '0.4px solid #efefef', borderRadius: 10, width: '100%' }}>
                                                    <Descriptions labelStyle={{ fontWeight: 600 }} layout="vertical">
                                                        <Descriptions.Item label="Document Date">{moment(documentDetail?.date).locale('id').format('dddd, D MMMM YYYY')}</Descriptions.Item>
                                                        <Descriptions.Item label="Sender Document">{documentDetail?.created?.name}</Descriptions.Item>
                                                        <Descriptions.Item label="Archive Location">{documentDetail?.archive_location?.archive_location_name ?? '-'}</Descriptions.Item>
                                                        <Descriptions.Item label="Document Type">
                                                            {documentDetail?.letter_type?.letter_type_name ?? '-'}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label="Subject">{documentDetail?.subject}</Descriptions.Item>
                                                    </Descriptions>
                                                </Card>
                                            </Row>

                                            <DataTable sources={logDisposition} columns={columnsLog} search={false} />
                                        </TabPane> : null
                                }
                            </>
                            : null
                    }
                </Tabs>
            </Card>

            {
                showModalDisposition ?
                    <FormModal defaultValues={defaultValues} title={dispositionModalTitle} submitForm={(v) => onFinishDisposition(v)} width={600} showModal={showModalDisposition} onCloseModal={() => setShowModalDisposition(false)} saving={isSaving}>
                        <Form.Item label="Response Disposition" name="response_id" rules={[{ required: true }]}>
                            <Select>
                                {
                                    responseDisposition.map((el, key) => (
                                        <Option value={el.response_disposition_id} key={key}>
                                            {el.response_disposition_name}
                                        </Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label="Response Description" name="response_description" rules={[{ required: true }]}>
                            <TextArea placeholder="Response Description" />
                        </Form.Item>
                        <Form.Item label="Department" name="receiver_department_id" rules={[{ required: true }]}>
                            <Select onChange={changeEmployee}>
                                {
                                    department.map((el, key) => (
                                        <Option value={el.department_id} key={key}>
                                            {el.department_name}
                                        </Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label="Disposition" name="receiver_user_id" rules={[{ required: true }]}>
                            <Select>
                                {
                                    employee.map((el, key) => (
                                        <Option value={el.id} key={key}>
                                            {el.name}
                                        </Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label="Action Disposition" name="action_id" rules={[{ required: true }]}>
                            <Select>
                                {
                                    actionDisposition.map((el, key) => (
                                        <Option value={el.action_disposition_id} key={key}>
                                            {el.action_disposition_name}
                                        </Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label="Action Description" name="action_description" rules={[{ required: true }]}>
                            <TextArea placeholder="Action Description" />
                        </Form.Item>
                    </FormModal>
                    : null
            }

            {
                showModalEndDisp ?
                    <FormModal defaultValues={defaultValues} title={endDispModalTitle} submitForm={(v) => onFinishEndDisposition(v)} width={600} showModal={showModalEndDisp} onCloseModal={() => setShowModalEndDisp(false)} saving={isSaving}>
                        <Form.Item label="Response Disposition" name="response_id" rules={[{ required: true }]}>
                            <Select>
                                {
                                    responseDisposition.map((el, key) => (
                                        <Option value={el.response_disposition_id} key={key}>
                                            {el.response_disposition_name}
                                        </Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label="Response Description" name="response_description" rules={[{ required: true }]}>
                            <TextArea placeholder="Response Description" />
                        </Form.Item>
                        <Form.Item label="Archive Location" name="archive_location_id" rules={[{ required: true }]}>
                            <Select>
                                {
                                    archiveLocation.map((el, key) => (
                                        <Option value={el.id} key={key}>
                                            {el.name}
                                        </Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </FormModal>
                    :
                    null
            }
        </div>
    )
}

export default DocumentIncomingDetail