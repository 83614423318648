import React, { useState, useEffect } from 'react'
import { Button, Card } from 'antd'
import { DataTable, PageHeader, Spinner } from './../../components'
import { ApiService } from '../../services/ApiService'
import moment from 'moment/moment'

const FileLog = () => {
    const [loaded, setLoaded] = useState(false)
    const [fileLog, setFileLog] = useState([])

    const fetchFileLog = () => {
        ApiService.getList('file-log').then((res) => {
            setFileLog(res.data)
            setLoaded(true)
        }).catch(() => {
        })
    }

    useEffect(() => {
        fetchFileLog()

    }, []);

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Original Filename',
            dataIndex: 'original_filename',
            key: 'original_filename',
            align: 'center'
        },
        {
            title: 'Url',
            key: 'filename',
            dataIndex: 'filename',
            render: (row) => (
                <Button onClick={() => window.open(row)}>Preview</Button>
            ),
            align: 'center'
        },
        {
            title: 'Created At',
            key: 'created_at',
            render: (row) => (
                moment(row.createdAt).format('DD MMMM YYYY hh:mm:ss')
            ),
            align: 'center'
        },
        {
            title: 'Created By',
            key: 'created_by',
            render: (row) => (
                row?.created?.name ?? ''
            ),
            align: 'center'
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            align: 'center'
        },
    ];

    return (
        <div>
            <PageHeader title="Document Log" breadcrumbs={[['Document Log']]} />
            <Card style={{ borderRadius: 10 }}>
                {
                    loaded ?
                        <DataTable size="small" columns={columns} sources={fileLog} bordered />
                        : <Spinner />
                }
            </Card>
        </div>
    )
}

export default FileLog
