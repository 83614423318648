import React, { useState, useEffect } from 'react';
import { Card, Form, Input, Button, Select, Typography, DatePicker, message, Col, Row, Radio, Space, Switch, InputNumber } from 'antd';
import { DeleteFilled, PlusOutlined } from '@ant-design/icons';
import { PageHeader, FileUpload, FormModal, DetailModal, DataTable, SelectButton } from '../../components';
import { ApiService } from '../../services/ApiService';
import { Editor } from '@tinymce/tinymce-react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { currentUser } from '../../utils/authData'

const { Text, Title } = Typography
const { TextArea } = Input
const { Option } = Select

const CreateSuratKeluar = () => {
    const history = useHistory()
    const [form] = Form.useForm()
    const [saving, setSaving] = useState(false)
    // eslint-disable-next-line
    const [uploading, setUploading] = useState(false)
    const [department, setDepartment] = useState([])
    const [letterType, setLetterType] = useState([])
    const [letterClassification, setLetterClassification] = useState([])
    const [letterPriority, setLetterPriority] = useState([])
    const [letterOption, setLetterOption] = useState([])
    const [courier, setCourier] = useState([])
    const [archiveLocation, setArchiveLocation] = useState([])
    const [receiver, setReceiver] = useState([])
    const [actionDisposition, setActionDisposition] = useState([])
    const [content, setContent] = useState('')
    const [attachmentFile, setAttachmentFile] = useState([])
    const [radio, setRadio] = useState(null)
    const [status, setStatus] = useState('')
    // eslint-disable-next-line
    const [letterNumber, setLetterNumber] = useState([])
    const [bookingModalIsVisible, setBookingModalIsVisible] = useState(false)
    const [listBookingIsVisible, setListBookingIsVisible] = useState(false)
    const [archiveLocationModal, setArchiveLocationModal] = useState(false)
    const [statusArchive, setStatusArchive] = useState('')
    const [archiveModalTitle, setArchiveModalTitle] = useState('')
    const [archiveLocatinDefaultValues, setArchiveLocationDefaultValues] = useState([])
    const [courierModalTitle, setCourierModalTitle] = useState('')
    const [courierModal, setCourierModal] = useState(false)
    const [courierDefaultValues, setCourierDefaultValues] = useState([])
    const [statusCourier, setStatusCourier] = useState('')
    // eslint-disable-next-line
    const [modalBookingTitle, setModalBookingTitle] = useState('')
    // eslint-disable-next-line
    const [listBooking, setListBooking] = useState([])
    // eslint-disable-next-line
    const [defaultValues, setDefaultValues] = useState([])
    const user = currentUser

    const fileUploaded = (v) => {
        let atcs = attachmentFile
        if (v.message === 'Success') {
            atcs.push({ filename: v.response.data.filename })
        }
        setAttachmentFile(atcs)
    }

    const handleEditorChange = (content) => {
        setContent(content);
    }

    const fetchDepartment = () => {
        ApiService.getList('department').then((res) => {
            setDepartment(res.data)
        }).catch((err) => {
            message.warning(err.response.data.message, 2)
        })
    }

    const fetchLetterType = () => {
        ApiService.getList('letter-type').then((res) => {
            setLetterType(res.data)
        }).catch((err) => {
            message.warning(err.response.data.message, 2)
        })
    }

    const fetchLetterClassification = () => {
        ApiService.getList('letter-classification').then((res) => {
            setLetterClassification(res.data)
        }).catch((err) => {
            message.warning(err.response.data.message, 2)
        })
    }

    const fetchCourier = () => {
        ApiService.getList('courier').then((res) => {
            setCourier(res.data)
        }).catch((err) => {
            message.warning(err.response.data.message, 2)
        })
    }

    const fetchLetterPriority = () => {
        ApiService.getList('letter-priority').then((res) => {
            setLetterPriority(res.data)
        }).catch((err) => {
            message.warning(err.response.data.message, 2)
        })
    }

    const fetchArchiveLocation = () => {
        ApiService.getList('archive-location').then((res) => {
            setArchiveLocation(res.data)
        }).catch((err) => {
            message.warning(err.response.data.message, 2)
        })
    }

    const fetchReceiver = (el) => {
        ApiService.getObject('employee-by-department', el).then((res) => {
            setReceiver(res.data)
        }).catch((err) => {
            message.warning(err.response.data.message, 2)
        })
    }

    const fetchActionDisposition = () => {
        ApiService.getList('action-disposition').then((res) => {
            setActionDisposition(res.data)
        }).catch((err) => {
            message.warning(err.response.data.message, 2)
        })
    }

    const fetchLetterOption = () => {
        ApiService.getList('letter-option').then((res) => {
            setLetterOption(res.data)
        }).catch((err) => {
            message.warning(err.response.data.message, 2)
        })
    }

    const fetchLetterNumber = (el) => {
        ApiService.getObject('letter-number', el).then((res) => {
            setLetterNumber(res.data)
            form.setFieldsValue({ letter_number: res.data, modal_letter_number: res.data });
        }).catch((err) => {
            message.warning('Nomor surat belum terdaftar')
        })
    }

    // const fetchBookingLetter = () => {
    //     ApiService.getList('letter-number-booking').then((res) => {
    //         setListBooking(res.data)
    //     }).catch((err) => {
    //     })
    // }

    const changeEmployee = (el) => {
        fetchReceiver(el)
        const receiver = form.getFieldValue('receiver')
        receiver.map((e) => {
            return e.user_id = null
        })
    }

    const changeLetterNumber = (el) => {
        fetchLetterNumber(el)
    }

    const changeNumberFormat = (el) => {
        fetchLetterNumber(el)
    }

    // const bookingModal = () => {
    //     setBookingModalIsVisible(true)
    //     setDefaultValues({
    //         id: 0,
    //         letter_type_id: '',
    //         letter_sequence: '',
    //     })
    //     setModalBookingTitle('Booking Nomor Dokumen')
    // }

    // const listBookingModal = () => {
    //     setListBookingIsVisible(true)
    //     setModalBookingTitle('List Booking Nomor Dokumen')
    // }

    const addCourier = () => {
        setCourierModalTitle('Tambah Kurir')
        setCourierDefaultValues({
            courier_id: 0,
            courier_name: '',
            code: '',
            status: true
        })
        setCourierModal(true)
        setStatusCourier(true)
    }

    const addArchiveLocation = () => {
        setArchiveModalTitle('Tambah Lokasi Arsip')
        setArchiveLocationDefaultValues({
            archive_location_id: 0,
            archive_location_name: '',
            code: '',
            status: true,
        })
        setArchiveLocationModal(true)
        setStatusArchive(true)
    }

    const onFinishBooking = (v) => {
        const data = {
            letter_type_id: v.modal_letter_type_id,
            letter_number: v.modal_letter_number,
        }
        ApiService.store('letter-number-booking', data).then((res) => {
            message.success(res.message)
        }).catch((err) => {
            message.warning(err.response.data.message)
        })
    }

    const onFinish = (v) => {
        setSaving(true)
        const data = {
            body: content,
            from_user: v.from_user,
            from_department_id: user.from_department_id,
            receiver_eksternal: v.receiver_eksternal,
            subject: v.subject,
            letter_number: v.letter_number,
            letter_option_id: radio,
            date: moment(v.letter_date).format('YYYY-MM-DD'),
            total_attachment: v.total_attachment,
            letter_type_id: v.letter_type_id,
            letter_classification_id: v.letter_classification_id,
            letter_priority_id: v.letter_priority_id,
            archive_location_id: v.archive_location_id,
            courier_id: v.courier_id,
            status: status,
            receiver: v.receiver ? v.receiver : null,
            attachment_file: attachmentFile,
            booking_id: v.booking_id ?? 0,
            created_by: user.id,
            updated_by: user.id
        }
        ApiService.store(`outgoing-mail`, data)
            .then((res) => {
                message.success(res.message, 2);
                setTimeout(() => {
                    history.push('/outgoing-document')
                }, 1000);
                setSaving(false)
            }).catch((err) => {
                message.warning(err.response.data.message, 2)
            })
    }

    const onFinishArchiveLocation = (el) => {
        let payload = {
            ...el,
            status: statusArchive ? 1 : 0
        }

        setSaving(true)
        ApiService.store(`archive-location`, payload)
            .then((res) => {
                message.success(res.message, 2)
                setArchiveLocationModal(false)
                setSaving(false)
                fetchArchiveLocation()
            }).catch((err) => {
                message.warning(err.response.data.message, 2)
            })
    }

    const onFinishCourier = (el) => {
        let payload = {
            ...el,
            status: statusCourier ? 1 : 0
        }

        setSaving(true)
        ApiService.store(`courier`, payload)
            .then((res) => {
                message.success(res.message, 2)
                setCourierModal(false)
                setSaving(false)
                fetchCourier()
            }).catch((err) => {
                message.warning(err.response.data.message, 2)
            })
    }

    const select = (v) => {
        form.setFieldsValue({ letter_type_id: v.letter_type_id, letter_number: v.letter_number, booking_id: v.id })
        setListBookingIsVisible(false)
    }

    useEffect(() => {
        fetchArchiveLocation()
        fetchCourier()
        fetchDepartment()
        fetchLetterClassification()
        fetchLetterPriority()
        fetchLetterType()
        fetchActionDisposition()
        fetchLetterOption()
        // fetchBookingLetter()
    }, []);

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Tipe Dokumen',
            dataIndex: 'letter_type',
            key: 'letter_type',
            align: 'center'
        },
        {
            title: 'No Dokumen',
            dataIndex: 'letter_number',
            key: 'letter_number',
            align: 'center'
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <SelectButton onSelect={() => select(row)} />
                    </Space>
                </div>
            ),
            align: 'center'
        },
    ];

    return (
        <div>
            <PageHeader title="Dokumen Keluar" breadcrumbs={[['Home'], ['Dokumen Keluar'], ['Tambah']]} />

            <Card style={{ borderRadius: 15 }} className="content-container">
                <Card>
                    <Form onFinish={onFinish} form={form} layout='vertical'>
                        <div className='mb-4'>
                            <Title style={{ fontSize: 18, fontWeight: 'bold' }}>DOCUMENT INFORMATION</Title>
                        </div>

                        <Row justify='space-between'>
                            <Col span={11}>
                                <Form.Item label="Sender" name="from_user" rules={[{ required: true }]}>
                                    <Input placeholder="Sender" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item label="Subject" name="subject" rules={[{ required: true }]}>
                                    <Input placeholder='Subject' />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row justify='space-between'>
                            <Col span={11}>
                                <Form.Item label="Document Type" name="letter_type_id" rules={[{ required: true }]}>
                                    <Select placeholder="Document Type" onChange={changeLetterNumber}>
                                        {
                                            letterType.map((el, key) => (
                                                <Option key={key} value={el.letter_type_id}>{el.letter_type_name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item label="Date" name="letter_date" rules={[{ required: true }]}>
                                    <DatePicker placeholder="Date" style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row justify='space-between'>
                            <Col span={11}>
                                <Form.Item label="Document Number" name="letter_number" rules={[{ required: true }]}>
                                    <Input disabled />
                                </Form.Item>
                                <Form.Item name="booking_id" hidden>
                                    <Input />
                                </Form.Item>
                            </Col>
                            {
                                /* 
                                <Col span={11}>
                                    <div style={{ marginTop: 30, display: 'flex', justifyContent: 'start' }}>
                                        <div style={{ marginRight: 15 }}>
                                            <Button type="primary" className="button-primary" shape='round' onClick={() => listBookingModal()}> Pilih No Dokumen Booking</Button>
                                        </div>
                                        <div>
                                            <Button type="primary" className="button-primary" shape='round' icon={<PlusOutlined />} style={{ backgroundColor: "#63a67a", border: 'none' }} onClick={() => bookingModal()}>Booking No Dokumen</Button>
                                        </div>
                                    </div>
                                </Col> 
                                */
                            }
                        </Row>

                        <Row justify='space-between'>
                            <Col span={11}>
                                <Form.Item label="Document Classification" name="letter_classification_id" rules={[{ required: true }]}>
                                    <Select placeholder="Document Classification">
                                        {
                                            letterClassification.map((el, key) => (
                                                <Option key={key} value={el.letter_classification_id}>{el.letter_classification_name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={11}>
                                <Form.Item label="Document Priority" name="letter_priority_id" rules={[{ required: true }]}>
                                    <Select placeholder="Document Priority">
                                        {
                                            letterPriority.map((el, key) => (
                                                <Option key={key} value={el.letter_priority_id}>{el.letter_priority_name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item label="Document Option" name="letter_option_id" rules={[{ required: true }]}>
                            <Radio.Group value={radio}>
                                {
                                    letterOption.map((el, key) => (
                                        <Radio key={key} value={el.letter_option_id} onClick={() => setRadio(el.letter_option_id)}>{el.letter_option_name}</Radio>
                                    ))
                                }
                            </Radio.Group>
                        </Form.Item>

                        <Row justify='space-between'>
                            {
                                radio === 2 ?
                                    <Col span={11}>
                                        <Row justify='space-between'>
                                            <Col span={15}>
                                                <Form.Item label="Courier" name="courier_id" rules={[{ required: true }]}>
                                                    <Select placeholder="Courier">
                                                        {
                                                            courier.map((el, key) => (
                                                                <Option key={key} value={el.courier_id}>{el.courier_name}</Option>
                                                            ))
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col>
                                                <Button type='primary' shape='round' style={{ backgroundColor: '#39ba94', border: 'none', marginTop: 30 }} onClick={addCourier}>Add Courier</Button>
                                            </Col>
                                        </Row>
                                    </Col> :
                                    null
                            }

                            <Col span={11}>
                                <Row justify='space-between'>
                                    <Col span={15}>
                                        <Form.Item label="Archive Location" name="archive_location_id" rules={[{ required: true }]}>
                                            <Select placeholder="Archive Location">
                                                {
                                                    archiveLocation.map((el, key) => (
                                                        <Option key={key} value={el.archive_location_id}>{el.archive_location_name}</Option>
                                                    ))
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Button type='primary' shape='round' style={{ backgroundColor: '#fdb314', border: 'none', marginTop: 30 }} onClick={addArchiveLocation}>Add Archive Location</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row justify='space-between'>
                            <Col span={11}>
                                <Form.Item label="Total Attachment" name="total_attachment">
                                    <InputNumber type="number" placeholder='Total Attachment' style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <div className='mb-5'>
                            <Title style={{ fontSize: 18, fontWeight: 'bold' }}>Document Body</Title>
                            <Editor
                                apiKey={process.env.REACT_APP_TINYMCE_KEY}
                                value={content}
                                init={{
                                    height: 400,
                                    menubar: true,
                                    plugins: [
                                        'advlist autolink lists link image charmap print preview anchor',
                                        'searchreplace visualblocks code fullscreen',
                                        'insertdatetime media table paste code help wordcount'
                                    ],
                                    toolbar:
                                        'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
                                }}
                                onEditorChange={(value) => handleEditorChange(value)}
                            />
                        </div>

                        <Card bodyStyle={{ border: '0.7px solid #d7d7d7', borderRadius: 10, marginBottom: 15 }}>
                            <Title style={{ fontSize: 18, fontWeight: 700 }}>SEND TO</Title>
                            <div style={{ marginBottom: 15 }}>
                                {
                                    (radio === 3) ?
                                        <Text>(<span style={{ fontWeight: 'bold' }}>*</span>) Click Add for add Receiver Disposition.</Text>
                                        :
                                        <Text>(<b>*</b>) Set Document Option For Input Receiver</Text>

                                }
                            </div>

                            {
                                radio === 1 ?
                                    <>
                                        <Form.List name="receiver">
                                            {(fields, { add, remove }) => (
                                                <>
                                                    {fields.map(({ key, name, ...restField }) => (
                                                        <Space key={key} style={{ display: 'flex', width: "100%" }} align="baseline">
                                                            <Form.Item
                                                                {...restField}
                                                                label="Department"
                                                                name={[name, 'receiver_department_id']}
                                                                rules={[{ required: true, message: 'Missing Receiver' }]}
                                                                style={{ width: 500 }}
                                                            >
                                                                <Select placeholder="Department" onChange={changeEmployee}>
                                                                    {
                                                                        department.map((el, key) => (
                                                                            <Option key={key} value={el.department_id}>{el.department_name}</Option>
                                                                        ))
                                                                    }
                                                                </Select>
                                                            </Form.Item>

                                                            <Form.Item
                                                                {...restField}
                                                                label="Receiver"
                                                                name={[name, 'receiver_user_id']}
                                                                rules={[{ required: true, message: 'Missing Receiver' }]}
                                                                style={{ width: 500 }}
                                                            >
                                                                <Select placeholder="Receiver" allowClear>
                                                                    {
                                                                        receiver.map((el, key) => (
                                                                            <Option key={key} value={el.id}>{el.name}</Option>
                                                                        ))
                                                                    }
                                                                </Select>
                                                            </Form.Item>
                                                            <DeleteFilled style={{ color: "#f44336", fontSize: 18, position: 'relative', top: '35px' }} onClick={() => remove(name)} />
                                                        </Space>
                                                    ))}
                                                    <Form.Item>
                                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                            Add Receiver
                                                        </Button>
                                                    </Form.Item>
                                                </>
                                            )}
                                        </Form.List>
                                        {
                                            /* 
                                            <Row justify='space-between'>
                                                <Col span={11}>
                                                    <Form.Item label="Direktorat" name="department_id" rules={[{ required: true }]}>
                                                        <Select placeholder="Direktorat" onChange={changeEmployee}>
                                                            {
                                                                department.map((el, key) => (
                                                                    <Option key={key} value={el.id}>{el.name}</Option>
                                                                ))
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={11}>
                                                    <Form.Item label="Kepada" name="user_id" rules={[{ required: true }]}>
                                                        <Select placeholder="Kepada">
                                                            {
                                                                receiver.map((el, key) => (
                                                                    <Option key={key} value={el.id}>{el.name}</Option>
                                                                ))
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            </Row> 
                                            */
                                        }
                                    </>
                                    :
                                    radio === 2 ?
                                        <Row>
                                            <Col span={11}>
                                                <Form.Item label="Receiver Eksternal" name="receiver_eksternal" rules={[{ required: true }]}>
                                                    <Input placeholder='Receiver Eksternal' />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        :
                                        radio === 3 ?
                                            <>
                                                <Form.List name="receiver">
                                                    {(fields, { add, remove }) => (
                                                        <>
                                                            {fields.map(({ key, name, ...restField }) => (
                                                                <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                                                    <Form.Item
                                                                        {...restField}
                                                                        label="Department"
                                                                        name={[name, 'receiver_department_id']}
                                                                        rules={[{ required: true, message: 'Missing Receiver' }]}
                                                                        style={{ width: 400 }}
                                                                    >
                                                                        <Select placeholder="Department" onChange={changeEmployee}>
                                                                            {
                                                                                department.map((el, key) => (
                                                                                    <Option key={key} value={el.department_id}>{el.department_name}</Option>
                                                                                ))
                                                                            }
                                                                        </Select>
                                                                    </Form.Item>

                                                                    <Form.Item
                                                                        {...restField}
                                                                        label="Receiver"
                                                                        name={[name, 'receiver_user_id']}
                                                                        rules={[{ required: true, message: 'Missing Receiver' }]}
                                                                        style={{ width: 400 }}
                                                                    >
                                                                        <Select placeholder="Receiver">
                                                                            {
                                                                                receiver.map((el, key) => (
                                                                                    <Option key={key} value={el.id}>{el.name}</Option>
                                                                                ))
                                                                            }
                                                                        </Select>
                                                                    </Form.Item>

                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'approver']}
                                                                        label="Approved"
                                                                        rules={[{ required: true, message: 'Missing Level' }]}
                                                                    >
                                                                        <Select placeholder="Approved">
                                                                            <Option key='0' value={0}>Mengetahui</Option>
                                                                            <Option key='1' value={1}>Approver</Option>
                                                                        </Select>
                                                                    </Form.Item>
                                                                    <DeleteFilled style={{ color: "#f44336", fontSize: 18, position: 'relative', top: '35px' }} onClick={() => remove(name)} />
                                                                </Space>
                                                            ))}
                                                            <Form.Item>
                                                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                                    Add Receiver
                                                                </Button>
                                                            </Form.Item>
                                                        </>
                                                    )}
                                                </Form.List>
                                            </>
                                            : null
                            }
                        </Card>

                        {
                            radio === 3 ?
                                <>
                                    <Form.Item label="Action Dispositon" name="action_id" rules={[{ required: true }]}>
                                        <Select placeholder="Action Dispositon">
                                            {
                                                actionDisposition.map((el, key) => (
                                                    <Option key={key} value={el.action_disposition_id}>{el.action_disposition_name}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label="Action Description" name="action_description" rules={[{ required: true }]}>
                                        <TextArea placeholder='Action Description' />
                                    </Form.Item>
                                </>
                                : null
                        }

                        <Card bodyStyle={{ border: '0.7px solid #d7d7d7', borderRadius: 10, marginBottom: 15 }}>
                            <div className='mb-3' >
                                <Text style={{ fontSize: 18, fontWeight: 'bold' }}>Upload Attachment</Text><br />
                                <Text>Format file: jpg | jpeg | png | pdf | doc | xls</Text><br />
                                <Text>(<span style={{ fontWeight: 'bold' }}>**</span>) max 20 MB</Text>
                            </div>

                            <Form.Item>
                                <FileUpload path="file-attachment" multiple={true} dragger onUploaded={(v, key) => fileUploaded(v)} isUploading={(v) => setUploading(v)} />
                            </Form.Item>
                        </Card>

                        <Form.Item className='mt-4'>
                            <Row>
                                <Col span={5}>
                                    <Button type="primary" className="button-primary" name="status" onClick={() => setStatus("draft")} style={{ width: '80%', backgroundColor: '#0a96b7', border: 'none', borderRadius: 10 }} htmlType="submit">
                                        Save as Draft
                                    </Button>
                                </Col>
                                <Col span={5}>
                                    <Button type="primary" className="button-primary" name="status" onClick={() => setStatus("sent")} style={{ width: '80%', backgroundColor: '#63a67a', border: 'none', borderRadius: 10 }} htmlType="submit">
                                        Save and Send
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Item>
                    </Form>
                </Card>
            </Card>

            {
                bookingModalIsVisible ?
                    <FormModal defaultValues={defaultValues} form={form} showModal={bookingModalIsVisible} title={modalBookingTitle} onCloseModal={() => setBookingModalIsVisible(false)} submitForm={(v) => onFinishBooking(v)}>
                        <Form.Item name="id" hidden>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Document Type" name="modal_letter_type_id">
                            <Select placeholder="Document Type" onChange={changeNumberFormat}>
                                {
                                    letterType.map((e, key) => (
                                        <Option key={key} value={e.document_type_id}>{e.document_type_name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label="Document Number" name="modal_letter_number" required rules={[{ required: true }]}>
                            <Input disabled placeholder="Document Number" />
                        </Form.Item>
                    </FormModal>
                    : null
            }

            {
                listBookingIsVisible ?
                    <DetailModal showModal={listBookingIsVisible} title={modalBookingTitle} onCloseModal={() => setListBookingIsVisible(false)}>
                        <DataTable size="small" columns={columns} sources={listBooking} bordered />
                    </DetailModal>
                    :
                    null
            }

            {
                archiveLocationModal ?
                    <FormModal defaultValues={archiveLocatinDefaultValues} title={archiveModalTitle} submitForm={(v) => onFinishArchiveLocation(v)} width={600} showModal={archiveLocationModal} onCloseModal={() => setArchiveLocationModal(false)} saving={saving}>
                        <Form.Item name="archive_locaton_id" hidden>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Name" name="archive_location_name" required rules={[{ required: true }]}>
                            <Input placeholder="Name" />
                        </Form.Item>
                        <Form.Item label="Code" name="code">
                            <Input placeholder="Code" />
                        </Form.Item>
                        <Form.Item label="Status" name="status" rules={[{ required: true }]}>
                            <Switch checkedChildren="Ya" unCheckedChildren="Tidak" onChange={(v) => setStatus(v)} defaultChecked={defaultValues.status} />
                        </Form.Item>
                    </FormModal>
                    : null
            }

            {
                courierModal ?
                    <FormModal defaultValues={courierDefaultValues} title={courierModalTitle} submitForm={(v) => onFinishCourier(v)} width={600} showModal={courierModal} onCloseModal={() => setCourierModal(false)} saving={saving}>
                        <Form.Item name="courier_id" hidden>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Name" name="courier_name" required rules={[{ required: true }]}>
                            <Input placeholder="Name" />
                        </Form.Item>
                        <Form.Item label="Code" name="code">
                            <Input placeholder="Code" />
                        </Form.Item>
                        <Form.Item label="Status" name="status" rules={[{ required: true }]}>
                            <Switch checkedChildren="Ya" unCheckedChildren="Tidak" onChange={(v) => setStatus(v)} defaultChecked={defaultValues.status} />
                        </Form.Item>
                    </FormModal> : null
            }
        </div >
    );
}

export default CreateSuratKeluar