import { useState, useEffect } from 'react'
import { Row, Col, Card, message, Typography, Image, Button, Tabs, Descriptions } from 'antd'
import { PageHeader, Spinner, DataTable } from '../../components'
import { useHistory, useParams } from 'react-router-dom'
import { currentUser } from '../../utils/authData'
import { ApiService } from '../../services/ApiService'
import moment from 'moment'
import { Docs, Excel, Pdf } from '../../components/Folder'

const { Text, Title } = Typography;
const { TabPane } = Tabs;
const fileUrl = process.env.REACT_APP_FILE_URL;
const defaultTab = (sessionStorage.getItem('selectedDashboardTab')) ? sessionStorage.getItem('selectedDashboardTab') : 'incoming'


const PembuatanSuratMasukDetail = () => {
    const history = useHistory()
    const { id } = useParams()
    const [incomingMailById, setIncomingMailById] = useState([])
    const [attachmentFile, setAttachmentFile] = useState([])
    const [listReceiveDisposition, setListReceiveDisposition] = useState([])
    const [loaded, setLoaded] = useState(true)
    const user = currentUser

    const fetchIncomingMailById = () => {
        setLoaded(false);
        const data = {
            user_id: user.id
        }
        ApiService.getObject('incoming-mail-create', id, data).then((res) => {
            setIncomingMailById(res.data[0])
            setLoaded(true)
        }).catch((err) => {
            message.warning(err.message, 2)
            console.log(err)
        })
    }

    const fetchAttachment = () => {
        setLoaded(false);
        ApiService.getObject('letter-attachment', id).then((res) => {
            setAttachmentFile(res.data)
        }).catch((err) => {
            message.warning('Dokumen tidak memiliki lampiran')
        })
    }

    const fetchReceiveDisposition = () => {
        ApiService.getObject('receiver-disposition-mail', id).then((res) => {
            setListReceiveDisposition(res.data)
        }).catch((err) => {
        })
    }

    useEffect(() => {
        fetchIncomingMailById()
        fetchAttachment()
        fetchReceiveDisposition()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const attachmentPage = (v) => {
        window.open(`${process.env.REACT_APP_FILE_URL}/${v}`)
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Lampiran',
            render: (row) => (
                row?.filename.split('.')[1] === 'jpg' || row?.filename.split('.')[1] === 'png' || row?.filename.split('.')[1] === 'svg' || row?.filename.split('.')[1] === 'jpeg' ?
                    <>
                        <Row justify='center'>
                            <Image width={'30%'} src={row?.filename ? `${fileUrl}/${row?.filename}` : null} />
                        </Row>
                        <Row justify='center'>
                            <Text className='mb-2'>{row?.filename.split('/')[1]}</Text>
                        </Row>
                    </>
                    : row?.filename.split('.')[1] === 'pdf' ?
                        <>
                            <Row justify='center'>
                                <Pdf onClick={() => attachmentPage(row?.filename)} />
                            </Row>
                            <Row justify='center'>
                                <Text className='mb-2'>{row?.filename.split('/')[1]}</Text>
                            </Row>
                        </>
                        : row?.filename.split('.')[1] === 'doc' || row?.filename.split('.')[1] === 'docx' ?
                            <>
                                <Row justify='center'>
                                    <Docs onClick={() => attachmentPage(row?.filename)} />
                                </Row>
                                <Row justify='center'>
                                    <Text className='mb-2'>{row?.filename.split('/')[1]}</Text>
                                </Row>
                            </>
                            : row?.filename.split('.')[1] === 'xls' || row?.filename.split('.')[1] === 'xlsx' ?
                                <>
                                    <Row justify='center'>
                                        <Excel onClick={() => attachmentPage(row?.filename)} />
                                    </Row>
                                    <Row justify='center'>
                                        <Text className='mb-2'>{row?.filename.split('/')[1]}</Text>
                                    </Row>
                                </>
                                :
                                <>
                                    <Row justify='center'>
                                        <Button onClick={() => attachmentPage(row?.filename)} />
                                    </Row>
                                    <Row justify='center'>
                                        <Text className='mb-2'>{row?.filename.split('/')[1]}</Text>
                                    </Row>
                                </>
            ),
            key: 'attachment_file',
            align: 'center'
        }
    ];

    const columns2 = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '3%'
        },
        {
            title: 'Tanggal Dokumen',
            render: (row) => (
                moment(row?.date).locale('id').format('dddd, D MMMM YYYY')
            ),
            key: 'date',
            align: 'center'
        },
        {
            title: 'Pengirim',
            render: (row) => (
                row?.sender_disposition
            ),
            key: 'letter_number',
            align: 'center'
        },
        {
            title: 'Respon Disposisi',
            render: (row) => (
                row?.response_disposition ? row?.response_disposition : '-'
            ),
            key: 'subject',
            align: 'center'
        },
        {
            title: 'Keterangan',
            render: (row) => (
                row?.response_description ? row?.response_description : '-'
            ),
            key: 'subject',
            align: 'center'
        },
        {
            title: 'Intruksi',
            render: (row) => (
                row?.action_disposition
            ),
            key: 'subject',
            align: 'center'
        },
        {
            title: 'Informasi Tambahan',
            render: (row) => (
                row?.action_description
            ),
            key: 'subject',
            align: 'center'
        },
        {
            title: 'Diteruskan Ke',
            render: (row) => (
                row?.receiver_user
            ),
            key: 'to',
            align: 'center'
        },
    ];

    // const exportReport = () => {
    //     window.open(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/incoming-mail-pdf/${id}`);
    // }

    const changeTab = (selected) => {
        sessionStorage.setItem('selectedDashboardTab', selected)
    }

    const LetterDetail = () => {
        return (
            <>
                <Row className='mb-5' justify='space-between'>
                    <Col span={15}>
                        {
                            loaded ?
                                <table className="table-collapse table-default" style={{ width: '100%' }}>
                                    <tbody>
                                        <tr>
                                            <th>Nomor Dokumen</th>
                                            <td>{incomingMailById.letter_number}</td>
                                        </tr>
                                        <tr>
                                            <th>Subjek</th>
                                            <td>{incomingMailById.subject}</td>
                                        </tr>
                                        <tr>
                                            <th>Tanggal Dokumen</th>
                                            <td>{moment(incomingMailById.date).format('dddd, DD MMMM YYYY')}</td>
                                        </tr>
                                        <tr>
                                            <th>Penerima</th>
                                            <td>{incomingMailById.receiver_user ?? incomingMailById.receiver_eksternal}</td>
                                        </tr>
                                        <tr>
                                            <th>Departemen Penerima</th>
                                            <td>{incomingMailById.receiver_department ?? '-'}</td>
                                        </tr>
                                        <tr>
                                            <th>Tipe Dokumen</th>
                                            <td>{incomingMailById.letter_type}</td>
                                        </tr>
                                        <tr>
                                            <th>Arsip Dokumen</th>
                                            <td>{incomingMailById.archive_location}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                : <Spinner />
                        }
                    </Col>
                    <Col span={8}>
                        {/* <Button onClick={exportReport} type='primary' size='large' shape='round' icon={<FilePdfOutlined />}>Download Dokumen</Button> */}
                    </Col>
                </Row>

                <Card title="Lampiran">
                    {
                        attachmentFile.length === 0 ?
                            <Card>
                                <Text>Tidak Ada Lampiran</Text>
                            </Card>
                            :
                            <DataTable size="small" columns={columns} sources={attachmentFile} bordered search={false} />
                    }
                </Card>
            </>
        )
    }

    return (
        <div>
            <PageHeader title={`Dokumen Masuk #${id}`} breadcrumbs={[['Dokumen Masuk'], ['Detail']]} onBack={() => history.push('/surat-masuk')} />

            <Card title="Keterangan Dokumen" style={{ borderRadius: 10 }}>
                {
                    incomingMailById.type === 3 ?
                        <Tabs defaultActiveKey={defaultTab} onChange={changeTab}>
                            <TabPane tab="Dokumen Masuk" key="incoming">
                                <LetterDetail />
                            </TabPane>
                            <TabPane tab="Disposisi" key="disposition">
                                <Row className='mb-4' justify='space-between'>
                                    <Col span={17}>
                                        <Title style={{ fontSize: 22, fontWeight: 400 }}>Nomor Dokumen: {incomingMailById.letter_number}</Title>
                                    </Col>
                                </Row>
                                <Row className='mb-5'>
                                    {
                                        loaded &&
                                        <Card bodyStyle={{ border: '0.4px solid #efefef', borderRadius: 10, width: '100%' }}>
                                            <Descriptions labelStyle={{ fontWeight: 600 }} layout="vertical">
                                                <Descriptions.Item label="Tanggal Dokumen">{incomingMailById.date}</Descriptions.Item>
                                                <Descriptions.Item label="Asal Dokumen">{incomingMailById.from}</Descriptions.Item>
                                                <Descriptions.Item label="Lokasi Arsip Saat Ini">{incomingMailById.archive_location ? incomingMailById.archive_location : '-'}</Descriptions.Item>
                                                <Descriptions.Item label="Sifat Dokumen">
                                                    {incomingMailById.letter_type}
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Perihal">{incomingMailById.subject}</Descriptions.Item>
                                            </Descriptions>
                                        </Card>
                                    }
                                </Row>
                                <DataTable sources={listReceiveDisposition} columns={columns2} />
                            </TabPane>
                        </Tabs> :
                        <LetterDetail />
                }
            </Card>
        </div >
    )
}

export default PembuatanSuratMasukDetail