import { useState, useEffect } from 'react'
import { Button, Card, Col, Form, Image, Input, Row, Space, Tree, Typography, message, Dropdown } from 'antd'
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import moment from 'moment'
import { Docs, Excel, Folder, Images, Pdf, Ppt } from '../../../../components/Folder';
import { ApiService } from '../../../../services/ApiService'
import IcFolder from '../../../../assets/images/ic_folder.png'
import EmptyFolderImage from '../../../../assets/images/empty-folder.png'
import EmptyDocumentImage from '../../../../assets/images/empty-document.png'
import { decrypt } from '../../../../utils/helpers'
import { GenerateFolder } from '../../../../utils/tree';
// import { role } from '../../../../utils/authData'

const { DirectoryTree } = Tree;
const { Text } = Typography;
const fileUrl = process.env.REACT_APP_FILE_URL;

const DocumentWorkFlow = () => {
    const [form] = Form.useForm()
    const [loaded, setLoaded] = useState(false)
    const [loadedSearch, setLoadedSearch] = useState(false)
    const [nestedTree, setNestedTree] = useState([])
    const [allData, setAllData] = useState([])
    const [inTree, setInTree] = useState([])
    // eslint-disable-next-line
    const [resultSearch, setResultSearch] = useState(false)
    const [resultFile, setResultFile] = useState([])
    const [isFile, setIsFile] = useState(false)
    const [fileNameUrl, setFileNameUrl] = useState('')
    const [fileName, setFileName] = useState('')
    const [stringValue, setStringValue] = useState('')
    const user = localStorage.getItem('currentUser') ? JSON.parse(decrypt(localStorage.getItem('currentUser'))) : []
    const role = localStorage.getItem('role') ? JSON.parse(decrypt(localStorage.getItem('role'))) : []

    function getNestedChildren(folder, file) {
        let folderChild = folder
        for (let i = 0; i < folderChild.length; i++) {
            let children = []
            for (let j = 0; j < file.length; j++) {
                if (folderChild[i].root_id === file[j].parent) {
                    children.push(file[j])
                }
            }

            Object.assign(folderChild[i], { children: children })
        }

        const data = [Object.assign({ title: "Document Workflow", icon: <Image preview={false} src={IcFolder} width={20} />, }, { children: folderChild })]

        return data;
    }

    const fetchNestedTree = () => {
        setLoaded(false)
        ApiService.getList('doc-workflow', { role: role }).then((res) => {
            const tree = res.data
            setAllData(tree)
            const data = GenerateFolder(tree)

            let folder = []
            let file = []
            data.forEach((el) => {
                if (el.type === "FOLDER") {
                    folder.push(el)
                } else {
                    file.push(el)
                }
            })
            const generateTree = getNestedChildren(folder, tree)
            setNestedTree(generateTree)
            setLoaded(true)
        })
    }

    const getInFolder = (v) => {
        const data = allData.filter((el) => el.parent === v && el.type === 'FILE')
        setInTree(data)
    }

    useEffect(() => {
        fetchNestedTree()

        // eslint-disable-next-line
    }, []);

    const onSelect = (selectedKeys, info) => {
        const v = info.node
        setResultSearch(false)
        form.resetFields(['search_file']);
        form.setFieldsValue({ name: v.name })
        if (v.type === 'FOLDER') {
            getInFolder(v.root_id)
            setIsFile(false)
        }
        if (v.type === 'FILE') {
            setIsFile(true)
            setFileName(v.original_filename)
            setFileNameUrl(v.original_filename)
        }
    }

    const onSelectFile = (url, name, id) => {
        setIsFile(true)
        setFileNameUrl(name)
        setFileName(name)
    }

    const downloadFile = (v) => {
        let payload = {
            document_id: v.id,
            description: 'Download File from Web',
            original_filename: v.original_filename,
            filename: v.name,
        }

        ApiService.store('file-log', payload).then((res) => {
            window.open(`${fileUrl}/${v.original_filename}`)
        }).catch((err) => {
            message.warning('Log Not Saved')
        })
    }

    const searchFileManager = (v) => {
        form.resetFields(['name']);
        setLoadedSearch(false)
        let payload = {
            search: v.search_file === '' ? stringValue : v.search_file
        }
        let params = {
            user_id: user.id,
            role: role
        }

        ApiService.store('search-file', payload, params)
            .then((res) => {
                setIsFile(false)
                setResultSearch(true)
                setResultFile(res.data)
                setLoadedSearch(true)
            })
    }

    const clearSearch = () => {
        setIsFile(false)
        setResultSearch(false)
        form.resetFields(['search_file']);
    }

    const changeInputValue = (v) => {
        setStringValue(v)
    }

    const items = (v) => {
        return (
            <div style={{ backgroundColor: 'white', padding: 5, borderRadius: 5, border: '1px solid #e8e8e8', width: 150 }}>
                <Row className="mb-1">
                    <a className="a-link" onClick={() => downloadFile({ name: v.name, original_filename: v.original_filename, id: v.file_origins_id })} href='!#'>
                        Download
                    </a>
                </Row>
            </div>
        )
    }

    return (
        <div>
            <Card style={{ borderRadius: 10 }}
                title={
                    <Row justify='space-between'>
                        <Col>
                            <Text>Document Workflow</Text>
                        </Col>
                    </Row>
                }>
                <Row justify='space-between'>
                    <Col span={8}>
                        <Card>
                            {
                                loaded ?
                                    <DirectoryTree
                                        onSelect={onSelect}
                                        treeData={nestedTree}
                                        titleRender={(node) => {
                                            const { title, type } = node;
                                            return (
                                                <>
                                                    {
                                                        type === 'FILE' ?
                                                            <Dropdown overlay={items(node)} trigger={["contextMenu"]}>
                                                                <span>{title}</span>
                                                            </Dropdown>
                                                            : <span>{title}</span>
                                                    }
                                                </>
                                            )
                                        }}
                                    />
                                    : null
                            }
                        </Card>
                    </Col>
                    <Col span={15}>
                        <Card>
                            <div style={{ marginBottom: '15px' }}>
                                <Form form={form} onFinish={searchFileManager}>
                                    <Row justify='start'>
                                        <Col span={24}>
                                            <Form.Item name="search_file">
                                                <Input placeholder='Search My Folder...' onChange={(v) => changeInputValue(v.target.value)} style={{ width: '100%', borderRadius: '10px' }} suffix=
                                                    {
                                                        <Space>
                                                            <Button type='text' icon={<CloseOutlined />} size="small" onClick={clearSearch} />
                                                            <Button onClick={searchFileManager} type="primary" shape="circle" icon={<SearchOutlined />} />
                                                        </Space>
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>

                            {
                                !isFile ?
                                    !resultSearch ?
                                        inTree.length !== 0 ?
                                            <Row>
                                                {
                                                    inTree.map((el) => (
                                                        <Col span={6}>
                                                            {
                                                                el?.type === 'FOLDER' ?
                                                                    <Folder width={50} buttonHeight={70} onClick={() => getInFolder(el?.root_id)} title={el?.name} />
                                                                    :
                                                                    el?.original_filename.split('.')[1].toLowerCase() === 'pdf' ?
                                                                        <Pdf width={50} buttonHeight={70} onClick={() => onSelectFile(el?.name, el?.original_filename, el?.file_origins_id)} title={`${el?.original_filename.split("/")[1].substring(0, 20)}...`} />
                                                                        :
                                                                        el.original_filename.split('.')[1].toLowerCase() === 'pptx' || el.original_filename.split('.')[1].toLowerCase() === 'ppt' ?
                                                                            <Ppt width={50} buttonHeight={70} onClick={() => onSelectFile(el?.name, el?.original_filename, el?.file_origins_id)} title={`${el?.original_filename.split("/")[1].substring(0, 20)}...`} />
                                                                            :
                                                                            el?.original_filename.split('.')[1].toLowerCase() === 'docx' || el?.original_filename.split('.')[1].toLowerCase() === 'doc' ?
                                                                                <Docs width={50} buttonHeight={70} onClick={() => onSelectFile(el?.name, el?.original_filename, el?.file_origins_id)} title={`${el?.original_filename.split("/")[1].substring(0, 20)}...`} />
                                                                                :
                                                                                el?.original_filename.split('.')[1].toLowerCase() === 'xlsx' || el?.original_filename.split('.')[1].toLowerCase() === 'xls' ?
                                                                                    <Excel width={50} buttonHeight={70} onClick={() => onSelectFile(el.name, el?.original_filename, el?.file_origins_id)} title={`${el?.original_filename.split("/")[1].substring(0, 20)}...`} />
                                                                                    :
                                                                                    <Images width={50} buttonHeight={70} onClick={() => onSelectFile(el?.name, el?.original_filename, el?.file_origins_id)} title={`${el?.original_filename.split("/")[1].substring(0, 20)}...`} />
                                                            }
                                                        </Col>
                                                    ))
                                                }
                                            </Row>
                                            :
                                            <div className='text-center'>
                                                <Image src={EmptyFolderImage} preview={false} width={150} />
                                                <p style={{ fontSize: 18, fontWeight: 'bold', color: 'black' }}>File not found</p>
                                            </div>
                                        :
                                        loadedSearch ?
                                            resultFile.length !== 0 ?
                                                <table className='table-custom'>
                                                    <thead>
                                                        <tr>
                                                            <th>Kind</th>
                                                            <th>Filename</th>
                                                            <th>Total Page</th>
                                                            <th>Created At</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            resultFile.map((el, key) => (
                                                                <tr key={key} onClick={() => window.open(`${el?.filename}`)}>
                                                                    <td>
                                                                        {
                                                                            el?.filename_document?.toLowerCase().split('.')[1] === 'pdf' ?
                                                                                <Pdf width={30} buttonHeight={50} onClick={() => window.open(`${el?.filename}`)} />
                                                                                :
                                                                                el?.filename_document?.toLowerCase().split('.')[1] === 'doc' || el?.filename_document?.toLowerCase().split('.')[1] === 'docx' ?
                                                                                    <Docs width={30} buttonHeight={50} onClick={() => window.open(`${el?.filename}`)} /> :
                                                                                    el?.filename_document?.toLowerCase().split('.')[1] === 'xls' || el?.filename_document?.toLowerCase().split('.')[1] === 'xlsx' ?
                                                                                        <Excel width={30} buttonHeight={50} onClick={() => window.open(`${el?.filename}`)} /> :
                                                                                        el?.filename_document?.toLowerCase().split('.')[1] === 'ppt' || el?.filename_document?.toLowerCase().split('.')[1] === 'pptx' ?
                                                                                            <Ppt width={30} buttonHeight={50} onClick={() => window.open(`${el?.filename}`)} />
                                                                                            :
                                                                                            <Images width={30} buttonHeight={50}
                                                                                                onClick={() => window.open(`${el?.filename}`)} />
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {el?.filename_document}
                                                                    </td>
                                                                    <td>
                                                                        {el?.page}
                                                                    </td>
                                                                    <td>
                                                                        {moment(el?.created_at).format('dddd, DD MMMM YYYY')}
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                                :
                                                <div className='text-center'>
                                                    <Image src={EmptyDocumentImage} preview={false} width={150} />
                                                    <p style={{ fontSize: 18, fontWeight: 'bold', color: 'black' }}>Document not found</p>
                                                </div>
                                            :
                                            null
                                    :
                                    <div>
                                        {
                                            fileName.split('.')[1].toLowerCase() === 'pdf' || fileName.split('.')[1].toLowerCase() === 'jpg' || fileName.split('.')[1].toLowerCase() === 'jpeg' || fileName.split('.')[1].toLowerCase() === 'png' ?
                                                <Row>
                                                    <iframe src={`${fileUrl}/${fileNameUrl}#toolbar=0`} width="730" height="950" title="Document Review" />
                                                </Row>
                                                :
                                                <Card style={{ borderRadius: 10 }}>
                                                    <Row justify='center' className='mb-3'>
                                                        <div className='text-center'>
                                                            <h1 style={{ fontSize: 22, fontWeight: 600 }}>Document Can't be Show</h1>
                                                            <p>Download file for show Document</p>
                                                        </div>
                                                    </Row>
                                                    <Row justify='center'>
                                                        <div>
                                                            <Button type='primary' onClick={downloadFile}>Download</Button>
                                                        </div>
                                                    </Row>
                                                </Card>
                                        }
                                    </div>
                            }
                        </Card>
                    </Col>
                </Row>
            </Card>
        </div>
    )
}

export default DocumentWorkFlow