import { useState, useEffect } from 'react'
import { Card, Form, Input, Select, Switch, message, Row, Col, Transfer, Button } from 'antd'
import { PageHeader } from '../../../components'
import { ApiService } from '../../../services/ApiService'
import { useHistory, useParams } from 'react-router-dom'
import { docPermission } from '../../../utils/docPermission'

const { Option } = Select

const CreateEmployee = () => {
    const history = useHistory()
    const { id } = useParams()
    const [form] = Form.useForm()
    const [loaded, setLoaded] = useState(false)
    const [status, setStatus] = useState(false)
    const [position, setPosition] = useState([])
    const [department, setDepartment] = useState([])
    const [role, setRole] = useState([])
    const [listRoleAccess, setListRoleAccess] = useState([])

    const fetchEmployee = () => {
        ApiService.getObject('employee', id).then((res) => {
            form.setFieldsValue({
                ...res.data,
                status: parseInt(res.data.status)
            })
            let role_access = []
            res.data?.user_role_access?.forEach((item, i) => {
                role_access.push(item.code)
            })
            setListRoleAccess(role_access.sort());
            setStatus(parseInt(res.data.status) === 1 ? true : false)
            setLoaded(true)
        }).catch((err) => {
        })
    }

    const fetchPosition = () => {
        ApiService.getList('position').then((res) => {
            setPosition(res.data)
        }).catch((err) => {
        })
    }

    const fetchDepartment = () => {
        ApiService.getList('department').then((res) => {
            setDepartment(res.data)
        }).catch((err) => {
        })
    }

    const fetchRole = () => {
        ApiService.getList('role').then((res) => {
            setRole(res.data)
        }).catch((err) => {
        })
    }

    useEffect(() => {
        if (id !== '0') {
            fetchEmployee()
        }

        fetchDepartment()
        fetchPosition()
        fetchRole()
        setLoaded(true)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const filterOption = (inputValue, option) => option.title.toLowerCase().indexOf(inputValue) > -1;
    const handleChange = (roleAcc) => {
        setListRoleAccess(roleAcc);
    };
    const handleSearch = (dir, value) => {
        console.log('search:', dir, value);
    };

    const onFinish = (el) => {
        let payload = {
            ...el,
            status: status ? 1 : 0,
            list_role: listRoleAccess
        }

        if (id === '0') {
            ApiService.store(`employee`, payload)
                .then((res) => {
                    message.success(res.message, 2)
                    setTimeout(() => {
                        history.push('/organization/employee')
                    }, 500);
                }).catch((err) => {
                    message.warning(err.response.data.message)
                })
        } else {
            ApiService.update(`employee`, id, payload)
                .then((res) => {
                    message.success(res.message, 2)
                    setTimeout(() => {
                        history.push('/organization/employee')
                    }, 500);
                }).catch((err) => {
                    message.warning(err.response.data.message)
                })
        }
    }

    return (
        <div>
            {
                parseInt(id) === 0 ?
                    <PageHeader title="Add Employee" breadcrumbs={[['Organization'], ['Employee', '/organization/employee'], ['Add']]} />
                    :
                    <PageHeader title="Edit Employee" breadcrumbs={[['Organization'], ['Employee', '/organization/employee'], ['Edit']]} />
            }
            <Card style={{ borderRadius: 10 }}>
                {
                    loaded ?
                        <Form layout='vertical' onFinish={onFinish} form={form}>
                            <Row justify='space-between'>
                                <Col span={11}>
                                    <Form.Item label="Employee Name" name="name" required rules={[{ required: true }]}>
                                        <Input placeholder="Employee Name" />
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item label="Username" name="username" required rules={[{ required: true }]}>
                                        <Input placeholder="Username" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify='space-between'>
                                <Col span={11}>
                                    {
                                        id === '0' ?
                                            <Form.Item label="Password" name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
                                                <Input.Password />
                                            </Form.Item>
                                            :
                                            null
                                    }
                                </Col>
                                <Col span={11}>
                                    <Form.Item label="Pin" name="pin" rules={[{ required: true, message: 'Please input your pin!' }]}>
                                        <Input.Password />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify='space-between'>
                                <Col span={11}>
                                    <Form.Item label="Department" name="department_id" required rules={[{ required: true }]}>
                                        <Select placeholder="Department">
                                            {
                                                department.map((e, key) => (
                                                    <Option key={key} value={e.department_id}>{e.department_name}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item label="Position" name="position_id" required rules={[{ required: true }]}>
                                        <Select placeholder="Position">
                                            {
                                                position.map((e, key) => (
                                                    <Option key={key} value={e.position_id}>{e.position_name}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify='space-between'>
                                <Col span={11}>
                                    <Form.Item label="Email" name="email" required rules={[{ required: true }]}>
                                        <Input placeholder="Email" />
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item label="Phone" name="phone">
                                        <Input placeholder="Phone" rules={[{ type: "number" }]} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify='space-between'>
                                <Col span={11}>
                                    <Form.Item label="Address" name="address">
                                        <Input.TextArea placeholder='Address' />
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item label="Role" name="role_id" required rules={[{ required: true }]}>
                                        <Select placeholder="Role">
                                            {
                                                role.map((e, key) => (
                                                    <Option key={key} value={e.id}>{e.name}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item label="Status" name="status" rules={[{ required: true }]}>
                                <Switch checkedChildren="Aktif" unCheckedChildren="Tidak" onChange={(v) => setStatus(v)} checked={status} />
                            </Form.Item>
                            <div className='mb-3'>
                                <Transfer
                                    dataSource={docPermission.sort((a, b) => a.key < b.key ? -1 : 1)}
                                    showSearch
                                    listStyle={{
                                        width: 350,
                                        height: 400,
                                    }}
                                    filterOption={filterOption}
                                    targetKeys={listRoleAccess}
                                    onChange={handleChange}
                                    onSearch={handleSearch}
                                    render={(item) => item.title}
                                />
                            </div>
                            <Button type="primary" htmlType="submit">Submit</Button>
                        </Form>
                        : null
                }
            </Card>
        </div>
    )
}

export default CreateEmployee