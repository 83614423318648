import React, { useState, useEffect } from 'react';
import { Row, message, Card, Descriptions, Col, Form, Input, Button, Typography, Tabs } from 'antd';
import { BasicModal, FileUpload, ImageViewer, PageHeader } from '../../components';
import { ApiService } from '../../services/ApiService';
import { currentUser } from '../../utils/authData';

const { TabPane } = Tabs;
const { Title } = Typography
const fileUrl = process.env.REACT_APP_FILE_URL

const defaultTab = (sessionStorage.getItem('selectedEditProfile')) ? sessionStorage.getItem('selectedEditProfile') : 'profile'

const Profile = () => {
    const [form] = Form.useForm()
    const [userProfile, setUserProfile] = useState([])
    const [showEditProfilePicture, setShowEditProfilePicture] = useState(false)
    const [showEditSignature, setShowEditSignature] = useState(false)
    const [uploadingProfilePicture, setUploadingProfilePicture] = useState(false)
    const [uploadingSignature, setUploadingSignature] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})
    const [profileFile, setProfileFile] = useState(process.env.PUBLIC_URL + '/images/noimage.png')
    const [signatureFile, setSignatureFile] = useState(process.env.PUBLIC_URL + '/images/noimage.png')
    const [loaded, setLoaded] = useState(false)
    const [editing, setEditing] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [tab, setTab] = useState('profile')
    const user = currentUser
    const userId = user.id;

    const fetchEmployee = async (userId) => {
        setLoaded(false)
        ApiService.getObject('employee', userId).then((res) => {
            setUserProfile(res.data)
            setLoaded(true)
        }).catch((err) => {
            message.warning(err.response.data.message, 2)
        })
    }

    const profilePictureUploaded = (v) => {
        setLoaded(false)
        if (uploadingProfilePicture) {
            setUploadingProfilePicture(false)
        }
        ApiService.update('employee', userId, { avatar: v.response.data.filename }).then((res) => {
            setProfileFile(res.data.avatar)
            setShowEditProfilePicture(false)
            setLoaded(true)
        })
    }

    const signatureUploaded = (v) => {
        setLoaded(false)
        if (uploadingSignature) {
            setUploadingSignature(false)
        }
        ApiService.update('employee', userId, { signature: v.response.data.filename }).then((res) => {
            setSignatureFile(res.data.signature)
            setShowEditSignature(false)
            setLoaded(true)
        })
    }

    const editProfile = (v) => {
        setEditing(true)
        setDefaultValues({
            id: v.id,
            name: v.name,
            email: v.email,
            phone: v.phone,
            username: v.username,
            address: v.address
        })
    }

    const onFinish = (el) => {
        setIsSaving(true)
        const payload = {
            ...el
        }

        ApiService.update(`employee`, el.id, payload)
            .then((res) => {
                message.success(res.message, 2)
                setEditing(false)
                setIsSaving(false)
                fetchEmployee(userId)
            }).catch((err) => {
                message.warning(err.response.data.message, 2)
            })
    }

    const onFinishPassword = (el) => {
        setIsSaving(true)
        const payload = {
            ...el
        }
        ApiService.store('employee/changePassword', payload).then((res) => {
            message.success(res.message)
            form.setFieldsValue({
                password: '',
                confirm_password: ''
            })

            // setTimeout(() => {
            //     history.push('/profile')
            // }, 1000);
        }).catch((err) => {
            message.warning(err.response.data.message)
        })
    }

    const onFinishPin = (el) => {
        setIsSaving(true)
        const payload = {
            ...el
        }
        ApiService.store('employee/changePin', payload).then((res) => {
            message.success(res.message)
            form.setFieldsValue({
                pin: '',
                confirm_pin: ''
            })

            // setTimeout(() => {
            //     history.push('/profile')
            // }, 1000);
        }).catch((err) => {
            message.warning(err.response.data.message)
        })
    }

    useEffect(() => {
        fetchEmployee(userId)
    }, [userId]);

    const changeTab = (selected) => {
        setTab(selected)
        sessionStorage.setItem('selectedEditProfile', selected)
    }

    return (
        <div>
            {
                tab === 'profile' ?
                    <PageHeader title="My Profile" breadcrumbs={[['Profile']]} onEdit={() => editProfile(userProfile)} />
                    :
                    <PageHeader title="My Profile" breadcrumbs={[['Profile']]} />
            }

            <Card style={{ borderRadius: 10 }}>
                <Tabs defaultActiveKey={defaultTab} onChange={changeTab}>
                    <TabPane tab="Edit Profile" key="profile">
                        {
                            loaded ?
                                <>
                                    <Card>
                                        <Row style={{ marginBottom: 20 }}>
                                            <Col span={8}>
                                                <Title style={{ fontSize: 16 }}>Profile Picture</Title>
                                                <div style={{ width: 300, border: '1px solid #ededed', alignItems: 'center', justifyContent: "center" }}>
                                                    <ImageViewer src={userProfile.avatar ? `${fileUrl}/${userProfile.avatar}` : profileFile} onEdit={() => setShowEditProfilePicture(true)} style={{ width: '100%', alignItems: 'center', justify: "center" }} previewable />
                                                </div>
                                            </Col>
                                            <Col>
                                                <Title style={{ fontSize: 16 }}>Signature</Title>
                                                <div style={{ width: 300, border: '1px solid #ededed', alignItems: 'center', justifyContent: "center" }}>
                                                    <ImageViewer src={userProfile.signature ? `${fileUrl}/${userProfile.signature}` : signatureFile} onEdit={() => setShowEditSignature(true)} style={{ width: '100%' }} previewable />
                                                </div>
                                            </Col>
                                        </Row>
                                        {
                                            editing ?
                                                <>
                                                    <Title style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 15 }}>USER INFO</Title>
                                                    <Form initialValues={defaultValues} onFinish={(v) => onFinish(v)} layout="vertical">
                                                        <Form.Item name="id" hidden>
                                                            <Input />
                                                        </Form.Item>
                                                        <Row justify='space-between'>
                                                            <Col span={6}>
                                                                <Form.Item label="Name" name="name">
                                                                    <Input placeholder="Name" />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={6}>
                                                                <Form.Item label="Email" name="email">
                                                                    <Input placeholder='Email' />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={6}>
                                                                <Form.Item label="Phone" name="phone">
                                                                    <Input placeholder='Phone' />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <Row justify='space-between'>
                                                            <Col span={6}>
                                                                <Form.Item label="Username" name="username">
                                                                    <Input placeholder="Username" />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={6}>
                                                                <Form.Item label="Address" name="address">
                                                                    <Input.TextArea placeholder='Address' />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={6}>

                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col span={3}>
                                                                <Button loading={isSaving} type="primary" name="status" style={{ backgroundColor: '#63a67a', border: 'none', borderRadius: 10 }} htmlType="submit">
                                                                    Perbarui Data
                                                                </Button>
                                                            </Col>
                                                            <Col>
                                                                <Button style={{ borderRadius: 10 }} onClick={() => setEditing(false)}> Batal</Button>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </>
                                                :
                                                <Descriptions layout='vertical'>
                                                    <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label="Name">{userProfile.name}</Descriptions.Item>
                                                    <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label="Email">{userProfile.email}</Descriptions.Item>
                                                    <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label="Phone">{userProfile.phone}</Descriptions.Item>
                                                    <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label="Username">{userProfile.username}</Descriptions.Item>
                                                    <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label="Address">
                                                        {userProfile.address ? userProfile.address : '-'}
                                                    </Descriptions.Item>
                                                </Descriptions>
                                        }
                                    </Card>
                                </>
                                : null
                        }
                    </TabPane>
                    <TabPane tab="Edit Password" key="password">
                        <Card>
                            <Form
                                form={form}
                                onFinish={(v) => onFinishPassword(v)}
                                labelCol={{ span: 4 }}
                                wrapperCol={{ span: 10 }}
                            >
                                <Form.Item label="New Password" name="password">
                                    <Input.Password />
                                </Form.Item>
                                <Form.Item label="Confirm New Password" name="confirm_password">
                                    <Input.Password />
                                </Form.Item>
                                <Button type="primary" className="button-primary" style={{ width: '20%', backgroundColor: '#63a67a', border: 'none', borderRadius: 10 }} htmlType="submit">Simpan Perubahan</Button>
                            </Form>
                        </Card>
                    </TabPane>
                    <TabPane tab="Edit Pin" key="pin">
                        <Card>
                            <Form
                                form={form}
                                onFinish={(v) => onFinishPin(v)}
                                labelCol={{ span: 4 }}
                                wrapperCol={{ span: 10 }}
                            >
                                <Form.Item label="New Pin" name="pin">
                                    <Input.Password />
                                </Form.Item>
                                <Form.Item label="Confirm New Pin" name="confirm_pin">
                                    <Input.Password />
                                </Form.Item>
                                <Button type="primary" className="button-primary" style={{ width: '20%', backgroundColor: '#63a67a', border: 'none', borderRadius: 10 }} htmlType="submit">Simpan Perubahan</Button>
                            </Form>
                        </Card>
                    </TabPane>
                </Tabs>

                <BasicModal title="Change Profile Picture" showModal={showEditProfilePicture} onCloseModal={() => setShowEditProfilePicture(false)}>
                    {
                        (showEditProfilePicture) ?
                            <FileUpload dragger path="photo" onUploaded={(v) => profilePictureUploaded(v)} isUploading={(v) => setUploadingProfilePicture(v)} />
                            : null
                    }
                </BasicModal>

                <BasicModal title="Change Signature" showModal={showEditSignature} onCloseModal={() => setShowEditSignature(false)}>
                    {
                        (showEditSignature) ?
                            <FileUpload dragger path="signature" onUploaded={(v) => signatureUploaded(v)} isUploading={(v) => setUploadingSignature(v)} />
                            : null
                    }
                </BasicModal>

            </Card>
        </div>
    );
};

export default Profile;
