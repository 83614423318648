import React from 'react'
import { Card, Tabs } from 'antd'
import { PageHeader } from '../../components'
import MyFolder from './tabs/my-folder'
import DocumentWorkFlow from './tabs/doc-workflow'
// import RootFolder from './tabs/root-folder'
// import SharingDocument from './tabs/sharing'

const FileManager = () => {
    return (
        <div>
            <PageHeader title=" File Manager" breadcrumbs={[['File Manager']]} />
            <Card style={{ borderRadius: 10 }}>
                <Tabs type='card' defaultActiveKey="my_folder">
                    <Tabs.TabPane tab="My Folder" key="my_folder">
                        <MyFolder />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Document Workflow" key="doc_workflow">
                        <DocumentWorkFlow />
                    </Tabs.TabPane>
                    {/* <Tabs.TabPane tab="All Folder" key="root_folder">
                        <RootFolder />
                    </Tabs.TabPane> */}
                </Tabs>
            </Card>
        </div>
    )
}

export default FileManager