import React from 'react';

const DashboardItem = (props) => {
    return (
        <div style={{ marginBottom: 20 }} className="box-shadow-3">
            <div style={{ width: '100%', backgroundColor: '#fff', borderRadius: 10, padding: '10px 10px 10px 25px' }}>
                <div style={{ width: '100%', height: 50, padding: 5, borderBottom: '1px solid #f0f0f0', fontSize: 16, fontWeight: 500, verticalAlign: 'middle' }}>
                    {props.title}
                </div>
                <div style={{ height: props.height, paddingLeft: 20, paddingRight: 20, paddingTop: 5, verticalAlign: 'middle' }}>
                    {props.children}
                </div>
            </div>
        </div>
    );
}

export default DashboardItem;
