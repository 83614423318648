import React, { useState, useEffect } from 'react';
import { Affix, Card, Avatar, Row, Col, message, Typography } from 'antd';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { currentUser } from '../../utils/authData';
import { ApiService } from '../../services/ApiService';

ChartJS.register(ArcElement, Tooltip, Legend);

const { Text } = Typography
const fileUrl = process.env.REACT_APP_FILE_URL

const ProfileBox = () => {
    const [loaded, setLoaded] = useState(false)
    const [user, setUser] = useState(null)
    const [dashboardEmployee, setDashboardEmployee] = useState([])
    const [avatar, setAvatar] = useState([])
    const userId = currentUser.id

    const fetchUserLogin = () => {
        setLoaded(false)
        ApiService.getObject('employee', userId).then((res) => {
            setUser(res.data)
            if (res.data.avatar === null || res.data.avatar === '') {
                setAvatar(null)
            } else {
                setAvatar(fileUrl + '/' + res.data.avatar)
            }
            setLoaded(true)
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const fetchDashboardEmployee = () => {
        setLoaded(false)
        ApiService.getList('dashboard-employee').then((res) => {
            setDashboardEmployee(res.data)
            setLoaded(true)
        }).catch((err) => {
            err.warning(err.message, 2)
        })
    }

    const backgroundColor = [
        "#a870fe", "#fe895f", "#5968fd", "#2ee1ad",
        "#48c3fe", "#eea4a3", "#ffb119", "#8879f0",
        "#557aaa", "#405c80", "#cfd9e6", "#306ebf"
    ];
    const borderColor = [
        "#a870fe", "#fe895f", "#5968fd", "#2ee1ad",
        "#48c3fe", "#eea4a3", "#ffb119", "#8879f0",
        "#557aaa", "#405c80", "#cfd9e6", "#306ebf"
    ];

    const chartStatus = {
        labels: dashboardEmployee.listDepartment,
        datasets: [
            {
                label: '',
                data: dashboardEmployee.listEmployee,
                backgroundColor: backgroundColor,
                borderColor: borderColor,
                borderWidth: 1
            }
        ]
    }

    useEffect(() => {
        fetchUserLogin()
        fetchDashboardEmployee()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {loaded &&
                <div style={{ marginBottom: 10 }}>
                    {
                        user ?
                            <Card style={{ width: '100%', minHeight: 100, padding: 0, color: 'white', borderRadius: 10 }} className="box-shadow-2">
                                <Row className='mb-4' justify="space-between">
                                    <Col span={12}>
                                        <Text className="m-0">
                                            <b>Selamat Datang</b> <br /> SMAIL Dashboard
                                        </Text>
                                    </Col>
                                    <Col>
                                        {
                                            avatar !== null ?
                                                <Avatar src={avatar} size={50} />
                                                : <Avatar size={50}>{(user.name.substring(0, 2)).toUpperCase()}</Avatar>
                                        }
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Text><b>{user.name}</b></Text>
                                        <br />
                                        <Text style={{ color: "#999999", fontWeight: 'bold' }}>{user.position.name}</Text>
                                    </Col>
                                </Row>
                            </Card>
                            : null
                    }
                    <br />
                    <Affix offsetTop={10}>
                        <Card title="Data Pegawai Aktif" style={{ borderRadius: 10 }} className="box-shadow-2">
                            <Row>
                                <Doughnut
                                    data={chartStatus}
                                    height={300}
                                />
                            </Row>
                        </Card>
                    </Affix>
                </div>
            }
        </>
    );
}

export default ProfileBox;
