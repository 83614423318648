import React, { useState, useEffect } from 'react';
import { Card, Form, Input, Button, Select, Typography, DatePicker, message, Row, Col, Space } from 'antd';
import { DeleteFilled, PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { FileUpload, PageHeader } from '../../components';
import { ApiService } from '../../services/ApiService';
import { currentUser } from '../../utils/authData';
import moment from 'moment';

const { Text, Title } = Typography
const { TextArea } = Input
const { Option } = Select

const CreateSuratDisposisi = () => {
    const history = useHistory()
    const [loaded, setLoaded] = useState(true)
    const [uploading, setUploading] = useState(false)
    const [department, setDepartment] = useState([])
    const [letterType, setLetterType] = useState([])
    const [letterClassification, setLetterClassification] = useState([])
    const [letterPriority, setLetterPriority] = useState([])
    const [actionDisposition, setActionDisposition] = useState([])
    const [archiveLocation, setArchiveLocation] = useState([])
    const [receiver, setReceiver] = useState([])
    const [status, setStatus] = useState('')
    const [attachmentFile, setAttachmentFile] = useState([])
    const [saving, setSaving] = useState(false)
    const user = currentUser

    const fileUploaded = (v) => {
        let atcs = attachmentFile
        if (v.message === 'Success') {
            atcs.push({ filename: v.response.data.filename })
        }
        setAttachmentFile(atcs)
    }

    const fetchDepartment = () => {
        setLoaded(false)
        ApiService.getList('department').then((res) => {
            setDepartment(res.data)
            setLoaded(true)
        }).catch((err) => {
            message.warning(err.message)
        })
    }

    const fetchLetterType = () => {
        setLoaded(false)
        ApiService.getList('letter-type').then((res) => {
            setLetterType(res.data)
            setLoaded(true)
        }).catch((err) => {
            message.warning(err.message)
        })
    }

    const fetchLetterClassification = () => {
        setLoaded(false)
        ApiService.getList('letter-classification').then((res) => {
            setLetterClassification(res.data)
            setLoaded(true)
        }).catch((err) => {
            message.warning(err.message)
        })
    }

    const fetchLetterPriority = () => {
        setLoaded(false)
        ApiService.getList('letter-priority').then((res) => {
            setLetterPriority(res.data)
            setLoaded(true)
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const fetchArchiveLocation = () => {
        setLoaded(false)
        ApiService.getList('archive-location').then((res) => {
            setArchiveLocation(res.data)
            setLoaded(true)
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const fetchActionDisposition = () => {
        ApiService.getList('action-disposition').then((res) => {
            setActionDisposition(res.data)
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const fetchReceiver = (el) => {
        ApiService.getObject('employee-by-department', el).then((res) => {
            setReceiver(res.data)
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const changeEmployee = (el) => {
        fetchReceiver(el)
    }

    const onFinish = (v) => {
        const data = {
            from: v.from,
            subject: v.subject,
            letter_number: v.letter_number,
            date: moment(v.letter_date).format('YYYY-MM-DD'),
            attachment: v.attachment,
            letter_type_id: v.letter_type_id,
            letter_classification_id: v.letter_classification_id,
            letter_priority_id: v.letter_priority_id,
            letter_confidentiality_id: v.letter_confidentiality_id,
            archive_location_id: v.archive_location_id,
            status: status,
            receiver: v.receiver,
            action_id: v.action_id,
            action_description: v.action_description,
            attachment_file: attachmentFile,
            created_by: user.id,
            updated_by: user.id
        }

        setSaving(true)
        ApiService.store(`disposition-mail`, data)
            .then((res) => {
                message.success('mail saved', 2);
                setTimeout(() => {
                    history.push('/surat-disposisi')
                }, 1000);
                setSaving(false)
            }).catch((err) => {
                message.warning(err.message)
            })
    }

    useEffect(() => {
        fetchArchiveLocation()
        fetchDepartment()
        fetchLetterClassification()
        fetchLetterPriority()
        fetchLetterType()
        fetchActionDisposition()
    }, []);

    return (
        <div>
            <PageHeader title="Tambah Dokumen Masuk Disposisi" breadcrumbs={[['Dokumen Masuk'], ['Disposisi'], ['Tambah']]} />

            {
                loaded ?
                    <Card style={{ borderRadius: 15 }} className="content-container">
                        <Card>
                            <Form onFinish={onFinish} layout='vertical'>
                                <div className='mb-4'>
                                    <Title style={{ fontSize: 18, fontWeight: 'bold' }}>INFORMASI SURAT</Title>
                                    <Text>(<span style={{ fontWeight: 'bold' }}>**</span>) Inputan dengan tanda (<span style={{ color: 'red' }}> * </span>) wajib diisi! Silahkan lengkapi informasi dibawah</Text>
                                </div>

                                <Row justify='space-between'>
                                    <Col span={11}>
                                        <Form.Item label="Dari" name="from" rules={[{ required: true }]}>
                                            <Input placeholder="Dari" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={11}>
                                        <Form.Item label="Perihal" name="subject" rules={[{ required: true }]}>
                                            <Input placeholder='Perihal' />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row justify='space-between'>
                                    <Col span={11}>
                                        <Form.Item label="No Surat" name="letter_number" rules={[{ required: true }]}>
                                            <Input placeholder='No Surat' />
                                        </Form.Item>
                                    </Col>
                                    <Col span={11}>
                                        <Form.Item label="Tanggal Surat" name="letter_date" rules={[{ required: true }]}>
                                            <DatePicker placeholder="Tanggal" style={{ width: 525 }} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={11}>
                                        <Form.Item label="Lampiran" name="attachment">
                                            <Input placeholder='Lampiran' />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row justify='space-between'>
                                    <Col span={11}>
                                        <Form.Item label="Jenis Surat" name="letter_type_id" rules={[{ required: true }]}>
                                            <Select placeholder="Jenis Surat">
                                                {
                                                    letterType.map((el, key) => (
                                                        <Option key={key} value={el.id}>{el.name}</Option>
                                                    ))
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col span={11}>
                                        <Form.Item label="Klasifikasi Surat" name="letter_classification_id" rules={[{ required: true }]}>
                                            <Select placeholder="Klasifikasi Surat">
                                                {
                                                    letterClassification.map((el, key) => (
                                                        <Option key={key} value={el.id}>{el.name}</Option>
                                                    ))
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row justify='space-between'>
                                    {/* <Col span={11}>
                                <Form.Item label="Sifat Surat" name="letter_confidentiality_id" rules={[{ required: true }]}>
                                    <Select placeholder="Sifat Surat">
                                        {
                                            letterConfidentiality.map((el, key) => (
                                                <Option key={key} value={el.id}>{el.name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col> */}

                                    <Col span={11}>
                                        <Form.Item label="Prioritas Surat" name="letter_priority_id" rules={[{ required: true }]}>
                                            <Select placeholder="Prioritas Surat">
                                                {
                                                    letterPriority.map((el, key) => (
                                                        <Option key={key} value={el.id}>{el.name}</Option>
                                                    ))
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col span={11}>
                                        <Form.Item label="Lokasi Arsip Fisik" name="archive_location_id" rules={[{ required: true }]}>
                                            <Select placeholder="Lokasi Arsip Fisik">
                                                {
                                                    archiveLocation.map((el, key) => (
                                                        <Option key={key} value={el.id}>{el.name}</Option>
                                                    ))
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Card bodyStyle={{ border: '0.7px solid #d7d7d7', borderRadius: 10, marginBottom: 15 }}>
                                    <Title style={{ fontSize: 18, fontWeight: 700 }}>DITUJUKAN KEPADA</Title>
                                    <div style={{ marginBottom: 15 }}>
                                        {
                                            <Text>(<b>*</b>) Pilih opsi surat untuk menentukan penerima atau disposisi</Text>

                                        }
                                    </div>
                                    <Form.List name="receiver">
                                        {(fields, { add, remove }) => (
                                            <>
                                                {fields.map(({ key, name, ...restField }) => (
                                                    <Space key={key} style={{ display: 'flex' }} align="baseline">
                                                        <Form.Item
                                                            {...restField}
                                                            label="Department"
                                                            name={[name, 'department_id']}
                                                            rules={[{ required: true, message: 'Missing Receiver' }]}
                                                            style={{ width: 400 }}
                                                        >
                                                            <Select placeholder="Department" onChange={changeEmployee}>
                                                                {
                                                                    department.map((el, key) => (
                                                                        <Option key={key} value={el.id}>{el.name}</Option>
                                                                    ))
                                                                }
                                                            </Select>
                                                        </Form.Item>

                                                        <Form.Item
                                                            {...restField}
                                                            label="Penerima"
                                                            name={[name, 'user_id']}
                                                            rules={[{ required: true, message: 'Missing Receiver' }]}
                                                            style={{ width: 400 }}
                                                        >
                                                            <Select placeholder="Penerima">
                                                                {
                                                                    receiver.map((el, key) => (
                                                                        <Option key={key} value={el.id}>{el.name}</Option>
                                                                    ))
                                                                }
                                                            </Select>
                                                        </Form.Item>

                                                        <Form.Item
                                                            {...restField}
                                                            label="Berkas Fisik"
                                                            name={[name, 'physical_file']}
                                                            rules={[{ required: true, message: 'Missing Physical File' }]}
                                                        >
                                                            <Select placeholder="Berkas Fisik">
                                                                <Option key='file_true' value={1}>Ya</Option>
                                                                <Option key='file_false' value={0}>Tidak</Option>
                                                            </Select>
                                                        </Form.Item>

                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'approver']}
                                                            label="Approved"
                                                            rules={[{ required: true, message: 'Missing Level' }]}
                                                        >
                                                            <Select placeholder="Approved">
                                                                <Option key='0' value={0}>Mengetahui</Option>
                                                                <Option key='1' value={1}>Approver</Option>
                                                            </Select>
                                                        </Form.Item>
                                                        <DeleteFilled style={{ color: "#f44336", fontSize: 18, position: 'relative', top: '35px' }} onClick={() => remove(name)} />
                                                    </Space>
                                                ))}
                                                <Form.Item>
                                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                        Tambah Penerima
                                                    </Button>
                                                </Form.Item>
                                            </>
                                        )}
                                    </Form.List>
                                </Card>

                                <Form.Item label="Arahan" name="action_id" rules={[{ required: true }]}>
                                    <Select placeholder="Arahan">
                                        {
                                            actionDisposition.map((el, key) => (
                                                <Option key={key} value={el.id}>{el.name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Keterangan" name="action_description" rules={[{ required: true }]}>
                                    <TextArea placeholder='Keterangan' />
                                </Form.Item>

                                <Card bodyStyle={{ border: '0.7px solid #d7d7d7', borderRadius: 10, marginBottom: 15 }}>
                                    <div className='my-3' >
                                        <Text style={{ fontSize: 18, fontWeight: 'bold' }}>UNGGAH BERKAS</Text><br />
                                        <Text>Format file yang diperbolehkan: jpg | jpeg | png | pdf | doc | xls</Text><br />
                                        <Text>(<span style={{ fontWeight: 'bold' }}>**</span>) max 20 MB</Text>
                                    </div>

                                    <Form.Item>
                                        <FileUpload path="file-attachment" multiple={true} dragger onUploaded={(v, key) => fileUploaded(v)} isUploading={(v) => setUploading(v)} />
                                    </Form.Item>
                                </Card>

                                <Form.Item className='mt-4'>
                                    <Row>
                                        <Col span={5}>
                                            <Button loading={saving || uploading} type="primary" name="status" onClick={() => setStatus("sent")} style={{ width: '80%', backgroundColor: '#63a67a', border: 'none', borderRadius: 10 }} htmlType="submit">
                                                Simpan dan Kirim
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Card>
                    : null
            }
        </div >
    );
}

export default CreateSuratDisposisi
