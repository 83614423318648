import React, { useState, useEffect } from 'react';
import { Card, Form, Input, Button, Select, Typography, DatePicker, message, Row, Col, Checkbox, InputNumber } from 'antd';
import { useHistory } from 'react-router-dom';
import { FileUpload, PageHeader } from '../../components';
import { ApiService } from '../../services/ApiService';
import { currentUser } from '../../utils/authData';
import moment from 'moment';

const { Text, Title } = Typography
const { TextArea } = Input
const { Option } = Select

const CreateSuratMasuk = () => {
    const history = useHistory()
    const [form] = Form.useForm()
    const [loaded, setLoaded] = useState(true)
    const [uploading, setUploading] = useState(false)
    const [department, setDepartment] = useState([])
    const [letterType, setLetterType] = useState([])
    const [letterClassification, setLetterClassification] = useState([])
    const [letterPriority, setLetterPriority] = useState([])
    const [receiver, setReceiver] = useState([])
    const [attachmentFile, setAttachmentFile] = useState([])
    const [actionDisposition, setActionDisposition] = useState([])
    const [status, setStatus] = useState('')
    const [checkBox, setCheckbox] = useState(false)
    const [saving, setSaving] = useState(false)
    const user = currentUser

    const fileUploaded = (v) => {
        let atcs = attachmentFile
        if (v.message === 'Success') {
            atcs.push({ filename: v.response.data.filename })
        }
        setAttachmentFile(atcs)
    }

    const fetchDepartment = () => {
        setLoaded(false)
        ApiService.getList('department').then((res) => {
            setDepartment(res.data)
            setLoaded(true)
        }).catch((err) => {
            message.warning(err.message)
        })
    }

    const fetchLetterType = () => {
        setLoaded(false)
        ApiService.getList('letter-type').then((res) => {
            setLetterType(res.data)
            setLoaded(true)
        }).catch((err) => {
            message.warning(err.message)
        })
    }

    const fetchLetterClassification = () => {
        setLoaded(false)
        ApiService.getList('letter-classification').then((res) => {
            setLetterClassification(res.data)
            setLoaded(true)
        }).catch((err) => {
            message.warning(err.message)
        })
    }

    const fetchLetterPriority = () => {
        setLoaded(false)
        ApiService.getList('letter-priority').then((res) => {
            setLetterPriority(res.data)
            setLoaded(true)
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const fetchReceiver = (el) => {
        ApiService.getObject('employee-by-department', el).then((res) => {
            setReceiver(res.data)
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const fetchActionDisposition = () => {
        ApiService.getList('action-disposition').then((res) => {
            setActionDisposition(res.data)
        }).catch((err) => {
        })
    }

    const changeEmployee = (el) => {
        fetchReceiver(el)
        form.setFieldsValue({ user_id: null })
    }

    const onFinish = (v) => {
        const data = {
            from_user: v.from_user,
            subject: v.subject,
            receiver_department_id: v.receiver_department_id,
            receiver_user_id: v.receiver_user_id,
            letter_number: v.letter_number,
            date: moment(v.letter_date).format('YYYY-MM-DD'),
            attachment: v.attachment,
            letter_type_id: v.letter_type_id,
            letter_classification_id: v.letter_classification_id,
            letter_priority_id: v.letter_priority_id,
            status: status,
            attachment_file: attachmentFile,
            action_id: v.action_id,
            action_description: v.action_description,
            disposition: checkBox,
            created_by: user.id,
            updated_by: user.id
        }

        setSaving(true)
        ApiService.store(`incoming-mail`, data)
            .then((res) => {
                message.success('mail saved', 2);
                setTimeout(() => {
                    history.push('/surat-masuk')
                }, 1000);
                setSaving(false)
            }).catch((err) => {
                message.warning(err.message)
            })
    }

    const changeCheckBox = (e) => {
        setCheckbox(e.target.checked)
    }

    useEffect(() => {
        fetchDepartment()
        fetchLetterClassification()
        fetchLetterPriority()
        fetchLetterType()
        fetchActionDisposition()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <PageHeader title="Tambah Dokumen Masuk" breadcrumbs={[['Dokumen Masuk'], ['Tambah']]} />

            {
                loaded ?
                    <Card style={{ borderRadius: 15 }} className="content-container">
                        <Card>
                            <Form form={form} onFinish={onFinish} layout='vertical'>
                                <div className='mb-4'>
                                    <Title style={{ fontSize: 18, fontWeight: 'bold' }}>INFORMASI SURAT</Title>
                                    <Text>(<span style={{ fontWeight: 'bold' }}>**</span>) Inputan dengan tanda (<span style={{ color: 'red' }}> * </span>) wajib diisi! Silahkan lengkapi informasi dibawah</Text>
                                </div>

                                <Row justify='space-between'>
                                    <Col span={11}>
                                        <Form.Item label="Pengirim" name="from_user" rules={[{ required: true }]}>
                                            <Input placeholder="Pengirim" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={11}>
                                        <Form.Item label="Perihal" name="subject" rules={[{ required: true }]}>
                                            <Input placeholder='Perihal' />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Card bodyStyle={{ border: '0.7px solid #d7d7d7', borderRadius: 10, margin: '15px 0px' }}>
                                    <div className='mb-2'>
                                        <Text>(<span style={{ fontWeight: 'bold' }}>**</span>) Ceklis apabila anda ingin mendisposisikan surat</Text>
                                    </div>

                                    <Row className='mb-4'>
                                        <Checkbox onChange={changeCheckBox}><b>Disposisi</b></Checkbox>
                                    </Row>

                                    <Title style={{ fontSize: 18, fontWeight: 700 }}>DITUJUKAN KEPADA</Title>
                                    <Row justify='space-between'>
                                        <Col span={11}>
                                            <Form.Item label="Direktorat" name="receiver_department_id" rules={[{ required: true }]}>
                                                <Select placeholder="Direktorat" onChange={changeEmployee}>
                                                    {
                                                        department.map((el, key) => (
                                                            <Option key={key} value={el.id}>{el.name}</Option>
                                                        ))
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={11}>
                                            <Form.Item label="Penerima" name="receiver_user_id" rules={[{ required: true }]}>
                                                <Select placeholder="Penerima" allowClear>
                                                    {
                                                        receiver.map((el, key) => (
                                                            <Option key={key} value={el.id}>{el.name}</Option>
                                                        ))
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    {
                                        checkBox ?
                                            <>
                                                <Form.Item label="Arahan" name="action_id" rules={[{ required: true }]}>
                                                    <Select placeholder="Arahan">
                                                        {
                                                            actionDisposition.map((el, key) => (
                                                                <Option key={key} value={el.id}>{el.name}</Option>
                                                            ))
                                                        }
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item label="Keterangan" name="action_description" rules={[{ required: true }]}>
                                                    <TextArea placeholder='Keterangan' />
                                                </Form.Item>
                                            </>
                                            :
                                            null
                                    }
                                </Card>

                                <Row justify='space-between'>
                                    <Col span={11}>
                                        <Form.Item label="Jenis Surat" name="letter_type_id" rules={[{ required: true }]}>
                                            <Select placeholder="Jenis Surat">
                                                {
                                                    letterType.map((el, key) => (
                                                        <Option key={key} value={el.id}>{el.name}</Option>
                                                    ))
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={11}>
                                        <Form.Item label="Nomor Surat" name="letter_number" rules={[{ required: true }]}>
                                            <Input placeholder='Nomor Surat' />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row justify='space-between'>
                                    <Col span={11}>
                                        <Form.Item label="Tanggal Surat" name="letter_date" rules={[{ required: true }]}>
                                            <DatePicker placeholder="Tanggal" style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={11}>
                                        <Form.Item label="Total Lampiran" name="attachment">
                                            <InputNumber type="number" placeholder='Total Lampiran' style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row justify='space-between'>
                                    <Col span={11}>
                                        <Form.Item label="Prioritas Surat" name="letter_priority_id" rules={[{ required: true }]}>
                                            <Select placeholder="Prioritas Surat">
                                                {
                                                    letterPriority.map((el, key) => (
                                                        <Option key={key} value={el.id}>{el.name}</Option>
                                                    ))
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col span={11}>
                                        <Form.Item label="Klasifikasi Surat" name="letter_classification_id" rules={[{ required: true }]}>
                                            <Select placeholder="Klasifikasi Surat">
                                                {
                                                    letterClassification.map((el, key) => (
                                                        <Option key={key} value={el.id}>{el.name}</Option>
                                                    ))
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Card bodyStyle={{ border: '0.7px solid #d7d7d7', borderRadius: 10, marginBottom: 15 }}>
                                    <div className='my-3' >
                                        <Text style={{ fontSize: 18, fontWeight: 'bold' }}>UNGGAH BERKAS</Text><br />
                                        <Text>Format file yang diperbolehkan: jpg | jpeg | png | pdf | doc | xls</Text><br />
                                        <Text>(<span style={{ fontWeight: 'bold' }}>**</span>) max 20 MB</Text>
                                    </div>

                                    <Form.Item>
                                        <FileUpload path='file-attachment' multiple={true} dragger onUploaded={(v, key) => fileUploaded(v)} isUploading={(v) => setUploading(v)} />
                                    </Form.Item>
                                </Card>

                                <Form.Item className='mt-4'>
                                    <Row>
                                        <Col span={5}>
                                            <Button loading={saving || uploading} type="primary" name="status" onClick={() => setStatus("sent")} style={{ width: '80%', backgroundColor: '#63a67a', border: 'none', borderRadius: 10 }} htmlType="submit">
                                                Simpan dan Kirim
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Card>
                    : null
            }
        </div >
    );
}

export default CreateSuratMasuk
