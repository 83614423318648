import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Layout } from 'antd'
import Login from './views/auth/Login'
import Forgot from './views/auth/Forgot'
import SignUp from './views/auth/SignUp'
import { GlobalStyles } from './components/styles/GlobalStyles'
import './assets/styles.less'
import './assets/main.css'
import './assets/custom-style.scss'
import AppContent from './views/AppContent'

function App() {
    const [authenticated, setAuthenticated] = useState(true)

    useEffect(() => {
        let currentUser = localStorage.getItem('currentUser')
        let pathname = window.location.pathname
        if (!currentUser) {
            setAuthenticated(false)
            if (pathname !== "/login") {
                window.location.href = "/login?redirect_to=" + pathname
            }
        } else {
            setAuthenticated(true)
            if (pathname === "/login") {
                window.location.href = "/"
            }
        }
    }, []);

    return (
        <>
            <GlobalStyles />
            <div>
                <Router>
                    <Layout theme="light">
                        <Layout style={{ minHeight: '100vh' }}>
                            {authenticated ?
                                <AppContent />
                                :
                                <Switch>
                                    <Route exact path="/login" component={Login} />
                                    <Route exact path="/forgot-password" component={Forgot} />
                                    <Route exact path="/sign-up" component={SignUp} />
                                </Switch>
                            }
                        </Layout>
                    </Layout>
                </Router>
            </div>
        </>
    );
}

export default App;
