import { useState, useEffect } from 'react'
import { Row, Col, Card, message, Form, Input, Select, Button, Image } from 'antd'
import { FilePdfOutlined } from '@ant-design/icons'
import { PageHeader, Spinner, DataTable } from '../../components'
import { useHistory, useParams } from 'react-router-dom'
import { currentUser } from '../../utils/authData'
import { ApiService } from '../../services/ApiService'
import moment from 'moment'

const { TextArea } = Input
const { Option } = Select

const layout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 10 },
};

const PenerimaSuratDisposisi = () => {
    const history = useHistory()
    const { id } = useParams()
    const [dispositionMailById, setDispositionMailById] = useState([])
    const [attachmentFile, setAttachmentFile] = useState([])
    const [loaded, setLoaded] = useState(true)
    const [response, setResponse] = useState([])
    const [statusDisposition, setStatusDisposition] = useState('')
    const [isSaving, setIsSaving] = useState(false)
    const user = currentUser

    const fetchIncomingMailById = () => {
        setLoaded(false);
        const data = {
            user_id: user.id
        }

        ApiService.getObject('disposition-mail-receive', id, data).then((res) => {
            setDispositionMailById(res.data[0])
            setLoaded(true)
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const fetchAttachment = () => {
        setLoaded(false)
        ApiService.getObject('letter-attachment', id).then((res) => {
            setAttachmentFile(res.data)
            setLoaded(true)
        }).catch((err) => {
            message.warning('File tidak ada')
        })
    }

    const fetchResponse = () => {
        ApiService.getList('response-disposition').then((res) => {
            setResponse(res.data)
        }).catch((err) => {
            message.warning('Gagal Mengambil data')
        })
    }

    const onFinish = (el) => {
        let payload = {
            ...el,
            status_disposition: statusDisposition
        }

        const data = {
            user_id: user.id
        }
        setIsSaving(false)
        ApiService.update('disposition-mail-response', id, payload, data).then((res) => {
            message.success("Sukses Update Respon")
            setLoaded(true)
            setIsSaving(true)
        }).catch((err) => {
            message.warning("Gagal Update Respon")
        })
    }

    useEffect(() => {
        fetchIncomingMailById()
        fetchAttachment()
        fetchResponse()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const columns = [
        {
            title: 'Lampiran',
            render: (row) => (
                row?.filename.split('.')[1] === 'pdf' ?
                    <a href={`${process.env.REACT_APP_FILE_URL}/${row.filename}`} target="_blank" rel="noreferrer">
                        {`${process.env.REACT_APP_FILE_URL}/${row.filename}`}
                    </a>
                    :
                    <Image src={`${process.env.REACT_APP_FILE_URL}/${row.filename}`} width={200} />
            ),
            key: 'attachment_file',
            align: 'center'
        }
    ];

    const exportReport = () => {
        window.open(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/disposition-mail-pdf/${id}`);
    }

    return (
        <div>
            <PageHeader title={`Pembuatan Surat Disposisi #${id}`} breadcrumbs={[['Pembuatan Surat Disposisi'], ['Detail']]} onBack={() => history.push('/surat-disposisi')} />

            <Card title="Keterangan Surat" style={{ borderRadius: 10 }}>
                <Row className='mb-5' justify='space-between'>
                    <Col span={15}>
                        {
                            loaded ?
                                <table className="table-collapse table-default" style={{ width: '100%' }}>
                                    <tbody>
                                        <tr>
                                            <th>Nomor Surat</th><td>{dispositionMailById.letter_number}</td>
                                        </tr>
                                        <tr>
                                            <th>Subjek</th><td>{dispositionMailById.subject}</td>
                                        </tr>
                                        <tr>
                                            <th>Tanggal Surat</th><td>{moment(dispositionMailById.date).format('dddd, DD MMMM YYYY')}</td>
                                        </tr>
                                        <tr>
                                            <th>Tipe Surat</th><td>{dispositionMailById.letter_type}</td>
                                        </tr>
                                        <tr>
                                            <th>Pengirim</th><td>{dispositionMailById.from}</td>
                                        </tr>
                                        <tr>
                                            <th>Department Pengirim</th><td>{dispositionMailById.sender_department ? dispositionMailById.sender_department : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th>Arsip Surat</th><td>{dispositionMailById.archive_location}</td>
                                        </tr>
                                        <tr>
                                            <th>Arahan</th><td>{dispositionMailById.action_disposition ? dispositionMailById.action_disposition : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th>Keterangan Arahan</th><td>{dispositionMailById.action_description ? dispositionMailById.action_description : '-'}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                : <Spinner />
                        }
                    </Col>
                    <Col span={8}>
                        <Button onClick={exportReport} type='primary' size='large' shape='round' icon={<FilePdfOutlined />}>Download Surat</Button>
                    </Col>
                </Row>

                <Card title="Lampiran">
                    {
                        <DataTable size="small" columns={columns} sources={attachmentFile} bordered />
                    }
                </Card>

                {
                    dispositionMailById.approver === 1 && dispositionMailById.response_id === null ?
                        <Card title="Respon Disposisi">
                            <Form {...layout} onFinish={onFinish} saving={isSaving}>
                                <Form.Item label="Respon" name="response_id" rules={[{ required: true }]}>
                                    <Select placeholder="Respon">
                                        {
                                            response.map((el, key) => (
                                                <Option key={key} value={el.id}>{el.name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Keterangan" name="response_description" rules={[{ required: true }]}>
                                    <TextArea placeholder="Keterangan" />
                                </Form.Item>
                                <Row>
                                    <Col span={4}>
                                        <Button style={{ borderRadius: 10, width: '90%', backgroundColor: '#f6685e', borderColor: '#f6685e' }} type="primary" htmlType="submit" onClick={() => setStatusDisposition('reject')} >Tolak</Button>
                                    </Col>
                                    <Col span={4}>
                                        <Button style={{ borderRadius: 10, width: '90%', backgroundColor: '#5db761', borderColor: '#5db761' }} type="primary" htmlType="submit" onClick={() => setStatusDisposition('approve')}>Approve</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card> :

                        <Card title="Respon Disposisi" >
                            <table className="table-collapse table-default" style={{ width: '100%' }}>
                                <tbody>
                                    <tr>
                                        <th>Respon</th><td>{dispositionMailById.response_disposition}</td>
                                    </tr>
                                    <tr>
                                        <th>Keterangan</th><td>{dispositionMailById.response_description}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Card>
                }

            </Card>
        </div >
    )
}

export default PenerimaSuratDisposisi