import { useState, useEffect } from 'react'
import { DataTable, PageHeader } from '../../components'
import { Card } from 'antd'
import { ApiService } from '../../services/ApiService'
import { Spinner } from 'react-bootstrap'
import moment from 'moment'

const Index = () => {
    const [logData, setLogData] = useState([])
    const [loaded, setLoaded] = useState(false)

    const fetchLoanData = () => {
        setLoaded(false)
        ApiService.getList("loan-log").then((res) => {
            setLogData(res.data)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchLoanData()

    }, [])

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Approval ID',
            dataIndex: 'approval_document_status_id',
            key: 'approval_id',
            align: 'center'
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description'
        },
        {
            title: 'Created By',
            render: (row) => (
                row?.user?.name ?? "-"
            ),
            key: 'user'
        },
        {
            title: 'Confirm By',
            render: (row) => (
                row?.confirm?.name ?? "-"
            ),
            key: 'confirm'
        },
        {
            title: 'Reason Loan',
            render: (row) => (
                row?.approval_status?.reason_loan
            ),
            key: 'reason_loan'
        },
        {
            title: 'Loan Date',
            render: (row) => (
                `${moment(row?.approval_status?.start_date).format("DD MMMM YYYY")} - ${moment(row?.approval_status?.end_date).format("DD MMMM YYYY")}`
            ),
            key: 'loan_date'
        }
    ]

    return (
        <div>
            <PageHeader title="Loan Log" breadcrumbs={[['Loan Log']]} />
            <Card style={{ borderRadius: 10 }}>
                {
                    loaded ?
                        <DataTable sources={logData} columns={columns} bordered size="small" />
                        : <Spinner />
                }
            </Card>
        </div>
    )
}

export default Index