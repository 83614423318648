import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { PageHeader } from '../../components'
import { Button, Card, Col, Descriptions, Row, Tag, message } from 'antd'
import { ApiService } from '../../services/ApiService'
import moment from 'moment'

const Detail = () => {
    const { id } = useParams()
    const [loaded, setLoaded] = useState(false)
    const [docUrl, setDocUrl] = useState("")
    const [docLoanData, setDocLoanData] = useState({})

    const fetchLoanData = () => {
        setLoaded(false)
        ApiService.getObject("approval", id).then((res) => {
            setDocUrl(res.data?.document?.filename)
            setDocLoanData(res.data)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchLoanData()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const onCofirmReturn = () => {
        ApiService.update("returning", id).then((res) => {
            message.success("Success Returning Document")
            fetchLoanData()
        }).catch((err) => {
            message.warning("Failed Returning Document")
        })
    }

    return (
        <div>
            <PageHeader title="Detail" breadcrumbs={[['Document Return', '/document-return'], ['Detail']]} />
            <Card style={{ borderRadius: 10 }}>
                <div style={{ marginBottom: 40 }}>
                    <Descriptions title={
                        <Row justify="space-between">
                            <Col>
                                Document Information
                            </Col>
                            <Col>
                                {
                                    docLoanData.is_returning === 0 ?
                                        <Button type="primary" onClick={onCofirmReturn}>Confirm</Button> :
                                        <Button type="primary" disabled onClick={onCofirmReturn}>Confirm</Button>
                                }
                            </Col>
                        </Row>
                    } bordered>
                        <Descriptions.Item label="Title">{docLoanData?.document?.title}</Descriptions.Item>
                        <Descriptions.Item label="Original Filename">{docLoanData?.document?.original_filename}</Descriptions.Item>
                        <Descriptions.Item label="Status">
                            <Tag color={docLoanData?.status === "WAITING" ? "blue" : docLoanData?.status === "APPROVED" || docLoanData?.status === "FINISHED" ? "green" : "red"}>
                                {docLoanData?.status}
                            </Tag>
                        </Descriptions.Item>
                        <Descriptions.Item label="Arhice Location">{docLoanData?.document?.archive_location?.archive_location_name}</Descriptions.Item>
                        <Descriptions.Item label="Returning Status">
                            <Tag color={docLoanData?.is_returning === 0 ? "red" : "green"}>
                                {docLoanData?.is_returning === 0 ? "Not Returned" : "Returned"}
                            </Tag>
                        </Descriptions.Item>
                        <Descriptions.Item label="Loan Date">
                            {`${moment(docLoanData?.start_date).format("DD MMMM YYYY")} - ${moment(docLoanData?.end_date).format("DD MMMM YYYY")}`}
                        </Descriptions.Item>
                    </Descriptions>
                </div>

                {
                    loaded ?
                        <div>
                            <Card title="Document Preview">
                                <Row justify="center">
                                    <iframe src={`${docUrl}#toolbar=0`} width="730" height="950" title="Document Review" />
                                </Row>
                            </Card>
                        </div>
                        : null
                }
            </Card>
        </div>
    )
}

export default Detail