import React, { useState, useEffect } from 'react';
import { Card, Space, message, Form, Input, Image, Row, Typography, Button } from 'antd';
import { PageHeader, EditButton, DeleteButton, DataTable, Spinner, FormModal, FileUpload } from '../../components';
import { ApiService } from '../../services/ApiService';
import { useParams, useHistory } from 'react-router-dom';
import { Docs, Excel, Pdf } from '../../components/Folder';

const { Text } = Typography
const fileUrl = process.env.REACT_APP_FILE_URL

const UpdateAttachment = () => {
    const history = useHistory()
    const [listAttachment, setListAttachment] = useState([])
    const [attachmentFile, setAttachmentFile] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [modalTitle, setModalTitle] = useState(null)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})
    const [isSaving, setIsSaving] = useState(false)
    // eslint-disable-next-line
    const [uploading, setUploading] = useState(false)
    const { id } = useParams()

    const fileUploaded = (v) => {
        let atcs = attachmentFile
        if (v.message === 'Success') {
            atcs.push({ filename: v.response.data.filename })
        }
        setAttachmentFile(atcs)
    }

    const fetchAttachment = (id) => {
        setLoaded(false)
        ApiService.getObject('letter-attachment', id).then((res) => {
            setListAttachment(res.data)
            setLoaded(true)
        }).catch((err) => {
            err.message(err.message, 2)
        })
    }

    const edit = (v) => {
        setModalTitle('Edit Attachment')
        setDefaultValues({
            id: v.id,
            letter_id: id,
        })
        setIsModalVisible(true)
    }

    const destroy = (id) => {
        ApiService.destroy('letter-attachment', id).then((res) => {
            message.success(res.message, 2)
            fetchAttachment(id)
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const onFinish = (el) => {
        let payload = {
            letter_id: id,
            attachment_file: attachmentFile
        }
        setIsSaving(true)
        ApiService.update(`letter-attachment`, el.id, payload)
            .then((res) => {
                message.success(res.message, 2)
                setIsModalVisible(false)
                setIsSaving(false)
                fetchAttachment(id)
            }).catch((err) => {
                message.warning(err.message, 2)
            })
    }

    const columnsAttachment = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'File Name',
            render: (row) => (
                row?.filename.split('.')[1] === 'jpg' || row?.filename.split('.')[1] === 'png' || row?.filename.split('.')[1] === 'svg' || row?.filename.split('.')[1] === 'jpeg' ?
                    <>
                        <Row justify='center'>
                            <Image width={'30%'} src={row?.filename ? `${fileUrl}/${row?.filename}` : null} />
                        </Row>
                        <Row justify='center'>
                            <Text className='mb-2'>{row?.filename.split('/')[1]}</Text>
                        </Row>
                    </>
                    : row?.filename.split('.')[1] === 'pdf' ?
                        <>
                            <Row justify='center'>
                                <Pdf onClick={() => attachmentPage(row?.filename)} />
                            </Row>
                            <Row justify='center'>
                                <Text className='mb-2'>{row?.filename.split('/')[1]}</Text>
                            </Row>
                        </>
                        : row?.filename.split('.')[1] === 'doc' || row?.filename.split('.')[1] === 'docx' ?
                            <>
                                <Row justify='center'>
                                    <Docs onClick={() => attachmentPage(row?.filename)} />
                                </Row>
                                <Row justify='center'>
                                    <Text className='mb-2'>{row?.filename.split('/')[1]}</Text>
                                </Row>
                            </>
                            : row?.filename.split('.')[1] === 'xls' || row?.filename.split('.')[1] === 'xlsx' ?
                                <>
                                    <Row justify='center'>
                                        <Excel onClick={() => attachmentPage(row?.filename)} />
                                    </Row>
                                    <Row justify='center'>
                                        <Text className='mb-2'>{row?.filename.split('/')[1]}</Text>
                                    </Row>
                                </>
                                :
                                <>
                                    <Row justify='center'>
                                        <Button onClick={() => attachmentPage(row?.filename)} />
                                    </Row>
                                    <Row justify='center'>
                                        <Text className='mb-2'>{row?.filename.split('/')[1]}</Text>
                                    </Row>
                                </>
            ),
            key: 'filename',
            align: 'center'
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => edit(row)} />
                        <DeleteButton onConfirm={() => destroy(row.id)} />
                    </Space>
                </div>
            ),
            align: 'center'
        },
    ]

    const attachmentPage = (v) => {
        window.open(`${process.env.REACT_APP_FILE_URL}/${v}`)
    }

    useEffect(() => {
        fetchAttachment(id)
    }, [id]);


    return (
        <div>
            <PageHeader title="Edit Lampiran" breadcrumbs={[['Dokumen Keluar'], ['Edit'], ['Lampiran']]} onBack={() => history.push(`/surat-keluar/update/${id}`)} />

            <Card style={{ borderRadius: 15 }} className="content-container">
                {
                    loaded ?
                        <DataTable columns={columnsAttachment} sources={listAttachment} />
                        : <Spinner />
                }
            </Card>

            {
                (isModalVisible) ?
                    <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => onFinish(v)} width={600} showModal={isModalVisible} onCloseModal={() => setIsModalVisible(false)} saving={isSaving}>
                        <Form.Item hidden name='id'>
                            <Input />
                        </Form.Item>
                        <Form.Item>
                            <FileUpload path="file-attachment" multiple={true} dragger onUploaded={(v, key) => fileUploaded(v)} isUploading={(v) => setUploading(v)} />
                        </Form.Item>
                    </FormModal> :
                    null
            }

        </div>
    );
};

export default UpdateAttachment;
