import { useState, useEffect } from 'react'
import { Row, Col, Card, message, Tag, Image, Typography } from 'antd'
import { PageHeader, Spinner, DataTable } from '../../components'
import { useHistory, useParams } from 'react-router-dom'
import { currentUser } from '../../utils/authData'
import { ApiService } from '../../services/ApiService'
import parse from 'html-react-parser'
import moment from 'moment'

const { Text } = Typography

const DraftSuratKeluarDetail = () => {
    const history = useHistory()
    const { id } = useParams()
    const [draftOutgoingMail, setDraftOutgoingMail] = useState([])
    const [attachmentFile, setAttachmentFile] = useState([])
    const [receiver, setReceiver] = useState([])
    const [loaded, setLoaded] = useState(true)
    const user = currentUser

    const fetchDraftOutgoingMailById = () => {
        setLoaded(false);
        const data = {
            user_id: user.id
        }
        ApiService.getObject('draft-outgoing', id, data).then((res) => {
            setDraftOutgoingMail(res.data.draft[0])
            setReceiver(res.data.draftReceiver)
            setLoaded(true)
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const fetchAttachmentFile = () => {
        ApiService.getObject('letter-attachment', id).then((res) => {
            setAttachmentFile(res.data)
        }).catch((err) => {
            message.warning('Surat tidak memiliki lampiran')
        })
    }

    useEffect(() => {
        fetchDraftOutgoingMailById()
        fetchAttachmentFile()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const columns = [
        {
            title: 'Nama',
            render: (row) => (
                row?.draft_receiver_user

            ),
            key: 'name',
            align: 'center'
        },
        {
            title: 'Departemen',
            render: (row) => (
                row?.draft_receiver_department

            ),
            key: 'department',
            align: 'center'
        },
        {
            title: 'Status Approver',
            render: (row) => (
                row?.approver === 1 && row?.status_approver === 1 ? <Tag color={"green"}>Approve</Tag> :
                    row?.approver === 1 && row?.status_approver === 2 ? <Tag color={"red"}>Ditolak</Tag> : <Tag color={"blue"}>Mengetahui</Tag>
            ),
            key: 'approve_status',
            align: 'center'
        },
        {
            title: 'Keterangan',
            render: (row) => (
                row?.response_description ? row?.response_description : '-'

            ),
            key: 'description',
            align: 'center'
        },
    ];

    const columnsAttachment = [
        {
            title: 'Lampiran',
            render: (row) => (
                row?.filename.split('.')[1] === 'pdf' ?
                    <a href={`${process.env.REACT_APP_FILE_URL}/${row.filename}`} target="_blank" rel="noreferrer" >
                        {`${process.env.REACT_APP_FILE_URL}/${row.filename}`}
                    </a>
                    :
                    <Image src={`${process.env.REACT_APP_FILE_URL}/${row.filename}`} width={200} />
            ),
            key: 'attachment_file',
            align: 'center'
        }
    ];

    // const exportReport = () => {
    //     window.open(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/outgoing-mail-pdf/${id}`);
    // }

    return (
        <div>
            <PageHeader title={`Draft Dokumen Keluar #${id}`} breadcrumbs={[['Draft Dokumen Keluar'], ['Detail']]} onBack={() => history.push('/surat-keluar')} />

            <Card title="Keterangan Dokumen" style={{ borderRadius: 10 }}>
                <Row className='mb-5' justify='space-between'>
                    <Col span={15}>
                        {
                            loaded ?
                                <table className="table-collapse table-default" style={{ width: '100%' }}>
                                    <tbody>
                                        <tr>
                                            <th>Nomor Dokumen</th><td>{draftOutgoingMail.letter_number}</td>
                                        </tr>
                                        <tr>
                                            <th>Subjek</th><td>{draftOutgoingMail.subject}</td>
                                        </tr>
                                        <tr>
                                            <th>Tanggal Dokumen</th><td>{moment(draftOutgoingMail.date).format('dddd, DD MMMM YYYY')}</td>
                                        </tr>
                                        <tr>
                                            <th>Tipe Dokumen</th><td>{draftOutgoingMail.letter_type}</td>
                                        </tr>
                                        <tr>
                                            <th>Opsi Dokumen</th><td>{draftOutgoingMail.letter_option}</td>
                                        </tr>
                                        <tr>
                                            <th>Arsip Dokumen</th><td>{draftOutgoingMail.archive_location}</td>
                                        </tr>
                                        <tr>
                                            <th>Arahan</th><td>{draftOutgoingMail.action_disposition ? draftOutgoingMail.action_disposition : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th>Keterangan Arahan</th><td>{draftOutgoingMail.action_description ? draftOutgoingMail.action_description : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th>Body</th><td>{draftOutgoingMail.body ? parse(draftOutgoingMail.body) : '-'}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                :
                                <Spinner />
                        }
                    </Col>
                    <Col span={8}>
                        {/* <Button onClick={exportReport} type='primary' size='large' shape='round' icon={<FilePdfOutlined />}>Download Dokumen</Button> */}
                    </Col>
                </Row>

                <Card title="Lampiran">
                    {
                        attachmentFile.length === 0 ?
                            <Card>
                                <Text>No Attachment File</Text>
                            </Card>
                            :
                            <DataTable size="small" columns={columnsAttachment} sources={attachmentFile} bordered search={false} />
                    }
                </Card>

                <Card title="Penerima" style={{ borderRadius: 10 }}>
                    {
                        (loaded) ?
                            <DataTable size="small" columns={columns} sources={receiver} bordered />
                            : <Spinner />
                    }
                </Card>

            </Card>
        </div >
    )
}

export default DraftSuratKeluarDetail