import React, { useState, useEffect } from 'react';
import { Card, Space, message, Form, Input, Select } from 'antd';
import { PageHeader, EditButton, DataTable, Spinner, FormModal } from '../../components';
import { ApiService } from '../../services/ApiService';
import { useParams } from 'react-router-dom';
import { currentUser } from '../../utils/authData'
import { useHistory } from 'react-router-dom';

const { Option } = Select

const UpdateReceiver = () => {
    const [form] = Form.useForm()
    const history = useHistory()
    const [listReceiver, setListReceiver] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [modalTitle, setModalTitle] = useState(null)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})
    const [isSaving, setIsSaving] = useState(false)
    const [employee, setEmployee] = useState([])
    const [department, setDepartment] = useState([])
    const [letterOption, setLetterOption] = useState([])
    const { id } = useParams()
    const user = currentUser

    const fetchReceiver = () => {
        setLoaded(false)
        ApiService.getObject('outgoing-mail', id).then((res) => {
            setListReceiver(res.data.receiver)
            setLetterOption(res.data.outgoing_mail[0].letter_option_id)
            setLoaded(true)
        }).catch((err) => {
            err.message('Failed')
        })
    }

    const fetchEmployee = (el) => {
        ApiService.getObject('employee-by-department', el).then((res) => {
            setEmployee(res.data)
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const fetchDepartment = () => {
        ApiService.getList('department').then((res) => {
            setDepartment(res.data)
        }).catch((err) => {
            message.warning('Failed')
        })
    }

    const changeEmployee = (el) => {
        fetchEmployee(el)
        form.setFieldsValue({ user_id: null })
    }

    const edit = (v) => {
        setModalTitle('Edit Penerima')
        setDefaultValues({
            id: v.id,
            letter_id: id,
            receiver_user_id: v.receiver_user_id,
            receiver_eksternal: v.receiver_eksternal,
            receiver_department_id: v.receiver_department_id,
            updated_by: user.id
        })
        setIsModalVisible(true)
    }

    const onFinish = (el) => {
        let payload = {
            ...el
        }

        setIsSaving(true)
        ApiService.update(`letter-receiver`, el.id, payload)
            .then((res) => {
                message.success(res.message, 2)
                setIsModalVisible(false)
                setIsSaving(false)
                fetchReceiver(id)
            }).catch((err) => {
                message.warning(err.message, 2)
            })
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Nama',
            render: (row) => (
                row?.receiver_user ?? row?.receiver_eksternal
            ),
            key: 'name'
        },
        {
            title: 'Departemen',
            render: (row) => (
                row?.receiver_department
            ),
            key: 'name'
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => edit(row)} />
                    </Space>
                </div>
            ),
            align: 'center'
        },
    ];

    useEffect(() => {
        fetchReceiver()
        fetchDepartment()

    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div>
            <PageHeader title="Edit Penerima" breadcrumbs={[['Dokumen Keluar'], ['Edit'], ['Penerima']]} onBack={() => history.push(`/surat-keluar/update/${id}`)} />

            <Card style={{ borderRadius: 15 }} className="content-container">
                {
                    loaded ?
                        <DataTable columns={columns} sources={listReceiver} />
                        : <Spinner />
                }
            </Card>

            {
                (isModalVisible) ?
                    <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => onFinish(v)} width={600} showModal={isModalVisible} onCloseModal={() => setIsModalVisible(false)} saving={isSaving}>
                        <Form.Item name="id" hidden>
                            <Input />
                        </Form.Item>
                        {
                            letterOption === 2 ?
                                <>
                                    <Form.Item label="Departemen" name="receiver_department_id" required rules={[{ required: true }]}>
                                        <Select disabled>
                                            {
                                                department.map((el, key) => (
                                                    <Option key={key} value={el.id}>{el.name}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label="Nama" name="receiver_eksternal" required rules={[{ required: true }]}>
                                        <Input placeholder='Nama' />
                                    </Form.Item>
                                </> :
                                <>
                                    <Form.Item label="Departemen" name="receiver_department_id" required rules={[{ required: true }]}>
                                        <Select onChange={changeEmployee}>
                                            {
                                                department.map((el, key) => (
                                                    <Option key={key} value={el.id}>{el.name}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label="Nama" name="receiver_user_id" required rules={[{ required: true }]}>
                                        <Select>
                                            {
                                                employee.map((el, key) => (
                                                    <Option key={key} value={el.id}>{el.name}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>

                                </>
                        }
                    </FormModal>
                    :
                    null
            }

        </div>
    );
};

export default UpdateReceiver;
