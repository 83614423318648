import React, { useState, useEffect } from 'react';
import { Card, Typography, Col, Row, message, Space, Tag, Button, Tabs, Badge } from 'antd';
import { Mail, Send } from 'react-feather';
import { DataTable, PageHeader, Spinner, ShowButton, DetailModal, DeleteButton, EditButton } from '../../components';
import StatCard from '../../components/shared/StatCard';
import { ApiService } from '../../services/ApiService';
import Avatar from 'antd/lib/avatar/avatar';
import moment from 'moment';
import 'moment/locale/id';
import { currentUser } from '../../utils/authData';
import { useHistory } from 'react-router-dom';

const { Text } = Typography;
const { TabPane } = Tabs;
const defaultTab = (sessionStorage.getItem('selectedDashboardTab')) ? sessionStorage.getItem('selectedDashboardTab') : 'create'

const SuratDisposisi = () => {
    const [loaded, setLoaded] = useState(true)
    const [dispositionMailCreate, SetDispositionMailCreate] = useState([])
    const [dispositionMailReceive, SetDispositionMailReceive] = useState([])
    const [dispositionMailById, SetDispositionMailById] = useState([])
    const [isRead, setIsRead] = useState([])
    const [modalTitle, setModalTitle] = useState(null)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [tabs, setTabs] = useState(sessionStorage.getItem('selectedDashboardTab'))
    const history = useHistory()
    const user = currentUser

    const fetchDispositionMailCreate = () => {
        setLoaded(false);
        const data = {
            user_id: user.id
        }
        ApiService.getList('disposition-mail-create', data).then((res) => {
            SetDispositionMailCreate(res.data)
            setLoaded(true)
        }).catch((err) => {
        })
    }

    const fetchDispositionMailReceive = () => {
        setLoaded(false);
        const data = {
            user_id: user.id
        }
        ApiService.getList('disposition-mail-receive', data).then((res) => {
            SetDispositionMailReceive(res.data)
            setIsRead(res.data[0].is_read)
            setLoaded(true)
        }).catch((err) => {
        })
    }

    const edit = (v) => {
        setModalTitle('Detail Dokumen Masuk')
        setIsModalVisible(true)
        SetDispositionMailById(v)
    }

    const updateRead = (v) => {
        setIsModalVisible(true)
        const data = {
            user_id: user.id
        }

        ApiService.update('update-read', v.id, 0, data).then((res) => {
            setIsRead(1)
        }).catch((err) => {
        })
        SetDispositionMailById(v)
    }


    const destroy = (id) => {
        ApiService.destroy('incoming-mail', id).then((res) => {
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    useEffect(() => {
        fetchDispositionMailCreate()
        fetchDispositionMailReceive()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '3%'
        },
        {
            title: 'Tipe Surat',
            render: (row) => (
                isRead === 0 ?
                    <Badge dot>
                        {row?.letter_type}
                    </Badge> :
                    row?.letter_type

            ),
            key: 'letter_type',
            align: 'center'
        },
        {
            title: 'Tanggal Surat',
            render: (row) => (
                moment(row?.date).locale('id').format('dddd, D MMMM YYYY')
            ),
            key: 'date',
            align: 'center'
        },
        {
            title: 'Nomor Surat',
            render: (row) => (
                row?.letter_number
            ),
            key: 'letter_number',
            align: 'center'
        },
        {
            title: 'Subjek',
            render: (row) => (
                row?.subject
            ),
            key: 'subject',
            align: 'center'
        },
        {
            title: 'Pengirim',
            render: (row) => (
                row?.from
            ),
            key: 'sender',
            align: 'center'
        },
        {
            title: 'Status Pengiriman',
            render: (row) => (
                <Tag color={row?.status === 0 ? "blue" : "green"}>{(row?.status === 0) ? 'Draft' : 'Terkirim'}</Tag>
            ),
            key: 'sending_status',
            align: 'center'
        },
        {
            title: 'Status Surat',
            render: (row) => (
                row?.letter_status === 0 ? <Tag color={"blue"}>Menunggu</Tag> : row?.letter_status === 1 ? <Tag color={"green"}>Disetujui</Tag> : <Tag color={"red"}>Ditolak</Tag>
            ),
            key: 'sending_status',
            align: 'center'
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <ShowButton onShow={() => updateRead(row)} />
                        <DeleteButton onConfirm={() => destroy(row.letter_id)} />
                    </Space>
                </div>
            )
        },
    ];

    const columns2 = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '3%'
        },
        {
            title: 'Tipe Surat',
            render: (row) => (
                row?.letter_type

            ),
            key: 'letter_type',
            align: 'center'
        },
        {
            title: 'Tanggal Surat',
            render: (row) => (
                moment(row?.date).locale('id').format('dddd, D MMMM YYYY')
            ),
            key: 'date',
            align: 'center'
        },
        {
            title: 'Nomor Surat',
            render: (row) => (
                row?.letter_number
            ),
            key: 'letter_number',
            align: 'center'
        },
        {
            title: 'Subjek',
            render: (row) => (
                row?.subject
            ),
            key: 'subject',
            align: 'center'
        },
        {
            title: 'Penerima',
            render: (row) => (
                row?.user ? row?.user : row?.to_receiver
            ),
            key: 'receiver',
            align: 'center'
        },
        {
            title: 'Status Pengiriman',
            render: (row) => (
                <Tag color={row?.status === 0 ? "blue" : "green"}>{(row?.status === 0) ? 'Draft' : 'Terkirim'}</Tag>
            ),
            key: 'sending_status',
            align: 'center'
        },
        {
            title: 'Status Surat',
            render: (row) => (
                row?.letter_status === 0 ? <Tag color={"blue"}>Menunggu</Tag> : row?.letter_status === 1 ? <Tag color={"green"}>Disetujui</Tag> : <Tag color={"red"}>Ditolak</Tag>
            ),
            key: 'sending_status',
            align: 'center'
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <ShowButton onShow={() => edit(row)} />
                        <EditButton onEdit={() => {
                            history.push(`/surat-disposisi/update/${row.id}`)
                        }} />
                        <DeleteButton onConfirm={() => destroy(row.letter_id)} />
                    </Space>
                </div>
            )
        },
    ];

    const changeTab = (selected) => {
        sessionStorage.setItem('selectedDashboardTab', selected)
        setTabs(selected)
    }

    return (
        <div>
            {
                user.role_id === 1 || user.role_id === 2 ?
                    <PageHeader title="Dokumen Masuk Disposisi" breadcrumbs={[['Dokumen Masuk'], ['Disposisi']]} createLink={'/surat-disposisi/create'} />
                    :
                    <PageHeader title="Dokumen Masuk Disposisi" breadcrumbs={[['Dokumen Masuk'], ['Disposisi']]} />
            }

            <Row justify='space-between'>
                {
                    user.role_id === 1 || user.role_id === 2 ?
                        <>
                            <Col span={9}>
                                <StatCard
                                    type="fill"
                                    title="Total Dokumen Masuk Disposisi"
                                    value={dispositionMailReceive.length}
                                    icon={<Send size={30} strokeWidth={1} />}
                                    color={"#62a67a"}
                                />
                            </Col>
                            <Col span={9}>
                                <StatCard
                                    type="fill"
                                    title="Total Pembuatan Dokumen Masuk Disposisi"
                                    value={dispositionMailCreate.length}
                                    icon={<Send size={30} strokeWidth={1} />}
                                    color={"#e1a949"}
                                />
                            </Col>
                            <Col span={3}>
                            </Col>
                        </> :
                        <>

                            <Col span={9}>
                                <StatCard
                                    type="fill"
                                    title="Total Dokumen Masuk Disposisi"
                                    value={dispositionMailReceive.length}
                                    icon={<Send size={30} strokeWidth={1} />}
                                    color={"#62a67a"}
                                />
                            </Col>
                            <Col span={9}>
                            </Col>
                            <Col span={3}>
                            </Col>
                        </>
                }
            </Row>

            <Row>
                <Col span={24}>
                    <Card style={{ borderRadius: 10 }}>
                        {
                            user.role_id === 1 || user.role_id === 2 ?
                                <Tabs defaultActiveKey={defaultTab} onChange={changeTab}>
                                    <TabPane tab="Pembuatan Surat Disposisi" key="create">
                                        {
                                            (loaded) ?
                                                <DataTable size="small" columns={columns2} sources={dispositionMailCreate} bordered />
                                                : <Spinner />
                                        }
                                    </TabPane>
                                    <TabPane tab="Dokumen Masuk Disposisi" key="receive">
                                        {
                                            (loaded) ?
                                                <DataTable size="small" columns={columns} sources={dispositionMailReceive} bordered />
                                                : <Spinner />
                                        }
                                    </TabPane>
                                </Tabs> :
                                <>
                                    {
                                        (loaded) ?
                                            <DataTable size="small" columns={columns} sources={dispositionMailReceive} bordered />
                                            : <Spinner />
                                    }
                                </>

                        }

                        {
                            isModalVisible && dispositionMailById ?
                                <DetailModal title={modalTitle} showModal={isModalVisible} onCloseModal={() => setIsModalVisible(false)}>
                                    <Row style={{ marginBottom: 10 }}>
                                        <Col span={24} align="end">
                                            <Text>
                                                {moment(dispositionMailById.date).format('dddd, D MMMM YYYY')}
                                            </Text>
                                        </Col>
                                    </Row>
                                    <Row className='mb-3'>
                                        <Col style={{ alignItems: 'center', justifyContent: 'center', marginTop: 8 }} span={4}>
                                            <Avatar shape='square' size={50}><Mail style={{ marginTop: '-5px' }} /></Avatar>
                                        </Col>
                                        <Col span={14}>
                                            <Text style={{ fontSize: 18, fontWeight: 'bold' }}><b>Subjek:</b> {dispositionMailById.subject}</Text><br />
                                            <Text style={{ fontSize: 12 }}><b>Pengirim:</b> {dispositionMailById.from}</Text><br />
                                            <Text style={{ fontSize: 12 }}><b>No Surat:</b> {dispositionMailById.letter_number}</Text>
                                        </Col>
                                        <Col>
                                            {
                                                user.role_id === 1 || user.role_id === 2 ?
                                                    tabs === 'create' ?
                                                        <Button type="primary" className="button-primary" style={{ marginTop: 20, marginLeft: 12, borderRadius: 10 }} onClick={() => history.push(`/surat-disposisi-detail-create/${dispositionMailById.id}`)}>Lihat Surat</Button>
                                                        :
                                                        <Button type="primary" className="button-primary" style={{ marginTop: 20, marginLeft: 12, borderRadius: 10 }} onClick={() => history.push(`/surat-disposisi-detail-receive/${dispositionMailById.id}`)}>Lihat Surat</Button>
                                                    :
                                                    <Button type="primary" className="button-primary" style={{ marginTop: 20, marginLeft: 12, borderRadius: 10 }} onClick={() => history.push(`/surat-disposisi-detail-receive/${dispositionMailById.id}`)}>Lihat Surat</Button>
                                            }
                                        </Col>
                                    </Row>

                                    {
                                        dispositionMailById.action_disposition ?
                                            <>
                                                <Row>
                                                    <Text style={{ fontSize: 14 }}><b>Arahan:</b> {dispositionMailById.action_disposition}</Text>
                                                </Row>
                                                <Row>
                                                    <Text style={{ fontSize: 14 }}><b>Keterangan:</b> {dispositionMailById.action_description}</Text>
                                                </Row>
                                            </> :
                                            <>
                                                <Row>
                                                    <Text style={{ fontSize: 14 }}><b>Arahan:</b> - </Text>
                                                </Row>
                                                <Row>
                                                    <Text style={{ fontSize: 14 }}><b>Keterangan:</b> - </Text>
                                                </Row>
                                            </>
                                    }
                                </DetailModal> :
                                null
                        }
                    </Card>
                </Col>
            </Row>
        </div >
    )
}

export default SuratDisposisi
