import React, { useState, useEffect } from 'react';
import { Card, Form, Input, Button, Select, Typography, DatePicker, message, Col, Row, Image, Checkbox, InputNumber } from 'antd';
import { PageHeader, FileUpload, DataTable } from '../../components';
import { ApiService } from '../../services/ApiService';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { currentUser } from '../../utils/authData'

const { Text, Title } = Typography
const { Option } = Select
const { TextArea } = Input

const UpdateSuratMasuk = () => {
    const history = useHistory()
    const [form] = Form.useForm();
    const [saving, setSaving] = useState(false)
    const [loaded, setLoaded] = useState(true)
    const [department, setDepartment] = useState([])
    const [letterType, setLetterType] = useState([])
    const [letterClassification, setLetterClassification] = useState([])
    const [letterPriority, setLetterPriority] = useState([])
    const [receiver, setReceiver] = useState([])
    const [attachmentFile, setAttachmentFile] = useState([])
    const [listAttachment, setListAttachment] = useState([])
    const [actionDisposition, setActionDisposition] = useState([])
    const [checkBox, setCheckbox] = useState(false)
    const [status, setStatus] = useState('')
    const [uploading, setUploading] = useState(false)
    const { id } = useParams()
    const user = currentUser

    const fileUploaded = (v) => {
        let atcs = attachmentFile
        if (v.message === 'Success') {
            atcs.push({ filename: v.response.data.url })
        }
        setAttachmentFile(atcs)
    }

    const fetchIncomingMail = (id, form) => {
        setLoaded(false)
        const user_id = {
            user_id: user.id
        }
        ApiService.getObject('incoming-mail-create', id, user_id).then(res => {
            const data = res.data[0]

            if (data.type === 'DISP' || data.type === 3) {
                setCheckbox(true)
            }

            form.setFieldsValue({
                id: data.id,
                letter_id: data.letter_id,
                from_user: data.from_user,
                subject: data.subject,
                receiver_department_id: data.receiver_department_id,
                receiver_user_id: data.receiver_user_id,
                check_disposition: checkBox,
                letter_number: data.letter_number,
                letter_date: moment(data.date),
                total_attachment: data.total_attachment,
                letter_type_id: data.letter_type_id,
                letter_classification_id: data.letter_classification_id,
                letter_priority_id: data.letter_priority_id,
                letter_confidentiality_id: data.letter_confidentiality_id,
                action_id: data.action_id,
                action_description: data.action_description,
                status: data.status
            })
            fetchReceiver(data.receiver_department_id)
            setLoaded(true)
        }).catch((err) => {
            console.log(err)
            message.warning(err.message)
        })
    }

    const fetchDepartment = () => {
        ApiService.getList('department').then((res) => {
            setDepartment(res.data)
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const fetchLetterType = () => {
        ApiService.getList('letter-type').then((res) => {
            setLetterType(res.data)
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const fetchLetterClassification = () => {
        ApiService.getList('letter-classification').then((res) => {
            setLetterClassification(res.data)
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const fetchLetterPriority = () => {
        ApiService.getList('letter-priority').then((res) => {
            setLetterPriority(res.data)
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const fetchReceiver = (el) => {
        ApiService.getObject('employee-by-department', el).then((res) => {
            setReceiver(res.data)
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const fetchAttachment = (id) => {
        ApiService.getObject('letter-attachment', id).then((res) => {
            setListAttachment(res.data)
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const fetchActionDisposition = () => {
        ApiService.getList('action-disposition').then((res) => {
            setActionDisposition(res.data)
        }).catch((err) => {
        })
    }

    const changeEmployee = (el) => {
        fetchReceiver(el)
        form.setFieldsValue({ user_id: null })
    }

    const onFinish = (v) => {
        setSaving(true)
        let data = {
            from_user: v.from_user,
            subject: v.subject,
            receiver_department_id: v.receiver_department_id,
            receiver_user_id: v.receiver_user_id,
            letter_number: v.letter_number,
            date: moment(v.letter_date).format('YYYY-MM-DD'),
            total_attachment: v.total_attachment,
            letter_type_id: v.letter_type_id,
            letter_classification_id: v.letter_classification_id,
            letter_priority_id: v.letter_priority_id,
            action_id: v.action_id ? v.action_id : 0,
            action_description: v.action_description ? v.action_description : '-',
            status: status,
            attachment_file: attachmentFile,
            check_disposition: checkBox,
            updated_by: user.id
        }
        ApiService.update(`incoming-mail`, id, data)
            .then((res) => {
                message.success('mail saved', 2);
                setTimeout(() => {
                    history.push('/surat-masuk')
                }, 1000);
                setSaving(false)
            }).catch((err) => {
                message.warning(err.message, 2)
            })
    }

    useEffect(() => {
        fetchIncomingMail(id, form)
        fetchDepartment()
        fetchLetterClassification()
        fetchLetterPriority()
        fetchLetterType()
        fetchAttachment(id)
        fetchActionDisposition()
    }, [id, form]); // eslint-disable-line react-hooks/exhaustive-deps

    const columnsAttachment = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'File Name',
            render: (row) => (
                row?.filename.split('.')[1] === 'pdf' ?
                    <a href={`${process.env.REACT_APP_FILE_URL}/${row.filename}`} target="_blank" without rel="noreferrer">
                        {`${process.env.REACT_APP_FILE_URL}/${row.filename}`}
                    </a>
                    :
                    <Image src={`${process.env.REACT_APP_FILE_URL}/${row.filename}`} width={200} />
            ),
            key: 'name',
            align: 'center'
        },
    ]

    const changeCheckBox = (e) => {
        setCheckbox(e.target.checked)
    }

    return (
        <div>
            <PageHeader title="Edit Dokumen Masuk" breadcrumbs={[['Dokumen Masuk'], ['Edit']]} onBack={() => history.push('/surat-masuk')} />

            <Card style={{ borderRadius: 15 }} className="content-container">
                <Card>
                    {
                        loaded &&
                        <Form onFinish={onFinish} layout='vertical' form={form}>
                            <div className='mb-4'>
                                <Title style={{ fontSize: 18, fontWeight: 'bold' }}>INFORMASI SURAT</Title>
                                <Text>(<span style={{ fontWeight: 'bold' }}>**</span>) Inputan dengan tanda (<span style={{ color: 'red' }}> * </span>) wajib diisi! Silahkan lengkapi informasi dibawah</Text>
                            </div>

                            <Row justify='space-between'>
                                <Col span={11}>
                                    <Form.Item label="Dari" name="from_user" rules={[{ required: true }]}>
                                        <Input placeholder="Dari" />
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item label="Perihal" name="subject" rules={[{ required: true }]}>
                                        <Input placeholder='Perihal' />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Card bodyStyle={{ border: '0.7px solid #d7d7d7', borderRadius: 10, marginBottom: 15 }}>
                                <div className='mb-2'>
                                    <Text>(<span style={{ fontWeight: 'bold' }}>**</span>) Ceklis apabila anda ingin mendisposisikan surat</Text>
                                </div>

                                <Row className='mb-4'>
                                    <Form.Item name="check_disposition">
                                        <Checkbox checked={checkBox} onChange={changeCheckBox}><b>Disposisi</b></Checkbox>
                                    </Form.Item>
                                </Row>

                                <Title style={{ fontSize: 18, fontWeight: 700 }}>DITUJUKAN KEPADA</Title>
                                <Row justify='space-between'>
                                    <Col span={11}>
                                        <Form.Item label="Direktorat" name="receiver_department_id" rules={[{ required: true }]}>
                                            <Select placeholder="Direktorat" onChange={changeEmployee}>
                                                {
                                                    department.map((el, key) => (
                                                        <Option key={key} value={el.id}>{el.name}</Option>
                                                    ))
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={11}>
                                        <Form.Item label="Kepada" name="receiver_user_id" rules={[{ required: true }]}>
                                            <Select placeholder="Kepada" allowClear>
                                                {
                                                    receiver.map((el, key) => (
                                                        <Option key={key} value={el.id}>{el.name}</Option>
                                                    ))
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                {
                                    checkBox ?
                                        <>
                                            <Form.Item label="Arahan" name="action_id" rules={[{ required: true }]}>
                                                <Select placeholder="Arahan">
                                                    {
                                                        actionDisposition.map((el, key) => (
                                                            <Option key={key} value={el.id}>{el.name}</Option>
                                                        ))
                                                    }
                                                </Select>
                                            </Form.Item>
                                            <Form.Item label="Keterangan" name="action_description" rules={[{ required: true }]}>
                                                <TextArea placeholder='Keterangan' />
                                            </Form.Item>
                                        </>
                                        :
                                        null
                                }
                            </Card>

                            <Row justify='space-between'>
                                <Col span={11}>
                                    <Form.Item label="Jenis Dokumen" name="letter_type_id" rules={[{ required: true }]}>
                                        <Select placeholder="Jenis Dokumen">
                                            {
                                                letterType.map((el, key) => (
                                                    <Option key={key} value={el.id}>{el.name}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item label="Nomor Dokumen" name="letter_number" rules={[{ required: true }]}>
                                        <Input placeholder='No Dokumen' />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row justify='space-between'>
                                <Col span={11}>
                                    <Form.Item label="Tanggal Dokumen" name="letter_date" rules={[{ required: true }]}>
                                        <DatePicker placeholder="Tanggal" style={{ width: "100%" }} />
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item label="Total Lampiran" name="total_attachment">
                                        <InputNumber type="number" placeholder='Total Lampiran' style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row justify='space-between'>
                                <Col span={11}>
                                    <Form.Item label="Prioritas Dokumen" name="letter_priority_id" rules={[{ required: true }]}>
                                        <Select placeholder="Prioritas Dokumen">
                                            {
                                                letterPriority.map((el, key) => (
                                                    <Option key={key} value={el.id}>{el.name}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={11}>
                                    <Form.Item label="Klasifikasi Dokumen" name="letter_classification_id" rules={[{ required: true }]}>
                                        <Select placeholder="Klasifikasi Dokumen">
                                            {
                                                letterClassification.map((el, key) => (
                                                    <Option key={key} value={el.id}>{el.name}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Card bodyStyle={{ border: '0.7px solid #d7d7d7', borderRadius: 10, marginBottom: 15 }}>
                                <div className='mb-3'>
                                    <Title style={{ fontSize: 18, fontWeight: 'bold' }}>LIST ATTACHMENT</Title>
                                    {
                                        listAttachment.length > 0 ?
                                            <>
                                                <DataTable columns={columnsAttachment} sources={listAttachment} />
                                                <Button className="mt-3" onClick={() => {
                                                    history.push('/surat-masuk/update-attachment/' + id)
                                                }}>Edit Attachment </Button>
                                            </>
                                            :
                                            <>
                                                <div className='mb-5'>
                                                    <Button disabled className="mt-3" onClick={() => {
                                                        history.push('/surat-masuk/update-attachment/' + id)
                                                    }}>Edit Attachment </Button>
                                                </div>
                                                <div className='mb-3' >
                                                    <Text style={{ fontSize: 18, fontWeight: 'bold' }}>UNGGAH BERKAS</Text><br />
                                                    <Text>Format file yang diperbolehkan: jpg | jpeg | png | pdf | doc | xls</Text><br />
                                                    <Text>(<span style={{ fontWeight: 'bold' }}>**</span>) max 20 MB</Text>
                                                </div>

                                                <Form.Item>
                                                    <FileUpload multiple={true} dragger onUploaded={(v, key) => fileUploaded(v)} isUploading={(v) => setUploading(v)} />
                                                </Form.Item>
                                            </>
                                    }

                                </div>
                            </Card>

                            <Form.Item className='mt-4'>
                                <Row>
                                    <Col span={5}>
                                        <Button loading={saving || uploading} type="primary" name="status" onClick={() => setStatus("sent")} style={{ width: '80%', backgroundColor: '#63a67a', border: 'none', borderRadius: 10 }} htmlType="submit">
                                            Simpan dan Kirim
                                        </Button>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Form>
                    }
                </Card>
            </Card>
        </div >
    );
}

export default UpdateSuratMasuk
