import React, { useState, useEffect } from 'react';
import { Card, Col, Row, message, Button, DatePicker, Space, Tag } from 'antd';
import { FileExcelOutlined, ZoomInOutlined } from '@ant-design/icons';
import { DataTable, Spinner, PageHeader } from '../../../components';
import { ApiService } from '../../../services/ApiService';
import moment from 'moment';
import 'moment/locale/id';
import { currentUser } from '../../../utils/authData';

const { RangePicker } = DatePicker

const ReportSuratKeluar = () => {
    const [loaded, setLoaded] = useState(true)
    const [loadedData, setLoadedData] = useState(false)
    const [outgoingMail, setOutgoingMail] = useState([])
    const [selectedDate, setSelectedDate] = useState([null, null])
    const user = currentUser

    const fetchOutgoingMail = (v) => {
        setLoaded(false)
        setLoadedData(false)
        const data = {
            user_id: user.id,
            start_date: v.start_date,
            end_date: v.end_date
        }
        ApiService.getList('outgoing-report', data).then((res) => {
            setOutgoingMail(res.data)
            setLoaded(true)
            setLoadedData(true)
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    useEffect(() => {
        fetchOutgoingMail({ start_date: moment().startOf('days').format('YYYY-MM-DD'), end_date: moment().endOf('days').format('YYYY-MM-DD') })
        setSelectedDate([moment().startOf('days'), moment().endOf('days')])
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Tipe Surat',
            render: (row) => (
                row?.letter_type
            ),
            key: 'letter_type',
            align: 'center'
        },
        {
            title: 'Jenis Surat',
            render: (row) => (
                row?.type === 1 || row?.type === 'IN' ? <Tag color={"green"}>Dokumen Masuk</Tag> : row?.type === 2 || row?.type === 'OUT' ? <Tag color={"green"}>Dokumen Keluar</Tag> : <Tag color={"gray"}>Surat Disposisi</Tag>
            ),
            key: 'type',
            align: 'center'
        },
        {
            title: 'Tanggal',
            render: (row) => (
                moment(row?.date).locale('id').format('dddd, D MMMM YYYY')
            ),
            key: 'date',
            align: 'center'
        },
        {
            title: 'Nomor Surat',
            render: (row) => (
                row?.letter_number
            ),
            key: 'letter_number',
            align: 'center'
        },
        {
            title: 'Opsi Surat',
            render: (row) => (
                row?.letter_option
            ),
            key: 'letter_option',
            align: 'center'
        },
        {
            title: 'Perihal',
            render: (row) => (
                row?.subject
            ),
            key: 'subject',
            align: 'center'
        },
        {
            title: 'Penerima',
            render: (row) => (
                row?.user ? row?.user : row?.to_receiver
            ),
            key: 'from',
            align: 'center'
        },
        {
            title: 'Departemen Penerima',
            render: (row) => (
                row?.department ? row?.department : '-'
            ),
            key: 'sender_department',
            align: 'center'
        },
    ];

    const exportReport = () => {
        window.open(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/outgoing-excel?user_id=${user.id}&start_date=${selectedDate[0]}&end_date=${selectedDate[1]}`);
    }

    const filterData = () => {
        fetchOutgoingMail({ start_date: moment(selectedDate[0]).format('YYYY-MM-DD'), end_date: moment(selectedDate[1]).format('YYYY-MM-DD') })
    }

    return (
        <div>
            <PageHeader title="Report Dokumen Keluar" breadcrumbs={[['Report'], ['Dokumen Keluar']]} />

            <Card style={{ borderRadius: 10 }}>
                <Row className='mb-3' justify='space-between'>
                    <Col>
                        {
                            outgoingMail.length === 0 ?
                                <Button onClick={exportReport} type='primary' size='medium' shape='round' disabled icon={<FileExcelOutlined />}>Download Report</Button>
                                :
                                <Button onClick={exportReport} type='primary' size='medium' shape='round' icon={<FileExcelOutlined />}>Download Report</Button>
                        }
                    </Col>
                    <Col>
                        <Space style={{ float: 'right' }}>
                            <RangePicker onChange={(v) => setSelectedDate(v)} style={{ width: '300px', float: 'right' }} defaultValue={selectedDate} />
                            <Button icon={<ZoomInOutlined />} loading={!loadedData} onClick={() => filterData()} type="primary">
                                Show
                            </Button>
                        </Space>
                    </Col>
                </Row>
                {
                    (loaded) ?
                        <DataTable size="small" columns={columns} sources={outgoingMail} bordered />
                        : <Spinner />
                }
            </Card>
        </div>
    )
}

export default ReportSuratKeluar
