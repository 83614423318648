import React from 'react';
import { Button, Typography, Row, Col } from 'antd';
import { PlusOutlined, FilePdfOutlined, ArrowLeftOutlined, EditOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom';
import { Breadcrumbs } from './Breadcrumb';

export const PageHeader = (props) => {
    const { breadcrumbs = null } = props
    const { Title } = Typography
    return (
        <Row className='mb-3' justify='space-between'>
            <Col>
                <Title level={3}>{props.title}</Title>
                {
                    breadcrumbs ?
                        <Breadcrumbs items={breadcrumbs} /> : null
                }
            </Col>

            <Col>
                {
                    (props.createLink) ?
                        <div style={{ marginTop: 10 }}>
                            <Link to={props.createLink}>
                                <Button className='button-primary' style={{ borderRadius: 10, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.2)' }} key="1" type="primary"><PlusOutlined /> Add {props.title}</Button>
                            </Link>
                            {/* <Button style={{ borderRadius: 10 }} key="2" className="border-success"><FileExcelOutlined /> Export To Excel</Button> */}
                        </div>
                        :
                        (props.onAdd) ?
                            <div style={{ marginTop: 10 }}>
                                <Button className='button-primary' style={{ borderRadius: 10, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.2)' }} key="1" onClick={() => props.onAdd()} type="primary"><PlusOutlined /> Add {props.title}</Button>
                            </div>
                            :
                            (props.exportToPdf) ?
                                <div style={{ marginTop: 10 }}>
                                    <Button style={{ borderRadius: 10, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.2)' }} key="3" className="border-error"><FilePdfOutlined /> Export To Pdf</Button>
                                </div>
                                : (props.onEdit) ?
                                    <div style={{ marginTop: 10 }}>
                                        <Button style={{ borderRadius: 10, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.2)' }} key="1" onClick={() => props.onEdit()} type="primary"><EditOutlined /> Edit {props.title}</Button>
                                    </div>
                                    : (props.onBack) ?
                                        <div style={{ marginTop: 10 }}>
                                            <Button className='button-primary' style={{ borderRadius: 10, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.2)' }} key="1" onClick={() => props.onBack()} type="primary"><ArrowLeftOutlined /> Back</Button>
                                        </div> :
                                        (props.onGenerate) ?
                                            <div style={{ marginTop: 10 }}>
                                                <Button className='button-primary' style={{ borderRadius: 10, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.2)' }} key="1" onClick={() => props.onGenerate()} type="primary">{props.titleButton}</Button>
                                            </div> :
                                            (props.onUpload) ?
                                                <div style={{ marginTop: 10 }}>
                                                    <Button className='button-primary' style={{ borderRadius: 10, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.2)' }} key="1" onClick={() => props.onUpload()} type="primary">{props.buttonTitle ? props.buttonTitle : props.title}</Button>
                                                </div> : null
                }
            </Col>
        </Row>
    );
}