import React, { useState, useEffect } from 'react'
import { Card, Form, Input, Switch, Space, message } from 'antd'
import { EditButton, DeleteButton, DataTable, PageHeader, Spinner, FormModal } from '../../../components'
import { ApiService } from '../../../services/ApiService'

const DocumentPriority = () => {
    const [loaded, setLoaded] = useState(true)
    const [documentPriority, setDocumentPriority] = useState([])
    const [modalTitle, setModalTitle] = useState(null)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})
    const [isSaving, setIsSaving] = useState(false)
    const [status, setStatus] = useState(false)

    const fetchDocumentPriority = () => {
        setLoaded(false);
        ApiService.getList('letter-priority').then((res) => {
            setDocumentPriority(res.data)
            setLoaded(true)
        }).catch((err) => {
        })
    }

    const add = () => {
        setModalTitle('Add Document Priority')
        setDefaultValues({
            letter_priority_id: 0,
            letter_priority_name: '',
            code: '',
            status: true
        })
        setIsModalVisible(true)
        setStatus(true)
    }

    const edit = (v) => {
        setModalTitle('Edit Document Priority')
        setDefaultValues({
            letter_priority_id: v.letter_priority_id,
            letter_priority_name: v.letter_priority_name,
            code: v.code,
            status: parseInt(v.status)
        })
        setIsModalVisible(true)
        setStatus(v.status)
    }

    const destroy = (id) => {
        ApiService.destroy('letter-priority', id).then((res) => {
            message.success(res.message, 2)
            setTimeout(() => {
                fetchDocumentPriority()
            }, 500);
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const onFinish = (el) => {
        let payload = {
            letter_priority_name: el.letter_priority_name.toUpperCase(),
            code: el.code.toUpperCase(),
            status: status ? 1 : 0
        }

        if (el.letter_priority_id === 0) {
            setIsSaving(true)
            ApiService.store(`letter-priority`, payload)
                .then((res) => {
                    message.success(res.message, 2)
                    setIsModalVisible(false)
                    setIsSaving(false)
                    setTimeout(() => {
                        fetchDocumentPriority()
                    }, 500);
                }).catch((err) => {
                    message.warning(err.response.data.message)
                })
        } else {
            setIsSaving(true)
            ApiService.update(`letter-priority`, el.letter_priority_id, payload)
                .then((res) => {
                    message.success(res.message, 2)
                    setIsModalVisible(false)
                    setIsSaving(false)
                    setTimeout(() => {
                        fetchDocumentPriority()
                    }, 500);
                }).catch((err) => {
                    message.warning(err.response.data.message)
                })
        }
    }

    useEffect(() => {
        fetchDocumentPriority()
    }, []);

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Priority Document',
            dataIndex: 'letter_priority_name',
            key: 'letter_priority_name',
        },
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => edit(row)} />
                        <DeleteButton onConfirm={() => destroy(row.letter_priority_id)} />
                    </Space>
                </div>
            )
        },
    ];

    return (
        <div>
            <PageHeader title="Priority Document" breadcrumbs={[['Master'], ['Priority Document']]} onAdd={add} />
            <Card style={{ borderRadius: 10 }}>

                {
                    (loaded) ?
                        <DataTable size="small" columns={columns} sources={documentPriority} bordered />
                        : <Spinner />
                }

                {
                    (isModalVisible) ?
                        <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => onFinish(v)} width={600} showModal={isModalVisible} onCloseModal={() => setIsModalVisible(false)} saving={isSaving}>
                            <Form.Item name="letter_priority_id" hidden>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Nama" name="letter_priority_name" required rules={[{ required: true }]}>
                                <Input placeholder="Nama" />
                            </Form.Item>
                            <Form.Item label="Kode" name="code">
                                <Input placeholder="Kode" />
                            </Form.Item>
                            <Form.Item label="Status" name="status" rules={[{ required: true }]}>
                                <Switch checkedChildren="Ya" unCheckedChildren="Tidak" onChange={(v) => setStatus(v)} defaultChecked={defaultValues.status} />
                            </Form.Item>
                        </FormModal> :
                        null
                }
            </Card>
        </div>
    )
}

export default DocumentPriority
