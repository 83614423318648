import { useState, useEffect } from 'react'
import { DataTable, PageHeader, ShowButton } from '../../components'
import { Card, Space, Tag } from 'antd'
import { ApiService } from '../../services/ApiService'
import { Spinner } from 'react-bootstrap'
import moment from 'moment/moment'
import { useHistory } from 'react-router-dom'

const DocReturn = () => {
    const history = useHistory()
    const [loaded, setLoaded] = useState(false)
    const [docLoanData, setDocLoanData] = useState([])

    const fetchLoanData = () => {
        setLoaded(false)
        ApiService.getList("approval-return").then((res) => {
            setDocLoanData(res.data)
            console.log(res.data)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchLoanData()

    }, [])

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'User',
            render: (row) => (
                row?.created?.name
            ),
            key: 'user'
        },
        {
            title: 'Title',
            render: (row) => (
                row?.document?.title
            ),
            key: 'title'
        },
        {
            title: 'Document Original Filename',
            render: (row) => (
                row?.document?.original_filename
            ),
            key: 'original_filename'
        },
        {
            title: 'Returning Status',
            render: (row) => (
                <Tag color={row?.is_returning === 0 ? "red" : "green"}>
                    {row?.is_returning === 0 ? "Not Returned" : "Returned"}
                </Tag>
            ),
            key: 'return_status',
            align: 'center'
        },
        {
            title: 'Loan Date',
            render: (row) => (
                `${moment(row?.start_date).format("DD MMMM YYYY")} - ${moment(row?.end_date).format("DD MMMM YYYY")}`
            ),
            key: 'loan_date',
            align: 'center'
        },
        {
            title: 'Action',
            render: (row) => (
                <Space>
                    <ShowButton onShow={() => history.push(`/document-return/detail/${row?.approval_document_status_id}`)} />
                </Space>
            ),
            key: 'action',
            align: 'center',
            width: '10%'
        }
    ]

    return (
        <div>
            <PageHeader title="Document Return" breadcrumbs={[['Document Return']]} />
            <Card style={{ borderRadius: 10 }}>
                {
                    loaded ?
                        <DataTable size="small" bordered sources={docLoanData} columns={columns} />
                        : <Spinner />
                }
            </Card>
        </div>
    )
}

export default DocReturn