import React, { useState, useEffect } from 'react';
import { Card, Form, Input, Button, Select, Typography, DatePicker, message, Col, Row, Radio, Space, Image, InputNumber } from 'antd';
import { PageHeader, FileUpload, DataTable, FormModal, DetailModal, SelectButton, TextButton } from '../../components';
import { PlusOutlined, DeleteFilled } from '@ant-design/icons';
import { ApiService } from '../../services/ApiService';
import { Editor } from '@tinymce/tinymce-react';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { currentUser } from '../../utils/authData';
import { Docs, Excel, Pdf } from '../../components/Folder';

const { Text, Title } = Typography
const { TextArea } = Input
const { Option } = Select
const fileUrl = process.env.REACT_APP_FILE_URL

const UpdateSuratKeluar = () => {
    const history = useHistory()
    const [form] = Form.useForm()
    const [saving, setSaving] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [department, setDepartment] = useState([])
    const [letterType, setLetterType] = useState([])
    // eslint-disable-next-line
    const [letterNumber, setLetterNumber] = useState([])
    const [letterClassification, setLetterClassification] = useState([])
    const [letterPriority, setLetterPriority] = useState([])
    const [letterOption, setLetterOption] = useState([])
    const [courier, setCourier] = useState([])
    const [archiveLocation, setArchiveLocation] = useState([])
    const [receiver, setReceiver] = useState([])
    const [listReceiver, setListReceiver] = useState([])
    const [actionDisposition, setActionDisposition] = useState([])
    const [content, setContent] = useState('')
    const [attachmentFile, setAttachmentFile] = useState([])
    const [listAttachment, setListAttachment] = useState([])
    const [letterTypeId, setLetterTypeId] = useState([])
    const [listBooking, setListBooking] = useState([])
    // eslint-disable-next-line
    const [defaultValues, setDefaultValues] = useState([])
    const [radio, setRadio] = useState(null)
    const [status, setStatus] = useState('')
    // eslint-disable-next-line
    const [modalBookingTitle, setModalBookingTitle] = useState('')
    const [bookingModalIsVisible, setBookingModalIsVisible] = useState(false)
    const [listBookingIsVisible, setListBookingIsVisible] = useState(false)
    const { id } = useParams()
    const user = currentUser

    const fileUploaded = (v) => {
        let atcs = attachmentFile
        if (v.message === 'Success') {
            atcs.push({ filename: v.data.data.url })
        }
        setAttachmentFile(atcs)
    }

    const handleEditorChange = (content) => {
        setContent(content);
    }

    const fetchOutgoingMail = (id, form) => {
        // setLoaded(false)
        const user_id = {
            user_id: user.id
        }
        ApiService.getObject('outgoing-mail', id, user_id).then(res => {
            const data = res.data.outgoing_mail[0]
            form.setFieldsValue({
                id: data.id,
                letter_id: data.letter_id,
                body: data.body,
                from_user: data.from_user,
                receiver_user_id: data.receiver_user_id,
                receiver_eksternal: data.receiver_eksternal,
                subject: data.subject,
                receiver_department_id: data.receiver_department_id,
                position_id: data.position_id,
                // letter_number: data.letter_number,
                letter_date: moment(data.date),
                total_attachment: data.total_attachment,
                letter_type_id: data.letter_type_id,
                letter_classification_id: data.letter_classification_id,
                letter_priority_id: data.letter_priority_id,
                letter_confidentiality_id: data.letter_confidentiality_id,
                letter_option_id: data.letter_option_id,
                archive_location_id: data.archive_location_id,
                courier_id: data.courier_id,
                status: data.status,
                action_id: data.action_id,
                action_description: data.action_description,
                updated_by: user.id
            })
            setContent(res.data.outgoing_mail[0].body)
            setRadio(res.data.outgoing_mail[0].letter_option_id)
            setLetterTypeId(res.data.outgoing_mail[0].letter_type_id)
            // setLoaded(true)
        }).catch((err) => {
        })
    }

    const fetchListReceiver = (id) => {
        ApiService.getObject('draft-outgoing', id).then((res) => {
            setListReceiver(res.data.draftReceiver)
        }).catch((err) => {
        })
    }

    const fetchDepartment = () => {
        ApiService.getList('department').then((res) => {
            setDepartment(res.data)
        }).catch((err) => {
        })
    }

    const fetchLetterType = () => {
        ApiService.getList('letter-type').then((res) => {
            setLetterType(res.data)
        }).catch((err) => {
        })
    }

    const fetchLetterClassification = () => {
        ApiService.getList('letter-classification').then((res) => {
            setLetterClassification(res.data)
        }).catch((err) => {
        })
    }

    const fetchLetterOption = () => {
        ApiService.getList('letter-option').then((res) => {
            setLetterOption(res.data)
        }).catch((err) => {
        })
    }

    const fetchCourier = () => {
        ApiService.getList('courier').then((res) => {
            setCourier(res.data)
        }).catch((err) => {
        })
    }

    const fetchLetterPriority = () => {
        ApiService.getList('letter-priority').then((res) => {
            setLetterPriority(res.data)
        }).catch((err) => {
        })
    }

    const fetchArchiveLocation = () => {
        ApiService.getList('archive-location').then((res) => {
            setArchiveLocation(res.data)
        }).catch((err) => {
        })
    }

    const fetchReceiver = () => {
        ApiService.getList('employee').then((res) => {
            setReceiver(res.data)
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const fetchActionDisposition = () => {
        ApiService.getList('action-disposition').then((res) => {
            setActionDisposition(res.data)
        }).catch((err) => {
        })
    }

    const fetchAttachment = (id) => {
        ApiService.getObject('letter-attachment', id).then((res) => {
            setListAttachment(res.data)
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const fetchLetterNumber = (el) => {
        ApiService.getObject('letter-number', el).then((res) => {
            setLetterNumber(res.data)
            form.setFieldsValue({ letter_number: res.data.letter_number });
        }).catch((err) => {
            message.warning(err.message)
        })
    }

    const fetchBookingLetter = () => {
        ApiService.getList('letter-number-booking').then((res) => {
            setListBooking(res.data)
        }).catch((err) => {
        })
    }

    const changeLetterNumber = (el) => {
        if (!el) {
            fetchLetterNumber(letterTypeId)
        } else {
            fetchLetterNumber(el)
        }
    }

    const changeNumberFormat = (el) => {
        fetchLetterNumber(el)
    }

    // const bookingModal = () => {
    //     setBookingModalIsVisible(true)
    //     setDefaultValues({
    //         id: 0,
    //         letter_type_id: '',
    //         letter_sequence: '',
    //     })
    //     setModalBookingTitle('Booking Nomor Dokumen')
    // }

    // const listBookingModal = () => {
    //     setListBookingIsVisible(true)
    //     setModalBookingTitle('List Booking Nomor Dokumen')
    // }

    const deleteReceiver = (id) => {
        ApiService.destroy('draft-receiver', id).then((res) => {
            message.success('Berhasil menghapus penerima, silahkan masukan penerima baru')
            fetchListReceiver(id)
        }).catch((err) => {
            message.warning('Gagal menghapus penerima')
        })
    }

    const onFinishBooking = (v) => {
        const data = {
            letter_type_id: v.modal_letter_type_id,
            letter_number: v.modal_letter_number,
        }
        ApiService.store('letter-number-booking', data).then((res) => {
            message.success('Sukses booking nomor surat')
        }).catch((err) => {
            message.warning(err.response.data.message)
        })
    }

    const onFinish = (v) => {
        setSaving(true)
        let data = {
            body: content,
            from_user: v.from_user,
            from_department_id: user.from_department_id,
            subject: v.subject,
            letter_number: v.letter_number,
            date: moment(v.letter_date).format('YYYY-MM-DD'),
            total_attachment: v.total_attachment,
            letter_type_id: v.letter_type_id,
            letter_classification_id: v.letter_classification_id,
            letter_priority_id: v.letter_priority_id,
            letter_confidentiality_id: v.letter_confidentiality_id,
            letter_option_id: v.letter_option_id,
            archive_location_id: v.archive_location_id,
            courier_id: v.courier_id,
            receiver: v.receiver,
            action_id: v.action_id,
            action_description: v.action_description,
            status: status,
            attachment_file: attachmentFile,
            booking_id: v.booking_id
        }
        ApiService.update(`outgoing-mail`, id, data)
            .then((res) => {
                message.success('mail saved', 2);
                setTimeout(() => {
                    history.push('/surat-keluar')
                }, 1000);
                setSaving(false)
            }).catch((err) => {
                message.warning(err.response.data.message, 2)
            })
    }

    const select = (v) => {
        form.setFieldsValue({ letter_type_id: v.letter_type_id, letter_number: v.letter_number, booking_id: v.id })
    }

    const attachmentPage = (v) => {
        window.open(`${process.env.REACT_APP_FILE_URL}/${v}`)
    }

    useEffect(() => {
        fetchOutgoingMail(id, form)
        fetchListReceiver(id)
        fetchArchiveLocation()
        fetchCourier()
        fetchDepartment()
        fetchLetterClassification()
        fetchLetterPriority()
        fetchLetterType()
        fetchReceiver()
        fetchActionDisposition()
        fetchLetterOption()
        fetchLetterNumber(letterTypeId)
        fetchAttachment(id)
        fetchBookingLetter()
    }, [id, form, letterTypeId]); // eslint-disable-line react-hooks/exhaustive-deps

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Name',
            render: (row) => (
                row?.draft_receiver_user ?? row?.draft_receiver_eksternal
            ),
            key: 'name'
        },
        {
            title: 'Departemen',
            render: (row) => (
                row?.draft_receiver_department ?? '-'
            ),
            key: 'department'
        },
        {
            title: 'Action',
            width: '10%',
            render: (row) => (
                <div className='text-center'>
                    <TextButton type={"danger"} shape={"round"} title={"Hapus"} onClick={() => deleteReceiver(row.id)} />
                </div>
            ),
            key: 'action'
        }
    ]

    const columnsAttachment = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'File Name',
            render: (row) => (
                row?.filename.split('.')[1] === 'jpg' || row?.filename.split('.')[1] === 'png' || row?.filename.split('.')[1] === 'svg' || row?.filename.split('.')[1] === 'jpeg' ?
                    <>
                        <Row justify='center'>
                            <Image width={'30%'} src={row?.filename ? `${fileUrl}/${row?.filename}` : null} />
                        </Row>
                        <Row justify='center'>
                            <Text className='mb-2'>{row?.filename.split('/')[1]}</Text>
                        </Row>
                    </>
                    : row?.filename.split('.')[1] === 'pdf' ?
                        <>
                            <Row justify='center'>
                                <Pdf onClick={() => attachmentPage(row?.filename)} />
                            </Row>
                            <Row justify='center'>
                                <Text className='mb-2'>{row?.filename.split('/')[1]}</Text>
                            </Row>
                        </>
                        : row?.filename.split('.')[1] === 'doc' || row?.filename.split('.')[1] === 'docx' ?
                            <>
                                <Row justify='center'>
                                    <Docs onClick={() => attachmentPage(row?.filename)} />
                                </Row>
                                <Row justify='center'>
                                    <Text className='mb-2'>{row?.filename.split('/')[1]}</Text>
                                </Row>
                            </>
                            : row?.filename.split('.')[1] === 'xls' || row?.filename.split('.')[1] === 'xlsx' ?
                                <>
                                    <Row justify='center'>
                                        <Excel onClick={() => attachmentPage(row?.filename)} />
                                    </Row>
                                    <Row justify='center'>
                                        <Text className='mb-2'>{row?.filename.split('/')[1]}</Text>
                                    </Row>
                                </>
                                :
                                <>
                                    <Row justify='center'>
                                        <Button onClick={() => attachmentPage(row?.filename)} />
                                    </Row>
                                    <Row justify='center'>
                                        <Text className='mb-2'>{row?.filename.split('/')[1]}</Text>
                                    </Row>
                                </>
            ),
            key: 'filename',
            align: 'center'
        },
    ]

    const columnsBooking = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Tipe Dokumen',
            dataIndex: 'letter_type',
            key: 'letter_type',
            align: 'center'
        },
        {
            title: 'No Dokumen',
            dataIndex: 'letter_number',
            key: 'letter_number',
            align: 'center'
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <SelectButton onSelect={() => select(row)} />
                    </Space>
                </div>
            ),
            align: 'center'
        },
    ];

    return (
        <div>
            <PageHeader title="Dokumen Keluar" breadcrumbs={[['Dokumen Keluar'], ['Edit']]} onBack={() => history.push('/surat-keluar')} />

            <Card style={{ borderRadius: 15 }} className="content-container">
                <Card>
                    <Form onFinish={onFinish} layout='vertical' form={form}>

                        <div className='mb-4'>
                            <Title style={{ fontSize: 18, fontWeight: 'bold' }}>INFORMASI SURAT</Title>
                            <Text>(<span style={{ fontWeight: 'bold' }}>**</span>) Inputan dengan tanda (<span style={{ color: 'red' }}> * </span>) wajib diisi! Silahkan lengkapi data dibawah</Text>
                        </div>

                        <Row justify='space-between'>
                            <Col span={11}>
                                <Form.Item label="Pengirim" name="from_user" rules={[{ required: true }]}>
                                    <Input placeholder="Dari" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item label="Perihal" name="subject" rules={[{ required: true }]}>
                                    <Input placeholder='Perihal' />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row justify='space-between'>
                            <Col span={11}>
                                <Form.Item label="Jenis Dokumen" name="letter_type_id" rules={[{ required: true }]}>
                                    <Select placeholder="Jenis Dokumen" onChange={changeLetterNumber}>
                                        {
                                            letterType.map((el, key) => (
                                                <Option key={key} value={el.id}>{el.name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={11}>
                                <Form.Item label="Tanggal Dokumen" name="letter_date" rules={[{ required: true }]}>
                                    <DatePicker placeholder="Tanggal" style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row justify='space-between'>
                            <Col span={11}>
                                <Form.Item label="No Dokumen" name="letter_number" rules={[{ required: true }]}>
                                    <Input disabled />
                                </Form.Item>
                                <Form.Item name="booking_id" hidden>
                                    <Input />
                                </Form.Item>
                            </Col>
                            {/* <Col span={11}>
                                <div style={{ marginTop: 30, display: 'flex', justifyContent: 'start' }}>
                                    <div style={{ marginRight: 15 }}>
                                        <Button type="primary" className="button-primary" shape='round' onClick={() => listBookingModal()}> Pilih No Dokumen Booking</Button>
                                    </div>
                                    <div>
                                        <Button type="primary" className="button-primary" shape='round' icon={<PlusOutlined />} style={{ backgroundColor: "#63a67a", border: 'none' }} onClick={() => bookingModal()}>Booking No Dokumen</Button>
                                    </div>
                                </div>
                            </Col> */}
                        </Row>

                        <Row justify='space-between'>
                            <Col span={11}>
                                <Form.Item label="Klasifikasi Dokumen" name="letter_classification_id" rules={[{ required: true }]}>
                                    <Select placeholder="Klasifikasi Dokumen">
                                        {
                                            letterClassification.map((el, key) => (
                                                <Option key={key} value={el.id}>{el.name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={11}>
                                <Form.Item label="Prioritas Dokumen" name="letter_priority_id" rules={[{ required: true }]}>
                                    <Select placeholder="Prioritas Dokumen">
                                        {
                                            letterPriority.map((el, key) => (
                                                <Option key={key} value={el.id}>{el.name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item disabled label="Opsi Dokumen" name="letter_option_id" rules={[{ required: true }]}>
                            <Radio.Group disabled value={radio}>
                                {
                                    letterOption.map((el, key) => (
                                        <Radio key={key} value={el.id} onClick={() => setRadio(el.id)}>{el.name}</Radio>
                                    ))
                                }
                            </Radio.Group>
                        </Form.Item>

                        <Row>
                            <Col span={11}>
                                <Form.Item label="Total Lampiran" name="total_attachment">
                                    <InputNumber type="number" placeholder='Total Lampiran' style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                        </Row>


                        <Row justify='space-between'>
                            {
                                radio === 2 ?
                                    <Col span={11}>
                                        <Form.Item label="Kurir Dokumen" name="courier_id" rules={[{ required: true }]}>
                                            <Select placeholder="Kurir Dokumen">
                                                {
                                                    courier.map((el, key) => (
                                                        <Option key={key} value={el.id}>{el.name}</Option>
                                                    ))
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col> : null
                            }

                            <Col span={11}>
                                <Form.Item label="Lokasi Arsip Fisik" name="archive_location_id" rules={[{ required: true }]}>
                                    <Select placeholder="Lokasi Arsip Fisik">
                                        {
                                            archiveLocation.map((el, key) => (
                                                <Option key={key} value={el.id}>{el.name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <div className='mb-3'>
                            <Title style={{ fontSize: 18, fontWeight: 'bold' }}>BODY SURAT</Title>
                            <Editor
                                apiKey={process.env.REACT_APP_TINYMCE_KEY}
                                value={content}
                                init={{
                                    height: 400,
                                    menubar: true,
                                    plugins: [
                                        'advlist autolink lists link image charmap print preview anchor',
                                        'searchreplace visualblocks code fullscreen',
                                        'insertdatetime media table paste code help wordcount'
                                    ],
                                    toolbar:
                                        'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
                                }}
                                onEditorChange={(value) => handleEditorChange(value)}
                            />
                        </div>


                        <Card bodyStyle={{ border: '0.7px solid #d7d7d7', borderRadius: 10, marginBottom: 15 }}>
                            <Title style={{ fontSize: 18, fontWeight: 'bold' }}>LIST PENERIMA SURAT</Title>
                            <div className='mb-3'>
                                <DataTable columns={columns} sources={listReceiver} />
                            </div>

                            <div bodyStyle={{ border: '0.7px solid #d7d7d7', borderRadius: 10, marginBottom: 15 }}>
                                {
                                    radio === 2 && listReceiver.length !== 0 ?
                                        null
                                        :
                                        <>
                                            <Title style={{ fontSize: 18, fontWeight: 700 }}>TAMBAH PENERIMA</Title>
                                            <div style={{ marginBottom: 15 }}>
                                                {
                                                    (radio === 3) ?
                                                        <Text>(<span style={{ fontWeight: 'bold' }}>*</span>) Klik (Tambah Penerima) untuk menambahkan penerima Dokumen Disposisi.</Text>
                                                        :
                                                        <Text>(<b>*</b>) Pilih opsi surat untuk menentukan penerima</Text>

                                                }
                                            </div>
                                        </>
                                }

                                {
                                    radio === 1 ?
                                        <>
                                            <Form.List name="receiver">
                                                {(fields, { add, remove }) => (
                                                    <>
                                                        {fields.map(({ key, name, ...restField }) => (
                                                            <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                                                <Form.Item
                                                                    {...restField}
                                                                    label="Department"
                                                                    name={[name, 'receiver_department_id']}
                                                                    rules={[{ required: true, message: 'Missing Receiver' }]}
                                                                    style={{ width: 500 }}
                                                                >
                                                                    <Select placeholder="Department">
                                                                        {
                                                                            department.map((el, key) => (
                                                                                <Option key={key} value={el.id}>{el.name}</Option>
                                                                            ))
                                                                        }
                                                                    </Select>
                                                                </Form.Item>

                                                                <Form.Item
                                                                    {...restField}
                                                                    label="Penerima"
                                                                    name={[name, 'receiver_user_id']}
                                                                    rules={[{ required: true, message: 'Missing Receiver' }]}
                                                                    style={{ width: 500 }}
                                                                >
                                                                    <Select placeholder="Penerima">
                                                                        {
                                                                            receiver.map((el, key) => (
                                                                                <Option key={key} value={el.id}>{el.name}</Option>
                                                                            ))
                                                                        }
                                                                    </Select>
                                                                </Form.Item>

                                                                <DeleteFilled style={{ color: "#f44336", fontSize: 18, padding: "65px 0 0 10px" }} onClick={() => remove(name)} />
                                                            </Space>
                                                        ))}
                                                        <Form.Item>
                                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                                Tambah Penerima
                                                            </Button>
                                                        </Form.Item>
                                                    </>
                                                )}
                                            </Form.List>
                                        </>
                                        :
                                        <Row>
                                            <Col span={11}>
                                                {
                                                    listReceiver.length !== 0 ?
                                                        null
                                                        :
                                                        <Form.Item label="Kepada" name="to_receiver" rules={[{ required: true }]}>
                                                            <Input placeholder='Kepada' />
                                                        </Form.Item>
                                                }
                                            </Col>
                                        </Row>
                                }
                            </div>
                        </Card>

                        {
                            radio === 3 ?
                                <>
                                    <Form.Item label="Arahan" name="action_id" rules={[{ required: true }]}>
                                        <Select placeholder="Arahan">
                                            {
                                                actionDisposition.map((el, key) => (
                                                    <Option key={key} value={el.id}>{el.name}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label="Keterangan" name="action_description" rules={[{ required: true }]}>
                                        <TextArea placeholder='Keterangan' />
                                    </Form.Item>
                                </>
                                : null
                        }

                        <Card bodyStyle={{ border: '0.7px solid #d7d7d7', borderRadius: 10, marginBottom: 15 }}>
                            <div className='mb-3'>
                                <Title style={{ fontSize: 18, fontWeight: 'bold' }}>LIST ATTACHMENT</Title>
                                {
                                    listAttachment.length > 0 ?
                                        <>
                                            <DataTable columns={columnsAttachment} sources={listAttachment} />
                                            <Button className="mt-3" onClick={() => {
                                                history.push('/surat-keluar/update-attachment/' + id)
                                            }}>Edit Attachment </Button>
                                        </>
                                        :
                                        <>
                                            <div className='mb-5'>
                                                <Button disabled className="mt-3" onClick={() => {
                                                    history.push('/surat-keluar/update-attachment/' + id)
                                                }}>Edit Attachment </Button>
                                            </div>
                                            <div className='mb-3' >
                                                <Text style={{ fontSize: 18, fontWeight: 'bold' }}>UNGGAH BERKAS</Text><br />
                                                <Text>Format file yang diperbolehkan: jpg | jpeg | png | pdf | doc | xls</Text><br />
                                                <Text>(<span style={{ fontWeight: 'bold' }}>**</span>) max 20 MB</Text>
                                            </div>

                                            <Form.Item>
                                                <FileUpload multiple={true} dragger onUploaded={(v, key) => fileUploaded(v)} isUploading={(v) => setUploading(v)} />
                                            </Form.Item>
                                        </>
                                }
                            </div>


                        </Card>

                        <Form.Item className='mt-4'>
                            <Row>
                                <Col span={5}>
                                    <Button loading={saving || uploading} type="primary" name="status" onClick={() => setStatus("draft")} style={{ width: '80%', backgroundColor: '#0a96b7', border: 'none', borderRadius: 10 }} htmlType="submit">
                                        Simpan Draft
                                    </Button>
                                </Col>
                                <Col span={5}>
                                    <Button loading={saving || uploading} type="primary" name="status" onClick={() => setStatus("sent")} style={{ width: '80%', backgroundColor: '#63a67a', border: 'none', borderRadius: 10 }} htmlType="submit">
                                        Simpan dan Kirim
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Item>
                    </Form>
                </Card>
            </Card>

            {
                bookingModalIsVisible ?
                    <FormModal defaultValues={defaultValues} form={form} showModal={bookingModalIsVisible} title={modalBookingTitle} onCloseModal={() => setBookingModalIsVisible(false)} submitForm={(v) => onFinishBooking(v)}>
                        <Form.Item name="id" hidden>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Jenis Dokumen" name="modal_letter_type_id">
                            <Select placeholder="Jenis Dokumen" onChange={changeNumberFormat}>

                                {
                                    letterType.map((e, key) => (
                                        <Option key={key} value={e.id}>{e.name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label="Nomor Dokumen" name="modal_letter_number" required rules={[{ required: true }]}>
                            <Input disabled placeholder="Nomor Dokumen" />
                        </Form.Item>
                    </FormModal>
                    : null
            }

            {
                listBookingIsVisible ?
                    <DetailModal showModal={listBookingIsVisible} title={modalBookingTitle} onCloseModal={() => setListBookingIsVisible(false)}>
                        <DataTable size="small" columns={columnsBooking} sources={listBooking} bordered />
                    </DetailModal>
                    :
                    null
            }
        </div >
    );
}

export default UpdateSuratKeluar
