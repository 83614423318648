import { useState, useEffect } from 'react'
import { PageHeader } from '../../components'
import { Button, Card, Form, message, Row, Select, DatePicker, Input } from 'antd'
import { ApiService } from '../../services/ApiService'
import { useHistory } from 'react-router-dom'

const { RangePicker } = DatePicker

const Create = () => {
    const history = useHistory()
    const [archiveLocation, setArchiveLocation] = useState([])
    const [documentList, setDocumentList] = useState([])
    const [documentOption, setDocumentOption] = useState([])
    const [showDoc, setShowDoc] = useState(false)
    const [docUrl, setDocUrl] = useState("")

    const fetchArchiveLocation = () => {
        ApiService.getList("archive-location").then((res) => {
            const v = res.data
            let arr = []

            v.forEach((el) => {
                arr.push({ value: el.archive_location_id, label: el.archive_location_name })
            })
            setArchiveLocation(arr)
        })
    }

    const fetchDocument = (v) => {
        ApiService.getObject('doc-by-archive', v).then((res) => {
            const v = res.data
            setDocumentList(v)
            let arr = []

            v.forEach((el, key) => {
                arr.push({ value: el.id, label: el.original_filename })
            })
            setDocumentOption(arr)
        })
    }

    useEffect(() => {
        fetchArchiveLocation()

    }, [])

    const onChangeArchiveLocation = (v) => {
        fetchDocument(v)
    }

    const onChangeDocUrl = (v) => {
        const doc = documentList.filter((el) => el.id === v)
        setShowDoc(false)
        setDocUrl(doc[0].filename)
        setShowDoc(true)
    }

    const saveApproval = (v) => {
        let payload = {
            ...v,
            start_date: v.date[0],
            end_date: v.date[1]
        }

        ApiService.store("approval", payload).then((res) => {
            message.success("Data Saved")
            history.push("/document-loan")
        }).catch((err) => {
            message.warning("Failed save data")
        })
    }

    return (
        <div>
            <PageHeader title="Create Document Loaning" breadcrumbs={[['Document Loaning', '/document-loan'], ['Create']]} />
            <Card style={{ borderRadius: 10 }}>
                <Form layout='vertical' onFinish={saveApproval}>
                    <Form.Item
                        label="Loan Date"
                        name="date"
                        required
                        rules={[{ required: true }]}
                    >
                        <RangePicker />
                    </Form.Item>
                    <Form.Item
                        label="Reason For Loan"
                        name="reason_loan"
                        required
                        rules={[{ required: true }]}
                    >
                        <Input.TextArea rows={2} placeholder="Reason" />
                    </Form.Item>
                    <Form.Item
                        label="Archive Location"
                        name="archive_location_id"
                        required
                        rules={[{ required: true }]}
                    >
                        <Select
                            showSearch
                            options={archiveLocation}
                            placeholder="Select Archive Location"
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            onChange={(v) => onChangeArchiveLocation(v)}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Document List"
                        name="document_id"
                        required
                        rules={[{ required: true }]}
                    >
                        <Select
                            showSearch
                            placeholder="Select Document"
                            options={documentOption}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            onChange={(v) => onChangeDocUrl(v)}
                        />
                    </Form.Item>
                    {
                        showDoc ?
                            <div>
                                <Card title="Document Preview">
                                    <Row justify="center">
                                        <iframe src={`${docUrl}#toolbar=0`} width="730" height="950" title="Document Review" />
                                    </Row>
                                </Card>
                            </div>
                            : null
                    }
                    <Form.Item>
                        <Button type="primary" htmlType="submit">Submit</Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    )
}

export default Create