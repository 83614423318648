import axios from 'axios'
import { decrypt } from '../utils/helpers'
import { handleResponse } from './handleResponse'
require('dotenv').config()

const host = process.env.REACT_APP_API_URL
const version = process.env.REACT_APP_API_VERSION
const clientKey = process.env.REACT_APP_CLIENT_KEY
const token = localStorage.getItem('accessToken') ? JSON.parse(decrypt(localStorage.getItem('accessToken'))) : null

const auth = async (payload) => {
    return await axios.post(`${host}/${version}/auth/login`, payload, {
        headers: {
            'client-key': clientKey,
        }
    }).then(handleResponse)
}

const getList = async (path, params) => {
    return await axios.get(`${host}/${version}/${path}`, {
        params,
        headers: {
            'client-key': clientKey,
            'authorization': `Bearer ${token}`
        }
    }).then(handleResponse)
}

const getObject = async (path, id, params) => {
    return await axios.get(`${host}/${version}/${path}/${id}`, {
        params,
        headers: {
            'client-key': clientKey,
            'authorization': `Bearer ${token}`
        }
    }).then(handleResponse)
}

const store = async (path, payload, params) => {
    return await axios.post(`${host}/${version}/${path}`, payload, {
        params,
        headers: {
            'client-key': clientKey,
            'authorization': `Bearer ${token}`
        }
    }).then(handleResponse)
}

const update = async (path, id, payload, params) => {
    return await axios.put(`${host}/${version}/${path}/${id}`, payload, {
        params,
        headers: {
            'client-key': clientKey,
            'authorization': `Bearer ${token}`
        }
    }).then(handleResponse)
}

const destroy = async (path, id, params) => {
    return await axios.delete(`${host}/${version}/${path}/${id}`, {
        params,
        headers: {
            'client-key': clientKey,
            'authorization': `Bearer ${token}`
        }
    }).then(handleResponse)
}

export const ApiService = {
    auth,
    getList,
    getObject,
    store,
    update,
    destroy
}