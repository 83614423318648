import { useState, useEffect } from 'react'
import { Card, Space, message, Tag } from 'antd'
import { EditButton, DeleteButton, DataTable, PageHeader, Spinner } from '../../../components'
import { ApiService } from '../../../services/ApiService'
import { useHistory } from 'react-router-dom'

const Pegawai = () => {
    const history = useHistory()
    const [loaded, setLoaded] = useState(true)
    const [employee, setEmployee] = useState([])

    const fetchEmployee = () => {
        setLoaded(false);
        ApiService.getList('employee').then((res) => {
            setEmployee(res.data)
            setLoaded(true)
        }).catch((err) => {
        })
    }

    const add = () => {
        history.push('/organization/employee/add/0')
    }

    const destroy = (id) => {
        ApiService.destroy('employee', id).then((res) => {
            message.success(res.message, 2)
            setTimeout(() => {
                fetchEmployee()
            }, 500);
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    useEffect(() => {
        fetchEmployee()

    }, []);

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Employee Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Department',
            render: (row) => (
                row?.department?.department_name
            ),
            key: 'department_name'
        },
        {
            title: 'Position',
            render: (row) => (
                row?.position?.position_name
            ),
            key: 'position_name'
        },
        {
            title: 'Role',
            render: (row) => (
                row.role?.name
            ),
            key: 'role_name'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            align: 'center',
            key: 'status',
            render: (text) => (
                <Tag color={text === 1 ? "green" : "red"}>{(text === 1) ? 'Active' : 'InActive'}</Tag>
            )
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => history.push(`/organization/employee/add/${row.id}`)} />
                        <DeleteButton onConfirm={() => destroy(row.id)} />
                    </Space>
                </div>
            )
        },
    ];

    return (
        <div>
            <PageHeader title="Employee" breadcrumbs={[['Organization'], ['Employee']]} onAdd={add} />
            <Card style={{ borderRadius: 10 }}>
                {
                    (loaded) ?
                        <DataTable size="small" columns={columns} sources={employee} bordered />
                        : <Spinner />
                }
            </Card>
        </div>
    )
}

export default Pegawai
