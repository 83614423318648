import { useState, useEffect } from 'react'
import { Row, Col, Card, message, Tag, Image, Typography, Button } from 'antd'
import { PageHeader, Spinner, DataTable } from '../../components'
import { useHistory, useParams } from 'react-router-dom'
import { currentUser } from '../../utils/authData'
import { ApiService } from '../../services/ApiService'
import parse from 'html-react-parser'
import moment from 'moment'
import { Docs, Excel, Pdf } from '../../components/Folder'

const { Text } = Typography
const fileUrl = process.env.REACT_APP_FILE_URL

const DokumenKeluarDetail = () => {
    const history = useHistory()
    const { id } = useParams()
    const [outgoingMail, setOutgoingMail] = useState([])
    const [attachmentFile, setAttachmentFile] = useState([])
    const [receiver, setReceiver] = useState([])
    const [loaded, setLoaded] = useState(true)
    const user = currentUser

    const fetchIncomingMailById = () => {
        setLoaded(false);
        const data = {
            user_id: user.id
        }
        ApiService.getObject('outgoing-mail', id, data).then((res) => {
            setOutgoingMail(res.data.outgoing_mail[0])
            setReceiver(res.data.receiver)
            setLoaded(true)
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const fetchAttachmentFile = () => {
        ApiService.getObject('letter-attachment', id).then((res) => {
            setAttachmentFile(res.data)
        }).catch((err) => {
            message.warning('Surat tidak memiliki lampiran')
        })
    }

    useEffect(() => {
        fetchIncomingMailById()
        fetchAttachmentFile()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const attachmentPage = (v) => {
        window.open(`${process.env.REACT_APP_FILE_URL}/${v}`)
    }

    const columns = [
        {
            title: 'Nama',
            render: (row) => (
                row?.receiver_user

            ),
            key: 'name',
            align: 'center'
        },
        {
            title: 'Departemen',
            render: (row) => (
                row?.receiver_department

            ),
            key: 'department',
            align: 'center'
        },
        {
            title: 'Status Approver',
            render: (row) => (
                row?.approver === 1 && row?.status_approver === 1 ? <Tag color={"green"}>Approve</Tag> :
                    row?.approver === 1 && row?.status_approver === 2 ? <Tag color={"red"}>Ditolak</Tag> : <Tag color={"blue"}>Mengetahui</Tag>
            ),
            key: 'approve_status',
            align: 'center'
        },
        {
            title: 'Keterangan',
            render: (row) => (
                row?.response_description ? row?.response_description : '-'

            ),
            key: 'description',
            align: 'center'
        },
    ];

    const columnsAttachment = [
        {
            title: 'Lampiran',
            render: (row) => (
                row?.filename.split('.')[1] === 'jpg' || row?.filename.split('.')[1] === 'png' || row?.filename.split('.')[1] === 'svg' || row?.filename.split('.')[1] === 'jpeg' ?
                    <>
                        <Row justify='center'>
                            <Image width={'30%'} src={row?.filename ? `${fileUrl}/${row?.filename}` : null} />
                        </Row>
                        <Row justify='center'>
                            <Text className='mb-2'>{row?.filename.split('/')[1]}</Text>
                        </Row>
                    </>
                    : row?.filename.split('.')[1] === 'pdf' ?
                        <>
                            <Row justify='center'>
                                <Pdf onClick={() => attachmentPage(row?.filename)} />
                            </Row>
                            <Row justify='center'>
                                <Text className='mb-2'>{row?.filename.split('/')[1]}</Text>
                            </Row>
                        </>
                        : row?.filename.split('.')[1] === 'doc' || row?.filename.split('.')[1] === 'docx' ?
                            <>
                                <Row justify='center'>
                                    <Docs onClick={() => attachmentPage(row?.filename)} />
                                </Row>
                                <Row justify='center'>
                                    <Text className='mb-2'>{row?.filename.split('/')[1]}</Text>
                                </Row>
                            </>
                            : row?.filename.split('.')[1] === 'xls' || row?.filename.split('.')[1] === 'xlsx' ?
                                <>
                                    <Row justify='center'>
                                        <Excel onClick={() => attachmentPage(row?.filename)} />
                                    </Row>
                                    <Row justify='center'>
                                        <Text className='mb-2'>{row?.filename.split('/')[1]}</Text>
                                    </Row>
                                </>
                                :
                                <>
                                    <Row justify='center'>
                                        <Button onClick={() => attachmentPage(row?.filename)} />
                                    </Row>
                                    <Row justify='center'>
                                        <Text className='mb-2'>{row?.filename.split('/')[1]}</Text>
                                    </Row>
                                </>
            ),
            key: 'attachment_file',
            align: 'center'
        }
    ];

    // const exportReport = () => {
    //     window.open(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/outgoing-mail-pdf/${id}`);
    // }

    return (
        <div>
            <PageHeader title={`Dokumen Keluar #${id}`} breadcrumbs={[['Dokumen Keluar'], ['Detail']]} onBack={() => history.push('/surat-keluar')} />

            <Card title="Keterangan Dokumen" style={{ borderRadius: 10 }}>
                <Row className='mb-5' justify='space-between'>
                    <Col span={15}>
                        {
                            loaded ?
                                <table className="table-collapse table-default" style={{ width: '100%' }}>
                                    <tbody>
                                        <tr>
                                            <th>Nomor Dokumen</th><td>{outgoingMail.letter_number}</td>
                                        </tr>
                                        <tr>
                                            <th>Subjek</th><td>{outgoingMail.subject}</td>
                                        </tr>
                                        <tr>
                                            <th>Tanggal Dokumen</th><td>{moment(outgoingMail.date).format('dddd, DD MMMM YYYY')}</td>
                                        </tr>
                                        <tr>
                                            <th>Tipe Dokumen</th><td>{outgoingMail.letter_type}</td>
                                        </tr>
                                        <tr>
                                            <th>Opsi Dokumen</th><td>{outgoingMail.letter_option}</td>
                                        </tr>
                                        <tr>
                                            <th>Arsip Dokumen</th><td>{outgoingMail.archive_location}</td>
                                        </tr>
                                        <tr>
                                            <th>Arahan</th><td>{outgoingMail.action_disposition ? outgoingMail.action_disposition : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th>Keterangan Arahan</th><td>{outgoingMail.action_description ? outgoingMail.action_description : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th>Body</th><td>{outgoingMail.body ? parse(outgoingMail.body) : '-'}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                :
                                <Spinner />
                        }
                    </Col>
                    <Col span={8}>
                        {/* <Button onClick={exportReport} type='primary' size='large' shape='round' icon={<FilePdfOutlined />}>Download Dokumen</Button> */}
                    </Col>
                </Row>

                <Card title="Lampiran">
                    {
                        attachmentFile.length === 0 ?
                            <Card>
                                <Text>No Attachment File</Text>
                            </Card>
                            :
                            <DataTable size="small" columns={columnsAttachment} sources={attachmentFile} bordered search={false} />
                    }
                </Card>

                <Card title="Penerima" style={{ borderRadius: 10 }}>
                    {
                        (loaded) ?
                            <DataTable size="small" columns={columns} sources={receiver} bordered search={false} />
                            : <Spinner />
                    }
                </Card>

            </Card>
        </div >
    )
}

export default DokumenKeluarDetail