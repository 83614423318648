import React, { useState, useEffect } from 'react'
import { Typography, Card, Form, Input, Switch, Select, message, Space, Tag, Row, Col } from 'antd'
import { EditButton, DeleteButton, DataTable, PageHeader, Spinner, FormModal } from './../../../components'
import { ApiService } from '../../../services/ApiService'
import { currentUser } from '../../../utils/authData'

const { Text } = Typography
const { Option } = Select

const Permission = () => {
    const [loaded, setLoaded] = useState(true)
    const [roleModules, setRoleModules] = useState([])
    const [role, setRole] = useState([])
    const [menu, setMenu] = useState([])
    const [modalTitle, setModalTitle] = useState(null)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})
    const [isSaving, setIsSaving] = useState(false)
    const [created, setCreated] = useState(false)
    const [readed, setReaded] = useState(false)
    const [updated, setUpdated] = useState(false)
    const [deleted, setDeleted] = useState(false)
    const [listMenu, setListMenu] = useState(null)
    const user = currentUser

    const fetchRoleModules = () => {
        setLoaded(false);
        ApiService.getList('role-module').then((res) => {
            setRoleModules(res.data)
            setLoaded(true)
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const fetchRole = () => {
        setLoaded(false);
        ApiService.getList('role').then((res) => {
            setRole(res.data)
            setLoaded(true)
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const fetchMenu = () => {
        setLoaded(false);
        ApiService.getList('menu').then((res) => {
            setMenu(res.data)
            setLoaded(true)
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const add = () => {
        setModalTitle('Add Permission')
        setDefaultValues({
            id: 0,
            is_created: false,
            is_readed: false,
            is_updated: false,
            is_deleted: false,
        })
        setIsModalVisible(true)
        setCreated(false)
        setUpdated(false)
        setReaded(false)
        setDeleted(false)
    }

    const edit = (v) => {
        setModalTitle('Edit Permission')
        setDefaultValues({
            id: v.id,
            role_id: v.role_id,
            menu_id: JSON.parse(v.menu_id),
            is_created: parseInt(v.is_created),
            is_readed: parseInt(v.is_readed),
            is_updated: parseInt(v.is_updated),
            is_deleted: parseInt(v.is_deleted),
        })
        setIsModalVisible(true)
        setCreated(v.is_created)
        setUpdated(v.is_updated)
        setReaded(v.is_readed)
        setDeleted(v.is_deleted)
        setListMenu(JSON.parse(v.menu_id))
    }

    const destroy = (id) => {
        ApiService.destroy('role-module', id, { user_id: user.id }).then((res) => {
            message.success(res.message, 2)
            fetchRoleModules()
        }).catch((err) => {
            message.warning(err.response.data.message, 2)
        })
    }

    const changeListMenu = (v) => {
        // const list = []
        // list.push(v)
        // console.log(list)
        setListMenu(v)
    }

    const onFinish = (el) => {
        let payload = {
            ...el,
            menu_id: JSON.stringify(listMenu),
            is_created: created ? 1 : 0,
            is_readed: readed ? 1 : 0,
            is_updated: updated ? 1 : 0,
            is_deleted: deleted ? 1 : 0
        }

        if (el.id === 0) {
            Object.assign(payload, { created_by: user.id, updated_by: user.id })
            setIsSaving(true)
            ApiService.store(`role-module`, payload)
                .then((res) => {
                    message.success(res.message, 2)
                    setIsModalVisible(false)
                    setIsSaving(false)
                    fetchRoleModules()
                }).catch((err) => {
                    message.warning(err.response.data.message, 2)
                    setIsSaving(false)
                })
        } else {
            Object.assign(payload, { updated_by: user.id })
            setIsSaving(true)
            ApiService.update(`role-module`, el.id, payload)
                .then((res) => {
                    message.success(res.message, 2)
                    setIsModalVisible(false)
                    setIsSaving(false)
                    fetchRoleModules()
                }).catch((err) => {
                    message.warning(err.response.data.message, 2)
                    setIsSaving(false)
                })
        }
    }

    useEffect(() => {
        fetchRoleModules()
        fetchRole()
        fetchMenu()
    }, []);

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Role Name',
            render: (row) => (
                row.role
            ),
            key: 'role_name',
            align: 'center'
        },
        {
            title: 'Menu',
            render: (row) => (
                <Text>{JSON.stringify(row.listNameMenu).replace(/"/g, " ")}</Text>
            ),
            key: 'menu',
            align: 'center'
        },
        {
            title: 'Create',
            render: (text) => (
                <Tag color={text.is_created === 1 ? "green" : "red"}>{(text.is_created === 1) ? 'Active' : 'InActive'}</Tag>
            ),
            key: 'create',
            align: 'center'
        },
        {
            title: 'Read',
            render: (text) => (
                <Tag color={text.is_readed === 1 ? "green" : "red"}>{(text.is_readed === 1) ? 'Active' : 'InActive'}</Tag>
            ),
            key: 'read',
            align: 'center'
        },
        {
            title: 'Update',
            render: (text) => (
                <Tag color={text.is_updated === 1 ? "green" : "red"}>{(text.is_updated === 1) ? 'Active' : 'InActive'}</Tag>
            ),
            key: 'update',
            align: 'center'
        },
        {
            title: 'Deleted',
            render: (text) => (
                <Tag color={text.is_deleted === 1 ? "green" : "red"}>{(text.is_deleted === 1) ? 'Active' : 'InActive'}</Tag>
            ),
            key: 'deleted',
            align: 'center'
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => edit(row)} />
                        <DeleteButton onConfirm={() => destroy(row.id)} />
                    </Space>
                </div>
            ),
            align: 'center'
        },
    ];

    return (
        <div>
            <PageHeader title="Permissions" breadcrumbs={[['Roles & Permissions'], ['Permissions']]} onAdd={add} />

            <Card style={{ borderRadius: 10 }}>

                {
                    (loaded) ?
                        <DataTable size="small" columns={columns} sources={roleModules} bordered />
                        : <Spinner />
                }

                {
                    (isModalVisible) ?
                        <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => onFinish(v)} width={600} showModal={isModalVisible} onCloseModal={() => setIsModalVisible(false)} saving={isSaving}>
                            <Form.Item name="id" hidden>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Menu" name="menu_id" required rules={[{ required: true }]}>
                                <Select mode='multiple' allowClear placeholder="Menu" onChange={changeListMenu}>
                                    {
                                        menu.map((el, key) => (
                                            <Option key={key} value={el.id}>{el.name}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item label="Role" name="role_id" required rules={[{ required: true }]}>
                                <Select placeholder="Role">
                                    {
                                        role.map((el, key) => (
                                            <Option key={key} value={el.id}>{el.name}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>

                            <Row>
                                <Col span={4}>
                                    <Form.Item label="Create" name="is_created" rules={[{ required: true }]}>
                                        <Switch checkedChildren="Yes" unCheckedChildren="No" onChange={(v) => setCreated(v)} defaultChecked={defaultValues.is_created} />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item label="Read" name="is_readed" rules={[{ required: true }]}>
                                        <Switch checkedChildren="Yes" unCheckedChildren="No" onChange={(v) => setReaded(v)} defaultChecked={defaultValues.is_readed} />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item label="Update" name="is_updated" rules={[{ required: true }]}>
                                        <Switch checkedChildren="Yes" unCheckedChildren="No" onChange={(v) => setUpdated(v)} defaultChecked={defaultValues.is_updated} />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item label="Delete" name="is_deleted" rules={[{ required: true }]}>
                                        <Switch checkedChildren="Ya" unCheckedChildren="Tidak" onChange={(v) => setDeleted(v)} defaultChecked={defaultValues.is_deleted} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Text>Inputs marked with (<span style={{ color: 'red' }}>*</span>) are required</Text>
                        </FormModal> :
                        null
                }
            </Card>
        </div>
    )
};

export default Permission;
