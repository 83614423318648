import React, { useState, useEffect } from 'react'
import { Card, Form, Input, Switch, message, Space, Select } from 'antd'
import { EditButton, DeleteButton, DataTable, PageHeader, Spinner, FormModal } from '../../../components'
import { ApiService } from '../../../services/ApiService'

const { Option } = Select

const DocumentNumber = () => {
    const [loaded, setLoaded] = useState(true)
    const [documentNumber, setDocumentNumber] = useState([])
    const [letterType, setLetterType] = useState([])
    const [modalTitle, setModalTitle] = useState(null)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})
    const [isSaving, setIsSaving] = useState(false)
    const [status, setStatus] = useState(false)

    const fetchDocumentNumber = () => {
        setLoaded(false);
        ApiService.getList('letter-number').then((res) => {
            setDocumentNumber(res.data)
            setLoaded(true)
        }).catch((err) => {
        })
    }

    const fetchLetterType = () => {
        ApiService.getList('letter-type').then((res) => {
            setLetterType(res.data)
        }).catch((err) => {
            message.warning(err.message)
        })
    }

    const add = () => {
        setModalTitle('Add Letter Number')
        setDefaultValues({
            letter_number_id: 0,
            letter_type_id: '',
            letter_number: '',
            status: true,
        })
        setIsModalVisible(true)
        setStatus(true)
    }

    const edit = (v) => {
        setModalTitle('Edit Letter Number')
        setDefaultValues({
            letter_number_id: v.letter_number_id,
            letter_type_id: v.letter_type_id,
            letter_number: v.letter_number,
            status: parseInt(v.status),
        })
        setIsModalVisible(true)
        setStatus(v.status)
    }

    const destroy = (id) => {
        ApiService.destroy('letter-number', id).then((res) => {
            message.success(res.message, 2)
            setTimeout(() => {
                fetchDocumentNumber()
            }, 500);
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const onFinish = (el) => {
        let payload = {
            letter_type_id: el.letter_type_id,
            letter_number: el.letter_number.toUpperCase(),
            status: status ? 1 : 0
        }
        if (el.letter_number_id === 0) {
            setIsSaving(true)
            ApiService.store(`letter-number`, payload)
                .then((res) => {
                    message.success(res.message, 2)
                    setIsModalVisible(false)
                    setIsSaving(false)
                    setTimeout(() => {
                        fetchDocumentNumber()
                    }, 500);
                }).catch((err) => {
                    message.warning(err.response.data.message)
                })
        } else {
            setIsSaving(true)
            ApiService.update(`letter-number`, el.letter_number_id, payload)
                .then((res) => {
                    message.success(res.message, 2)
                    setIsModalVisible(false)
                    setIsSaving(false)
                    setTimeout(() => {
                        fetchDocumentNumber()
                    }, 500);
                }).catch((err) => {
                    message.warning(err.response.data.message)
                })
        }
    }

    useEffect(() => {
        fetchDocumentNumber()
        fetchLetterType()

    }, []);

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Tipe Dokumen',
            render: (row) => (
                row?.letter_type?.letter_type_name
            ),
            key: 'letter_type',
            align: 'center'
        },
        {
            title: 'Nomor',
            dataIndex: 'letter_sequence',
            key: 'number',
            align: 'center'
        },
        {
            title: 'Format',
            dataIndex: 'letter_number',
            key: 'letter_number',
            align: 'center'
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => edit(row)} />
                        <DeleteButton onConfirm={() => destroy(row.letter_number_id)} />
                    </Space>
                </div>
            )
        },
    ];

    return (
        <div>
            <PageHeader title="Letter Number" breadcrumbs={[['Master'], ['Letter Number']]} onAdd={add} />
            <Card style={{ borderRadius: 10 }}>

                {
                    (loaded) ?
                        <DataTable size="small" columns={columns} sources={documentNumber} bordered />
                        : <Spinner />
                }

                {
                    (isModalVisible) ?
                        <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => onFinish(v)} width={600} showModal={isModalVisible} onCloseModal={() => setIsModalVisible(false)} saving={isSaving}>
                            <Form.Item name="letter_number_id" hidden>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Document Type" name="letter_type_id" required rules={[{ required: true }]}>
                                <Select>
                                    {
                                        letterType.map((el, key) => (
                                            <Option value={el.letter_type_id} key={key}>{el.letter_type_name}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item label="Number Format" name="letter_number">
                                <Input placeholder="Number Format" />
                            </Form.Item>
                            <Form.Item label="Status" name="status" rules={[{ required: true }]}>
                                <Switch checkedChildren="Ya" unCheckedChildren="Tidak" onChange={(v) => setStatus(v)} defaultChecked={defaultValues.status} />
                            </Form.Item>
                        </FormModal> :
                        null
                }
            </Card>
        </div>
    )
}

export default DocumentNumber
