import React from 'react'
import moment from 'moment'
import { Card, Col, Row, Typography, Image, Button } from 'antd'
import { Docs, Excel, Pdf } from '../../../components/Folder'
import HTMLReactParser from 'html-react-parser'

const { Title, Text } = Typography
const fileUrl = process.env.REACT_APP_FILE_URL

const attachmentPage = (v) => {
    console.log(v)
}

const LetterDetail = ({ data }) => {
    return (
        <>
            <div style={{ marginBottom: 20 }}>
                <Title style={{ fontSize: 20, fontWeight: 700, marginBottom: 20 }}>Incoming Document Info</Title>
                <hr />
            </div>
            <div style={{ marginBottom: 15 }}>
                <Row justify='space-between'>
                    <Col span={13}>
                        <table className="table-collapse table-default" style={{ width: '100%' }}>
                            <tbody>
                                <tr>
                                    <th>Document Number</th><td>{data?.letter_number}</td>
                                </tr>
                                <tr>
                                    <th>Subject</th><td>{data?.subject}</td>
                                </tr>
                                <tr>
                                    <th>Document Date</th><td>{moment(data?.date).format('dddd, DD MMMM YYYY')}</td>
                                </tr>
                                <tr>
                                    <th>Sender</th><td>
                                        {
                                            data?.created?.name
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <th>Sender Department</th><td>{data?.from_department?.department_name ?? '-'}</td>
                                </tr>
                                <tr>
                                    <th>Document Type</th><td>{data?.letter_type?.letter_type_name}</td>
                                </tr>
                                <tr>
                                    <th>Document Option</th><td>{data?.letter_option?.letter_option_name}</td>
                                </tr>
                                <tr>
                                    <th>Archive Location</th><td>{data?.archive_location?.archive_location_name}</td>
                                </tr>
                                <tr>
                                    <th>Body</th><td>{data?.body ? HTMLReactParser(data?.body) : '-'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                    <Col span={10}>
                        {/* <Text>Penerima</Text> */}
                        <div>
                            <table className="table-collapse table-default" style={{ width: '100%' }}>
                                <thead>
                                    <tr>
                                        <th>Sender Name</th>
                                        <th>Department</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data?.letter_movement?.map((el, key) => (
                                            <tr key={key} style={{ textAlign: 'center' }}>
                                                <td>{el?.receiver_user?.name}</td>
                                                <td>{el?.receiver_department?.department_name}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </Col>
                </Row>
            </div>

            <Card title="Lampiran">
                {
                    data?.letter_attachment?.length === 0 ?
                        <Card>
                            <Text>No Attachment</Text>
                        </Card>
                        :
                        <div>
                            <table className="table-collapse table-default" style={{ width: '100%' }}>
                                <thead>
                                    <tr>
                                        <th>Attachment</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data?.letter_attachment?.map((el, key) => (
                                            <tr key={key} style={{ textAlign: 'center' }}>
                                                {
                                                    el?.filename?.split('.')[1] === 'jpg' || el?.filename?.split('.')[1] === 'png' || el?.filename?.split('.')[1] === 'svg' || el?.filename?.split('.')[1] === 'jpeg' ?
                                                        <>
                                                            <Row justify='center'>
                                                                <Image width={'30%'} src={el?.filename ? `${fileUrl}/${el?.filename}` : null} />
                                                            </Row>
                                                            <Row justify='center'>
                                                                <Text className='mb-2'>{el?.original_filename}</Text>
                                                            </Row>
                                                        </>
                                                        : el?.filename?.split('.')[1] === 'pdf' ?
                                                            <>
                                                                <Row justify='center'>
                                                                    <Pdf onClick={() => attachmentPage(el?.filename)} />
                                                                </Row>
                                                                <Row justify='center'>
                                                                    <Text className='mb-2'>{el?.original_filename}</Text>
                                                                </Row>
                                                            </>
                                                            : el?.filename?.split('.')[1] === 'doc' || el?.filename?.split('.')[1] === 'docx' ?
                                                                <>
                                                                    <Row justify='center'>
                                                                        <Docs onClick={() => attachmentPage(el?.filename)} />
                                                                    </Row>
                                                                    <Row justify='center'>
                                                                        <Text className='mb-2'>{el?.original_filename}</Text>
                                                                    </Row>
                                                                </>
                                                                : el?.filename?.split('.')[1] === 'xls' || el?.filename?.split('.')[1] === 'xlsx' ?
                                                                    <>
                                                                        <Row justify='center'>
                                                                            <Excel onClick={() => attachmentPage(el?.filename)} />
                                                                        </Row>
                                                                        <Row justify='center'>
                                                                            <Text className='mb-2'>{el?.original_filename}</Text>
                                                                        </Row>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <Row justify='center'>
                                                                            <Button onClick={() => attachmentPage(el?.filename)} />
                                                                        </Row>
                                                                        <Row justify='center'>
                                                                            <Text className='mb-2'>{el?.original_filename}</Text>
                                                                        </Row>
                                                                    </>
                                                }
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                }
            </Card>
        </>
    )
}

export default LetterDetail;