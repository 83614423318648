import React from 'react';
import { Modal, Button, Form } from 'antd';

export const BasicModal = (props) => {
    const { width = 500, title = "Modal Detail" } = props
    return (
        <Modal
            centered
            title={title}
            width={width}
            open={props.showModal}
            onCancel={() => props.onCloseModal()}
            footer={[
                <Button key="back" onClick={() => props.onCloseModal()}>
                    Close
                </Button>
            ]}>
            {props.children}
        </Modal>
    );
}

export const FormModal = (props) => {
    const { width = 500, title = "Modal Detail", defaultValues = {}, form = null, loading = false } = props

    return (
        <Modal
            style={{ top: 20 }}
            title={title}
            width={width}
            open={props.showModal}
            onCancel={() => props.onCloseModal()}
            footer={null}>
            <Form
                layout="vertical"
                form={form}
                initialValues={defaultValues}
                onFinish={props.submitForm}>
                {props.children}
                <div className="full-width text-right">
                    <Button type="primary" className="button-primary" htmlType="submit" loading={loading || props.saving}>{(loading || props.saving) ? 'Saving' : 'Save'}</Button>
                </div>
            </Form>
        </Modal>
    );
}

export const DetailModal = (props) => {
    const { width = 500, title = "Modal Detail", defaultValues = {}, form = null } = props
    return (
        <Modal
            style={{ top: 20 }}
            title={title}
            width={width}
            open={props.showModal}
            onCancel={() => props.onCloseModal()}
            footer={[
                <Button key="back" onClick={() => props.onCloseModal()}>
                    Close
                </Button>
            ]}>
            <Form
                layout="vertical"
                form={form}
                initialValues={defaultValues}
                onFinish={props.submitForm}>
                {props.children}
            </Form>
        </Modal>
    );
}
