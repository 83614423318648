import { useState, useEffect } from 'react'
import { Card, Button, Transfer, message, Space } from 'antd'
import { DataTable, DeleteButton, FormModal, PageHeader, Spinner } from '../../../components'
import { rolePermission } from '../../../utils/rolePermission'
import { ApiService } from '../../../services/ApiService'
import { useParams } from 'react-router-dom'

const RolesDetail = () => {
    const { id } = useParams()
    const [rolePermissions, setRolePermissions] = useState([])
    const [listRolePermission, setListRolePermission] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [showFormModal, setShowFormModal] = useState(false)
    const [modalTitle, setModalTitle] = useState('')

    const fetchRolePermission = () => {
        ApiService.getObject('role-permission', id).then((res) => {
            setRolePermissions(res.data)

            let role_permission = []
            res.data?.forEach((item, i) => {
                role_permission.push(item.code)
            })
            setListRolePermission(role_permission.sort());
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchRolePermission()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const addPermission = () => {
        setShowFormModal(true)
        setModalTitle('Add Permission')
    }

    const filterOption = (inputValue, option) => option.title.toLowerCase().indexOf(inputValue) > -1;
    const handleChange = (roleAcc) => {
        setListRolePermission(roleAcc);
    };
    const handleSearch = (dir, value) => {
        console.log('search:', dir, value);
    };

    const onFinish = (v) => {
        setLoaded(true)
        let payload = {
            role_id: id,
            list_permission: listRolePermission
        }

        ApiService.store('role-permission', payload).then((res) => {
            message.success(res.message)
            setIsSaving(false)
            setShowFormModal(false)
            fetchRolePermission()
        }).catch((err) => {
            message.warning(err.response.data.message)
            setIsSaving(false)
            setShowFormModal(false)
        })
    }

    const onDelete = (v) => {
        ApiService.destroy('role-permission', v).then((res) => {
            message.success(res.message)
            fetchRolePermission()
        }).catch((err) => {
            message.warning(err.response.data.message)
        })
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Code Access',
            key: 'code_access',
            dataIndex: 'code'
        },
        {
            title: 'Action',
            key: 'action',
            render: (row) => (
                <Space>
                    <DeleteButton onConfirm={() => onDelete(row.id)} />
                </Space>
            ),
            align: 'center',
            width: '5%'
        }
    ];

    return (
        <div>
            <PageHeader title="Roles Detail" breadcrumbs={[['Roles & Permission'], ['Roles', '/roles-permissions/roles'], ['Detail']]} />
            <Card style={{ borderRadius: 10 }}
                title={
                    <div style={{ float: 'right' }}>
                        <Button type='primary' onClick={addPermission}>Add Permission</Button>
                    </div>
                }
            >
                {
                    (loaded) ?
                        <DataTable size="small" columns={columns} sources={rolePermissions} bordered />
                        : <Spinner />
                }
            </Card>

            {
                (showFormModal) ?
                    <FormModal title={modalTitle} submitForm={(v) => onFinish(v)} width={600} showModal={showFormModal} onCloseModal={() => setShowFormModal(false)} saving={isSaving}>
                        <div className='mb-3'>
                            <Transfer
                                dataSource={rolePermission.sort((a, b) => a.key < b.key ? -1 : 1)}
                                showSearch
                                listStyle={{
                                    width: 350,
                                    height: 400,
                                }}
                                filterOption={filterOption}
                                targetKeys={listRolePermission}
                                onChange={handleChange}
                                onSearch={handleSearch}
                                render={(item) => item.title}
                            />
                        </div>
                    </FormModal> :
                    null
            }
        </div>
    )
}

export default RolesDetail