import { useState, useEffect } from 'react';
import { Avatar, Badge, Layout, List, Menu, message, /*Switch,*/ Tag, Typography } from 'antd';
import { BarChart, Bell } from 'react-feather';
import DashHeader from './styles/Header';
import { Link } from 'react-router-dom';
import { useAppState } from './shared/AppProvider';
import { currentUser } from '../utils/authData';
import { logout } from '../services/AuthService';
import { useHistory } from 'react-router-dom';
import { ApiService } from '../services/ApiService';
import { MailTwoTone } from '@ant-design/icons';

const { SubMenu } = Menu;
const { Header } = Layout;
const { Text } = Typography;

const fileUrl = process.env.REACT_APP_FILE_URL

const MainHeader = () => {
    const history = useHistory();
    const [state, dispatch] = useAppState();
    const [user, setUser] = useState(null);
    const [letterNotif, setLetterNotif] = useState([])
    const [avatar, setAvatar] = useState(null)
    // eslint-disable-next-line
    const [isRead, setIsRead] = useState(0)
    const [letterLength, setLetterLength] = useState(null)
    const userId = currentUser.id

    const fetchUserLogin = () => {
        ApiService.getObject('employee', userId).then((res) => {
            setUser(res.data)
            if (res.data.avatar === null || res.data.avatar === '') {
                setAvatar(null)
            } else {
                setAvatar(fileUrl + '/' + res.data.avatar)
            }
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const fetchLetterNotRead = (v) => {
        const data = {
            user_id: userId
        }
        ApiService.getList('incoming-mail-not-read', data).then((res) => {
            setLetterNotif(res.data)
            setLetterLength(res.data.length)
        }).catch((err) => {
        })
    }

    useEffect(() => {
        fetchUserLogin()
        fetchLetterNotRead()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const updateRead = (v) => {
        const data = {
            user_id: user.id
        }

        ApiService.update('update-read', v.id, 0, data).then((res) => {
            setIsRead(1)
            fetchLetterNotRead()
            history.push(`/surat-masuk-detail-receive/${v.id}`)
        }).catch((err) => {
        })
    }

    return (
        <DashHeader>
            {
                user ?
                    <Header>
                        {state.mobile && (
                            <Link href="#!"
                                onClick={() => dispatch({ type: 'mobileDrawer' })}
                                className="trigger">
                                <BarChart size={20} strokeWidth={1} />
                            </Link>
                        )}
                        <span className="mr-auto" />

                        <Menu mode="horizontal">
                            {/* <Switch checkedChildren="Collapse" unCheckedChildren="Non Collapse" size="large" checked={!!state.collapsed} onChange={checked => dispatch({ type: 'collapse' })} /> */}
                            {/* <Menu.Item key="options" onClick={() => dispatch({ type: 'options' })}>
                                <Settings size={20} strokeWidth={1} />
                            </Menu.Item> */}
                            <SubMenu
                                key="bell-notification"
                                title={
                                    <Badge count={letterLength}>
                                        <span className="submenu-title-wrapper">
                                            <Bell size={20} strokeWidth={1} />
                                        </span>
                                    </Badge>
                                }
                            >
                                <div className='dropdown-notification-box'>
                                    <Menu.Item
                                        style={{ height: 'auto', backgroundColor: 'transparent' }}
                                    >
                                        {
                                            (letterNotif.length > 0) ?
                                                <List
                                                    itemLayout="horizontal"
                                                    dataSource={letterNotif}
                                                    renderItem={item => (
                                                        <List.Item className="notification" onClick={() => updateRead(item)}>
                                                            <div style={{ width: '100%', cursor: 'pointer', padding: 5 }}>
                                                                <List.Item.Meta
                                                                    avatar={<MailTwoTone style={{ fontSize: 20, marginTop: 10 }} />}
                                                                    title={item.subject}
                                                                    description=
                                                                    {
                                                                        <>
                                                                            <small>{item.from}</small>
                                                                            <br />
                                                                            {
                                                                                item.type === 'DISP' ?
                                                                                    <Tag color={'green'}>Disposisi</Tag>
                                                                                    :
                                                                                    <Tag color={'blue'}>Dokumen Masuk</Tag>
                                                                            }
                                                                        </>
                                                                    }
                                                                />
                                                            </div>
                                                            <hr />
                                                        </List.Item>
                                                    )}
                                                />
                                                :
                                                <div className="full-width text-center">
                                                    <Text type="secondary">Kamu tidak memiliki notifikasi</Text>
                                                </div>
                                        }

                                    </Menu.Item>
                                </div>

                            </SubMenu>

                            <SubMenu key="avatar" title=
                                {
                                    avatar !== null ?
                                        <Avatar src={avatar} size={40} />
                                        : <Avatar size={40}>{(user.name.substring(0, 2)).toUpperCase()}</Avatar>
                                }
                            >
                                <Menu.Item key="profile_edit" onClick={() => history.push("/profile")}>Profile</Menu.Item>
                                <Menu.Divider />
                                <Menu.Item key="logout" onClick={() => logout()}>
                                    Log Out
                                </Menu.Item>
                            </SubMenu>
                        </Menu>
                    </Header > : null
            }
        </DashHeader >
    );
};

export default MainHeader;
