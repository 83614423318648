import React, { useState, useEffect } from 'react'
import { Button, Card, Col, Form, Input, InputNumber, message, Row, Tag, Popover } from 'antd'
import { DataTable, FormModal, PageHeader, Spinner } from './../../components'
import { ApiService } from '../../services/ApiService'
import moment from 'moment/moment'
import { SendOutlined } from '@ant-design/icons'

const content = (
    <div>
        <p style={{ color: 'black' }}>Cannot Process</p>
    </div>
);

const DocumentProcessLog = () => {
    const [loaded, setLoaded] = useState(false)
    const [documentProcessLog, setDocumentProcessLog] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [modalTitle, setModalTitle] = useState('')
    const [defaultValues, setDefaultValues] = useState({})
    const [payloads, setPayloads] = useState({})

    const fetchDocumentProcessLog = () => {
        ApiService.getList('document-process-log').then((res) => {
            setDocumentProcessLog(res.data)
            setLoaded(true)
        }).catch(() => {
        })
    }

    useEffect(() => {
        fetchDocumentProcessLog()

    }, []);

    const onShowModal = (v) => {
        setShowModal(true)
        setModalTitle('Reupload Document')
        setDefaultValues({
            title: v.title,
            pdf_split: v.pdf_split
        })

        setPayloads({
            doc_process_log_id: v.doc_process_log_id,
            filename: v.filename,
            url: v.url,
            document_origin: v.document_origin,
            document_from: v.document_from,
            user_id: v.user_id,
            name: v.user_name,
            email: v.email,
            code: v.code,
        })
    }

    const onFinish = (v) => {
        let payload = {
            ...payloads,
            title: v.title,
            pdf_split: v.pdf_split,
        }

        ApiService.store('reupload-document', payload).then((res) => {
            message.success(res.message, 3)
            fetchDocumentProcessLog()
            setShowModal(false)
        }).catch((err) => {
            message.warning(err.response.data.message)
            setShowModal(false)
        })
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Title',
            key: 'title',
            dataIndex: 'title',
            align: 'center'
        },
        {
            title: 'Filename',
            key: 'filename',
            dataIndex: 'filename',
            align: 'center'
        },
        {
            title: 'Uploaded By',
            key: 'upload_by',
            dataIndex: 'user_name',
            align: 'center'
        },
        {
            title: 'Uploaded At',
            key: 'created_at',
            render: (row) => (
                moment(row?.doc_upload_date).format('DD MMMM YYYY hh:mm:ss')
            ),
            align: 'center'
        },
        {
            title: 'Split PDF',
            dataIndex: 'pdf_split',
            key: 'pdf_split',
            align: 'center'
        },
        {
            title: 'Status',
            key: 'status',
            render: (row) => (
                row?.status === 0 ? <Tag color="blue">In Progress</Tag> : row?.status === 1 ? <Tag color="green">Complete</Tag> : <Tag color="red">Failed</Tag>
            ),
            align: 'center'
        },
        {
            title: 'Action',
            key: 'action',
            render: (row) => (
                row?.code !== null ?
                    <Button type='link' onClick={() => onShowModal(row)}><SendOutlined /></Button> :
                    <Popover content={content} title="Failed" trigger="click">
                        <Button type='link'><SendOutlined /></Button>
                    </Popover>
            ),
            align: 'center'
        }
    ];

    return (
        <div>
            <PageHeader title="Document Process Log" breadcrumbs={[['Document Process Log']]} />
            <Card style={{ borderRadius: 10 }}>
                {
                    loaded ?
                        <DataTable size="small" columns={columns} sources={documentProcessLog} bordered />
                        : <Spinner />
                }
            </Card>
            {
                showModal ?
                    <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => onFinish(v)} width={800} showModal={showModal} onCloseModal={() => setShowModal(false)}>
                        <Row justify="space-between">
                            <Col span={11}>
                                <Form.Item label="Title" name="title">
                                    <Input placeholder="Title" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item label="PDF Split" name="pdf_split">
                                    <InputNumber placeholder="PDF Split" style={{ width: '100%' }} min={0} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </FormModal>
                    : null
            }
        </div>
    )
}

export default DocumentProcessLog
