import React, { useState, useEffect } from 'react';
import { Card, Space, Tag, message, Form, Input, Select } from 'antd';
import { PageHeader, EditButton, DataTable, Spinner, FormModal } from '../../components';
import { ApiService } from '../../services/ApiService';
import { useParams } from 'react-router-dom';
import { currentUser } from '../../utils/authData'
import { useHistory } from 'react-router-dom';

const { Option } = Select

const UpdateReceiverSuratDisposisi = () => {
    const history = useHistory()
    const [listReceiver, setListReceiver] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [modalTitle, setModalTitle] = useState(null)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})
    const [isSaving, setIsSaving] = useState(false)
    const [employee, setEmployee] = useState([])
    const [department, setDepartment] = useState([])
    const { id } = useParams()
    const user = currentUser

    const fetchReceiver = () => {
        setLoaded(false)
        ApiService.getObject('disposition-mail-create', id).then((res) => {
            setListReceiver(res.data.receiver)
            setLoaded(true)
        }).catch((err) => {
            err.message('Failed')
        })
    }

    const fetchEmployee = (el) => {
        ApiService.getObject('employee-by-department', el).then((res) => {
            setEmployee(res.data)
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const fetchDepartment = () => {
        ApiService.getList('department').then((res) => {
            setDepartment(res.data)
        }).catch((err) => {
            message.warning('Failed')
        })
    }

    const edit = (v) => {
        setModalTitle('Edit Penerima')
        setDefaultValues({
            id: v.id,
            letter_id: id,
            user_id: v.user_id,
            to_receiver: v.to_receiver,
            department_id: v.department_id,
            approver: v.approver,
            updated_by: user.id
        })
        setIsModalVisible(true)
    }

    const changeEmployee = (el) => {
        fetchEmployee(el)
    }

    const onFinish = (el) => {
        let payload = {
            ...el
        }
        setIsSaving(true)
        ApiService.update(`letter-receiver`, el.id, payload)
            .then((res) => {
                message.success(res.message, 2)
                setIsModalVisible(false)
                setIsSaving(false)
                fetchReceiver(id)
            }).catch((err) => {
                message.warning(err.message, 2)
            })
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Name',
            render: (row) => (
                row?.user ? row?.user : row?.to_receiver
            ),
            key: 'name'
        },
        {
            title: 'Departemen',
            dataIndex: 'department',
            key: 'department'
        },
        {
            title: 'Approver',
            render: (row) => (
                row?.approver === 0 ? <Tag color={"blue"}>Mengetahui</Tag> : <Tag color={"green"}>Approver</Tag>
            ),
            key: 'approver'
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => edit(row)} />
                    </Space>
                </div>
            ),
            align: 'center'
        },
    ];

    useEffect(() => {
        fetchReceiver()
        fetchDepartment()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div>
            <PageHeader title="Edit Penerima" breadcrumbs={[['Surat Disposisi'], ['Edit'], ['Penerima']]} onBack={() => history.push(`/surat-disposisi/update/${id}`)} />

            <Card style={{ borderRadius: 15 }} className="content-container">
                {
                    loaded ?
                        <DataTable columns={columns} sources={listReceiver} />
                        : <Spinner />
                }
            </Card>

            {
                (isModalVisible) ?
                    <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => onFinish(v)} width={600} showModal={isModalVisible} onCloseModal={() => setIsModalVisible(false)} saving={isSaving}>
                        <Form.Item name="id" hidden>
                            <Input />
                        </Form.Item>

                        <Form.Item label="Departemen" name="department_id" required rules={[{ required: true }]}>
                            <Select onChange={changeEmployee}>
                                {
                                    department.map((el, key) => (
                                        <Option key={key} value={el.id}>{el.name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label="Nama" name="user_id" required rules={[{ required: true }]}>
                            <Select>
                                {
                                    employee.map((el, key) => (
                                        <Option key={key} value={el.id}>{el.name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label="Approver" name="approver" required rules={[{ required: true }]}>
                            <Select placeholder="Approver">
                                <Option value={0}>Mengetahui</Option>
                                <Option value={1}>Approver</Option>
                            </Select>
                        </Form.Item>
                    </FormModal>
                    :
                    null
            }
        </div>
    );
};

export default UpdateReceiverSuratDisposisi;
