import { useState, useEffect } from 'react'
import { Card } from 'antd'
import { /*DataTable*/ PageHeader } from '../../components'
import { useHistory, useParams } from 'react-router-dom'
import { ApiService } from '../../services/ApiService'
import LetterDetail from './tabs'

const DocumentOutgoingDetail = () => {
    const { id } = useParams()
    const history = useHistory()
    const [documentDetail, setDocumentDetail] = useState([])
    const [loaded, setLoaded] = useState(false)

    const fetchOutgoingDetail = () => {
        ApiService.getObject('outgoing-document', id).then((res) => {
            setDocumentDetail(res.data)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchOutgoingDetail()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <PageHeader title="Outgoing Document" breadcrumbs={[['Outgoing Document'], ['Detail']]} onBack={() => history.push('/outgoing-document')} />
            <Card style={{ borderRadius: 10 }}>
                {
                    loaded ?
                        <LetterDetail data={documentDetail} />
                        : null
                }
            </Card>
        </div>
    )
}

export default DocumentOutgoingDetail