import React from 'react';
import { Popconfirm, Button } from 'antd'
import { Link } from 'react-router-dom'
import { DeleteFilled, EditFilled, CloseCircleFilled, CheckCircleFilled, PlusCircleFilled, EyeFilled, SaveFilled, ArrowLeftOutlined, FilePdfOutlined, DownloadOutlined } from '@ant-design/icons'

export const DeleteButton = (props) => {
    const { size = "small", title = "Delete this data ?" } = props
    return (
        <Popconfirm title={title} okText="Yes" cancelText="No" onConfirm={() => props.onConfirm()}>
            <Button danger type="link" size={size} shape="circle" icon={<DeleteFilled />} />
        </Popconfirm>
    );
}

export const DeleteButton2 = (props) => {
    const { title = 'Delete Data', popupTitle = "Delete this data...?", right = false, size = "middle" } = props
    return (
        <Popconfirm title={popupTitle} okText="Yes" cancelText="No" onConfirm={() => props.onConfirm()}>
            <Button danger size={size} type="primary" icon={<DeleteFilled />} style={(right) ? { float: 'right' } : null}>
                {title}
            </Button>
        </Popconfirm>
    );
}

export const EditButton = (props) => {
    const { link = false, to = "/", size = "small" } = props
    if (link) {
        return (
            <Link to={to}>
                <Button type="link" icon={<EditFilled />} />
            </Link>
        )
    }
    return (
        <Button type="link" size={size} shape="circle" onClick={() => props.onEdit()} icon={<EditFilled />} />
    );
}

export const EditButton2 = (props) => {
    const { title = 'Edit Data', right = false, size = "middle" } = props
    return (
        <Button size={size} type="primary" onClick={() => props.onEdit()} icon={<EditFilled />} style={(right) ? { float: 'right' } : null}>
            {title}
        </Button>
    );
}

export const ShowButton = (props) => {
    const { link = false, to = "/", size = "small" } = props
    if (link) {
        return (
            <Link to={to}>
                <Button type="link" icon={<EyeFilled />} />
            </Link>
        )
    }
    return (
        <Button type="link" size={size} shape="circle" onClick={() => props.onShow()} icon={<EyeFilled />} />
    );
}

export const NewButton = (props) => {
    const { to = null, title = 'Add Data', right = false, disabled = false } = props
    return (
        <Link to={to} style={(right) ? { float: 'right' } : null}>
            <Button disabled={disabled} type="primary" icon={<PlusCircleFilled />}> {title}</Button>
        </Link>
    );
}

export const BackButton = (props) => {
    const { to = null, title = 'Back', right = false } = props
    return (
        <Link to={to} style={(right) ? { float: 'right' } : null}>
            <Button type="danger" icon={<ArrowLeftOutlined />}> {title}</Button>
        </Link>
    );
}

export const AddButton = (props) => {
    const { title = 'Add Data', right = false, size = "middle" } = props
    return (
        <Button size={size} type="primary" onClick={() => props.onAdd()} icon={<PlusCircleFilled />} style={(right) ? { float: 'right' } : null}>
            {title}
        </Button>
    );
}

export const SelectButton = (props) => {
    const { title = 'Pilih', right = false, size = "middle" } = props
    return (
        <Button size={size} type="primary" shape='round' onClick={() => props.onSelect()} style={(right) ? { float: 'right' } : null}>
            {title}
        </Button>
    );
}

export const ViewButton = (props) => {
    const { to = null, size = "small" } = props
    return (
        <Link to={to}>
            <Button type="link" shape="circle" icon={<EyeFilled />} size={size}></Button>
        </Link>
    );
}

export const DetailButton = (props) => {
    const { to = null, title = 'View Detail', right = false } = props
    return (
        <Link to={to} style={(right) ? { float: 'right' } : null}>
            <Button type="primary" className="button-primary" icon={<EyeFilled />}> {title}</Button>
        </Link>
    );
}

export const SubmitButton = (props) => {
    const { title = 'save', size = "middle", right = false, loading = false } = props
    return (
        <Button loading={loading} type="primary" htmlType="submit" size={size} style={(right) ? { float: 'right' } : null} icon={<SaveFilled />} >{title}</Button>
    )
}

export const PrintPdfButton = (props) => {
    const { title = 'Print', right = false, size = "middle" } = props
    return (
        <Button size={size} type="primary" onClick={() => props.onPrint()} icon={<FilePdfOutlined />} style={(right) ? { float: 'right' } : null}>
            {title}
        </Button>
    );
}

export const DownloadButton = (props) => {
    const { to = '/' } = props
    return (

        <Link to={to} target="_blank" rel="noreferrer">
            <Button type="link" icon={<DownloadOutlined />} />
        </Link>
    );
}

export const CustomButton = (props) => {
    const { link = false, to = "/", size = "small", icon = <EditFilled /> } = props
    if (link) {
        return (
            <Link to={to}>
                <Button type="link" icon={icon} />
            </Link>
        )
    }
    return (
        <Button type="link" size={size} shape="circle" onClick={() => props.onEdit()} icon={icon} />
    );
}

export const ButtonUniversal = (props) => {
    const { link = false, to = "/", size = "small", icon = <EyeFilled /> } = props
    if (link) {
        return (
            <Link to={to}>
                <Button type="link" icon={icon} />
            </Link>
        )
    }
    return (
        <Button type="primary" className="button-primary" size={size} onClick={() => props.onClick()}>{props.title}</Button>
    );
}

export const TextButton = (props) => {
    const { size = "small", deleted = false } = props
    return (
        deleted ?
            <Popconfirm title={props.title} okText="Yes" cancelText="No" onConfirm={() => props.onConfirm()}>
                <Button type={props.type} size={size} shape={props.shape} style={props.style}>{props.title}</Button>
            </Popconfirm>
            :
            <Button type={props.type} size={size} shape={props.shape} onClick={() => props.onClick()} style={props.style}>{props.title}</Button>
    )
}

export const TextButtonDanger = (props) => {
    const { size = "small", deleted = false } = props
    return (
        deleted ?
            <Popconfirm title={props.title} okText="Yes" cancelText="No" onConfirm={() => props.onConfirm()}>
                <Button danger type={props.type} size={size} shape={props.shape} style={props.style}>{props.title}</Button>
            </Popconfirm>
            :
            <Button type={props.type} size={size} shape={props.shape} onClick={() => props.onClick()} style={props.style}>{props.title}</Button>
    )
}

export const ApproveButton = (props) => {
    const { link = false, to = "/", size = "small" } = props
    if (link) {
        return (
            <Link to={to}>
                <Button type="link" icon={<CheckCircleFilled />} />
            </Link>
        )
    }
    return (
        <Button type="link" size={size} shape="circle" onClick={() => props.onApprove()} icon={<CheckCircleFilled />} />
    );
}

export const RejectButton = (props) => {
    const { size = "small", title = "Delete this data ?" } = props
    return (
        <Popconfirm title={title} okText="Yes" cancelText="No" onConfirm={() => props.onConfirm()}>
            <Button danger type="link" size={size} shape="circle" icon={<CloseCircleFilled />} />
        </Popconfirm>
    );
}