import { useState, useEffect } from 'react'
import { DataTable, PageHeader } from '../../components'
import { Button, Card, Col, Descriptions, Row, Space, Tag, message } from 'antd'
import { useParams } from 'react-router-dom'
import { ApiService } from '../../services/ApiService'
import moment from 'moment'
import { currentUser } from '../../utils/authData'

const Detail = () => {
    const { id } = useParams()
    const user = currentUser
    const [loaded, setLoaded] = useState(false)
    const [docUrl, setDocUrl] = useState("")
    const [docLoanData, setDocLoanData] = useState({})
    const [approverData, setApproverData] = useState([])
    const [logData, setLogData] = useState([])
    const [isDisable, setIsDisable] = useState(false)

    const fetchLoanData = () => {
        setLoaded(false)
        ApiService.getObject("approval", id).then((res) => {
            const approver = res.data?.approvers

            setDocUrl(res.data?.document?.filename)
            setDocLoanData(res.data)
            setLogData(res.data?.logs)
            setApproverData(approver)

            const lev1 = approver.filter((el) => el.level === 1)
            const lev2 = approver.filter((el) => el.level === 2)

            if (res.data.status === "FINISHED" || res.data.status === "APPROVED" || res.data.status === "REJECT") {
                setIsDisable(true)
            } else {
                if (parseInt(lev1[0].user_id) === parseInt(user.id)) {
                    if (lev1[0].is_approve === 0) {
                        setIsDisable(false)
                    } else {
                        setIsDisable(true)
                    }
                }
                if (parseInt(lev2[0].user_id) === parseInt(user.id)) {
                    if (lev1[0].is_approve === 1 && lev2[0].is_approve === 0) {
                        setIsDisable(false)
                    } else {
                        setIsDisable(true)
                    }
                }
            }

            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchLoanData()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const onConfirm = (v) => {
        let payload = {
            approval_document_status_id: id,
            is_approve: v,
            user_id: user.id,
        }

        ApiService.store("approver", payload).then((res) => {
            message.success("Success Confirm Document")
            fetchLoanData()
        }).catch((err) => {
            message.warning("Failed Confirm Document")
        })
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'User',
            render: (row) => (
                row?.user?.name
            ),
            key: 'user'
        },
        {
            title: 'Date',
            render: (row) => (
                row?.date === null ? "-" : moment(row?.date).format("dddd, DD MMMM YYYY")
            ),
            key: 'date',
            align: 'center'
        },
        {
            title: 'Approve Status',
            render: (row) => (
                <Tag color={
                    row?.is_approve === null || row?.is_approve === 0 ? "blue" : row?.is_approve === 1 ? "green" : "red"
                }>
                    {
                        row?.is_approve === null || row?.is_approve === 0 ?
                            "WAITING" : row?.is_approve === 1 ? "APPROVED" : "REJECT"
                    }
                </Tag>
            ),
            key: 'approve_status',
            align: 'center'
        }
    ]

    const columnsLog = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Approval ID',
            dataIndex: 'approval_document_status_id',
            key: 'approval_id',
            align: 'center'
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description'
        },
        {
            title: 'Created By',
            render: (row) => (
                row?.user?.name ?? "-"
            ),
            key: 'user'
        },
        {
            title: 'Confirm By',
            render: (row) => (
                row?.confirm?.name ?? "-"
            ),
            key: 'confirm'
        },
        {
            title: 'Reason Loan',
            render: (row) => (
                row?.approval_status?.reason_loan
            ),
            key: 'reason_loan'
        },
        {
            title: 'Loan Date',
            render: (row) => (
                `${moment(row?.approval_status?.start_date).format("DD MMMM YYYY")} - ${moment(row?.approval_status?.end_date).format("DD MMMM YYYY")}`
            ),
            key: 'loan_date'
        }
    ]

    return (
        <div>
            <PageHeader title="Document Loaning Detail" breadcrumbs={[['Document Loaning', '/document-loan'], ['Detail']]} />
            <Card style={{ borderRadius: 10 }}>
                <div style={{ marginBottom: 40 }}>
                    <Descriptions title="Document Information" bordered>
                        <Descriptions.Item label="Title">{docLoanData?.document?.title}</Descriptions.Item>
                        <Descriptions.Item label="Original Filename">{docLoanData?.document?.original_filename}</Descriptions.Item>
                        <Descriptions.Item label="Status">
                            <Tag color={docLoanData?.status === "WAITING" ? "blue" : docLoanData?.status === "APPROVED" || docLoanData?.status === "FINISHED" ? "green" : "red"}>
                                {docLoanData?.status}
                            </Tag>
                        </Descriptions.Item>
                        <Descriptions.Item label="Reason Loan">{docLoanData?.reason_loan}</Descriptions.Item>
                        <Descriptions.Item label="Arhice Location">{docLoanData?.document?.archive_location?.archive_location_name}</Descriptions.Item>
                        {
                            docLoanData?.status === "CANCEL" ?
                                <Descriptions.Item label="Reason Cancel">{docLoanData?.reason_cancel}</Descriptions.Item>
                                : null
                        }
                        <Descriptions.Item label="Loan Date" span={docLoanData?.status === "CANCEL" ? 2 : 1}>
                            {`${moment(docLoanData?.start_date).format("DD MMMM YYYY")} - ${moment(docLoanData?.end_date).format("DD MMMM YYYY")}`}
                        </Descriptions.Item>
                    </Descriptions>
                </div>

                {
                    loaded ?
                        <div>
                            <Card title="Document Preview">
                                <Row justify="center">
                                    <iframe src={`${docUrl}#toolbar=0`} width="730" height="950" title="Document Review" />
                                </Row>
                            </Card>
                        </div>
                        : null
                }

                <Card title={
                    <Row justify="space-between">
                        <Col>
                            Approver
                        </Col>
                        <Col>
                            <Space>
                                <Button disabled={isDisable} onClick={() => onConfirm("APPROVED")} type='primary'>Approve</Button>
                                <Button disabled={isDisable} onClick={() => onConfirm("REJECT")} type='primary' danger>Reject</Button>
                            </Space>
                        </Col>
                    </Row>
                }>
                    <DataTable sources={approverData} columns={columns} pagination={false} search={false} />
                </Card>

                <Card title="Logs">
                    <DataTable sources={logData} columns={columnsLog} bordered size="small" />
                </Card>
            </Card>
        </div>
    )
}

export default Detail