import React, { useState, useEffect } from 'react'
import { Card, Form, Input, Space, message } from 'antd'
import { EditButton, DeleteButton, DataTable, PageHeader, Spinner, FormModal } from './../../../components'
import { ApiService } from '../../../services/ApiService'

const DocumentOption = () => {
    const [loaded, setLoaded] = useState(true)
    const [documentOption, setDocumentOption] = useState([])
    const [modalTitle, setModalTitle] = useState(null)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})
    const [isSaving, setIsSaving] = useState(false)

    const fetchDocumentOption = () => {
        setLoaded(false)
        ApiService.getList('letter-option').then((res) => {
            setDocumentOption(res.data)
            setLoaded(true)
        }).catch((err) => {
        })
    }

    const add = () => {
        setModalTitle('Add Document Option')
        setDefaultValues({
            letter_option_id: 0,
            letter_option_name: '',
        })
        setIsModalVisible(true)
    }

    const edit = (v) => {
        setModalTitle('Edit Document Option')
        setDefaultValues({
            letter_option_id: v.letter_option_id,
            letter_option_name: v.letter_option_name,
        })
        setIsModalVisible(true)
    }

    const destroy = (id) => {
        ApiService.destroy('letter-option', id).then((res) => {
            message.success(res.message, 2)
            setTimeout(() => {
                fetchDocumentOption()
            }, 500);
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const onFinish = (el) => {
        let payload = {
            letter_option_name: el.letter_option_name.toUpperCase()
        }

        if (el.letter_option_id === 0) {
            setIsSaving(true)
            ApiService.store(`letter-option`, payload)
                .then((res) => {
                    message.success(res.message, 2)
                    setIsModalVisible(false)
                    setIsSaving(false)
                    setTimeout(() => {
                        fetchDocumentOption()
                    }, 500);
                }).catch((err) => {
                    message.warning(err.message, 2)
                })
        } else {
            setIsSaving(true)
            ApiService.update(`letter-option`, el.letter_option_id, payload)
                .then((res) => {
                    message.success(res.message, 2)
                    setIsModalVisible(false)
                    setIsSaving(false)
                    setTimeout(() => {
                        fetchDocumentOption()
                    }, 500);
                }).catch((err) => {
                    message.warning(err.message, 2)
                })
        }
    }

    useEffect(() => {
        fetchDocumentOption()

    }, []);

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Document Option',
            dataIndex: 'letter_option_name',
            key: 'letter_option_name',
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => edit(row)} />
                        <DeleteButton onConfirm={() => destroy(row.letter_option_id)} />
                    </Space>
                </div>
            )
        },
    ];

    return (
        <div>
            <PageHeader title="Document Option" breadcrumbs={[['Master'], ['Document Option']]} onAdd={add} />
            <Card style={{ borderRadius: 10 }}>

                {
                    (loaded) ?
                        <DataTable size="small" columns={columns} sources={documentOption} bordered />
                        : <Spinner />
                }

                {
                    (isModalVisible) ?
                        <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => onFinish(v)} width={600} showModal={isModalVisible} onCloseModal={() => setIsModalVisible(false)} saving={isSaving}>
                            <Form.Item name="letter_option_id" hidden>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Nama" name="letter_option_name" required rules={[{ required: true }]}>
                                <Input placeholder="Nama" />
                            </Form.Item>
                        </FormModal> :
                        null
                }
            </Card>
        </div>
    )
}

export default DocumentOption
