import { useState, useEffect } from 'react'
import { DataTable, FormModal, PageHeader, RejectButton, ShowButton, Spinner } from '../../components'
import { Card, Form, Input, Space, Tag, message } from 'antd'
import { ApiService } from '../../services/ApiService'
import { useHistory } from 'react-router-dom'

const Index = () => {
    const history = useHistory()
    const [form] = Form.useForm()
    const [myApproval, setMyApproval] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [showModal, setShowModal] = useState(false)

    const fetchMyApproval = () => {
        setLoaded(false)
        ApiService.getList("approval").then((res) => {
            setMyApproval(res.data)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchMyApproval()

    }, [])

    const showCancelLoan = (v) => {
        form.setFieldsValue({
            id: v
        })
        setShowModal(true)
    }

    const cancelLoan = (v) => {
        ApiService.update("approval-cancel", v.id, { reason_cancel: v.reason_cancel }).then((res) => {
            message.success("Document Loan Canceled")
            fetchMyApproval()
            setShowModal(false)
        }).catch((err) => {
            message.warning("Failed Cancel Document Loan")
        })
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Approval ID',
            dataIndex: 'approval_document_status_id',
            key: 'approval_id',
            align: 'center'
        },
        {
            title: 'Title',
            render: (row) => (
                row?.document?.title
            ),
            key: 'title'
        },
        {
            title: 'Original Filename',
            render: (row) => (
                row?.document?.original_filename
            ),
            key: 'original_filename'
        },
        {
            title: 'Status',
            render: (row) => (
                <Tag color={
                    row?.status === "WAITING" ? "blue" : row?.status === "APPROVED" || row?.status === "FINISHED" ? "green" : "red"
                }>
                    {row?.status}
                </Tag>
            ),
            key: 'status',
            align: 'center'
        },
        {
            title: 'Action',
            render: (row) => (
                <Space>
                    <ShowButton onShow={() => history.push(`/document-loan/detail/${row.approval_document_status_id}`)} />
                    {
                        row?.status === "WAITING" ?
                            <RejectButton title={"Cancel this document loan?"} onConfirm={() => showCancelLoan(row?.approval_document_status_id)} />
                            :
                            null
                    }
                </Space>
            ),
            key: 'action',
            align: 'center',
            width: '10%'
        }
    ]

    return (
        <div>
            <PageHeader title="Document Loaning" breadcrumbs={[['Document Loaning']]} createLink={'/document-loan/create'} />
            <Card style={{ borderRadius: 10 }}>
                {
                    loaded ?
                        <DataTable bordered size="small" sources={myApproval} columns={columns} />
                        : <Spinner />
                }
            </Card>

            {
                showModal ?
                    <FormModal title="Reject Document" form={form} showModal={showModal} onCloseModal={() => setShowModal(false)} submitForm={cancelLoan}>
                        <Form.Item name="id" hidden>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Reason Cancel" name="reason_cancel">
                            <Input.TextArea rows={3} />
                        </Form.Item>
                    </FormModal>
                    : null
            }
        </div>
    )
}

export default Index