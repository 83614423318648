import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import AppProvider from '../components/shared/AppProvider';
import AppContainer from './AppContainer';
// import { currentUser } from '../utils/authData';

// Beranda
import Beranda from './beranda'

// Dokumen Masuk
import SuratMasuk from './surat-masuk'
import CreateSuratMasuk from './surat-masuk/create'
import PembuatanSuratMasukDetail from './surat-masuk/createDetail';
import PenerimaSuratMasukDetail from './surat-masuk/inDetail';
import UpdateSuratMasuk from './surat-masuk/update';
import UpdateAttachmentSuratMasuk from './surat-masuk/updateAttachment';

// Surat Disposisi
import SuratDisposisi from './surat-disposisi'
import CreateSuratDisposisi from './surat-disposisi/create'
import PenerimaSuratDisposisi from './surat-disposisi/inDetail';
import PembuatanSuratDisposisi from './surat-disposisi/createDetail';
import UpdateAttachmentSuratDisposisi from './surat-disposisi/updateAttachment';
import UpdateReceiverSuratDisposisi from './surat-disposisi/updateReceiver';
import UpdateSuratDisposisi from './surat-disposisi/update';

// Dokumen Keluar
import SuratKeluar from './surat-keluar'
import SuratKeluarDetail from './surat-keluar/detail'
import CreateSuratKeluar from './surat-keluar/create'
import UpdateSuratKeluar from './surat-keluar/update'
import UpdateReceiver from './surat-keluar/updateReceiver'
import UpdateAttachment from './surat-keluar/updateAttachment'

// DMS
import DocumentManagementSystem from './dms'

// File Manager
// import FileManager from '../views/file-manager/old'
// import FileManagerType from '../views/file-manager/old/type'
// import FileManagerDetail from '../views/file-manager/old/detail';
import FileManager from './file-manager'

// Organisasi
import Direktorat from './organization/department'
import Position from './organization/position'
import Employee from './organization/employee'

// Master
import ActionDisposition from './master/action-disposition'
import DocumentType from './master/document-type'
import Courier from './master/courier'
import ArchiveLocation from './master/archive-location'
import DocumentPriority from './master/document-priority'
import ResponDisposition from './master/response-disposition'
import SuratRahasia from './master/surat-rahasia'
import Expedition from './master/expedition'
import Menu from './master/menu'
import DocumentOption from './master/document-option'
import LetterNumber from './master/document-number'
import SetupFileManager from './master/file-manager'
import DetailSetupFileManager from './master/file-manager/detail'
import Folder from './master/folder'

// Report
import ReportSuratMasuk from './report/surat-masuk'
import ReportSuratKeluar from './report/surat-keluar'

// Roles Permission
import Roles from './roles-permission/roles'
import Permissions from './roles-permission/permission'

// Access Control
import Licenses from './access-control/licenses'
import ApiKey from './access-control/api-key'

// Customer
import Customer from './customer'

// Profile
import Profile from './profile'

import Login from './auth/Login'
import Forgot from './auth/Forgot'
import SignUp from './auth/SignUp'

// Not Found
import NotFound from './404';
import DraftSuratKeluarDetail from './surat-keluar/draftDetail';

// File Log
import FileLog from './file-log'

// Document Loan
import DocumentLoan from './document-loan/index'
import DocumentLoanDetail from './document-loan/detail'
import CreateDocumentLoan from './document-loan/create'

// Manage Archive
import ManageArchive from './manage-archive/index';

// Document Return
import DocumentReturn from './document-return/index';
import DocumentReturnDetail from './document-return/detail';

// Loan Log
import LoanLog from './loan-log/index';

// NEW
import IncomingDocument from './document-incoming';
import IncomingDocumentDetail from './document-incoming/detail';
import CreateIncomingDocument from './document-incoming/create';
import OutgoingDocument from './document-outgoing/index';
import OutgoingDocumentDetail from './document-outgoing/detail';
import CreateEmployee from './organization/employee/create';
import RolesDetail from './roles-permission/roles/detail';
import DocumentProcessLog from './document-process-log';

const AppContent = () => {
    const location = useLocation();
    // const user = currentUser

    return (
        <>
            <AppProvider>
                <AppContainer router={location}>
                    <Switch>
                        <Route exact path="/login" component={Login} />
                        <Route exact path="/forgot-password" component={Forgot} />
                        <Route exact path="/sign-up" component={SignUp} />

                        {/* Beranda */}
                        < Route exact path="/" component={Beranda} />

                        {/* New */}
                        <Route exact path="/incoming-document" component={IncomingDocument} />
                        <Route exact path="/incoming-document/:id" component={IncomingDocumentDetail} />
                        <Route exact path="/create-incoming-document/:id" component={CreateIncomingDocument} />
                        <Route exact path="/outgoing-document" component={OutgoingDocument} />
                        <Route exact path="/outgoing-document/:id" component={OutgoingDocumentDetail} />

                        {/* Surat masuk */}
                        <Route exact path="/surat-masuk" component={SuratMasuk} />
                        <Route exact path="/surat-masuk/create" component={CreateSuratMasuk} />
                        <Route exact path="/surat-masuk/update/:id" component={UpdateSuratMasuk} />
                        <Route exact path="/surat-masuk-detail-create/:id" component={PembuatanSuratMasukDetail} />
                        <Route exact path="/surat-masuk/update-attachment/:id" component={UpdateAttachmentSuratMasuk} />
                        <Route exact path="/surat-masuk-detail-receive/:id" component={PenerimaSuratMasukDetail} />

                        {/* Surat disposisi */}
                        <Route exact path="/surat-disposisi" component={SuratDisposisi} />
                        <Route exact path="/surat-disposisi/create" component={CreateSuratDisposisi} />
                        <Route exact path="/surat-disposisi-detail-create/:id" component={PembuatanSuratDisposisi} />
                        <Route exact path="/surat-disposisi/update-receiver/:id" component={UpdateReceiverSuratDisposisi} />
                        <Route exact path="/surat-disposisi/update-attachment/:id" component={UpdateAttachmentSuratDisposisi} />
                        <Route exact path="/surat-disposisi-detail-receive/:id" component={PenerimaSuratDisposisi} />
                        <Route exact path="/surat-disposisi/update/:id" component={UpdateSuratDisposisi} />

                        {/* Dokumen Keluar */}
                        <Route exact path="/surat-keluar" component={SuratKeluar} />
                        <Route exact path="/draft-detail/:id" component={DraftSuratKeluarDetail} />
                        <Route exact path="/surat-keluar-detail/:id" component={SuratKeluarDetail} />
                        <Route exact path="/surat-keluar/create" component={CreateSuratKeluar} />
                        <Route exact path="/surat-keluar/update/:id" component={UpdateSuratKeluar} />
                        <Route exact path="/surat-keluar/update-receiver/:id" component={UpdateReceiver} />
                        <Route exact path="/surat-keluar/update-attachment/:id" component={UpdateAttachment} />

                        {/* Document Management System */}
                        <Route exact path="/document-management-system" component={DocumentManagementSystem} />

                        {/* File Manager */}
                        <Route exact path="/file-manager" component={FileManager} />
                        {/* <Route exact path="/file-manager/:type" component={FileManagerType} />
                                    <Route exact path="/file-manager/:type/detail/:id" component={FileManagerDetail} /> */}

                        {/* Organisasi */}
                        <Route exact path="/organization/department" component={Direktorat} />
                        <Route exact path="/organization/position" component={Position} />
                        <Route exact path="/organization/employee" component={Employee} />
                        <Route exact path="/organization/employee/add/:id" component={CreateEmployee} />

                        {/* Master */}
                        <Route exact path="/master/action-disposition" component={ActionDisposition} />
                        <Route exact path="/master/document-type" component={DocumentType} />
                        <Route exact path="/master/courier" component={Courier} />
                        <Route exact path="/master/archive-location" component={ArchiveLocation} />
                        <Route exact path="/master/document-priority" component={DocumentPriority} />
                        <Route exact path="/master/response-disposition" component={ResponDisposition} />
                        <Route exact path="/master/surat-rahasia" component={SuratRahasia} />
                        <Route exact path="/master/expedition" component={Expedition} />
                        <Route exact path="/master/menu" component={Menu} />
                        <Route exact path="/master/document-option" component={DocumentOption} />
                        <Route exact path="/master/document-number" component={LetterNumber} />
                        <Route exact path="/master/folder" component={Folder} />
                        <Route exact path="/master/file-manager" component={SetupFileManager} />
                        <Route exact path="/master/file-manager/detail/:fileManagerId" component={DetailSetupFileManager} />

                        {/* Report */}
                        <Route exact path="/report/surat-masuk" component={ReportSuratMasuk} />
                        <Route exact path="/report/surat-keluar" component={ReportSuratKeluar} />

                        {/* Roles & Permissions */}
                        <Route exact path="/roles-permissions/permissions" component={Permissions} />
                        <Route exact path="/roles-permissions/roles" component={Roles} />
                        <Route exact path="/roles-permissions/roles/detail/:id" component={RolesDetail} />

                        {/* Access Control */}
                        <Route exact path="/access-control/api-key" component={ApiKey} />
                        <Route exact path="/access-control/licenses" component={Licenses} />

                        {/* Customer */}
                        <Route exact path="/customer" component={Customer} />

                        {/* Profile */}
                        <Route exact path="/profile" component={Profile} />

                        {/* File Log */}
                        <Route exact path="/document-log" component={FileLog} />

                        {/* Document Process Log */}
                        <Route exact path="/document-process-log" component={DocumentProcessLog} />

                        {/* Document Loan */}
                        <Route exact path='/document-loan' component={DocumentLoan} />
                        <Route exact path='/document-loan/detail/:id' component={DocumentLoanDetail} />
                        <Route exact path='/document-loan/create' component={CreateDocumentLoan} />

                        {/* Manage Archive */}
                        <Route exact path='/manage-archive' component={ManageArchive} />

                        {/* Document Return */}
                        <Route exact path='/document-return' component={DocumentReturn} />
                        <Route exact path='/document-return/detail/:id' component={DocumentReturnDetail} />

                        {/* Loan Log */}
                        <Route exact path='/loan-log' component={LoanLog} />

                        {/* Not Found */}
                        <Route component={NotFound} />

                    </Switch>
                </AppContainer>
            </AppProvider>
        </>
    );
}

export default AppContent;