import React, { useState, useEffect } from 'react';
import { Card, Form, Input, Button, Select, Typography, DatePicker, message, Col, Row, Tag } from 'antd';
import { PageHeader, FileUpload, DataTable } from '../../components';
import { ApiService } from '../../services/ApiService';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { currentUser } from '../../utils/authData'

const { Text, Title } = Typography
const { TextArea } = Input
const { Option } = Select

const UpdateSuratDisposisi = () => {
    const history = useHistory()
    const [form] = Form.useForm();
    const [saving, setSaving] = useState(false)
    // eslint-disable-next-line
    const [loaded, setLoaded] = useState(true)
    // eslint-disable-next-line
    const [department, setDepartment] = useState([])
    const [letterType, setLetterType] = useState([])
    const [letterClassification, setLetterClassification] = useState([])
    const [letterPriority, setLetterPriority] = useState([])
    const [archiveLocation, setArchiveLocation] = useState([])
    // eslint-disable-next-line
    const [receiver, setReceiver] = useState([])
    const [listReceiver, setListReceiver] = useState([])
    const [actionDisposition, setActionDisposition] = useState([])
    const [attachmentFile, setAttachmentFile] = useState([])
    const [listAttachment, setListAttachment] = useState([])
    const [status, setStatus] = useState('')
    const { id } = useParams()
    const user = currentUser
    const [uploading, setUploading] = useState(false)

    const fileUploaded = (v) => {
        let atcs = attachmentFile
        if (v.message === 'Success') {
            atcs.push({ filename: v.response.data.filename })
        }
        setAttachmentFile(atcs)
    }

    const fetchOutgoingMail = (id, form) => {
        setLoaded(false)
        const user_id = {
            user_id: user.id
        }
        ApiService.getObject('disposition-mail-create', id, user_id).then(res => {
            const data = res.data.disposition_mail[0]
            form.setFieldsValue({
                id: data.id,
                letter_id: data.letter_id,
                from: data.from,
                from_department: user.department_id,
                subject: data.subject,
                letter_number: data.letter_number,
                date: moment(data.date).format('YYYY-MM-DD'),
                total_attachment: data.total_attachment,
                letter_type_id: data.letter_type_id,
                letter_classification_id: data.letter_classification_id,
                letter_priority_id: data.letter_priority_id,
                letter_confidentiality_id: data.letter_confidentiality_id,
                letter_option_id: data.letter_option_id,
                archive_location_id: data.archive_location_id,
                status: data.status,
                action_id: data.action_id,
                action_description: data.action_description,
                updated_by: user.id
            })
            setListReceiver(res.data.receiver)
            setLoaded(true)
        }).catch((err) => {
            message.warning(err.message)
        })
    }

    const fetchDepartment = () => {
        ApiService.getList('department').then((res) => {
            setDepartment(res.data)
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const fetchLetterType = () => {
        ApiService.getList('letter-type').then((res) => {
            setLetterType(res.data)
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const fetchLetterClassification = () => {
        ApiService.getList('letter-classification').then((res) => {
            setLetterClassification(res.data)
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const fetchLetterPriority = () => {
        ApiService.getList('letter-priority').then((res) => {
            setLetterPriority(res.data)
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const fetchArchiveLocation = () => {
        ApiService.getList('archive-location').then((res) => {
            setArchiveLocation(res.data)
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const fetchReceiver = () => {
        ApiService.getList('employee').then((res) => {
            setReceiver(res.data)
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const fetchActionDisposition = () => {
        ApiService.getList('action-disposition').then((res) => {
            setActionDisposition(res.data)
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const fetchAttachment = (id) => {
        ApiService.getObject('letter-attachment', id).then((res) => {
            setListAttachment(res.data)
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const onFinish = (v) => {
        setSaving(true)
        let data = {
            from: v.from,
            subject: v.subject,
            letter_number: v.letter_number,
            date: moment(v.letter_date).format('YYYY-MM-DD'),
            total_attachment: v.total_attachment,
            letter_type_id: v.letter_type_id,
            letter_classification_id: v.letter_classification_id,
            letter_priority_id: v.letter_priority_id,
            letter_confidentiality_id: v.letter_confidentiality_id,
            archive_location_id: v.archive_location_id,
            status: status,
            receiver: v.receiver,
            action_id: v.action_id,
            action_description: v.action_description,
            attachment_file: attachmentFile,
            updated_by: user.id
        }
        ApiService.update(`disposition-mail`, id, data)
            .then((res) => {
                message.success('mail saved', 2);
                setTimeout(() => {
                    history.push('/surat-disposisi')
                }, 1000);
                setSaving(false)
            }).catch((err) => {
                message.warning(err.message, 2)
            })
    }

    useEffect(() => {
        fetchOutgoingMail(id, form)
        fetchArchiveLocation()
        fetchDepartment()
        fetchLetterClassification()
        fetchLetterPriority()
        fetchLetterType()
        fetchReceiver()
        fetchActionDisposition()
        fetchAttachment(id)
    }, [id, form]); // eslint-disable-line react-hooks/exhaustive-deps

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Name',
            render: (row) => (
                row?.user ? row?.user : row?.to_receiver
            ),
            key: 'name'
        },
        {
            title: 'Departemen',
            dataIndex: 'department',
            key: 'department'
        },
        {
            title: 'Approver',
            render: (row) => (
                row?.approver === 0 ? <Tag color={"blue"}>Mengetahui</Tag> : <Tag color={"green"}>Approver</Tag>
            ),
            key: 'approver'
        },
    ]

    const columnsAttachment = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'File Name',
            render: (row) => (
                row?.filename
            ),
            key: 'name'
        },
    ]

    return (
        <div>
            <PageHeader title="Surat Disposisi" breadcrumbs={[['Surat Disposisi'], ['Edit']]} onBack={() => history.push('/surat-disposisi')} />

            <Card style={{ borderRadius: 15 }} className="content-container">
                <Card>
                    <Form onFinish={onFinish} layout='vertical' form={form}>
                        <div className='mb-4'>
                            <Title style={{ fontSize: 18, fontWeight: 'bold' }}>INFORMASI SURAT</Title>
                            <Text>(<span style={{ fontWeight: 'bold' }}>**</span>) Inputan dengan tanda (<span style={{ color: 'red' }}> * </span>) wajib diisi! Silahkan lengkapi informasi dibawah</Text>
                        </div>

                        <Row justify='space-between'>
                            <Col span={11}>
                                <Form.Item label="Pengirim" name="from" rules={[{ required: true }]}>
                                    <Input placeholder="Pengirim" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item label="Perihal" name="subject" rules={[{ required: true }]}>
                                    <Input placeholder='Perihal' />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row justify='space-between'>
                            <Col span={11}>
                                <Form.Item label="No Surat" name="letter_number" rules={[{ required: true }]}>
                                    <Input placeholder='No Surat' />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item label="Tanggal Surat" name="letter_date" rules={[{ required: true }]}>
                                    <DatePicker placeholder="Tanggal" style={{ width: 525 }} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={11}>
                                <Form.Item label="Total Lampiran" name="total_attachment">
                                    <Input placeholder='Total Lampiran' />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row justify='space-between'>
                            <Col span={11}>
                                <Form.Item label="Jenis Surat" name="letter_type_id" rules={[{ required: true }]}>
                                    <Select placeholder="Jenis Surat">
                                        {
                                            letterType.map((el, key) => (
                                                <Option key={key} value={el.id}>{el.name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={11}>
                                <Form.Item label="Klasifikasi Surat" name="letter_classification_id" rules={[{ required: true }]}>
                                    <Select placeholder="Klasifikasi Surat">
                                        {
                                            letterClassification.map((el, key) => (
                                                <Option key={key} value={el.id}>{el.name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row justify='space-between'>
                            {/* <Col span={11}>
                                <Form.Item label="Sifat Surat" name="letter_confidentiality_id" rules={[{ required: true }]}>
                                    <Select placeholder="Sifat Surat">
                                        {
                                            letterConfidentiality.map((el, key) => (
                                                <Option key={key} value={el.id}>{el.name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col> */}

                            <Col span={11}>
                                <Form.Item label="Prioritas Surat" name="letter_priority_id" rules={[{ required: true }]}>
                                    <Select placeholder="Prioritas Surat">
                                        {
                                            letterPriority.map((el, key) => (
                                                <Option key={key} value={el.id}>{el.name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={11}>
                                <Form.Item label="Lokasi Arsip Fisik" name="archive_location_id" rules={[{ required: true }]}>
                                    <Select placeholder="Lokasi Arsip Fisik">
                                        {
                                            archiveLocation.map((el, key) => (
                                                <Option key={key} value={el.id}>{el.name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Card bodyStyle={{ border: '0.7px solid #d7d7d7', borderRadius: 10, marginBottom: 15 }}>
                            <Title style={{ fontSize: 18, fontWeight: 'bold' }}>LIST PENERIMA SURAT</Title>
                            <div className='mb-3'>
                                <DataTable columns={columns} sources={listReceiver} />

                                {
                                    listReceiver.length !== 0 ?
                                        <Button className="mt-3" onClick={() => {
                                            history.push('/surat-disposisi/update-receiver/' + id)
                                        }}>Edit Penerima </Button> :
                                        <Button disabled className="mt-3">Edit Penerima </Button>
                                }

                            </div>
                        </Card>

                        {/* <Card bodyStyle={{ border: '0.7px solid #d7d7d7', borderRadius: 10, marginBottom: 15 }}>
                            <Title style={{ fontSize: 18, fontWeight: 700 }}>DITUJUKAN KEPADA</Title>
                            <div style={{ marginBottom: 15 }}>
                                {
                                    <Text>(<b>*</b>) Pilih opsi surat untuk menentukan penerima atau disposisi</Text>

                                }
                            </div>
                            <Form.List name="receiver">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, ...restField }) => (
                                            <Space key={key} style={{ display: 'flex' }} align="baseline">
                                                <Form.Item
                                                    {...restField}
                                                    label="Department"
                                                    name={[name, 'department_id']}
                                                    rules={[{ required: true, message: 'Missing Receiver' }]}
                                                    style={{ width: 300 }}
                                                >
                                                    <Select placeholder="Department">
                                                        {
                                                            department.map((el, key) => (
                                                                <Option key={key} value={el.id}>{el.name}</Option>
                                                            ))
                                                        }
                                                    </Select>
                                                </Form.Item>

                                                <Form.Item
                                                    {...restField}
                                                    label="Penerima"
                                                    name={[name, 'user_id']}
                                                    rules={[{ required: true, message: 'Missing Receiver' }]}
                                                    style={{ width: 300 }}
                                                >
                                                    <Select placeholder="Penerima">
                                                        {
                                                            receiver.map((el, key) => (
                                                                <Option key={key} value={el.id}>{el.name}</Option>
                                                            ))
                                                        }
                                                    </Select>
                                                </Form.Item>

                                                <Form.Item
                                                    {...restField}
                                                    label="Berkas Fisik"
                                                    name={[name, 'physical_file']}
                                                    rules={[{ required: true, message: 'Missing Physical File' }]}
                                                >
                                                    <Select placeholder="Berkas Fisik">
                                                        <Option key='file_true' value={1}>Ya</Option>
                                                        <Option key='file_false' value={0}>Tidak</Option>
                                                    </Select>
                                                </Form.Item>

                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'approver']}
                                                    label="Approved"
                                                    rules={[{ required: true, message: 'Missing Level' }]}
                                                >
                                                    <Select placeholder="Approved">
                                                        <Option key='0' value={0}>Mengetahui</Option>
                                                        <Option key='1' value={1}>Approver</Option>
                                                    </Select>
                                                </Form.Item>
                                                <DeleteFilled style={{ color: "#f44336", fontSize: 18, padding: "65px 0 0 10px" }} onClick={() => remove(name)} />
                                            </Space>
                                        ))}
                                        <Form.Item>
                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                Tambah Penerima
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Card> */}

                        <Form.Item label="Arahan" name="action_id" rules={[{ required: true }]}>
                            <Select placeholder="Arahan">
                                {
                                    actionDisposition.map((el, key) => (
                                        <Option key={key} value={el.id}>{el.name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label="Keterangan" name="action_description" rules={[{ required: true }]}>
                            <TextArea placeholder='Keterangan' />
                        </Form.Item>

                        <Card bodyStyle={{ border: '0.7px solid #d7d7d7', borderRadius: 10, marginBottom: 15 }}>
                            <div className='mb-3'>
                                <Title style={{ fontSize: 18, fontWeight: 'bold' }}>LIST ATTACHMENT</Title>
                                {
                                    listAttachment.length > 0 ?
                                        <>
                                            <DataTable columns={columnsAttachment} sources={listAttachment} />
                                            <Button className="mt-3" onClick={() => {
                                                history.push('/surat-disposisi/update-attachment/' + id)
                                            }}>Edit Attachment </Button>
                                        </>
                                        :
                                        <>
                                            <div className='mb-5'>
                                                <Button disabled className="mt-3" onClick={() => {
                                                    history.push('/surat-disposisi/update-attachment/' + id)
                                                }}>Edit Attachment </Button>
                                            </div>
                                            <div className='mb-3' >
                                                <Text style={{ fontSize: 18, fontWeight: 'bold' }}>UNGGAH BERKAS</Text><br />
                                                <Text>Format file yang diperbolehkan: jpg | jpeg | png | pdf | doc | xls</Text><br />
                                                <Text>(<span style={{ fontWeight: 'bold' }}>**</span>) max 20 MB</Text>
                                            </div>

                                            <Form.Item>
                                                <FileUpload path='file-attachment' multiple={true} dragger onUploaded={(v, key) => fileUploaded(v)} isUploading={(v) => setUploading(v)} />
                                            </Form.Item>
                                        </>
                                }
                            </div>
                        </Card>

                        <Form.Item className='mt-4'>
                            <Row>
                                <Col span={5}>
                                    <Button loading={saving || uploading} type="primary" name="status" onClick={() => setStatus("sent")} style={{ width: '80%', backgroundColor: '#63a67a', border: 'none', borderRadius: 10 }} htmlType="submit">
                                        Simpan dan Kirim
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Item>
                    </Form>
                </Card>
            </Card>
        </div >
    );
}

export default UpdateSuratDisposisi
