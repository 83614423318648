import React, { useState, useEffect } from 'react'
import { Typography, Card, Row, Col, message, Avatar, Tag, Button, Tabs } from 'antd';
import { Mail, Send } from 'react-feather';
import { DataTable, PageHeader, Spinner, DetailModal, TextButton } from '../../components'
import StatCard from '../../components/shared/StatCard';
import { ApiService } from '../../services/ApiService';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/id';
import { currentUser } from '../../utils/authData'

const { TabPane } = Tabs;
const { Text } = Typography;

const defaultTab = (sessionStorage.getItem('selectedDashboardTab')) ? sessionStorage.getItem('selectedDashboardTab') : 'sent'

const DokumenKeluar = () => {
    const history = useHistory()
    const [loaded, setLoaded] = useState(true)
    const [outgoingMail, setOutgoingMail] = useState([])
    const [draftOutgoingMail, setDraftOutgoingMail] = useState([])
    const [outgoingMailById, setOutgoingMailById] = useState([])
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [modalTitle, setModalTitle] = useState(null)
    const user = currentUser

    const fetchOutgoingMail = () => {
        setLoaded(false);
        const data = {
            user_id: user.id
        }

        ApiService.getList('outgoing-mail', data).then((res) => {
            setOutgoingMail(res.data)
            setLoaded(true)
        }).catch((err) => {
        })
    }

    const fetchDraftOutgoingMail = () => {
        setLoaded(false);
        const data = {
            user_id: user.id
        }

        ApiService.getList('draft-outgoing', data).then((res) => {
            setDraftOutgoingMail(res.data)
            setLoaded(true)
        }).catch((err) => {
        })
    }

    const detail = (v) => {
        setIsModalVisible(true)
        setModalTitle('Detail Dokumen')
        setOutgoingMailById(v)
    }

    const destroy = (id) => {
        ApiService.destroy('outgoing-mail', id).then((res) => {
            fetchOutgoingMail()
            fetchDraftOutgoingMail()
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    useEffect(() => {
        fetchOutgoingMail()
        fetchDraftOutgoingMail()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const columnsSent = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '3%'
        },
        {
            title: 'Tipe Dokumen',
            render: (row) => (
                row?.letter_type

            ),
            key: 'letter_type',
            align: 'center'
        },
        {
            title: 'Tanggal Dokumen',
            render: (row) => (
                moment(row?.date).locale('id').format('dddd, D MMMM YYYY')
            ),
            key: 'date',
            align: 'center'
        },
        {
            title: 'Nomor Dokumen',
            render: (row) => (
                row?.letter_number
            ),
            key: 'letter_number',
            align: 'center'
        },
        {
            title: 'Subjek',
            render: (row) => (
                row?.subject
            ),
            key: 'subject',
            align: 'center'
        },
        {
            title: 'Penerima',
            render: (row) => (
                row?.receiver_user ? row?.receiver_user : row?.receiver_eksternal
            ),
            key: 'sender',
            align: 'center'
        },
        {
            title: 'Status Pengiriman',
            render: (row) => (
                <Tag color={row?.status === 0 ? "blue" : "green"}>{(row?.status === 0) ? 'Draft' : 'Terkirim'}</Tag>
            ),
            key: 'sending_status',
            align: 'center'
        },
        {
            title: 'Status Dokumen',
            render: (row) => (
                row?.type === 'DISP' || row?.type === 3 ?
                    row?.response_id === 0 || row?.response_id === null ?
                        <Tag color={"blue"}>Menunggu</Tag>
                        :
                        <Tag color={"green"}>Sudah Direspon</Tag>
                    :
                    <Tag color={"blue"}>Sudah Diterima</Tag>
            ),
            key: 'sending_status',
            align: 'center'
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Row>
                        <TextButton type={"primary"} shape={"round"} title={"Info"} onClick={() => detail(row)} />
                    </Row>
                    <Row className='mt-2'>
                        <TextButton type={"danger"} shape={"round"} title={"Hapus"} deleted onConfirm={() => destroy(row.id)} />
                    </Row>
                </div>
            ),
            align: 'center'
        },
    ];

    const columnsDraft = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '3%'
        },
        {
            title: 'Tipe Dokumen',
            render: (row) => (
                row?.letter_type

            ),
            key: 'letter_type',
            align: 'center'
        },
        {
            title: 'Tanggal Dokumen',
            render: (row) => (
                moment(row?.date).locale('id').format('dddd, D MMMM YYYY')
            ),
            key: 'date',
            align: 'center'
        },
        {
            title: 'Nomor Dokumen',
            render: (row) => (
                row?.letter_number
            ),
            key: 'letter_number',
            align: 'center'
        },
        {
            title: 'Subjek',
            render: (row) => (
                row?.subject
            ),
            key: 'subject',
            align: 'center'
        },
        {
            title: 'Penerima',
            render: (row) => (
                row?.draft_receiver_user ? row?.draft_receiver_user : row?.draft_receiver_eksternal
            ),
            key: 'sender',
            align: 'center'
        },
        {
            title: 'Status Pengiriman',
            render: (row) => (
                <Tag color={row?.status === 0 ? "blue" : "green"}>{(row?.status === 0) ? 'Draft' : 'Terkirim'}</Tag>
            ),
            key: 'sending_status',
            align: 'center'
        },
        {
            title: 'Status Dokumen',
            render: (row) => (
                row?.type === 'DISP' || row?.type === 3 ?
                    row?.response_id === 0 || row?.response_id === null ?
                        <Tag color={"blue"}>Menunggu</Tag>
                        :
                        <Tag color={"green"}>Sudah Direspon</Tag>
                    :
                    <Tag color={"blue"}>Sudah Diterima</Tag>
            ),
            key: 'sending_status',
            align: 'center'
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Row justify='space-between'>
                        <Col>
                            <TextButton type={"primary"} shape={"round"} title={"Info"} onClick={() => history.push(`/draft-detail/${row.id}`)} />
                        </Col>
                        <Col>
                            <TextButton type={"primary"} style={{ background: "#8bc34a", border: 'none', hover: "#add580" }} shape={"round"} title={"Edit"} onClick={() => {
                                history.push(`/surat-keluar/update/${row.id}`)
                            }} />
                        </Col>
                    </Row>
                    <Row className='mt-2' justify='center'>
                        <TextButton type={"danger"} shape={"round"} title={"Hapus"} deleted onConfirm={() => destroy(row.id)} />
                    </Row>
                </div>
            ),
            align: 'center'
        },
    ];

    const changeTab = (selected) => {
        sessionStorage.setItem('selectedDashboardTab', selected)
    }

    return (
        <div>
            <PageHeader title="Dokumen Keluar" breadcrumbs={[['Dokumen Keluar']]} createLink={'/surat-keluar/create'} />

            <Row justify='space-between'>
                <Col span={8}>
                    <StatCard
                        type="fill"
                        title="Total Dokumen Keluar"
                        value={outgoingMail.length}
                        icon={<Send size={30} strokeWidth={1} />}
                        color={"#07c3db"}
                    />
                </Col>
                <Col span={8}>
                    <StatCard
                        type="fill"
                        title="Total Draft"
                        value={draftOutgoingMail.length}
                        icon={<Send size={30} strokeWidth={1} />}
                        color={"#a957e0"}
                    />
                </Col>
                <Col span={4}>
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <Card style={{ borderRadius: 10 }}>
                        <Tabs defaultActiveKey={defaultTab} onChange={changeTab}>
                            <TabPane tab="Terkirim" key="sent">
                                {
                                    (loaded) ?
                                        <DataTable size="small" columns={columnsSent} sources={outgoingMail} bordered />
                                        : <Spinner />
                                }
                            </TabPane>
                            <TabPane tab="Draft" key="draft">
                                {
                                    (loaded) ?
                                        <DataTable size="small" columns={columnsDraft} sources={draftOutgoingMail} bordered />
                                        : <Spinner />
                                }
                            </TabPane>
                        </Tabs>
                    </Card>
                </Col>
            </Row>

            {
                isModalVisible ?
                    <DetailModal title={modalTitle} showModal={isModalVisible} onCloseModal={() => setIsModalVisible(false)}>
                        <Row style={{ marginBottom: 10 }}>
                            <Col span={24} align="end">
                                <Text>
                                    {moment(outgoingMailById.date).format('dddd, D MMMM YYYY')}
                                </Text>
                            </Col>
                        </Row>
                        <Row className='mb-3'>
                            <Col style={{ alignItems: 'center', justifyContent: 'center', marginTop: 8 }} span={4}>
                                <Avatar shape='square' size={50}><Mail style={{ marginTop: '-5px' }} /></Avatar>
                            </Col>
                            <Col span={14}>
                                <Text style={{ fontSize: 18, fontWeight: 'bold' }}>Perihal: {outgoingMailById.subject}</Text><br />
                                <Text style={{ fontSize: 12 }}><b>Penerima:</b> {outgoingMailById.receiver_user ?? outgoingMailById.receiver_eksternal}</Text><br />
                                <Text style={{ fontSize: 12 }}><b>No Dokumen:</b> {outgoingMailById.letter_number}</Text>
                            </Col>
                            <Col>
                                <Button type="primary" className="button-primary" style={{ marginTop: 20, marginLeft: 12, borderRadius: 10 }} onClick={() => history.push(`/surat-keluar-detail/${outgoingMailById.id}`)}>Lihat Dokumen</Button>
                            </Col>
                        </Row>
                    </DetailModal> : null
            }
        </div>
    )
}

export default DokumenKeluar
