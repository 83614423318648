import { Row, Col, Select, DatePicker, Button, Input } from 'antd';
import { Search } from 'react-feather';

const { RangePicker } = DatePicker
const Option = Select.Option

export const Filter = () => {
    return (
        <div style={{ marginTop: '10px' }}>
            <Row gutter={16}>
                <Col className="gutter-row" span={3}>
                    <Select defaultValue="This Month" md={10}>
                        <Option value="This Month">This Month</Option>
                        <Option value="Last Month">Last Month</Option>
                        <Option value="Today">Today</Option>
                        <Option value="Yesterday">Yesterday</Option>
                        <Option value="This Week">This Week</Option>
                        <Option value="Last Week">Last Week</Option>
                        <Option value="This Year">This Year</Option>
                        <Option value="Last Year">Last Year</Option>
                    </Select>
                </Col>
                <Col className="gutter-row" span={6}>
                    <RangePicker label="Date Range"/>
                </Col>
                <Col className="gutter-row" span={12}>
                    <Input prefix={
                        <Search
                            size={16}
                            strokeWidth={1}
                            style={{ color: 'rgba(0,0,0,.25)' }}
                        />
                    } placeholder="Search" />
                </Col>
                <Col className="gutter-row" span={3}>
                    <Button icon={
                            <Search
                                size={16}
                                strokeWidth={1}
                            />
                        } type="primary">
                        Apply
                    </Button>
                </Col>
            </Row>
        </div>
    );
};