import { useState, useEffect } from 'react'
import { Row, Col, Card, message, Image, Button, Tag } from 'antd'
import { FilePdfOutlined } from '@ant-design/icons'
import { PageHeader, Spinner, DataTable } from '../../components'
import { useHistory, useParams } from 'react-router-dom'
import { ApiService } from '../../services/ApiService'
import moment from 'moment'

const PembuatanSuratDisposisi = () => {
    const history = useHistory()
    const { id } = useParams()
    const [dispositionMailById, setDispositionMailById] = useState([])
    const [receiver, setReceiver] = useState([])
    const [attachmentFile, setAttachmentFile] = useState([])
    // eslint-disable-next-line
    const [checkAttachment, setCheckAttachment] = useState([])
    const [loaded, setLoaded] = useState(true)

    const fetchIncomingMailById = () => {
        setLoaded(false);
        ApiService.getObject('disposition-mail-create', id).then((res) => {
            setDispositionMailById(res.data.disposition_mail[0])
            setReceiver(res.data.receiver)
            setLoaded(true)
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const fetchAttachmentFile = () => {
        ApiService.getObject('letter-attachment', id).then((res) => {
            setAttachmentFile(`${process.env.REACT_APP_FILE_URL}/${res.data[0].filename}`)
            setCheckAttachment(res.data[0])
        }).catch((err) => {
        })
    }

    useEffect(() => {
        fetchIncomingMailById()
        fetchAttachmentFile()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const columns = [
        {
            title: 'Nama',
            render: (row) => (
                row?.user

            ),
            key: 'name',
            align: 'center'
        },
        {
            title: 'Departemen',
            render: (row) => (
                row?.department

            ),
            key: 'department',
            align: 'center'
        },
        {
            title: 'Respon Disposisi',
            render: (row) => (
                row?.response_disposition ? row?.response_disposition : '-'

            ),
            key: 'response_disposition',
            align: 'center'
        },
        {
            title: 'Keterangan',
            render: (row) => (
                row?.response_description ? row?.response_description : '-'

            ),
            key: 'description',
            align: 'center'
        },
        {
            title: 'Status Disetujui',
            render: (row) => (
                row?.status_approver === 0 ? <Tag color={"blue"}>Menunggu</Tag> : row?.status_approver === 1 ? <Tag color={"green"}>Disetujui</Tag> : <Tag color={"red"}>Ditolak</Tag>
            ),
            key: 'status_approver',
            align: 'center'
        },
    ];


    const columnsAttachment = [
        {
            title: 'Lampiran',
            render: (row) => (
                row?.filename.split('.')[1] === 'pdf' ?
                    <a href={`${process.env.REACT_APP_FILE_URL}/${row.filename}`} target="_blank" rel="noreferrer">
                        {`${process.env.REACT_APP_FILE_URL}/${row.filename}`}
                    </a>
                    :
                    <Image src={`${process.env.REACT_APP_FILE_URL}/${row.filename}`} width={200} />
            ),
            key: 'attachment_file',
            align: 'center'
        }
    ];

    const exportReport = () => {
        window.open(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/disposition-mail-pdf/${id}`);
    }

    return (
        <div>
            <PageHeader title={`Dokumen Masuk Disposisi #${id}`} breadcrumbs={[['Dokumen Masuk Disposisi'], ['Detail']]} onBack={() => history.push('/surat-disposisi')} />

            <Card title="Keterangan Surat" style={{ borderRadius: 10 }}>
                <Row className='mb-5' justify='space-between'>
                    <Col span={15}>
                        {
                            loaded ?
                                <table className="table-collapse table-default" style={{ width: '100%' }}>
                                    <tbody>
                                        <tr>
                                            <th>Nomor Surat</th><td>{dispositionMailById.letter_number}</td>
                                        </tr>
                                        <tr>
                                            <th>Pengirim</th><td>{dispositionMailById.from}</td>
                                        </tr>
                                        <tr>
                                            <th>Subjek</th><td>{dispositionMailById.subject}</td>
                                        </tr>
                                        <tr>
                                            <th>Tanggal Surat</th><td>{moment(dispositionMailById.date).format('dddd, DD MMMM YYYY')}</td>
                                        </tr>
                                        <tr>
                                            <th>Tipe Surat</th><td>{dispositionMailById.letter_type}</td>
                                        </tr>
                                        <tr>
                                            <th>Arsip Surat</th><td>{dispositionMailById.archive_location}</td>
                                        </tr>
                                        <tr>
                                            <th>Arahan</th><td>{dispositionMailById.action_disposition ? dispositionMailById.action_disposition : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th>Keterangan Arahan</th><td>{dispositionMailById.action_description ? dispositionMailById.action_description : '-'}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                : <Spinner />
                        }
                    </Col>
                    <Col span={8}>
                        <Button onClick={exportReport} type='primary' size='large' shape='round' icon={<FilePdfOutlined />}>Download Surat</Button>
                    </Col>
                </Row>

                <Card title="Lampiran">
                    {
                        <DataTable size="small" columns={columnsAttachment} sources={attachmentFile} bordered />
                    }
                </Card>

                {
                    (loaded) ?
                        <DataTable size="small" columns={columns} sources={receiver} bordered />
                        : <Spinner />
                }

            </Card>
        </div >
    )
}

export default PembuatanSuratDisposisi