import { useState, useEffect } from 'react';
import { Card, Col, Row, Space, message, Avatar, Typography, Tag, Button, Badge } from 'antd';
import { Mail } from 'react-feather';
import { DataTable, ShowButton, Spinner, DetailModal, PageHeader } from '../../components'
import ProfileBox from './Profile';
import DataSurat from './DataSurat';
import { ApiService } from '../../services/ApiService';
import moment from 'moment';
import { currentUser } from '../../utils/authData';
import { useHistory } from 'react-router-dom';
import 'moment/locale/id';

const { Text } = Typography

const Beranda = () => {
    const history = useHistory()
    const [loaded, setLoaded] = useState(true)
    const [incomingMailLatest, setIncomingMailLatest] = useState([])
    const [isModalVisible, setIsModalVisible] = useState(false)
    // eslint-disable-next-line
    const [isRead, setIsRead] = useState([])
    // eslint-disable-next-line
    const [letterLength, setLetterLength] = useState(null)
    const [modalTitle, setModalTitle] = useState(null)
    const [incomingMailById, setIncomingMailById] = useState(null)
    const user = currentUser

    const fetchIncomingMailLatest = () => {
        setLoaded(false)
        ApiService.getList('incoming-document-latest').then((res) => {
            setIncomingMailLatest(res.data)
            setLoaded(true)
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const fetchLetterNotRead = () => {
        const data = {
            user_id: user.id
        }
        ApiService.getList('incoming-mail-not-read', data).then((res) => {
            setLetterLength(res.data.length)
            // if (res.data.length !== 0) {
            //     toast.info(`Kamu mempunyai ${res.data.length} Dokumen Masuk baru`)
            // }
        }).catch((err) => {
        })
    }

    const updateRead = (v) => {
        setModalTitle('Detail Incoming Document')
        setIsModalVisible(true)
        setIncomingMailById(v)
        const data = {
            user_id: user.id
        }

        ApiService.update('update-read', v.id, 0, data).then((res) => {
            setIsRead(1)
        }).catch((err) => {
        })
    }

    useEffect(() => {
        fetchIncomingMailLatest()
        fetchLetterNotRead()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '3%'
        },
        {
            title: 'Document Type',
            render: (row) => (
                row.is_read === 0 ?
                    <Badge dot>
                        {row?.letter.letter_type?.letter_type_name}
                    </Badge> :
                    row?.letter.letter_type?.letter_type_name
            ),
            key: 'letter_type',
            align: 'center',
        },
        {
            title: 'Document Date',
            render: (row) => (
                moment(row?.letter.date).locale('id').format('dddd, D MMMM YYYY')
            ),
            key: 'date',
            align: 'center',
            defaultSortOrder: 'descend',
        },
        {
            title: 'Document Number',
            render: (row) => (
                row?.letter.letter_number
            ),
            key: 'letter_number',
            align: 'center'
        },
        {
            title: 'Subject',
            render: (row) => (
                row?.letter.subject ? row?.letter.subject : row?.letter.letter_subject
            ),
            key: 'subject',
            align: 'center'
        },
        {
            title: 'Sender',
            render: (row) => (
                <Tag>
                    {row?.letter?.created?.name}
                </Tag>
            ),
            key: 'sender',
            align: 'center'
        },
        {
            title: 'Type',
            render: (row) => (
                row?.letter.type === 'DISP' ? <Tag color="green">Disposisi</Tag> : <Tag color="blue">Bukan Disposisi</Tag>
            ),
            key: 'letter_type',
            align: 'center'
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <ShowButton onShow={() => updateRead(row)} />
                    </Space>
                </div>
            ),
            align: 'center'
        },
    ];

    return (
        <>
            {/* {
                letterLength !== 0 || !letterLength ?
                    <ToastContainer position="top-center" autoClose={1500} />
                    :
                    null
            } */}

            <div>
                <PageHeader breadcrumbs={[]} />

                <Row justify='space-between'>
                    <Col span={7}>
                        <ProfileBox />
                    </Col>

                    <Col span={16}>
                        <DataSurat />
                    </Col>
                </Row>

                <Card title="Dokumen Masuk Terbaru" style={{ borderRadius: 10 }}>
                    <div>
                        {
                            (loaded) ?
                                <DataTable size="small" columns={columns} sources={incomingMailLatest} bordered />
                                : <Spinner />
                        }
                    </div>

                    {
                        isModalVisible && incomingMailById ?
                            <DetailModal title={modalTitle} showModal={isModalVisible} onCloseModal={() => setIsModalVisible(false)}>
                                <Row style={{ marginBottom: 10 }}>
                                    <Col span={24} align="end">
                                        <Text>
                                            {moment(incomingMailById.date).format('dddd, D MMMM YYYY')}
                                        </Text>
                                    </Col>
                                </Row>
                                <Row className='mb-3'>
                                    <Col style={{ alignItems: 'center', justifyContent: 'center', marginTop: 8 }} span={4}>
                                        <Avatar shape='square' size={50}><Mail style={{ marginTop: '-5px' }} /></Avatar>
                                    </Col>
                                    <Col span={14}>
                                        <Text style={{ fontSize: 18, fontWeight: 'bold' }}><b>Subject:</b> {incomingMailById.letter.subject}</Text><br />
                                        <Text style={{ fontSize: 12 }}><b>Sender:</b> {incomingMailById.letter.from_user}</Text><br />
                                        <Text style={{ fontSize: 12 }}><b>Document Number:</b> {incomingMailById.letter.letter_number}</Text>
                                    </Col>
                                    <Col>
                                        <Button type="primary" className="button-primary" style={{ marginTop: 20, marginLeft: 12, borderRadius: 10 }} onClick={() => history.push(`/incoming-document/${incomingMailById.letter.id}`)}>Show Document</Button>
                                    </Col>
                                </Row>
                                {
                                    incomingMailById.type !== 'DISP' ?
                                        <Tag color="blue">Not Disposition</Tag>
                                        :
                                        <>
                                            <Row>
                                                <Text style={{ fontSize: 14 }}><b>Action Disposition:</b> {incomingMailById.action_disposition ?? '-'}</Text>
                                            </Row>
                                            <Row>
                                                <Text style={{ fontSize: 14 }}><b>Action Description:</b> {incomingMailById.action_description ?? '-'}</Text>
                                            </Row>
                                        </>
                                }
                            </DetailModal>
                            :
                            null
                    }
                </Card>
            </div >
        </>
    );
};

export default Beranda;
