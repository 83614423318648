import { useState, useEffect } from 'react';
import { Avatar, Badge, Card, Col, Row, Space, Tabs, Tag, Typography, Button, message } from 'antd';
import { Mail, /*Send*/ } from 'react-feather';
import { DataTable, DetailModal, PageHeader, Spinner, TextButton, TextButtonDanger } from '../../components';
import StatCard from '../../components/shared/StatCard';
import { ApiService } from '../../services/ApiService';
import { currentUser, role } from '../../utils/authData';
import moment from 'moment';
import 'moment/locale/id';
import { useHistory } from 'react-router-dom';

const { Text } = Typography
const { TabPane } = Tabs;
const defaultTab = (sessionStorage.getItem('selectedDashboardTab')) ? sessionStorage.getItem('selectedDashboardTab') : 'receive'

const IncomingDocument = () => {
    const user = currentUser
    const history = useHistory()
    const [incomingDocument, setIncomingDocument] = useState([])
    const [receiveDocument, setReceiveDocument] = useState([])
    const [receiveDocumentList, setReceiveDocumentList] = useState([])
    const [incomingModalVisible, setIncomingModalVisible] = useState(false)
    const [showModalReceive, setShowModalReceive] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [incomingDocumentInfo, setIncomingDocumentInfo] = useState(0)
    const [incomingModalInfoTitle, setIncomingModalInfoTitle] = useState('')
    const [receiveModalTitle, setReceiveModalTitle] = useState('')
    // const [dispositionMail, setDispositionMail] = useState([])

    const fetchIncomingDocument = () => {
        ApiService.getList('incoming-document').then((res) => {
            setIncomingDocument(res.data)
            setLoaded(true)
        })
    }

    const fetchReceiveDocument = () => {
        ApiService.getList('receiver-document').then((res) => {
            setReceiveDocumentList(res.data)
            setLoaded(true)
        })
    }

    // const fetchDispositionMail = () => {
    //     setLoaded(false)
    //     const data = {
    //         user_id: user.id
    //     }

    //     ApiService.getList('disposition-mail-receive', data).then((res) => {
    //         setDispositionMail(res.data)
    //         setLoaded(true)
    //     }).catch((err) => {
    //     })
    // }

    useEffect(() => {
        fetchIncomingDocument()
        fetchReceiveDocument()
        // fetchDispositionMail()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const changeTab = (selected) => {
        sessionStorage.setItem('selectedDashboardTab', selected)
    }

    const incomingColumns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '3%'
        },
        {
            title: 'Document Type',
            render: (row) => (
                row?.letter_type?.letter_type_name

            ),
            key: 'letter_type',
            align: 'center'
        },
        {
            title: 'Document Date',
            render: (row) => (
                moment(row?.date).locale('id').format('dddd, D MMMM YYYY')
            ),
            key: 'date',
            align: 'center',
        },
        {
            title: 'Document Number',
            render: (row) => (
                row?.letter_number
            ),
            key: 'letter_number',
            align: 'center'
        },
        {
            title: 'Subject',
            render: (row) => (
                row?.subject ? row?.subject : row?.letter_subject
            ),
            key: 'subject',
            align: 'center'
        },
        {
            title: 'Receiver',
            render: (row) => (
                row?.letter_movement?.map((el, key) => (
                    el.receiver_eksternal == null ?
                        <Tag>{el?.receiver_user?.name}</Tag> : <Tag>{el?.receiver_eksternal}</Tag>
                ))
            ),
            key: 'receiver',
            align: 'center'
        },
        {
            title: 'Sending Status',
            render: (row) => (
                <Tag color={row?.status === 0 ? "blue" : "green"}>{(row?.status === 0) ? 'Draft' : 'Terkirim'}</Tag>
            ),
            key: 'sending_status',
            align: 'center'
        },
        {
            title: 'Document Type',
            render: (row) => (
                row?.type === "DISP" ? <Tag color="green">Disposisi</Tag> : <Tag color="blue">Bukan Disposisi</Tag>
            ),
            key: 'letter_type',
            align: 'center'
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Row justify='space-between'>
                        <Col>
                            <TextButton type={"primary"} shape={"round"} title={"Info"} onClick={() => showIncoming(row)} />
                        </Col>
                        <Col>
                            <TextButton type={"primary"} style={{ background: "#8bc34a", border: 'none', hover: "#add580" }} onClick={() => {
                                history.push(`/surat-masuk/update/${row.id}`)
                            }} shape={"round"} title={"Edit"} />
                        </Col>
                    </Row>
                    <Row className='mt-2' justify='center'>
                        <TextButtonDanger type={"primary"} shape={"round"} title={"Delete"} deleted onConfirm={() => destroyIncoming(row.id)} />
                    </Row>
                </div>
            )
        },
    ];

    const columnsReceive = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '3%'
        },
        {
            title: 'Document Type',
            render: (row) => (
                row.is_read === 0 ?
                    <Badge dot>
                        {row?.letter.letter_type?.letter_type_name}
                    </Badge> :
                    row?.letter.letter_type?.letter_type_name
            ),
            key: 'letter_type',
            align: 'center',
        },
        {
            title: 'Document Date',
            render: (row) => (
                moment(row?.letter.date).locale('id').format('dddd, D MMMM YYYY')
            ),
            key: 'date',
            align: 'center',
            defaultSortOrder: 'descend',
        },
        {
            title: 'Document Number',
            render: (row) => (
                row?.letter.letter_number
            ),
            key: 'letter_number',
            align: 'center'
        },
        {
            title: 'Subject',
            render: (row) => (
                row?.letter.subject ? row?.letter.subject : row?.letter.letter_subject
            ),
            key: 'subject',
            align: 'center'
        },
        {
            title: 'Sender',
            render: (row) => (
                <Tag>
                    {row?.letter?.created?.name}
                </Tag>
            ),
            key: 'sender',
            align: 'center'
        },
        {
            title: 'Type',
            render: (row) => (
                row?.letter.type === 'DISP' ? <Tag color="green">Disposisi</Tag> : <Tag color="blue">Bukan Disposisi</Tag>
            ),
            key: 'letter_type',
            align: 'center'
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <TextButton type={"primary"} shape={"round"} title={"Info"} onClick={() => updateRead(row)} />
                        <TextButtonDanger type={"primary"} shape={"round"} title={"Delete"} deleted onConfirm={() => destroyReceive(row.id)} />
                    </Space>
                </div>
            ),
            align: 'center'
        },
    ];

    const showIncoming = (v) => {
        setIncomingModalInfoTitle('Document Incoming Info')
        setIncomingModalVisible(true)
        setIncomingDocumentInfo(v)
    }

    const destroyIncoming = (id) => {
        ApiService.destroy('incoming-mail-create', id).then((res) => {
            fetchIncomingDocument()
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const updateRead = (v) => {
        setReceiveModalTitle('Detail Dokumen Masuk')
        setShowModalReceive(true)
        setReceiveDocument(v)
        const data = {
            user_id: user.id
        }

        ApiService.update('update-read', v.id, 0, data).then((res) => {
            fetchReceiveDocument()
        }).catch((err) => {
        })
    }

    const destroyReceive = (id) => {
        setLoaded(false)
        ApiService.destroy('incoming-mail-receive', id).then((res) => {
            fetchReceiveDocument()
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    return (
        <div>
            {
                role.includes("SUPERADMIN", "ADMIN") ?
                    <PageHeader title="Incoming Document" breadcrumbs={[['Incoming Document']]} createLink={'/create-incoming-document/0'} />
                    :
                    <PageHeader title="Incoming Document" breadcrumbs={[['Incoming Document']]} />
            }

            <Row justify='space-between'>
                <Col span={role.includes("SUPERADMIN", "ADMIN") ? 7 : 9}>
                    {/* <StatCard
                        type="fill"
                        title="Total History Disposition"
                        value={dispositionMail.length}
                        icon={<Send size={27} strokeWidth={1} />}
                        color={"#f6ac0d"}
                    /> */}
                </Col>
                <Col span={role.includes("SUPERADMIN", "ADMIN") ? 7 : 9}>
                    <StatCard
                        type="fill"
                        title="Total Incoming Document"
                        value={receiveDocumentList.length}
                        icon={<Mail size={27} strokeWidth={1} />}
                        color={"#a957e0"}
                    />
                </Col>
                {
                    role.includes("SUPERADMIN", "ADMIN") ?
                        <Col span={role.includes("SUPERADMIN", "ADMIN") ? 7 : 10}>
                            <StatCard
                                type="fill"
                                title="Total Create Incoming Document"
                                value={incomingDocument.length}
                                icon={<Mail size={27} strokeWidth={1} />}
                                color={"#07c3db"}
                            />
                        </Col> :
                        <Col span={5}></Col>

                }
            </Row>

            <Row>
                <Col span={24}>
                    <Card style={{ borderRadius: 10 }}>
                        {
                            loaded ?
                                <Tabs defaultActiveKey={role.includes("SUPERADMIN", "ADMIN") ? defaultTab : 'receive'} onChange={changeTab}>
                                    {
                                        role.includes("SUPERADMIN", "ADMIN") ?
                                            <TabPane tab="Pembuatan Dokumen Masuk" key="create">
                                                <DataTable size="small" columns={incomingColumns} sources={incomingDocument} bordered />
                                            </TabPane>
                                            : null
                                    }
                                    <TabPane tab="Dokumen Masuk" key="receive">
                                        <DataTable size="small" columns={columnsReceive} sources={receiveDocumentList} bordered />
                                    </TabPane>
                                </Tabs>
                                : <Spinner />
                        }
                    </Card>
                </Col>
            </Row>

            {
                incomingModalVisible && incomingDocumentInfo ?
                    <DetailModal title={incomingModalInfoTitle} showModal={incomingModalVisible} onCloseModal={() => setIncomingModalVisible(false)}>
                        <Row style={{ marginBottom: 10 }}>
                            <Col span={24} align="end">
                                <Text>
                                    {moment(incomingDocumentInfo.date).format('dddd, D MMMM YYYY')}
                                </Text>
                            </Col>
                        </Row>
                        <Row className='mb-3'>
                            <Col style={{ alignItems: 'center', justifyContent: 'center', marginTop: 8 }} span={4}>
                                <Avatar shape='square' size={50}><Mail style={{ marginTop: '-5px' }} /></Avatar>
                            </Col>
                            <Col span={14}>
                                <Text style={{ fontSize: 18, fontWeight: 'bold' }}><b>Subject:</b> {incomingDocumentInfo.subject}</Text><br />
                                <Text style={{ fontSize: 12 }}><b>Sender:</b> {incomingDocumentInfo.from_user}</Text><br />
                                <Text style={{ fontSize: 12 }}><b>Document Number:</b> {incomingDocumentInfo.letter_number}</Text>
                            </Col>
                            <Col>
                                <Button type="primary" className="button-primary" style={{ marginTop: 20, marginLeft: 12, borderRadius: 10 }} onClick={() => history.push(`/incoming-document/${incomingDocumentInfo.id}`)}>Show Document</Button>
                            </Col>
                        </Row>
                        {
                            incomingDocumentInfo.type !== 'DISP' ?
                                <Row>
                                    <Tag color="blue">Not Disposition</Tag>
                                </Row>
                                :
                                <>
                                    <Row>
                                        <Text style={{ fontSize: 14 }}><b>Action Disposition:</b> {incomingDocumentInfo.action_disposition ?? '-'}</Text>
                                    </Row>
                                    <Row>
                                        <Text style={{ fontSize: 14 }}><b>Action Description:</b> {incomingDocumentInfo.action_description ?? '-'}</Text>
                                    </Row>
                                </>
                        }
                    </DetailModal>
                    :
                    null
            }

            {
                showModalReceive && receiveDocument ?
                    <DetailModal title={receiveModalTitle} showModal={showModalReceive} onCloseModal={() => setShowModalReceive(false)}>
                        <Row style={{ marginBottom: 10 }}>
                            <Col span={24} align="end">
                                <Text>
                                    {moment(receiveDocument.date).format('dddd, D MMMM YYYY')}
                                </Text>
                            </Col>
                        </Row>
                        <Row className='mb-3'>
                            <Col style={{ alignItems: 'center', justifyContent: 'center', marginTop: 8 }} span={4}>
                                <Avatar shape='square' size={50}><Mail style={{ marginTop: '-5px' }} /></Avatar>
                            </Col>
                            <Col span={14}>
                                <Text style={{ fontSize: 18, fontWeight: 'bold' }}><b>Subject:</b> {receiveDocument.letter.subject}</Text><br />
                                <Text style={{ fontSize: 12 }}><b>Sender:</b> {receiveDocument.letter.from_user}</Text><br />
                                <Text style={{ fontSize: 12 }}><b>Document Number:</b> {receiveDocument.letter.letter_number}</Text>
                            </Col>
                            <Col>
                                <Button type="primary" className="button-primary" style={{ marginTop: 20, marginLeft: 12, borderRadius: 10 }} onClick={() => history.push(`/incoming-document/${receiveDocument.letter.id}`)}>Show Document</Button>
                            </Col>
                        </Row>
                        {
                            receiveDocument.type !== 'DISP' ?
                                <Tag color="blue">Not Disposition</Tag>
                                :
                                <>
                                    <Row>
                                        <Text style={{ fontSize: 14 }}><b>Action Disposition:</b> {receiveDocument.action_disposition ?? '-'}</Text>
                                    </Row>
                                    <Row>
                                        <Text style={{ fontSize: 14 }}><b>Action Description:</b> {receiveDocument.action_description ?? '-'}</Text>
                                    </Row>
                                </>
                        }
                    </DetailModal>
                    :
                    null
            }
        </div >
    )
}

export default IncomingDocument
