import React, { useState, useEffect } from 'react'
import { Card, Form, Input, Switch, message, Space, Select } from 'antd'
import { EditButton, DeleteButton, DataTable, PageHeader, Spinner, FormModal } from '../../../components'
import { ApiService } from '../../../services/ApiService'

const ArchiveLocation = () => {
    const [loaded, setLoaded] = useState(true)
    const [archiveLocation, setArchiveLocation] = useState([])
    const [employees, setEmployees] = useState([])
    const [modalTitle, setModalTitle] = useState(null)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})
    const [isSaving, setIsSaving] = useState(false)
    const [status, setStatus] = useState(false)

    const fetchArchiveLocation = () => {
        setLoaded(false);
        ApiService.getList('archive-location').then((res) => {
            setArchiveLocation(res.data)
            setLoaded(true)
        }).catch((err) => {
        })
    }

    const fetchEmployee = () => {
        ApiService.getList("employee").then((res) => {
            const v = res.data
            let arr = []
            v.forEach((el) => {
                arr.push({ label: el.name, value: el.id })
            })

            setEmployees(arr)
        })
    }

    const add = () => {
        setModalTitle('Add Archive Location')
        setDefaultValues({
            archive_location_id: 0,
            archive_location_name: '',
            pic: '',
            code: '',
            status: true,
        })
        setIsModalVisible(true)
        setStatus(true)
    }

    const edit = (v) => {
        setModalTitle('Edit Archive Location')
        setDefaultValues({
            archive_location_id: v.archive_location_id,
            archive_location_name: v.archive_location_name,
            pic: parseInt(v.pic),
            code: v.code,
            status: parseInt(v.status),
        })
        setIsModalVisible(true)
        setStatus(v.status)
    }

    const destroy = (id) => {
        ApiService.destroy('archive-location', id).then((res) => {
            message.success(res.message, 2)
            setTimeout(() => {
                fetchArchiveLocation()
            }, 500);
        }).catch((err) => {
            message.warning(err.response.data.message, 2)
        })
    }

    const onFinish = (el) => {
        let payload = {
            archive_location_name: el.archive_location_name.toUpperCase(),
            pic: el.pic,
            code: el.code.toUpperCase(),
            status: status ? 1 : 0
        }
        if (el.archive_location_id === 0) {
            setIsSaving(true)
            ApiService.store(`archive-location`, payload)
                .then((res) => {
                    message.success(res.message, 2)
                    setIsModalVisible(false)
                    setIsSaving(false)
                    fetchArchiveLocation()
                }).catch((err) => {
                    message.warning(err.response.data.message)
                    setIsSaving(false)
                })
        } else {
            setIsSaving(true)
            ApiService.update(`archive-location`, el.archive_location_id, payload)
                .then((res) => {
                    message.success(res.message, 2)
                    setIsModalVisible(false)
                    setIsSaving(false)
                    fetchArchiveLocation()
                }).catch((err) => {
                    message.warning(err.response.data.message)
                    setIsSaving(false)
                })
        }
    }

    useEffect(() => {
        fetchArchiveLocation()
        fetchEmployee()

    }, []);

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Archive Location',
            dataIndex: 'archive_location_name',
            key: 'archive_location_name'
        },
        {
            title: 'PIC',
            render: (row) => (
                row?.user?.name
            ),
            key: 'pic',
            align: 'center'
        },
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code'
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => edit(row)} />
                        <DeleteButton onConfirm={() => destroy(row.archive_location_id)} />
                    </Space>
                </div>
            )
        },
    ];

    return (
        <div>
            <PageHeader title="Archive Location" breadcrumbs={[['Master'], ['Archive Location']]} onAdd={add} />
            <Card style={{ borderRadius: 10 }}>

                {
                    (loaded) ?
                        <DataTable size="small" columns={columns} sources={archiveLocation} bordered />
                        : <Spinner />
                }

                {
                    (isModalVisible) ?
                        <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => onFinish(v)} width={600} showModal={isModalVisible} onCloseModal={() => setIsModalVisible(false)} saving={isSaving}>
                            <Form.Item name="archive_location_id" hidden>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Nama" name="archive_location_name" required rules={[{ required: true }]}>
                                <Input placeholder="Nama" />
                            </Form.Item>
                            <Form.Item label="PIC" name="pic" required rules={[{ required: true }]}>
                                <Select
                                    showSearch
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={employees}
                                />
                            </Form.Item>
                            <Form.Item label="Kode" name="code">
                                <Input placeholder="Kode" />
                            </Form.Item>
                            <Form.Item label="Status" name="status" rules={[{ required: true }]}>
                                <Switch checkedChildren="Active" unCheckedChildren="Non Active" onChange={(v) => setStatus(v)} defaultChecked={defaultValues.status} />
                            </Form.Item>
                        </FormModal> :
                        null
                }
            </Card>
        </div>
    )
}

export default ArchiveLocation
