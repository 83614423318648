import React, { useEffect, useState } from 'react'
import { ApproveButton, DataTable, PageHeader, RejectButton, ShowButton, Spinner } from '../../components'
import { Card, Tabs, Tag, Space, message } from 'antd'
import { CloseCircleTwoTone, CheckCircleTwoTone } from '@ant-design/icons'
import { ApiService } from '../../services/ApiService'
import { useHistory } from 'react-router-dom'

const ManageArchive = () => {
    const history = useHistory()
    const [confirmDoc, setConfirmDoc] = useState([])
    const [notConfirmDoc, setNotConfirmDoc] = useState([])
    const [loanData, setLoanData] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [defaultTab, setDefaultTab] = useState('archive_confirm')

    const fetchDocument = () => {
        setLoaded(false)
        ApiService.getList('document').then((res) => {
            const v = res.data
            let arrConf = []
            let arrNotConf = []

            v.forEach((el) => {
                if (el.archive_confirmation === 'CONFIRM') {
                    arrConf.push(el)
                } else {
                    arrNotConf.push(el)
                }
            })

            setConfirmDoc(arrConf)
            setNotConfirmDoc(arrNotConf)
            setLoaded(true)
        })
    }

    const fetchLoanDocument = () => {
        ApiService.getList("approval-loan").then((res) => {
            setLoanData(res.data)
        })
    }

    useEffect(() => {
        fetchDocument()
        fetchLoanDocument()

    }, [])

    const approveDocument = (v) => {
        ApiService.update('document-status', v, { archive_confirmation: 'CONFIRM' })
            .then((res) => {
                message.success('Success Confirm Document')
                fetchDocument()
            }).catch((err) => {
                message.warning('Failed Confirm')
            })
    }

    const rejectDocument = (v) => {
        ApiService.update('document-status', v, { archive_confirmation: 'NOT CONFIRM' }).then((res) => {
            message.success('Success Reject Document')
            fetchDocument()
        }).catch((err) => {
            message.warning('Failed Reject')
        })
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: 'Original Filename',
            dataIndex: 'original_filename',
            key: 'original_filename',
        },
        {
            title: 'Archive Location',
            render: (row) => (
                row?.archive_location?.archive_location_name ?? '-'
            ),
            key: 'archive_location'
        },
        {
            title: 'Archive Confirm',
            render: (row) => (
                row?.archive_confirmation === 'CONFIRM' ? <CheckCircleTwoTone /> : <CloseCircleTwoTone twoToneColor='red' />
            ),
            key: 'archive_location',
            align: 'center'
        },
        {
            title: 'Availability',
            render: (row) => (
                row?.is_available === 1 ? <Tag color='green'>AVAILABLE</Tag> : <Tag color='red'>NOT AVAILABLE</Tag>
            ),
            key: 'availability',
            align: 'center'
        }
    ]

    if (defaultTab !== 'archive_confirm') {
        columns.push({
            title: 'Action',
            render: (row) => (
                <Space>
                    <ApproveButton onApprove={() => approveDocument(row.id)} />
                    <RejectButton onConfirm={() => rejectDocument(row.id)} />
                </Space>
            ),
            key: 'action',
            align: 'center',
            width: '8%'
        })
    }

    const columnLoans = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Approval ID',
            dataIndex: 'approval_document_status_id',
            key: 'approval_id',
            align: 'center'
        },
        {
            title: 'Title',
            render: (row) => (
                row?.approval_status?.document?.title
            ),
            key: 'title'
        },
        {
            title: 'Original Filename',
            render: (row) => (
                row?.approval_status?.document?.original_filename
            ),
            key: 'original_filename'
        },
        {
            title: 'Status',
            render: (row) => (
                <Tag color={
                    row?.approval_status?.status === null || row?.approval_status?.status === "WAITING" ? "blue"
                        : row?.approval_status?.status === "APPROVED" || row?.approval_status?.status === "FINISHED" ? "green" : "red"
                }>
                    {row?.approval_status?.status}
                </Tag>
            ),
            key: 'status',
            align: 'center'
        },
        {
            title: 'Action',
            render: (row) => (
                <Space>
                    <ShowButton onShow={() => history.push(`/document-loan/detail/${row.approval_document_status_id}`)} />
                </Space>
            ),
            key: 'action',
            align: 'center',
            width: '10%'
        }
    ]

    return (
        <div>
            <PageHeader title='Manage Archive' breadcrumbs={[['Manage Archive']]} />

            <Card style={{ borderRadius: 10 }}>
                <Tabs type='card' defaultActiveKey={defaultTab} onChange={(v) => setDefaultTab(v)}>
                    <Tabs.TabPane tab='Archive Confirm' key='archive_confirm'>
                        {
                            loaded ?
                                <DataTable size='small' columns={columns} sources={confirmDoc} bordered />
                                : <Spinner />
                        }
                    </Tabs.TabPane>
                    <Tabs.TabPane tab='Archive Not Confirm' key='archive_not_confirm'>
                        {
                            loaded ?
                                <DataTable size='small' columns={columns} sources={notConfirmDoc} bordered />
                                : <Spinner />
                        }

                    </Tabs.TabPane>
                    <Tabs.TabPane tab='Document Loan' key='document_loan'>
                        <DataTable size='small' columns={columnLoans} sources={loanData} bordered />
                    </Tabs.TabPane>
                </Tabs>
            </Card>
        </div>
    )
}

export default ManageArchive