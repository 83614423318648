import { useState, useEffect } from 'react'
import { Row, Col, Card, message, Button, Form, Input, Typography, Image, Tabs, Select, Descriptions } from 'antd'
import { DataTable, FormModal, PageHeader, Spinner } from '../../components'
import { useHistory, useParams } from 'react-router-dom'
import { currentUser } from '../../utils/authData'
import { ApiService } from '../../services/ApiService'
import parse from 'html-react-parser'
import moment from 'moment'
import { Docs, Excel, Pdf } from '../../components/Folder'

const { TextArea } = Input;
const { Text, Title } = Typography;
const { TabPane } = Tabs;
const { Option } = Select;
const fileUrl = process.env.REACT_APP_FILE_URL
const defaultTab = (sessionStorage.getItem('selectedDashboardTab')) ? sessionStorage.getItem('selectedDashboardTab') : 'incoming'

const PenerimaSuratMasukDetail = () => {
    const history = useHistory()
    const { id } = useParams()
    const [incomingMailById, setIncomingMailById] = useState([])
    const [attachmentFile, setAttachmentFile] = useState([])
    const [archiveLocation, setArchiveLocation] = useState([])
    const [responseDisposition, setResponseDisposition] = useState([])
    const [actionDisposition, setActionDisposition] = useState([])
    const [listReceiveDisposition, setListReceiveDisposition] = useState([])
    const [department, setDepartment] = useState([])
    const [employee, setEmployee] = useState([])
    const [defaultValues, setDefaultValues] = useState({})
    const [modalTitle, setModalTitle] = useState(null)
    const [isModalDispositionVisible, setIsModalDispositionVisible] = useState(false)
    const [isModalEndVisible, setIsModalEndVisible] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [loaded, setLoaded] = useState(true)
    const user = currentUser

    const fetchIncomingMailById = () => {
        setLoaded(false);
        const data = {
            user_id: user.id
        }
        ApiService.getObject('incoming-mail-receive', id, data).then((res) => {
            setIncomingMailById(res.data[0])
            setLoaded(true)
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const fetchDepartment = () => {
        ApiService.getList('department').then((res) => {
            setDepartment(res.data)
        }).catch((err) => {
        })
    }

    const fetchEmployee = (el) => {
        ApiService.getObject('employee-by-department', el).then((res) => {
            setEmployee(res.data)
        }).catch((err) => {
        })
    }

    const fetchAttachmentFile = () => {
        ApiService.getObject('letter-attachment', id).then((res) => {
            setAttachmentFile(res.data)
        }).catch((err) => {
            message.warning('Surat tidak memiliki lampiran')
        })
    }

    const fetchArchiveLocation = () => {
        ApiService.getList('archive-location').then((res) => {
            setArchiveLocation(res.data)
        }).catch((err) => {
        })
    }

    const fetchResponseDisposition = () => {
        ApiService.getList('response-disposition').then((res) => {
            setResponseDisposition(res.data)
        }).catch((err) => {
        })
    }

    const fetchActionDisposition = () => {
        ApiService.getList('action-disposition').then((res) => {
            setActionDisposition(res.data)
        }).catch((err) => {
        })
    }

    const fetchReceiveDisposition = () => {
        ApiService.getObject('receiver-disposition-mail', id).then((res) => {
            setListReceiveDisposition(res.data)
        }).catch((err) => {
        })
    }

    const changeEmployee = (el) => {
        fetchEmployee(el)
    }

    const setDisposition = () => {
        setModalTitle("Disposisi Dokumen")
        setDefaultValues({
            action_disposition_id: '',
            action_description: '',
        })
        setIsModalDispositionVisible(true)
    }

    const setEndDisposition = () => {
        setModalTitle("Akhiri Disposisi Dokumen")
        setDefaultValues({
            archive_location_id: '',
        })
        setIsModalEndVisible(true)
    }

    const onFinishDisposition = (el) => {
        let payload = {
            ...el,
            letter_id: parseInt(id),
            type: 3,
            status: 1,
            level: incomingMailById.level,
            physical_file: 1,
            status_disposition: 1,
            created_by: user.id,
            updated_by: user.id
        }

        const params = {
            user_id: user.id,
            id: id
        }

        setIsSaving(false)
        setLoaded(false)
        ApiService.store('disposition-mail-response', payload, params).then((res) => {
            message.success("Sukses Mengirim Disposisi")
            setIsSaving(true)
            setIsModalDispositionVisible(false)
            fetchReceiveDisposition()
        }).catch((err) => {
            message.warning("Gagal Update Respon")
        })
    }

    const onFinishEndDisposition = (el) => {
        let payload = {
            ...el,
            updated_by: user.id
        }

        const params = {
            user_id: user.id,
        }

        setIsSaving(false)
        ApiService.update('disposition-mail-location', id, payload, params).then((res) => {
            message.success("Sukses Update Respon")
            setIsSaving(true)
            setIsModalEndVisible(false)
            fetchIncomingMailById()
            fetchReceiveDisposition()
        }).catch((err) => {
            message.warning("Gagal Update Respon")
        })
    }

    useEffect(() => {
        fetchIncomingMailById()
        fetchDepartment()
        fetchAttachmentFile()
        fetchArchiveLocation()
        fetchResponseDisposition()
        fetchActionDisposition()
        fetchReceiveDisposition()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const attachmentPage = (v) => {
        window.open(`${process.env.REACT_APP_FILE_URL}/${v}`)
    }

    const columns = [
        {
            title: 'Lampiran',
            render: (row) => (
                row?.filename.split('.')[1] === 'jpg' || row?.filename.split('.')[1] === 'png' || row?.filename.split('.')[1] === 'svg' || row?.filename.split('.')[1] === 'jpeg' ?
                    <>
                        <Row justify='center'>
                            <Image width={'30%'} src={row?.filename ? `${fileUrl}/${row?.filename}` : null} />
                        </Row>
                        <Row justify='center'>
                            <Text className='mb-2'>{row?.filename.split('/')[1]}</Text>
                        </Row>
                    </>
                    : row?.filename.split('.')[1] === 'pdf' ?
                        <>
                            <Row justify='center'>
                                <Pdf onClick={() => attachmentPage(row?.filename)} />
                            </Row>
                            <Row justify='center'>
                                <Text className='mb-2'>{row?.filename.split('/')[1]}</Text>
                            </Row>
                        </>
                        : row?.filename.split('.')[1] === 'doc' || row?.filename.split('.')[1] === 'docx' ?
                            <>
                                <Row justify='center'>
                                    <Docs onClick={() => attachmentPage(row?.filename)} />
                                </Row>
                                <Row justify='center'>
                                    <Text className='mb-2'>{row?.filename.split('/')[1]}</Text>
                                </Row>
                            </>
                            : row?.filename.split('.')[1] === 'xls' || row?.filename.split('.')[1] === 'xlsx' ?
                                <>
                                    <Row justify='center'>
                                        <Excel onClick={() => attachmentPage(row?.filename)} />
                                    </Row>
                                    <Row justify='center'>
                                        <Text className='mb-2'>{row?.filename.split('/')[1]}</Text>
                                    </Row>
                                </>
                                :
                                <>
                                    <Row justify='center'>
                                        <Button onClick={() => attachmentPage(row?.filename)} />
                                    </Row>
                                    <Row justify='center'>
                                        <Text className='mb-2'>{row?.filename.split('/')[1]}</Text>
                                    </Row>
                                </>
            ),
            key: 'attachment_file',
            align: 'center'
        }
    ];

    const columns2 = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '3%'
        },
        {
            title: 'Tanggal Dokumen',
            render: (row) => (
                moment(row?.date).locale('id').format('dddd, D MMMM YYYY')
            ),
            key: 'date',
            align: 'center'
        },
        {
            title: 'Pengirim',
            render: (row) => (
                row?.from_user
            ),
            key: 'letter_number',
            align: 'center'
        },
        {
            title: 'Intruksi',
            render: (row) => (
                row?.action_disposition
            ),
            key: 'subject',
            align: 'center'
        },
        {
            title: 'Informasi Tambahan',
            render: (row) => (
                row?.action_description
            ),
            key: 'subject',
            align: 'center'
        },
        {
            title: 'Diteruskan Ke',
            render: (row) => (
                row?.receiver_user
            ),
            key: 'to',
            align: 'center'
        },
        {
            title: 'Respon Penerima Disposisi',
            render: (row) => (
                row?.response_disposition ? row?.response_disposition : '-'
            ),
            key: 'subject',
            align: 'center'
        },
        {
            title: 'Keterangan',
            render: (row) => (
                row?.response_description ? row?.response_description : '-'
            ),
            key: 'subject',
            align: 'center'
        },
    ];

    // const exportReport = () => {
    //     window.open(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/incoming-mail-pdf/${id}`);
    // }

    const changeTab = (selected) => {
        sessionStorage.setItem('selectedDashboardTab', selected)
    }

    const LetterDetail = () => {
        return (
            <>
                <Title style={{ fontSize: 20, fontWeight: 700, marginBottom: 20 }}>Keterangan Dokumen Masuk</Title>
                <Row className='mb-52' justify='space-between'>
                    <Col span={15}>
                        {
                            loaded ?
                                <table className="table-collapse table-default" style={{ width: '100%' }}>
                                    <tbody>
                                        <tr>
                                            <th>Nomor Dokumen</th><td>{incomingMailById.letter_number}</td>
                                        </tr>
                                        <tr>
                                            <th>Subjek</th><td>{incomingMailById.subject}</td>
                                        </tr>
                                        <tr>
                                            <th>Tanggal Dokumen</th><td>{moment(incomingMailById.date).format('dddd, DD MMMM YYYY')}</td>
                                        </tr>
                                        <tr>
                                            <th>Pengirim</th><td>
                                                {
                                                    incomingMailById.from_user
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Departemen Pengirim</th><td>{incomingMailById.sender_department ?? '-'}</td>
                                        </tr>
                                        {
                                            incomingMailById.letter_option_id === 2 ?
                                                <tr>
                                                    <th>Dikirim Ke</th><td>{incomingMailById.receiver_eksternal}</td>
                                                </tr>
                                                : null
                                        }
                                        <tr>
                                            <th>Tipe Dokumen</th><td>{incomingMailById.letter_type}</td>
                                        </tr>
                                        <tr>
                                            <th>Opsi Dokumen</th><td>{incomingMailById.letter_option}</td>
                                        </tr>
                                        <tr>
                                            <th>Arsip Dokumen</th><td>{incomingMailById.archive_location ? incomingMailById.archive_location : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th>Body</th><td>{incomingMailById.body ? parse(incomingMailById.body) : '-'}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                : <Spinner />
                        }
                    </Col>
                    <Col span={8}>
                        {/* <Button onClick={exportReport} type='primary' size='large' shape='round' icon={<FilePdfOutlined />}>Download Dokumen</Button> */}
                    </Col>
                </Row>

                <Card title="Lampiran">
                    {
                        attachmentFile.length === 0 ?
                            <Card>
                                <Text>Tidak Ada Lampiran</Text>
                            </Card>
                            :
                            <DataTable size="small" columns={columns} sources={attachmentFile} bordered search={false} />
                    }
                </Card>
            </>
        )
    }

    return (
        <div>
            <PageHeader title={`Dokumen Masuk #${id}`} breadcrumbs={[['Dokumen Masuk'], ['Detail']]} onBack={() => history.push('/surat-masuk')} />

            <Card style={{ borderRadius: 10 }}>
                {
                    incomingMailById.type === 'DISP' || incomingMailById.type === '3' ?
                        <Tabs defaultActiveKey={defaultTab} onChange={changeTab}>
                            <TabPane tab="Dokumen Masuk" key="incoming">
                                <LetterDetail />
                            </TabPane>
                            <TabPane tab="Disposisi" key="disposition">
                                <Row className='mb-4' justify='space-between'>
                                    <Col>
                                        <Title style={{ fontSize: 22, fontWeight: 400 }}>Nomor Dokumen: {incomingMailById.letter_number}</Title>
                                    </Col>
                                    <Col>
                                        {
                                            incomingMailById.response_id === null || incomingMailById.response_id === '' ?
                                                <>
                                                    <Button size='middle' type='danger' style={{ borderRadius: 10, marginRight: 20 }} onClick={setEndDisposition}>Selesaikan Edaran</Button>
                                                    <Button size='middle' type='primary' style={{ borderRadius: 10 }} onClick={setDisposition}>Disposisikan</Button>
                                                </> : null
                                        }
                                    </Col>
                                </Row>
                                <Row className='mb-5'>
                                    {
                                        loaded &&
                                        <Card bodyStyle={{ border: '0.4px solid #efefef', borderRadius: 10, width: '100%' }}>
                                            <Descriptions labelStyle={{ fontWeight: 600 }} layout="vertical">
                                                <Descriptions.Item label="Tanggal Dokumen">{incomingMailById.date}</Descriptions.Item>
                                                <Descriptions.Item label="Asal Dokumen">{incomingMailById.from_user}</Descriptions.Item>
                                                <Descriptions.Item label="Lokasi Arsip Saat Ini">{incomingMailById.archive_location ? incomingMailById.archive_location : '-'}</Descriptions.Item>
                                                <Descriptions.Item label="Sifat Dokumen">
                                                    {incomingMailById.letter_type}
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Perihal">{incomingMailById.subject}</Descriptions.Item>
                                            </Descriptions>
                                        </Card>
                                    }
                                </Row>
                                <DataTable sources={listReceiveDisposition} columns={columns2} search={false} />
                            </TabPane>
                        </Tabs>
                        :
                        <LetterDetail />
                }
            </Card>

            {
                isModalDispositionVisible ?
                    <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => onFinishDisposition(v)} width={600} showModal={isModalDispositionVisible} onCloseModal={() => setIsModalDispositionVisible(false)} saving={isSaving}>
                        <Form.Item label="Respon Disposisi" name="response_id" rules={[{ required: true }]}>
                            <Select>
                                {
                                    responseDisposition.map((el, key) => (
                                        <Option value={el.id} key={key}>
                                            {el.name}
                                        </Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label="Keterangan" name="response_description" rules={[{ required: true }]}>
                            <TextArea placeholder="Keterangan" />
                        </Form.Item>
                        <Form.Item label="Department" name="receiver_department_id" rules={[{ required: true }]}>
                            <Select onChange={changeEmployee}>
                                {
                                    department.map((el, key) => (
                                        <Option value={el.id} key={key}>
                                            {el.name}
                                        </Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label="Disposisi Kepada" name="receiver_user_id" rules={[{ required: true }]}>
                            <Select>
                                {
                                    employee.map((el, key) => (
                                        <Option value={el.id} key={key}>
                                            {el.name}
                                        </Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label="Instruksi" name="action_id" rules={[{ required: true }]}>
                            <Select>
                                {
                                    actionDisposition.map((el, key) => (
                                        <Option value={el.id} key={key}>
                                            {el.name}
                                        </Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label="Informasi Tambahan" name="action_description" rules={[{ required: true }]}>
                            <TextArea placeholder="Informasi Tambahan" />
                        </Form.Item>
                    </FormModal>
                    : null
            }

            {
                isModalEndVisible ?
                    <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => onFinishEndDisposition(v)} width={600} showModal={isModalEndVisible} onCloseModal={() => setIsModalEndVisible(false)} saving={isSaving}>
                        <Form.Item label="Respon Disposisi" name="response_id" rules={[{ required: true }]}>
                            <Select>
                                {
                                    responseDisposition.map((el, key) => (
                                        <Option value={el.id} key={key}>
                                            {el.name}
                                        </Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label="Keterangan" name="response_description" rules={[{ required: true }]}>
                            <TextArea placeholder="Keterangan" />
                        </Form.Item>
                        <Form.Item label="Lokasi Arsip" name="archive_location_id" rules={[{ required: true }]}>
                            <Select>
                                {
                                    archiveLocation.map((el, key) => (
                                        <Option value={el.id} key={key}>
                                            {el.name}
                                        </Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </FormModal>
                    :
                    null
            }
        </div >
    )
}

export default PenerimaSuratMasukDetail